
import { createSlice } from '@reduxjs/toolkit';

const initialState = 0;

const categoriapadreSlice = createSlice({
  name: 'categoriaPadre',
  initialState,
  reducers: {
    setCategoriaPadre: (state, action) => action.payload,
  },
});

export const { setCategoriaPadre } = categoriapadreSlice.actions;
export default categoriapadreSlice.reducer;
