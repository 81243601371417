import { createSlice } from '@reduxjs/toolkit';

const initialState = 100;

const NumeroOrdenSlice = createSlice({
  name: 'numeroOrden',
  initialState,
  reducers: {
    setNumeroOrden: (state, action) => action.payload,
  },
});

export const { setNumeroOrden } = NumeroOrdenSlice.actions;
export default NumeroOrdenSlice.reducer;
