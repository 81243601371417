/**
 * Página Admin Banners:
 * 
 * acá se Administran los banner del Sitio Web
 * 
 * @module AdminBannersjsx
 */

import { useState, useEffect } from "react";
import axios from "axios";
import { AdminSidebar } from "../../components";
import { useNavigate } from "react-router-dom";
import Mensaje from "../../components/Modales/Mensaje";

const Banners = () => {
  const navigate = useNavigate();


  /**
* @constant
* @name bannersWeb
* @type {string}
* 
* */

  const [bannersWeb, setBannersWeb] = useState("");


  /**
* @constant
* @name bannersMovil
* @type {string}
* 
* */
  const [bannersMovil, setBannersMovil] = useState("");


  /**
* @constant
* @name addBanner
* @type {number}
* 
* */
  const [addBanner, setAddBanner] = useState(1);

  /**
* @constant
* @name webInput
* @type {string}
* 
* */
  const [webInput, setWebInput] = useState("");

  /**
* @constant
* @name mobileInput
* @type {string}
* 
* */
  const [mobileInput, setMobileInput] = useState("");

  /**
* @constant
* @name urlInput
* @type {string}
* 
* */
  const [urlInput, setUrlInput] = useState("");

  /**
* @constant
* @name msg
* @type {string}
* 
* */
  const [msg, setMsg] = useState("");

  /**
* @constant
* @name tipo
* @type {string}
* 
* */
  const [tipo, setTipo] = useState("");


  const accessToekn = sessionStorage.getItem("access");


  /**
* @constant
* @name banner
* @type {?string}
* 
* */
  const [banner, setBanner] = useState(null);

  /**
* @constant
* @name tipoUrl
* @type {?string}
* 
* */
  const [tipoUrl, setTipoUrl] = useState(null);

  /**
* @constant
* @name urlSeleccionada
* @type {number}
* 
* */
  const [urlSeleccionada, setUrlSeleccionada] = useState(2);

  /**
* @constant
* @name padres
* @type {?string}
* 
* */
  const [padres, setPadres] = useState(null);

  /**
* @constant
* @name hijas
* @type {?string}
* 
* */
  const [hijas, setHijas] = useState(null);

  /**
* @constant
* @name tipoAgregar
* @type {number}
* 
* */
  const [tipoAgregar, setTipoAgregar] = useState(1);

  /**
* @constant
* @name urlSeleccionadaCrear
* @type {number}
* 
* */
  const [urlSeleccionadaCrear, setUrlSeleccionadaCrear] = useState(1);

  /**
* @constant
* @name mensaje
* @type {string}
* 
* */
  const [mensaje, setMensaje] = useState("");


  useEffect(() => {

    /**
* @function
* Peticion HTTP que retorna todos los banners web y movil
*@returns {Array}
* 
*/


    axios
      .get(process.env.REACT_APP_BACK_URL + `/api/banners/get_all_banners`)
      .then((response) => {
        setBannersWeb(response.data.banners_web);
        setBannersMovil(response.data.banners_movil);
      })
      .catch((error) => {
        console.log(error);
      });



    /**
* @function
* Peticion HTTP que retorna todos los tipos de URL
*@returns {Array}
* 
*/

    axios
      .get(process.env.REACT_APP_BACK_URL + `/api/url/listar_tipourl`)
      .then((response) => {
        setTipoUrl(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    /**
* @function
* Peticion HTTP que retorna todas las categorias y los banners
*@returns {Array}
* 
*/
    axios
      .get(process.env.REACT_APP_BACK_URL + `/api/admin/categoria/get_all_categorias_and_banners`)
      .then((response) => {
        setPadres(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    /**
* @function
* Peticion HTTP que retorna todas las categorias hijas por padre
*@returns {Array}
* 
*/
    axios
      .get(process.env.REACT_APP_BACK_URL + `/api/admin/categoria/get_all_categoria_hija_by_padre/0`)
      .then((response) => {
        setHijas(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

  }, []);


  /**
* @function
* Metodo que setea useStates para editar el banner
*@param banner 
* 
*/
  const editarBanner = (banner) => {
    setBanner(banner);
    setAddBanner(3);
    setUrlSeleccionada(banner.tipo_url_banner);
    if (banner.tipo_banner == 0)
      setTipoAgregar(1);
    else
      setTipoAgregar(2);
  };


  /**
* @function
* Metodo que setea useState de URL seleccionada
*@param banner 
* 
*/
  const handleUrlSeleccionada = (e) => {
    setUrlSeleccionada(e.target.value);
  };

  /**
* @function
* Metodo que setea useState de URL seleccionada crear
*@param banner 
* 
*/
  const handleUrlSeleccionadaCrear = (e) => {
    setUrlSeleccionadaCrear(e.target.value);
  };

  /**
* @function
* Metodo para volver atras
*@param banner 
* 
*/
  const handleVolverAtras = () => {
    setAddBanner(1);
  };


  /**
* @function
* Petición HTTP para desactivar un banner
*@param {array} bannerPrincipal Datos de banner para ser desactivado
* 
*/
  const desactivarBanner = async (bannerPrincipal) => {
    const formData = new FormData();
    if (tipoAgregar == 1) {
      formData.append("id_web", bannerPrincipal.id);
      formData.append("estado_web", 0);
    }
    else if (tipoAgregar == 2) {
      formData.append("id_movil", bannerPrincipal.id);
      formData.append("estado_movil", 0);
    }

    setMensaje("Desactivando el banner-alert");
    const response = await axios.post(
      process.env.REACT_APP_BACK_URL + `/api/admin/banner/update_banner`, formData, {
      headers: {
        Authorization: `Bearer ${accessToekn}`,
        "Content-Type": "multipart/form-data",
      },
    }).then(response => {
      location.href = "/admin/banners";
      setMensaje("Banner desactivado-verde");
    }).catch(error => {
      console.log(error);
    })

  };

  /**
* @function
* Petición HTTP para actualizar el banner
*@param {array} bannerPrincipal Datos de banner para ser desactivado
* 
*/
  const guardarCambios = async (bannerPrincipal) => {
    let foto = document.querySelector("#foto-banner-principal");
    let typeUrl = document.querySelector("#tipo-url-banner");

    let original = "";
    if (typeUrl.value == 2) {
      let padre = document.querySelector("#categoria-padre");
      let hija = document.querySelector("#categoria-hija");
      original = padre.value + "-" + hija.value;
    }
    else if (typeUrl.value == 3) {
      let input = document.querySelector("#sku-producto-url");
      original = input.value;
    }
    else if (typeUrl.value == 4) {
      let input = document.querySelector("#busqueda-producto-url");
      original = input.value;
    }
    else if (typeUrl.value == 5) {
      let input = document.querySelector("#url-producto-url");
      original = input.value;
      if (!original.includes("http://") && !original.includes("https://"))
        original = "https://" + original;
    }

    const formData = new FormData();
    if (tipoAgregar == 1) {
      formData.append("id_web", bannerPrincipal.id);
      formData.append("estado_web", 1);
      if (foto.value != null && foto.value != "")
        formData.append("foto_web", foto.files[0]);
    }
    else if (tipoAgregar == 2) {
      formData.append("id_movil", bannerPrincipal.id);
      formData.append("estado_movil", 1);
      if (foto.value != null && foto.value != "")
        formData.append("foto_movil", foto.files[0]);
    }
    formData.append("original", original);
    formData.append("tipo_url_banner", typeUrl.value);

    if ((original != "" && typeUrl.value > 2) || (typeUrl.value == 1 || typeUrl.value == 2)) {
      setMensaje("Modificando banner-alert");
      const response = await axios.post(
        process.env.REACT_APP_BACK_URL + `/api/admin/banner/update_banner`, formData, {
        headers: {
          Authorization: `Bearer ${accessToekn}`,
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        location.href = "/admin/banners";
        setMensaje("Banner editado-verde");
      }).catch(error => {
        setMensaje("Error al modificar el banner-rojo");
      })
    }
    else
      setMensaje("Complete todos los campos-rojo");
  };

  /**
* @function
* Petición HTTP para crear Banner validando que la foto se halla ingresado
*@param {Object} formData FormData con los datos del banner a insertar
* 
*/
  const crearBanner = async () => {
    let foto = document.querySelector("#banner-imagen-crear");
    let typeUrl = document.querySelector("#tipo-url-banner-crear");

    if (foto.value != null && foto.value != "") {
      let original = "";
      if (typeUrl.value == 2) {
        let padre = document.querySelector("#categoria-padre-crear");
        let hija = document.querySelector("#categoria-hija-crear");
        original = padre.value + "-" + hija.value;
      }
      else if (typeUrl.value == 3) {
        let input = document.querySelector("#urlInput");
        original = input.value;
      }
      else if (typeUrl.value == 4) {
        let input = document.querySelector("#urlInput");
        original = input.value;
      }
      else if (typeUrl.value == 5) {
        let input = document.querySelector("#urlInput");
        original = input.value;
        if (!original.includes("http://") && !original.includes("https://"))
          original = "https://" + original;
      }

      const formData = new FormData();
      if (tipoAgregar == 1) {
        formData.append("foto_web_1", foto.files[0]);
      }
      else if (tipoAgregar == 2) {
        formData.append("foto_movil_1", foto.files[0]);
      }
      formData.append("original", original);
      formData.append("tipo_url_banner", typeUrl.value);

      if ((original != "" && typeUrl.value > 2) || (typeUrl.value == 1 || typeUrl.value == 2)) {
        setMensaje("Creando banner-alert");
        const response = await axios.post(
          process.env.REACT_APP_BACK_URL + `/api/admin/banner/register_banner`, formData, {
          headers: {
            Authorization: `Bearer ${accessToekn}`,
            "Content-Type": "multipart/form-data",
          },
        }).then(response => {
          location.href = "/admin/banners";
          setMensaje("Banner creado-verde");
        }).catch(error => {
          setMensaje("Error al crear el banner-rojo");
        })
      }
      else
        setMensaje("Complete todos los campos-rojo");
    }
    else
      setMensaje("Complete todos los campos-rojo");
  };

  const cambiaHija = () => {
    let padre = document.querySelector("#categoria-padre");
    let hija = document.querySelector("#categoria-hija");
    let row = "";

    hijas.forEach(element => {
      if (padre.value == element.categoria_padre_id)
        row += `<option value=${element.id} key=${element.id}>${element.nombre_mostrar}</option>`;
    });

    hija.innerHTML = row;
  };

  const cambiaHijaCrear = () => {
    let padre = document.querySelector("#categoria-padre-crear");
    let hija = document.querySelector("#categoria-hija-crear");
    let row = "";

    hijas.forEach(element => {
      if (padre.value == element.categoria_padre_id)
        row += `<option value=${element.id} key=${element.id}>${element.nombre_mostrar}</option>`;
    });

    hija.innerHTML = row;
  };

  /**
* @function
* Metodo que muestra el preview del banner a subir
*/
  const cambiaImagen = () => {
    let img = document.querySelector("#imagen-banner-editar");
    let file = document.querySelector("#foto-banner-principal");
    if (file.value != null && file.value != "")
      img.src = URL.createObjectURL(file.files[0]);
  };

  /**
* @function
* Metodo que muestra el preview del banner a subir
*/
  const cambiaImagenCrear = () => {
    let img = document.querySelector("#imagen-banner-crear");
    let file = document.querySelector("#banner-imagen-crear");
    if (file.value != null && file.value != "")
      img.src = URL.createObjectURL(file.files[0]);
  };

  /**
* @function
* Petición HTTP que muestra la categoria según padre e hija
* @param padre Categoria padre
* @param hija Categoria hija
*/
  const verCategoria = () => {
    let padre = document.querySelector("#categoria-padre").value;
    let hija = document.querySelector("#categoria-hija").value;

    axios
      .get(process.env.REACT_APP_BACK_URL + `/api/categoria/get_categorias_by_id?padre=${padre}&hija=${hija}`)
      .then((response) => {
        window.open(process.env.REACT_APP_FRONT_URL + `/${response.data.padre}/${response.data.hija}`);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /**
* @function
* Petición HTTP que muestra si las palabras claves ingresadas funcionan
*/
  const verBusqueda = () => {
    let busqueda = document.querySelector("#busqueda-producto-url");
    window.open(process.env.REACT_APP_FRONT_URL + "/search?q=" + busqueda.value);
  };

  /**
* @function
* Petición HTTP que muestra si el SKU ingreado en el input es del producto que desea agregar al banner
*/
  const verProducto = () => {
    let sku = document.querySelector("#sku-producto-url").value;
    axios
      .get(process.env.REACT_APP_BACK_URL + `/api/admin/producto/get_producto_by_sku/${sku.toUpperCase()}`)
      .then((response) => {
        window.open(process.env.REACT_APP_FRONT_URL + "/" + response.data[0].slug);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /**
* @function
* Petición HTTP que muestra si la URL personalizada esta funcionando
*/
  const verUrlPersonalizada = () => {
    let url = document.querySelector("#url-producto-url").value;
    if (!url.includes("http://") && !url.includes("https://"))
      url = "https://" + url;
    window.open(url);
  };

  const verURL = () => {
    let tipoUrl = document.querySelector("#tipo-url-banner-crear").value
    if (tipoUrl == 2) {
      let padre = document.querySelector("#categoria-padre-crear").value;
      let hija = document.querySelector("#categoria-hija-crear").value;

      axios
        .get(process.env.REACT_APP_BACK_URL + `/api/categoria/get_categorias_by_id?padre=${padre}&hija=${hija}`)
        .then((response) => {
          window.open(process.env.REACT_APP_FRONT_URL + `/${response.data.padre}/${response.data.hija}`);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    else if (tipoUrl == 3) {
      let sku = document.querySelector("#urlInput").value;
      axios
        .get(process.env.REACT_APP_BACK_URL + `/api/admin/producto/get_producto_by_sku/${sku.toUpperCase()}`)
        .then((response) => {
          window.open(process.env.REACT_APP_FRONT_URL + "/" + response.data[0].slug);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    else if (tipoUrl == 4) {
      let busqueda = document.querySelector("#urlInput");
      window.open(process.env.REACT_APP_FRONT_URL + "/search?q=" + busqueda.value);
    }
    else if (tipoUrl == 5) {
      let url = document.querySelector("#urlInput").value;
      if (!url.includes("http://") && !url.includes("https://"))
        url = "https://" + url;
      window.open(url);
    }
  };

  return (
    <div>
      {" "}
      <AdminSidebar />
      <Mensaje mensaje={mensaje} setMensaje={setMensaje} />
      <div className="regular">
        <h1>
          Dreamtec - <span>Administrador de banners</span>
        </h1>

        <div className="shadow">
          {addBanner == 1 && (
            <div className="row">
              <div className="col-12">
                <div className="row justify-content-end">
                  <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12 mb-3">
                    <button
                      aria-label="Agregar nuevo banner"
                      onClick={() => {
                        setAddBanner(2);
                      }}
                      className="agregar"
                    >
                      <i className="fa-solid fa-circle-plus"></i> &nbsp;Agregar
                      nuevo banner
                    </button>
                  </div>
                </div>
              </div>

              <div className="col-12 mb-5">
                <div className="row">
                  <div className="col-12 text-start mb-3">
                    <h2>Banners formato web</h2>
                  </div>
                  <div className="col-12">
                    {bannersWeb.length > 0 ? (
                      <div className="row">
                        {bannersWeb.map((banner, index) => (
                          <div
                            className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mb-3 text-center d-flex align-self-center"
                            key={index}
                          >
                            <img
                              key={banner.id}
                              role="button"
                              src={process.env.REACT_APP_FRONT_URL + `${banner?.foto}`}
                              alt={`banner ${banner.id}`}
                              className="img-fluid"
                              onClick={() => editarBanner(banner)}
                              loading="lazy"
                            />
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="row my-5">
                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 fw-bold text-center">
                          <i className="fa-solid fa-circle-info"></i>&nbsp;No
                          hay banners formato web registrados
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-12">
                <div className="row">
                  <div className="col-12 text-start mb-3">
                    <h2>Banners formato movil</h2>
                  </div>
                  <div className="col-12">
                    {bannersMovil.length > 0 ? (
                      <div className="row">
                        {bannersMovil.map((banner, index) => (
                          <div
                            className="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mb-3 text-center d-flex align-self-center"
                            key={index}
                          >
                            <img
                              key={banner.id}
                              role="button"
                              src={process.env.REACT_APP_FRONT_URL + `${banner?.foto}`}
                              alt={`banner ${banner.id}`}
                              className="img-fluid"
                              onClick={() => editarBanner(banner)}
                              loading="lazy"
                            />
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="row my-5">
                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 fw-bold text-center">
                          <i className="fa-solid fa-circle-info"></i>&nbsp;No
                          hay banners formato movil registrados
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          {addBanner == 2 && (
            <div className="row">
              <div className="col-12 text-start mb-3">
                <h2>Agregar banner Vista {tipoAgregar == 1 ? "Escritorio" : "Móvil"}</h2>
                {tipoAgregar == 1 ? <p style={{ fontSize: "12px", fontWeight: "bold" }}>Optimizado para imágenes de 1920x559</p> : <p style={{ fontSize: "12px", fontWeight: "bold" }}>Optimizado para imágenes de 860x720</p>}
              </div>

              <div className="row">
                <div className="col-md-12">
                  <input type="radio" name="tipoAgregar" checked={tipoAgregar === 1} onClick={() => setTipoAgregar(1)} /> Banner Escritorio &nbsp;
                  <input type="radio" name="tipoAgregar" checked={tipoAgregar === 2} onClick={() => setTipoAgregar(2)} className="mb-1" /> Banner Móvil &nbsp;
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                  <br />
                  <label htmlFor="" className="mb-1">Tipo de URL para el banner</label>
                  <select defaultValue={urlSeleccionadaCrear} id="tipo-url-banner-crear" onChange={handleUrlSeleccionadaCrear} className="form-control mb-3 rounded-pill">
                    {tipoUrl && (
                      tipoUrl.map((tipo) => (
                        <option value={tipo.id} key={tipo.id}>{tipo.tipo_url_banner}</option>
                      ))
                    )}
                  </select>

                  {urlSeleccionadaCrear == 2 && (
                    <>
                      <label htmlFor="" className="mb-1">Categoría</label>
                      <select id="categoria-padre-crear" defaultValue={1} onChange={cambiaHijaCrear} className="form-control rounded-pill mb-2">
                        {
                          padres.map((padre) => (
                            <option value={padre.id} key={padre.id}>{padre.nombre_mostrar}</option>
                          ))
                        }
                      </select>
                      <label htmlFor="" className="mb-1">Sub Categoría</label>
                      <select defaultValue={1} className='form-control rounded-pill mb-2' id='categoria-hija-crear'>
                        {
                          hijas?.map((hija) => {
                            if (1 == hija.categoria_padre_id)
                              return <option value={hija.id} key={hija.id}>{hija.nombre_mostrar}</option>
                          })
                        }
                      </select>

                      <button
                        aria-label="Verificar categoria"
                        className="btnCelesteDreamtec mt-3 px-3 col-md-6 col-xs-12 w-100"
                        type="submit"
                        onClick={verURL}
                      >
                        Verificar categoría
                      </button>
                    </>
                  )}

                  {urlSeleccionadaCrear == 3 && (
                    <>
                      <label className="col-md-12 mt-3">
                        Ingrese el SKU del producto: &nbsp;
                        <span className="text-danger">*</span>
                      </label>

                      <input
                        className="form-control brDream"
                        type="text"
                        id="urlInput"
                        required
                      />
                      <button
                        aria-label="Verificar categoria"
                        className="btnCelesteDreamtec mt-3 px-3 col-md-6 col-xs-12 w-100"
                        type="submit"
                        onClick={verURL}
                      >
                        Verificar producto
                      </button>
                    </>
                  )}

                  {urlSeleccionadaCrear == 4 && (
                    <>
                      <label className="col-md-12 mt-3">
                        Ingrese las palabras de búsqueda: &nbsp;
                        <span className="text-danger">*</span>
                      </label>

                      <input
                        className="form-control brDream"
                        type="text"
                        id="urlInput"
                        required
                      />
                      <button
                        aria-label="Verificar categoria"
                        className="btnCelesteDreamtec mt-3 px-3 col-md-6 col-xs-12 w-100"
                        type="submit"
                        onClick={verURL}
                      >
                        Verificar palabras de búsqueda
                      </button>
                    </>
                  )}

                  {urlSeleccionadaCrear == 5 && (
                    <>
                      <label className="col-md-12 mt-3">
                        Ingrese la URL completa del banner: &nbsp;
                        <span className="text-danger">*</span>
                      </label>

                      <input
                        className="form-control brDream"
                        type="text"
                        id="urlInput"
                        required
                      />
                      <button
                        aria-label="Verificar categoria"
                        className="btnCelesteDreamtec mt-3 px-3 col-md-6 col-xs-12 w-100"
                        type="submit"
                        onClick={verURL}
                      >
                        Verificar url personalizada
                      </button>
                    </>
                  )}

                </div>

                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                  <br />
                  <label htmlFor="" className="mb-1">
                    Imagen del banner &nbsp;
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control col-md-6 col-xs-12 mb-3"
                    type="file"
                    accept="image/*"
                    onChange={cambiaImagenCrear}
                    id="banner-imagen-crear"
                  />
                  <img id="imagen-banner-crear" className="img-fluid" src="" alt="" loading="lazy" />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                  <button
                    aria-label="Agregar producto"
                    className="btnGreen cancel w-100"
                    type="submit"
                    onClick={() => {
                      setAddBanner(1);
                    }}
                  >
                    Volver atrás
                  </button>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                  <button
                    aria-label="Agregar banner"
                    className="btnGreen bg-primary w-100 text-white px-3 fw-bold"
                    type="submit"
                    onClick={crearBanner}
                  >
                    Agregar banner
                  </button>
                </div>
              </div>


              <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 my-3 text-center">
                <span
                  className={
                    tipo == 1 ? "text-success fw-bold" : "text-danger fw-bold"
                  }
                >
                  {msg}
                </span>
              </div>
            </div>
          )}
          {addBanner == 3 && (
            <>
              <div className="col-12 text-start mb-4">
                <h2>Modificar banner </h2>
                {banner.tipo_banner == 0 ? <p style={{ fontSize: "12px", fontWeight: "bold" }}>Optimizado para imágenes de 1920x559</p> : <p style={{ fontSize: "12px", fontWeight: "bold" }}>Optimizado para imágenes de 860x720</p>}

              </div>
              <div className="row">
                <div className="col-md-4">
                  <label htmlFor="" className="mb-1">Tipo de URL para el banner</label> <br />
                  <select defaultValue={banner.tipo_url_banner} id="tipo-url-banner" onChange={handleUrlSeleccionada} className="form-control mb-3 rounded-pill">
                    {tipoUrl && (
                      tipoUrl.map((tipo) => (
                        <option value={tipo.id} key={tipo.id}>{tipo.tipo_url_banner}</option>
                      ))
                    )}
                  </select>
                  {urlSeleccionada == 2 && (
                    <>
                      <label htmlFor="" className="mb-1">Categoría</label>
                      {banner.tipo_url_banner == 2 ? (
                        <>
                          <select id="categoria-padre" defaultValue={banner.original.split("-")[0]} onChange={cambiaHija} className="form-control rounded-pill mb-2">
                            {
                              padres.map((padre) => (
                                <option value={padre.id} key={padre.id}>{padre.nombre_mostrar}</option>
                              ))
                            }
                          </select>
                          <label htmlFor="" className="mb-1">Sub Categoría</label>
                          <select defaultValue={banner.original.split("-")[1]} className='form-control rounded-pill mb-2' id='categoria-hija'>
                            {
                              hijas?.map((hija) => {
                                if (banner.original.split("-")[0] == hija.categoria_padre_id)
                                  return <option value={hija.id} key={hija.id}>{hija.nombre_mostrar}</option>
                              })
                            }
                          </select>
                        </>
                      ) : (
                        <>
                          <select id="categoria-padre" className="form-control rounded-pill mb-2" onChange={cambiaHija}>
                            {
                              padres.map((padre) => (
                                <option value={padre.id} key={padre.id}>{padre.nombre_mostrar}</option>
                              ))
                            }
                          </select>
                          <label htmlFor="" className="mb-1">Sub Categoría</label>
                          <select className='form-control rounded-pill mb-2' id='categoria-hija'>
                            {
                              hijas?.map((hija) => {
                                if (1 == hija.categoria_padre_id)
                                  return <option value={hija.id} key={hija.id}>{hija.nombre_mostrar}</option>
                              })
                            }
                          </select>

                        </>
                      )}
                      <button
                        aria-label="Verificar categoria"
                        className="btnCelesteDreamtec mt-3 px-3 col-md-6 col-xs-12 w-100"
                        type="submit"
                        onClick={verCategoria}
                      >
                        Verificar categoría
                      </button>
                    </>
                  )}
                  {urlSeleccionada == 3 && (
                    <>
                      <label htmlFor="" className="mb-1">Ingrese el SKU del producto</label>
                      {banner.tipo_url_banner == 3 ? (
                        <input type="text" id="sku-producto-url" defaultValue={banner.original} className="form-control mb-2" />
                      ) : (
                        <input type="text" id="sku-producto-url" className="form-control mb-2" />
                      )}

                      <button
                        aria-label="Verificar producto"
                        className="btnCelesteDreamtec mt-3 px-3 col-md-6 col-xs-12 w-100"
                        type="submit"
                        onClick={verProducto}
                      >
                        Verificar producto
                      </button>
                    </>
                  )}
                  {urlSeleccionada == 4 && (
                    <>
                      <label htmlFor="" className="mb-1">Ingrese las palabras de búsqueda</label>
                      {banner.tipo_url_banner == 4 ? (
                        <input type="text" id="busqueda-producto-url" defaultValue={banner.original} className="form-control mb-2" />
                      ) : (
                        <input type="text" id="busqueda-producto-url" className="form-control mb-2" />
                      )}

                      <button
                        aria-label="Verificar producto"
                        className="btnCelesteDreamtec mt-3 px-3 col-md-6 col-xs-12 w-100"
                        type="submit"
                        onClick={verBusqueda}
                      >
                        Verificar palabras de búsqueda
                      </button>
                    </>
                  )}
                  {urlSeleccionada == 5 && (
                    <>
                      <label htmlFor="" className="mb-1">Ingrese la URL completa del banner</label>
                      {banner.tipo_url_banner == 5 ? (
                        <input type="text" id="url-producto-url" defaultValue={banner.original} className="form-control mb-2" />
                      ) : (
                        <input type="text" id="url-producto-url" className="form-control mb-2" />
                      )}
                      <button
                        aria-label="Verificar producto"
                        className="btnCelesteDreamtec mt-3 px-3 col-md-6 col-xs-12 w-100"
                        type="submit"
                        onClick={verUrlPersonalizada}
                      >
                        Verificar url personalizada
                      </button>
                    </>
                  )}


                </div>

                <div className="col-md-4">
                  <label htmlFor="" className="mb-1">Imagen del banner</label>
                  <input type="file" id="foto-banner-principal" className="form-control mb-3" onChange={cambiaImagen} />
                  <img src={process.env.REACT_APP_FRONT_URL + banner.foto} className="img-fluid mb-3" loading="lazy" id="imagen-banner-editar" />
                </div>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <button aria-label="volver atrás"
                    onClick={handleVolverAtras}
                    className="btnGreen bg-primary text-white px-3 mt-3 fw-bold cancel col-12"
                    type="submit">Volver Atrás
                  </button>
                </div>

                <div className="col-md-4">
                  <button
                    aria-label="Guardar cambios"
                    className="btnGreen mt-3 px-3 col-md-6 col-xs-12 w-100"
                    type="submit"
                    onClick={() => guardarCambios(banner)}
                  >
                    Guardar los Cambios del banner
                  </button>
                </div>

                <div className="col-md-4">
                  <button
                    aria-label="Desactivar banner"
                    className="btnRed mt-3 mb-1 px-3 col-md-6 col-xs-12 w-100"
                    type="submit"
                    onClick={() => desactivarBanner(banner)}
                  >
                    Desactivar Este Banner
                  </button>
                </div>
              </div>

            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Banners;
