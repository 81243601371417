
import { createSlice } from '@reduxjs/toolkit';

const initialState = 0;

const addToCartSlice = createSlice({
  name: 'addToCart',
  initialState,
  reducers: {
    setAddToCart: (state, action) => action.payload,
  },
});

export const { setAddToCart } = addToCartSlice.actions;
export default addToCartSlice.reducer;
