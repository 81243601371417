/**
 * Componente SwiperCategorias:
 * 
 * acá se el carrousel de categorias
 * 
 * @module ComponenteSwiperCategoriasjsx
 */


import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setBooleanCategoria } from "../../redux/esCategoriaSlice";
//import { setCategoriaPadre } from "../../redux/categoriapadreSlice";

import { useNavigate } from "react-router-dom";
import "./swipercategorias.css";

const SwiperCategorias = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  /**
* @constant
* @name gamerZoneHovered
* @type {boolean}
* 
* */
  const [gamerZoneHovered, setGamerZoneHovered] = useState(false);

  /**
* @constant
* @name homeOfficeHovered
* @type {boolean}
* 
* */
  const [homeOfficeHovered, setHomeOfficeHovered] = useState(false);


  /**
* @constant
* @name smartHomeHovered
* @type {boolean}
* 
* */
  const [smartHomeHovered, setSmartHomeHovered] = useState(false);

  /**
* @constant
* @name gamerZoneVisible
* @type {boolean}
* 
* */
  const [gamerZoneVisible, setGamerZoneVisible] = useState(false);


  const [gamerZoneStyle, setGamerZoneStyle] = useState({
    display: "none",
    backgroundColor: "",
  });

  const toggleGamerZone = () => {
    setGamerZoneVisible(!gamerZoneVisible);
    setSmartHomeVisible(false);
    setHomeOfficeVisible(false);
    setGamerZoneStyle({
      display: gamerZoneVisible ? "none" : "block",
      backgroundColor: gamerZoneVisible ? "" : "white",
    });
    setSmartHomeStyle({
      display: "none",
    });
    setHomeOfficeStyle({
      display: "none",
    });
  };

  /**
* @constant
* @name homeOfficeVisible
* @type {boolean}
* 
* */
  const [homeOfficeVisible, setHomeOfficeVisible] = useState(false);

  const [homeOfficeStyle, setHomeOfficeStyle] = useState({
    display: "none",
    backgroundColor: "",
  });

  const toggleHomeOffice = () => {
    setHomeOfficeVisible(!homeOfficeVisible);
    setGamerZoneVisible(false);
    setSmartHomeVisible(false);
    setHomeOfficeStyle({
      display: homeOfficeVisible ? "none" : "block",
      backgroundColor: homeOfficeVisible ? "" : "white",
    });

    setGamerZoneStyle({
      display: "none",
    });
    setSmartHomeStyle({
      display: "none",
    });
  };


  /**
* @constant
* @name smartHomeVisible
* @type {boolean}
* 
* */
  const [smartHomeVisible, setSmartHomeVisible] = useState(false);

  const [smartHomeStyle, setSmartHomeStyle] = useState({
    display: "none",
    backgroundColor: "",
  });

  const toggleSmartHome = () => {
    setSmartHomeVisible(!smartHomeVisible);
    setGamerZoneVisible(false);
    setHomeOfficeVisible(false);

    setSmartHomeStyle({
      display: smartHomeVisible ? "none" : "block",
      backgroundColor: smartHomeVisible ? "" : "white",
    });

    setGamerZoneStyle({
      display: "none",
    });
    setHomeOfficeStyle({
      display: "none",
    });
  };


  return (
    <div>
      <div className="contenedor-cat-movil px-0 py-2 bg-black w-100">
        <div className="row justify-content-center">
          <div className="col-lg-12 col-md-12 col-12 px-0">
            <div className="carrusel-cats">

              {/* Gamer Zone */}
              <div className="carousel-cell carousel-cell-subcat-movil mx-3 text-center align-self-center"
                onMouseOver={() => setGamerZoneHovered(true)}
                onMouseOut={() => setGamerZoneHovered(false)}
                onClick={toggleGamerZone}
              >
                <a className="link-menu-subcat link-menu-subcat-movil text-white text-decoration-none"
                  onClick={() => {
                    location.href = "/gamer-zone"
                  }}>
                  <img
                    src={gamerZoneHovered ? process.env.REACT_APP_FRONT_URL + "/uploads/assets/categorias/imagen-cat-gamer-zone.png" : process.env.REACT_APP_FRONT_URL + "/uploads/assets/categorias/imagen-cat-gamer-zone-b.png"}
                    alt="Logo Gamer Zone"
                    title="Logo Gamer Zone"
                    className="icono-menu-subcat icono-menu-subcat-movil"
                    height={40}
                    loading="lazy"
                  />
                  <p className="small mb-0">Gamer Zone</p>
                </a>
              </div>

              {/* Home Office */}
              <div
                className="carousel-cell carousel-cell-subcat-movil mx-3 text-center align-self-center"
                onMouseOver={() => setHomeOfficeHovered(true)}
                onMouseOut={() => setHomeOfficeHovered(false)}
                onClick={toggleHomeOffice}
              >
                <a className="link-menu-subcat link-menu-subcat-movil text-white text-decoration-none" onClick={() => {
                  location.href = "/home-office"
                }}>
                  <img
                    src={homeOfficeHovered ? process.env.REACT_APP_FRONT_URL + "/uploads/assets/categorias/imagen-cat-home-office.png" : process.env.REACT_APP_FRONT_URL + "/uploads/assets/categorias/imagen-cat-home-office-b.png"}
                    alt="Logo Home Office"
                    title="Logo Home Office"
                    height={40}
                    loading="lazy"
                  />
                  <p className="small mb-0">Home Office</p>
                </a>
              </div>

              {/* Smart Home */}
              <div
                className="carousel-cell carousel-cell-subcat-movil mx-3 text-center align-self-center"
                onMouseOver={() => setSmartHomeHovered(true)}
                onMouseOut={() => setSmartHomeHovered(false)}
                onClick={toggleSmartHome}
              >
                <a className="link-menu-subcat link-menu-subcat-movil text-white text-decoration-none" onClick={() => {
                  location.href = "/smart-home"
                }}>
                  <img
                    src={smartHomeHovered ? process.env.REACT_APP_FRONT_URL + "/uploads/assets/categorias/imagen-cat-smart-home.png" : process.env.REACT_APP_FRONT_URL + "/uploads/assets/categorias/imagen-cat-smart-home-b.png"}
                    alt="Logo Smart Home"
                    title="Logo Smart Home"
                    height={40}
                    loading="lazy"
                  />
                  <p className="small mb-0">Smart Home</p>
                </a>
              </div>

              {/* Industrial */}
              <div
                className="carousel-cell carousel-cell-subcat-movil mx-3 text-center align-self-center"
              >
                <a className="link-menu-subcat link-menu-subcat-movil text-white text-decoration-none" onClick={() => {
                  location.href = "/industrial"
                }}>
                  <img
                    src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/industrial/industrial-nav.png"}
                    alt="Logo Industrial"
                    title="Logo Industrial"
                    height={40}
                    loading="lazy"
                  />
                  <p className="small mb-0">Industrial</p>
                </a>
              </div>

              {/* Categoria ESPECIAL */}
              <div className="carousel-cell carousel-cell-subcat-movil mx-3 text-center align-self-center">
                <a
                  onClick={() => {
                    location.href = "/gamer-zone/segunda-seleccion";
                    dispatch(setBooleanCategoria("7"));
                  }}
                  className="link-menu-subcat link-menu-subcat-movil text-white text-decoration-none"
                >
                  <img
                    loading="lazy"
                    src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/categorias/open-box.png"}
                    className="icono-menu-subcat icono-menu-subcat-movil"
                    alt="LG Week"
                    title="LG Week"
                    height={40}
                  />
                  <br />
                  Open Box
                </a>
              </div>

              {/* Simuladores */}
              <div className="carousel-cell carousel-cell-subcat-movil mx-3 text-center align-self-center">
                <a
                  onClick={() => {
                    location.href = "/gamer-zone/simuladores";//simuladores
                    dispatch(setBooleanCategoria("1"));
                  }}
                  className="link-menu-subcat link-menu-subcat-movil text-white text-decoration-none"
                >
                  <img
                    loading="lazy"
                    src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/categorias/icono-header-subcat-1.png"}
                    className="icono-menu-subcat icono-menu-subcat-movil"
                    alt="Icono sillas gamer"
                    title="Icono sillas gamer"
                    height={40}
                  />
                  <br />
                  Simuladores
                </a>
              </div>

              {/* Monitores */}
              <div className="carousel-cell carousel-cell-subcat-movil mx-3 text-center align-self-center ">
                <a
                  onClick={() => {
                    location.href = "/gamer-zone/monitores";
                    dispatch(setBooleanCategoria("2"));
                  }}
                  className="link-menu-subcat link-menu-subcat-movil text-white text-decoration-none"
                >
                  <img
                    loading="lazy"
                    src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/categorias/icono-header-subcat-2.png"}
                    className="icono-menu-subcat icono-menu-subcat-movil"
                    alt="Icono monitores"
                    title="Icono monitores"
                    height={40}
                  />
                  <br />
                  Monitores
                </a>
              </div>

              {/* Impresoras */}
              <div className="carousel-cell carousel-cell-subcat-movil mx-3 text-center align-self-center">
                <a
                  onClick={() => {
                    location.href = "/home-office/impresoras";
                    dispatch(setBooleanCategoria("3"));
                  }}
                  className="link-menu-subcat link-menu-subcat-movil text-white text-decoration-none"
                >
                  <img
                    loading="lazy"
                    src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/categorias/icono-header-subcat-6.png"}
                    className="icono-menu-subcat icono-menu-subcat-movil"
                    alt="Icono impresoras"
                    title="Icono impresoras"
                    height={40}
                  />
                  <br />
                  Impresoras
                </a>
              </div>

              {/* Tablets */}
              <div className="carousel-cell carousel-cell-subcat-movil mx-3 text-center align-self-center">
                <a
                  onClick={() => {
                    location.href = "/smart-home/plotter";
                    dispatch(setBooleanCategoria("3"));
                  }}
                  className="link-menu-subcat link-menu-subcat-movil text-white text-decoration-none"
                >
                  <img
                    loading="lazy"
                    src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/categorias/icono-header-subcat-10.png"}
                    className="icono-menu-subcat icono-menu-subcat-movil"
                    alt="Icono tablets"
                    title="Icono tablets"
                    height={40}
                  />
                  <br />
                  Plotter
                </a>
              </div>

              {/* Notebooks */}
              <div className="carousel-cell carousel-cell-subcat-movil mx-3 text-center align-self-center">
                <a
                  onClick={() => {
                    location.href = "/gamer-zone/notebooks";
                    dispatch(setBooleanCategoria("5"));
                  }}
                  className="link-menu-subcat link-menu-subcat-movil text-white text-decoration-none"
                >
                  <img
                    loading="lazy"
                    src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/categorias/icono-header-subcat-3.png"}
                    className="icono-menu-subcat icono-menu-subcat-movil"
                    alt="Icono notebooks"
                    title="Icono notebooks"
                    height={40}
                  />
                  <br />
                  Notebooks
                </a>
              </div>

              {/* Computadores */}
              {/* <div className="carousel-cell carousel-cell-subcat-movil mx-3 text-center align-self-center">
                <a
                  onClick={() => {
                    nlocation.href = "/home-office/computadores";
                    dispatch(setBooleanCategoria("6"));
                  }}
                  className="link-menu-subcat link-menu-subcat-movil text-white text-decoration-none"
                >
                  <img
                    loading="lazy"
                    src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/categorias/icono-header-subcat-4.png"}
                    className="icono-menu-subcat icono-menu-subcat-movil"
                    alt="Icono computadores"
                    title="Icono computadores"
                    height={40}
                  />
                  <br />
                  Computadores
                </a>
              </div> */}

              {/* Escaners */}
              {/* <div className="carousel-cell carousel-cell-subcat-movil mx-3 text-center align-self-center">
                <a
                  onClick={() => {
                    location.href = "/home-office/escaner";
                    dispatch(setBooleanCategoria("7"));
                  }}
                  className="link-menu-subcat link-menu-subcat-movil text-white text-decoration-none"
                >
                  <img
                    loading="lazy"
                    src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/categorias/icono-header-subcat-99.png"}
                    className="icono-menu-subcat icono-menu-subcat-movil"
                    alt="Icono escaner"
                    title="Icono escaner"
                    height={40}
                  />
                  <br />
                  Escaners
                </a>
              </div> */}

              {/* Smart TV */}
              <div className="carousel-cell carousel-cell-subcat-movil mx-3 text-center align-self-center">
                <a
                  onClick={() => {
                    location.href = "/smart-home/smart-tv";
                    dispatch(setBooleanCategoria("8"));
                  }}
                  className="link-menu-subcat link-menu-subcat-movil text-white text-decoration-none"
                >
                  <img
                    loading="lazy"
                    src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/categorias/icono-header-subcat-9.png"}
                    className="icono-menu-subcat icono-menu-subcat-movil"
                    alt="Icono smart-tv"
                    title="Icono smart-tv"
                    height={40}
                  />
                  <br />
                  Smart TV
                </a>
              </div>

              {/* Accesorios */}
              <div className="carousel-cell carousel-cell-subcat-movil mx-3 text-center align-self-center">
                <a
                  onClick={() => {
                    location.href = "/gamer-zone/accesorios";
                    dispatch(setBooleanCategoria("9"));
                  }}
                  className="link-menu-subcat link-menu-subcat-movil text-white text-decoration-none"
                >
                  <img
                    loading="lazy"
                    src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/categorias/icono-header-subcat-8.png"}
                    className="icono-menu-subcat icono-menu-subcat-movil"
                    alt="Icono accesorios"
                    title="Icono accesorios"
                    height={40}
                  />
                  <br />
                  Accesorios
                </a>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SwiperCategorias;
