import FormularioIndustrial from "../components/Industrial/formulario";
import FooterIndustrial from "../components/FooterIndustrial/footer";

const ContactoIndustrial = () => {
  return (
    <>
      <FormularioIndustrial></FormularioIndustrial>

      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1664.6224473853745!2d-70.6154051!3d-33.4429257!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662cf83d29dd5cb%3A0x4ba1c55e12639917!2sManuel%20Montt%201667%2C%207501225%20Santiago%2C%20Providencia%2C%20Regi%C3%B3n%20Metropolitana!5e0!3m2!1ses-419!2scl!4v1722997075609!5m2!1ses-419!2scl"
        width="100%"
        height="450"
        style={{border: 0}}
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>

      <FooterIndustrial></FooterIndustrial>
    </>
  );
};

export default ContactoIndustrial;
