/**
 * Componente ModalEditarDireccion:
 * 
 * acá se muestra el modal para editar la dirección
 * 
 * @module ComponenteModalEditarDireccionjsx
 */


import { useState, useEffect } from "react";
import axios from "axios";

import { setTipoEnvio } from "../../redux/tipoEnvioSlice";
import { useSelector, useDispatch } from "react-redux";

const ModalEditarDireccion = (direccion) => {

  /**
* @constant
* @name selectedRegion
* @type {string}
* 
* */

  const [selectedRegion, setSelectedRegion] = useState("");

  /**
* @constant
* @name selectedComuna
* @type {string}
* 
* */

  const [selectedComuna, setSelectedComuna] = useState("");


  /**
* @constant
* @name regiones
* @type {Array}
* 
* */
  const [regiones, setRegiones] = useState([]);

  /**
* @constant
* @name comunas
* @type {Array}
* 
* */
  const [comunas, setComunas] = useState([]);

  /**
* @constant
* @name msg
* @type {string}
* 
* */
  const [msg, setMsg] = useState("");

  const tipoEnvio = useSelector((state) => state.tipoEnvio);
  const dispatch = useDispatch();

  const prevAdress = direccion.direccion;
  const [direccion1, setDireccion1] = useState(prevAdress);

  /**
* @constant
* @name usuario1
* @type {string}
* 
* */
  const [usuario1, setUsuario1] = useState(sessionStorage.getItem("IDusuario"));

  /**
* @function
* Petición HTTP que obtiene todas las regiones
*/
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BACK_URL + "/api/region/get_regiones")
      .then((response) => {
        setRegiones(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  /**
* @function
* Petición HTTP que obtiene todas las comunas por la región seleccionada
*/
  useEffect(() => {
    if (selectedRegion !== "") {
      axios
        .get(
          process.env.REACT_APP_BACK_URL + `/api/comuna/get_comunas_by_region/${selectedRegion}`
        )
        .then((response) => {
          setComunas(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setComunas([]);
    }
  }, [selectedRegion]);


  /**
* @function
* Petición HTTP que registra la dirección
* @param {string} usuario1 Id del usuario
* @param {string} selectedComuna Id de la comuna seleccionada
* @param {string} direccion1 Dirección
* @returns {string}
*/
  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post(process.env.REACT_APP_BACK_URL + `/api/direccion/register_direccion`, {
        usuario: usuario1,
        comuna: selectedComuna,
        direccion: direccion1,
      })
      .then((response) => {
        dispatch(setTipoEnvio(tipoEnvio + 1));
        setMsg("Dirección actualizada correctamente");
      })
      .catch((error) => {
        console.error(error);
        setMsg("No se pudo actualizar dirección, intente nuevamente");
      });
  };


  /**
* @function
* Metodo que setea el useState con la región seleccionada
*/
  const handleRegionChange = (event) => {
    setSelectedRegion(event.target.value);
    setSelectedComuna("");
  };

  /**
* @function
* Metodo que setea el useState con la comuna seleccionada
*/
  const handleComunaChange = (event) => {
    setSelectedComuna(event.target.value);
  };


  return (
    <div
      className="modal fade"
      id="modal-editar-direccion"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header border-bottom-0">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="btn_closeidmodal"
            ></button>
          </div>
          <div className="modal-body pt-0">
            <div className="container mb-3">
              <div className="row">
                <div className="col-12 text-center text-dark fw-bold">
                  Editar dirección
                </div>
              </div>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="direccionInput" className="form-label">
                  Dirección:<span className="text-danger">*</span>
                </label>
                <input
                  required
                  type="text"
                  className="form-control rounded-pill"
                  id="direccionInput"
                  placeholder="Ingrese la dirección"
                  value={direccion1}
                  onChange={(e) => {
                    setDireccion1(e.target.value);
                  }}
                />
              </div>
              <div
                className="
              "
              >
                <label className="d-xs-none" htmlFor="">
                  Región:<span className="text-danger">*</span>
                </label>
                <select
                  className="form-select rounded-pill mb-2"
                  name="form-select"
                  id="region"
                  value={selectedRegion}
                  onChange={handleRegionChange}
                  required
                  placeholder="Seleccione una opción"
                >
                  <option value="">Selecciona una región</option>
                  {regiones.map((region) => (
                    <option key={region.id} value={region.id}>
                      {region.nombre_region}
                    </option>
                  ))}
                </select>
              </div>
              <div
                className="mb-5
              "
              >
                <label className="d-xs-none" htmlFor="">
                  Comuna:<span className="text-danger">*</span>
                </label>
                <select
                  className="form-select rounded-pill "
                  name=""
                  id="comuna"
                  value={selectedComuna}
                  onChange={handleComunaChange}
                  disabled={comunas.length === 0}
                  required
                >
                  <option value="">Selecciona una comuna</option>
                  {comunas.map((comuna) => (
                    <option key={comuna.id} value={comuna.id}>
                      {comuna.nombre_comuna}
                    </option>
                  ))}
                </select>
              </div>
              <button type="submit" aria-label="Guardar datos" className="btn w-100 bg-turq btn-turq-hover py-2 text-white rounded-pill">
                Guardar datos
              </button>
              <div className="text-center mt-2">
                <span className="fw-bold">{msg}</span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalEditarDireccion;
