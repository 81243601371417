/**
 * Página Admin Categorias CRUD:
 * 
 * acá se agregan las categorias
 * 
 * @module AdminCategoriasCRUDjsx
 */


import React, { useEffect, useState } from "react";
import "./producto.css";
import { AdminSidebar } from "../../components";
import axios from "axios";
import Mensaje from "../../components/Modales/Mensaje";

const CategoriasCRUD = () => {


  /**
* @constant
* @name accion
* @type {number}
* 
* */
  const [accion, setAccion] = useState(1);

  /**
* @constant
* @name padres
* @type {?string}
* 
* */
  const [padres, setPadres] = useState(null);


  /**
* @constant
* @name hijas
* @type {?string}
* 
* */
  const [hijas, setHijas] = useState(null);

  /**
* @constant
* @name mensaje
* @type {string}
* 
* */
  const [mensaje, setMensaje] = useState("");
  const accessToekn = sessionStorage.getItem("access");



  useEffect(() => {

    /**
* @function
* Peticion HTTP que retorna todas las categorias y los banners
*@returns {Array}
* 
*/

    axios
      .get(process.env.REACT_APP_BACK_URL + `/api/admin/categoria/get_all_categorias_and_banners`)
      .then((response) => {
        setPadres(response.data);
      })
      .catch((error) => {
        console.log(error);
      });


    /**
  * @function
  * Peticion HTTP que retorna todas las categorias hijas por el padre
  *@returns {Array}
  * 
  */

    axios
      .get(process.env.REACT_APP_BACK_URL + `/api/admin/categoria/get_all_categoria_hija_by_padre/0`)
      .then((response) => {
        setHijas(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

  }, [accion]);


  /**
* @function
* Metodo que cambia las categorias hijas cuando se selecciona un padre
*@returns {Array}
* 
*/
  const cambiaHija = () => {
    let padre = document.querySelector("#categoria-padre-editar");
    let daug = document.querySelector("#categoria-hija-editar-select");
    let row = "";

    if (padre.value != 0) {
      hijas?.map((hija) => {
        if (padre.value == hija.categoria_padre_id)
          row += `<option value=${hija.id} key=${hija.id}>${hija.nombre_mostrar}</option>`;

      });
      daug.innerHTML = row;
    }
  };



  const muestraMostrar = () => {
    let daug = document.querySelector("#categoria-hija-editar-select");
    let input = document.querySelector("#categoria-hija-editar");
    let img = document.querySelector("#img-mostrar-modificar");
    hijas.forEach(element => {
      if (element.id == daug.value)
        img.src = process.env.REACT_APP_BACK_URL + element.foto;
    });
    input.value = daug.options[daug.selectedIndex].text;
  };

  /**
* @function
* Metodo que muestra un preview de la imagen subida
* 
*/
  const cambiaImagen = () => {
    let file = document.querySelector("#img-subir");
    let img = document.querySelector("#img-mostrar-crear");
    img.src = URL.createObjectURL(file.files[0]);
  };

  /**
* @function
* Metodo que muestra un preview de la imagen modificada
* 
*/
  const cambiaImagenModificar = () => {
    let file = document.querySelector("#img-subir-modificar");
    let img = document.querySelector("#img-mostrar-modificar");
    img.src = URL.createObjectURL(file.files[0]);
  };


  /**
* @function
* Petición HTTP para crear una categoria nueva validando que 
* este el nombre de la categoria hija y este la foto
* @param {Object} formData formData con los datos de la categoria
*/
  const crearCategoria = () => {
    let padre = document.querySelector("#categoria-padre-crear");
    let hija = document.querySelector("#categoria-hija-crear");
    let hijaGuion = hija.value.toLowerCase();
    let file = document.querySelector("#img-subir");

    if (hija.value != null && hija.value != "" && file.value != "" && file.value != null) {
      setMensaje("Creando categoría-alert");
      while (hijaGuion.includes(" "))
        hijaGuion = hijaGuion.replace(" ", "-");
      const formData = new FormData();
      formData.append("hija", hijaGuion);
      formData.append("categoria", padre.value);
      formData.append("nombre_mostrar", hija.value);
      formData.append("estado", 1);
      formData.append("foto", file.files[0]);
      formData.append("posicion", 99);

      axios
        .post(process.env.REACT_APP_BACK_URL + `/api/admin/categoria_hija/register_categoriahija`, formData, {
          headers: {
            Authorization: `Bearer ${accessToekn}`,
            "Content-Type": "multipart/form-data",
          }
        })
        .then((response) => {
          location.href = "/admin/categorias";
          setMensaje("Categoría registrada-verde");
        })
        .catch((error) => {
          setMensaje("Error al registrar la categoría-rojo");
        });
    }
    else
      setMensaje("Complete todos los campos-rojo");
  };

    /**
* @function
* Petición HTTP para modificar una categoria validando que 
* este el nombre de la categoria hija y este la foto
* @param {Object} formData formData con los datos de la categoria
*/
  const ModificarCategoria = () => {
    let padre = document.querySelector("#categoria-padre-editar");
    let daug = document.querySelector("#categoria-hija-editar-select");
    let input = document.querySelector("#categoria-hija-editar");
    let file = document.querySelector("#img-subir-modificar");
    let hij = input.value.toLowerCase();
    if (input.value != "" && daug.value != 0 && daug.value != "" && file.value != "" && file.value != null) {
      while (hij.includes(" "))
        hij = hij.replace(" ", "-");
      setMensaje("Modificando categoría-alert");
      const formData = new FormData();
      formData.append("id", daug.value);
      formData.append("estado", 1);
      formData.append("categoria", padre.value);
      formData.append("hija", hij);
      formData.append("nombre_mostrar", input.value);
      formData.append("posicion", 99);
      formData.append("foto", file.files[0]);

      axios
        .post(process.env.REACT_APP_BACK_URL + `/api/admin/categoria_hija/update_categoriahija`, formData, {
          headers: {
            Authorization: `Bearer ${accessToekn}`,
            "Content-Type": "multipart/form-data",
          }
        })
        .then((response) => {
          location.href = "/admin/categorias";
          setMensaje("Categoría Modificada-verde");
        })
        .catch((error) => {
          console.log(error);
        });
    }
    else
      setMensaje("Complete todos los campos-rojo");
  };

  return (

    <div>

      <>
        <Mensaje mensaje={mensaje} setMensaje={setMensaje} />
        <AdminSidebar />

        <div className="regular">
          <h1>Dreamtec - <span>Administrador de categorías</span></h1>

          <div className="shadow">

            <div className="container">
              <div className="row justify-content-center">
                {accion === 1 && (
                  <>
                    <div className="col-12 text-center mt-3 mb-5">
                      <h2>
                        Seleccione una opción para desplegar el contenido
                      </h2>

                    </div>

                    <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12 mb-3">
                      <button aria-label="Agregar Producto"
                        className="agregar"
                        onClick={() => {
                          setAccion(2);
                        }}
                      >
                        <i className="fa-solid fa-circle-plus"></i> &nbsp; Crear Categoría
                      </button>

                    </div>


                    <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12 mb-3">

                      <button aria-label="Editar Producto"
                        className="agregar"
                        onClick={() => {
                          setAccion(3);
                        }}
                      >
                        <i className="fa-solid fa-pen-to-square"></i> &nbsp; Editar Categoría
                      </button>

                    </div>
                  </>
                )}
              </div>

              {accion === 2 && (
                <>
                  <div className="row mb-3">
                    <div className="col-12 text-center">
                      <h2 className="mb-2">Crear una categoría</h2>
                      <p style={{ fontSize: "12px", fontWeight: "bold" }}>Optimizado para imágenes de 515x445</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <label htmlFor="">Seleccione una Categoría</label>
                      <select name="" id="categoria-padre-crear" className="form-control rounded-pill mb-3">
                        {padres.map((padre) => {
                          if (padre.id < 4)
                            return <option value={padre.id} key={padre.id}>{padre.nombre_mostrar}</option>
                        })}
                      </select>

                      <label htmlFor="">Nombre de la nueva sub categoría</label>
                      <input type="text" id="categoria-hija-crear" className="form-control" />
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="">Seleccione imagen de portada de la categoría</label>
                      <input type="file" id="img-subir" className="form-control mb-3" onChange={cambiaImagen} />
                      <img loading="lazy" src="" alt="" id="img-mostrar-crear" style={{ maxHeight: "150px" }} className="img-fluid" />
                    </div>
                  </div>

                  <div className="row mt-5 mb-5">
                    <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12">
                      <button
                        aria-label="Agregar banner"
                        className="btnGreen bg-primary w-100 text-white px-3 fw-bold"
                        type="submit"
                        onClick={crearCategoria}
                      >
                        Crear Categoría
                      </button>
                    </div>

                    <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12">
                      <button
                        aria-label="Agregar producto"
                        className="btnGreen cancel w-100"
                        type="submit"
                        onClick={() => {
                          setAccion(1);
                        }}
                      >
                        Volver atrás
                      </button>
                    </div>
                  </div>
                </>
              )}

              {accion === 3 && (
                <>
                  <div className="row mb-3">
                    <div className="col-12 text-center">
                      <h2 className="mb-2">Modificar una categoría</h2>
                      <p style={{ fontSize: "12px", fontWeight: "bold" }}>Optimizado para imágenes de 515x445</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <label htmlFor="">Seleccione una Categoría</label>
                      <select name="" id="categoria-padre-editar" onChange={cambiaHija} className="form-control rounded-pill mb-3">
                        {padres.map((padre) => {
                          if (padre.id < 4)
                            return <option value={padre.id} key={padre.id}>{padre.nombre_mostrar}</option>
                        })}
                      </select>

                      <label htmlFor="">Seleccione la sub categoría a editar</label>
                      <select name="" defaultValue={0} id="categoria-hija-editar-select" onChange={muestraMostrar} className="form-control rounded-pill mb-3">
                        <option disabled value={0}>Seleccione sub categoría</option>
                        {hijas.map((hija) => {
                          if (hija.categoria_padre_id == 1)
                            return <option value={hija.id} key={hija.id}>{hija.nombre_mostrar}</option>
                        })}
                      </select>

                      <label htmlFor="">Nombre de la sub categoría</label>
                      <input type="text" id="categoria-hija-editar" className="form-control" />
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="">Seleccione imagen de portada de la categoría</label>
                      <input type="file" className="form-control mb-3" defaultValue="" id="img-subir-modificar" onChange={cambiaImagenModificar} />
                      <img loading="lazy" src="img-fluid" id="img-mostrar-modificar" style={{ maxHeight: "150px" }} alt="" />
                    </div>
                  </div>

                  <div className="row mt-5 mb-5">
                    <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12">
                      <button
                        aria-label="Agregar banner"
                        className="btnGreen bg-primary w-100 text-white px-3 fw-bold"
                        type="submit"
                        onClick={ModificarCategoria}
                      >
                        Guardar Cambios
                      </button>
                    </div>

                    <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12">
                      <button
                        aria-label="Agregar producto"
                        className="btnGreen cancel w-100"
                        type="submit"
                        onClick={() => {
                          setAccion(1);
                        }}
                      >
                        Volver atrás
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>

          </div>

        </div>
      </>





    </div>
  );
};

export default CategoriasCRUD;
