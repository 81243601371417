import { configureStore } from '@reduxjs/toolkit';
import booleanReducer from './esBusquedaSlice';

import categoriaReducer from './esCategoriaSlice';

import tipoEnvioReducer from './tipoEnvioSlice';
import fechaEntregaReducer from './fechaEntregaSlice';
import comunaSelectedReducer from './comunaSelectedSlice';
import regionSelectedReducer from './regionSelectedSlice';
import addToCartSlice from './AddToCartSlice';
import checkStockSlice from './checkStockSlice';
import idCarroSlice from './idCarroSlice';
import categoriapadreSlice from './categoriapadreSlice';
import DetalleCarroSlice from './DetalleCarroSlice';
import NumeroOrdenSlice from './NumeroOrdenSlice';
import LoMasVendSlicer from './LoMasVendSlicer';

const store = configureStore({
  reducer: {
    boolean: booleanReducer,
    booleanCategoria: categoriaReducer,
    tipoEnvio: tipoEnvioReducer,
    fechaEntrega: fechaEntregaReducer,
    comunaSelected: comunaSelectedReducer,
    regionSelected: regionSelectedReducer,
    addToCart: addToCartSlice,
    checkStock: checkStockSlice,
    idCarro: idCarroSlice,
    categoriaPadre: categoriapadreSlice,
    detalleCarro: DetalleCarroSlice,
    numeroOrden: NumeroOrdenSlice,
    lomasvendido: LoMasVendSlicer,
    
  },
});

export default store;
