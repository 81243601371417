/**
 * Página Preguntas:
 * 
 * acá se muestran las preguntas frecuentes
 * 
 * @module PaginaPreguntasFrecuentesjsx
 */


import { useState, useEffect, useRef } from "react";
import axios from "axios";

import { useSelector, useDispatch } from "react-redux";

import { setTipoEnvio } from "../../redux/tipoEnvioSlice";
import { setFechaEntrega } from "../../redux/fechaEntregaSlice";
import { setComunaSelected } from "../../redux/comunaSelectedSlice";
import { Helmet } from "react-helmet";

const Preguntas = () => {

    /**
* @constant
* @name userAlready
* @type {string}
* 
* */
    const [userAlready] = useState(sessionStorage.getItem("IDusuario"));


    /**
  * @constant
  * @name tipoEnvio
  * @type {string}
  * 
  * */
    const tipoEnvio = useSelector((state) => state.tipoEnvio);


    const dispatch = useDispatch();

    /**
  * @function
  * Petición HTTP que trae la dirección ingresada por el usuario anteriormente, 
  * validando que inicio sesión y el tipo de envio sea distinto de 4 (Retiro en tienda)
  * @param {string} userAlready Id del usuario
  * @param {number} tipoEnvio Tipo de envio seleccionado anteriormente
  *@returns {Array}
  * 
  */
    useEffect(() => {
        if (userAlready && tipoEnvio !== 4) {
            axios
                .get(
                    process.env.REACT_APP_BACK_URL + `/api/direccion/get_direccion/${userAlready}`
                )
                .then((response) => {
                    dispatch(setTipoEnvio(response.data.direcicon.codigo_envio));
                    dispatch(setFechaEntrega(response.data.direcicon.fecha));
                    dispatch(setComunaSelected(response.data.direcicon.nombre_comuna));


                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [tipoEnvio]);

    return (

        <>
            <Helmet>
                <link rel="canonical" href={process.env.REACT_APP_FRONT_URL + "/faq"} />
            </Helmet>
            <div class="container mt-3 mb-4">
                <div class="row justify-content-center">
                    <div class="col-lg-10 col-md-10 col-11 px-1">
                        <a class="fs-14 text-black text-decoration-none" href="/">Inicio</a>
                        <span class="text-black"><i class="fa-solid fa-angle-right px-2 fs-12"></i></span>
                        <span class="fw-bold fs-6 text-turq">Preguntas frecuentes</span>
                    </div>
                </div>
            </div>

            <div class="container mb-5 mt-4">
                <div class="row justify-content-center">
                    <div class="col-lg-10 col-md-10 col-sm-11 col-12">
                        <div class="accordion accordion-flush" id="accordionFlushExample">


                            <div class="accordion-item acordeon-faq">
                                <h2 class="accordion-header" id="flush-headingOne_0">
                                    <button class="accordion-button acordeon-btn-faq text-uppercase collapsed fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne_0" aria-expanded="false" aria-controls="flush-collapseOne_0">
                                        ¿Cuanto tiempo tengo para retirar mi compra?                  </button>
                                </h2>
                                <div id="flush-collapseOne_0" class="accordion-collapse collapse fw-bold" aria-labelledby="flush-headingOne_0" data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body">
                                        <div class="row justify-content-center">
                                            <div class="col-lg-12 col-12 text-secondary fs-6">

                                                <p>El plazo que un cliente tiene para retirar su compra en tienda es de 5 días hábiles. Es importante que planifiques tu visita dentro de este período para asegurarte de recibir tu pedido a tiempo y evitar inconvenientes. Si necesitas más tiempo o tienes alguna pregunta adicional, no dudes en contactarnos para buscar una solución adecuada a tus necesidades.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="accordion-item acordeon-faq">
                                <h2 class="accordion-header" id="flush-headingOne_1">
                                    <button class="accordion-button collapsed acordeon-btn-faq text-uppercase fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne_1" aria-expanded="false" aria-controls="flush-collapseOne_1">
                                        ¿Dónde puedo retirar mis productos?				                  </button>
                                </h2>
                                <div id="flush-collapseOne_1" class="accordion-collapse collapse" aria-labelledby="flush-headingOne_1" data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body">
                                        <div class="row justify-content-center">
                                            <div class="col-lg-12 col-12 text-secondary fs-6">

                                                <p>Los productos adquiridos pueden retirar en Av. Manuel Montt 1667, Providencia, Región Metropolitana, desde las 10:00 hasta 18:00hrs, de lunes a sabado. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="accordion-item acordeon-faq">
                                <h2 class="accordion-header" id="flush-headingOne_2">
                                    <button class="accordion-button collapsed acordeon-btn-faq text-uppercase fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne_2" aria-expanded="false" aria-controls="flush-collapseOne_2">
                                        ¿Donde estan ubicados?				                  </button>
                                </h2>
                                <div id="flush-collapseOne_2" class="accordion-collapse collapse" aria-labelledby="flush-headingOne_2" data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body">
                                        <div class="row justify-content-center">
                                            <div class="col-lg-12 col-12 text-secondary fs-6">

                                                <p>Nos encontramos en Av. Manuel Montt 1667, Providencia, Región Metropolitana </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="accordion-item acordeon-faq">
                                <h2 class="accordion-header" id="flush-headingOne_3">
                                    <button class="accordion-button collapsed acordeon-btn-faq text-uppercase fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne_3" aria-expanded="false" aria-controls="flush-collapseOne_3">
                                        ¿plazo y cobertura de la garantía?				                 </button>
                                </h2>
                                <div id="flush-collapseOne_3" class="accordion-collapse collapse" aria-labelledby="flush-headingOne_3" data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body">
                                        <div class="row justify-content-center">
                                            <div class="col-lg-12 col-12 text-secondary fs-6">

                                                <p>
                                                    Conforme a las regulaciones legales actuales, la garantía tiene una duración de 6 meses desde la fecha de compra (aplicable a adquisiciones realizadas a partir del 24 de marzo de 2021, según la Ley 21.398). En líneas generales, esta cobertura se extiende a productos con defectos de fabricación. La aplicación concreta de la garantía varía según la naturaleza del producto y la marca correspondiente.

                                                    Es importante destacar que, en el caso de productos adquiridos a través de Dreamtec, la garantía se extiende hasta 6 meses. No obstante, cabe mencionar que algunas marcas pueden ofrecer una garantía extendida de hasta 18 meses. Te recomendamos revisar la documentación específica de la marca correspondiente para obtener detalles adicionales sobre la duración de la garantía.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="accordion-item acordeon-faq">
                                <h2 class="accordion-header" id="flush-headingOne_4">
                                    <button class="accordion-button collapsed acordeon-btn-faq text-uppercase fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne_4" aria-expanded="false" aria-controls="flush-collapseOne_4">
                                        ¿Y si el producto no me gustó o no es compatible?				                  </button>
                                </h2>
                                <div id="flush-collapseOne_4" class="accordion-collapse collapse" aria-labelledby="flush-headingOne_4" data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body">
                                        <div class="row justify-content-center">
                                            <div class="col-lg-12 col-12 text-secondary fs-6">

                                                <p>Agradecemos tu interés en nuestros productos/servicios. En este momento, nuestra empresa no ofrece una garantía de satisfacción. Sin embargo, nos esforzamos por brindar productos/servicios de alta calidad y estamos comprometidos a abordar cualquier problema que puedas experimentar de manera eficiente.

                                                    Entendemos la importancia de la satisfacción del cliente y estamos aquí para responder cualquier pregunta adicional que puedas tener sobre nuestros productos/servicios. Si surge alguna inquietud específica, no dudes en ponerte en contacto con nosotros directamente. Además, recuerda que tienes un plazo de 10 días para ejercer el derecho de retracto desde el momento de la compra.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="accordion-item acordeon-faq">
                                <h2 class="accordion-header" id="flush-headingOne_5">
                                    <button class="accordion-button collapsed acordeon-btn-faq text-uppercase fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne_5" aria-expanded="false" aria-controls="flush-collapseOne_5">
                                        ¿Cuáles son los medios de pago?				                  </button>
                                </h2>
                                <div id="flush-collapseOne_5" class="accordion-collapse collapse" aria-labelledby="flush-headingOne_5" data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body">
                                        <div class="row justify-content-center">
                                            <div class="col-lg-12 col-12 text-secondary fs-6">

                                                <p>La metodologia de pago con las que contamos son las siguientes: efectivos, trasferencia, deposito, tarjeta debito, tarjeta de credito, boton de pago y mercado pago</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="accordion-item acordeon-faq">
                                <h2 class="accordion-header" id="flush-headingOne_6">
                                    <button class="accordion-button collapsed acordeon-btn-faq text-uppercase fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne_6" aria-expanded="false" aria-controls="flush-collapseOne_6">
                                        ¿Cuáles son las cuentas bancarias a las que puedo transferir?								                  </button>
                                </h2>
                                <div id="flush-collapseOne_6" class="accordion-collapse collapse" aria-labelledby="flush-headingOne_6" data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body">
                                        <div class="row justify-content-center">
                                            <div class="col-lg-12 col-12 text-secondary fs-6">

                                                <p>Rut: 76.905.656-4<br />
                                                    Razón Social: Dreamtec Holdings Spa<br />
                                                    Dirección: Av. Manuel Montt 1667<br />
                                                    Giro: ventas al por menor de tecnología<br />
                                                    <br />
                                                    Datos bancarios:<br />
                                                    Cuenta Scotiabank<br />
                                                    Dreamtec Holdings Spa.<br />
                                                    Cta Cte.<br />
                                                    980532240<br />
                                                    administracion@dreamtec.cl<br />
                                                    76.905.656-4<br />
                                                    <br />
                                                    Cuenta Santander<br />
                                                    76.905.656-4<br />
                                                    Dreamtec Holdings Spa.<br />
                                                    Cta. Cte.<br />
                                                    0-000-8355297-2</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="accordion-item acordeon-faq">
                                <h2 class="accordion-header" id="flush-headingOne_7">
                                    <button class="accordion-button collapsed acordeon-btn-faq text-uppercase fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne_7" aria-expanded="false" aria-controls="flush-collapseOne_7">
                                        ¿Cómo puedo anular una orden si aún no la he pagado?</button>
                                </h2>
                                <div id="flush-collapseOne_7" class="accordion-collapse collapse" aria-labelledby="flush-headingOne_7" data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body">
                                        <div class="row justify-content-center">
                                            <div class="col-lg-12 col-12 text-secondary fs-6">

                                                <p>La orden de compra se anula automáticamente después de dos días si no se ha realizado el pago. Esto es para asegurar la disponibilidad del producto y gestionar eficientemente nuestro inventario. Te recomendamos completar la compra dentro de este período para evitar la cancelación de tu pedido. Si tienes alguna dificultad para realizar el pago o necesitas más tiempo, por favor contáctanos para buscar una solución.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="accordion-item acordeon-faq">
                                <h2 class="accordion-header" id="flush-headingOne_8">
                                    <button class="accordion-button collapsed acordeon-btn-faq text-uppercase fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne_8" aria-expanded="false" aria-controls="flush-collapseOne_8">
                                        ¿Puedo pedir una copia de la boleta o factura?								                  </button>
                                </h2>
                                <div id="flush-collapseOne_8" class="accordion-collapse collapse" aria-labelledby="flush-headingOne_8" data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body">
                                        <div class="row justify-content-center">
                                            <div class="col-lg-12 col-12 text-secondary fs-6">

                                                <p>Para solicitar tu boleta o factura, te pedimos que te pongas en contacto con nuestra administración. Puedes hacerlo enviando un correo electrónico a administracion@dreamtec.cl. Nuestro equipo estará encantado de asistirte con cualquier consulta y asegurar que recibas la documentación necesaria para tu compra</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="accordion-item acordeon-faq">
                                <h2 class="accordion-header" id="flush-headingOne_9">
                                    <button class="accordion-button collapsed acordeon-btn-faq text-uppercase fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne_9" aria-expanded="false" aria-controls="flush-collapseOne_9">
                                        ¿Cómo solicito mi historial de compras?								                  </button>
                                </h2>
                                <div id="flush-collapseOne_9" class="accordion-collapse collapse" aria-labelledby="flush-headingOne_9" data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body">
                                        <div class="row justify-content-center">
                                            <div class="col-lg-12 col-12 text-secondary fs-6">

                                                <p>Para solicitar tu historial de compra, te pedimos que te comuniques con nosotros enviando un correo electrónico a administracion@dreamtec.cl. Nuestro equipo de administración estará encantado de asistirte y proporcionarte toda la información que necesites sobre tus compras anteriores. No dudes en detallar cualquier consulta adicional que puedas tener, y te responderemos a la brevedad posible.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="accordion-item acordeon-faq">
                                <h2 class="accordion-header" id="flush-headingOne_10">
                                    <button class="accordion-button collapsed acordeon-btn-faq text-uppercase fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne_10" aria-expanded="false" aria-controls="flush-collapseOne_10">
                                        ¿Para retirar en tienda, ¿Qué debo presentar?								                  </button>
                                </h2>
                                <div id="flush-collapseOne_10" class="accordion-collapse collapse" aria-labelledby="flush-headingOne_10" data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body">
                                        <div class="row justify-content-center">
                                            <div class="col-lg-12 col-12 text-secondary fs-6">

                                                <p>Para retirar tu pedido, es necesario presentar el carnet de identidad como medida de verificación. Además, te pedimos que envíes un correo electrónico con anticipación, indicando el nombre completo de la persona que recogerá el pedido. Esto nos permitirá asegurarnos de que el proceso de entrega se realice de manera segura y eficiente. Agradecemos tu cooperación en este asunto</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="accordion-item acordeon-faq">
                                <h2 class="accordion-header" id="flush-headingOne_11">
                                    <button class="accordion-button collapsed acordeon-btn-faq text-uppercase fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne_11" aria-expanded="false" aria-controls="flush-collapseOne_11">
                                        ¿Qué empresas de transporte realizan los envíos?								                  </button>
                                </h2>
                                <div id="flush-collapseOne_11" class="accordion-collapse collapse" aria-labelledby="flush-headingOne_11" data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body">
                                        <div class="row justify-content-center">
                                            <div class="col-lg-12 col-12 text-secondary fs-6">

                                                <p>Blue Express se encarga de los envíos a nivel regional, mientras que disponemos de nuestra propia flota de vehículos para realizar entregas en la Región Metropolitana.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="accordion-item acordeon-faq">
                                <h2 class="accordion-header" id="flush-headingOne_12">
                                    <button class="accordion-button collapsed acordeon-btn-faq text-uppercase fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne_12" aria-expanded="false" aria-controls="flush-collapseOne_12">
                                        ¿Puedo elegir la empresa de transporte para Despacho a domicilio?								                  </button>
                                </h2>
                                <div id="flush-collapseOne_12" class="accordion-collapse collapse" aria-labelledby="flush-headingOne_12" data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body">
                                        <div class="row justify-content-center">
                                            <div class="col-lg-12 col-12 text-secondary fs-6">

                                                <p>Sí, tienes la opción de seleccionar la empresa de transporte para el despacho a domicilio. Sin embargo, es importante tener en cuenta que cualquier costo adicional asociado con la elección de una empresa específica será responsabilidad del cliente. Te recomendamos contactarnos para discutir las opciones disponibles y encontrar la solución que mejor se ajuste a tus necesidades de entre</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="accordion-item acordeon-faq">
                                <h2 class="accordion-header" id="flush-headingOne_13">
                                    <button class="accordion-button collapsed acordeon-btn-faq text-uppercase fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne_13" aria-expanded="false" aria-controls="flush-collapseOne_13">
                                        ¿Cuáles son los tiempos de despacho?								                  </button>
                                </h2>
                                <div id="flush-collapseOne_13" class="accordion-collapse collapse" aria-labelledby="flush-headingOne_13" data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body">
                                        <div class="row justify-content-center">
                                            <div class="col-lg-12 col-12 text-secondary fs-6">

                                                <p>Los tiempos de despacho pueden variar dependiendo de las comunas seleccionadas para la entrega. Nuestro objetivo es asegurar que tu pedido llegue lo más pronto posible a tu domicilio.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="accordion-item acordeon-faq">
                                <h2 class="accordion-header" id="flush-headingOne_14">
                                    <button class="accordion-button collapsed acordeon-btn-faq text-uppercase fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne_14" aria-expanded="false" aria-controls="flush-collapseOne_14">
                                        La encomienda llegó dañada ¿Qué debo hacer?								                  </button>
                                </h2>
                                <div id="flush-collapseOne_14" class="accordion-collapse collapse" aria-labelledby="flush-headingOne_14" data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body">
                                        <div class="row justify-content-center">
                                            <div class="col-lg-12 col-12 text-secondary fs-6">

                                                <p>En caso de que tu encomienda llegue dañada, te sugerimos seguir estos pasos para resolver la situación de manera efectiva: primero, asegúrate de documentar el daño mediante fotografías detalladas. Luego, envía un correo detallando la situación a nuestro departamento de post-venta a mflores@dreamtec.cl. Incluye en tu correo las imágenes del daño junto con tu número de pedido y cualquier otra información relevante. Nuestro equipo de atención al cliente se pondrá en contacto contigo lo antes posible para resolver el problema y ofrecerte una solución satisfactoria.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="accordion-item acordeon-faq">
                                <h2 class="accordion-header" id="flush-headingOne_15">
                                    <button class="accordion-button collapsed acordeon-btn-faq text-uppercase fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne_15" aria-expanded="false" aria-controls="flush-collapseOne_15">
                                        ¿se puede retirar el pedido de mercado libre en nuestra tienda?				                  </button>
                                </h2>
                                <div id="flush-collapseOne_15" class="accordion-collapse collapse" aria-labelledby="flush-headingOne_15" data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body">
                                        <div class="row justify-content-center">
                                            <div class="col-lg-12 col-12 text-secondary fs-6">

                                                <p>No podemos encargarnos de la entrega, ya que este proceso está gestionado directamente por la logística de Mercado Libre. Te recomendamos contactar con su equipo de soporte para coordinar los detalles de la entrega y resolver cualquier consulta relacionada con la logística de tu pedido.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>

        </>

    );

}

export default Preguntas;