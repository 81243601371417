import React from "react";

import FormularioIndustrial from "../components/Industrial/formulario";
import FooterIndustrial from '../components/FooterIndustrial/footer';
import "./industrial.css";

const Industrial = () => {
  return (
    <>
      <div>
        <div className="container mt-4 bgBienvenido">
          <div className="row">
            <div className="col-md-6"></div>
            <div className="col-md-6 text-white pt-5">
              <h1 className={`pt-5 fw-bold h1`}>
                Bienvenido
                <br />
                Dreamtec Industrial
              </h1>
              <span className="fs-6 fw-bold sueno">
                La tecnología que sueñas está en Dreamtec.
              </span>
            </div>
          </div>
        </div>

        <div className="container p-5 mt-5">
          <div className="row justify-content-center">
            <div className="col-xxl-5 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 text-center align-self-center mb-4">
              <h1 className="h1 shadow-0 mb-0 azul_oscuro">Somos Partner</h1>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mb-4 text-center">
              <img
                src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/industrial/chile_compra.png"}
                alt="Logo de Chile compra"
                className="img-fluid"
              />
            </div>
          </div>
        </div>

        <div className="container-fluid bg-body-tertiary">
          <div className="container py-5 my-5">
            <div className="row">
              <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-5 col-12 text-center d-xxl-block d-xl-block d-lg-block d-md-none d-sm-none d-none">
                <img
                  src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/industrial/evalua_empresa.png"}
                  alt="dos personas juntas trabajando mirando unos rollos de pvc para ploter"
                  className="img-fluid"
                />
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 text-white">
                <img
                  src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/industrial/ico_industrial.png"}
                  alt="industrial"
                  className="img-fluid industrial mb-3"
                />
                <h1 className="h1">
                  Eleva tu empresa
                  <br />
                  con dreamtec industrial
                </h1>
                <p className="p_industrial">
                  Productos diseñados para satisfacer las necesidades
                  específicas de tu negocio, desde impresión y bordado hasta
                  soluciones textiles de vanguardia. Descubre cómo nuestra
                  tecnología puede impulsar la eficiencia y la calidad en tus
                  operaciones.
                </p>
                <a href="#" className="btn btn-primary_industrial">
                  Ver Productos
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-5">
          <div className="row justify-content-md-center">
            <h1 className="text-center py-5 h1 azul_oscuro shadow-0">Conoce Nuestros Productos</h1>

            {[
              {
                src: process.env.REACT_APP_FRONT_URL + "/uploads/assets/industrial/producto_uno.png",
                alt: "Plotter",
                title: "PLOTTER",
                url_page: "/industrial/plotter",
              },
              {
                src: process.env.REACT_APP_FRONT_URL + "/uploads/assets/industrial/producto_dos.png",
                alt: "Máquinas de Bordar",
                title: "MAQUINAS DE BORDAR",
                url_page: "/industrial/maquinas-bordar",
              },
              {
                src: process.env.REACT_APP_FRONT_URL + "/uploads/assets/industrial/producto_tres.png",
                alt: "Impresora Textil",
                title: "IMPRESIÓN TEXTIL Y DE SUBLIMACIÓN",
                url_page: "/industrial/impresora-textil",
              },
            ].map((product, index) => (
              <div
                key={index}
                className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-12 col-12 border rounded-4 border-3 border-primary-subtle m-2 p-0"
              >
                <div className="superior_industrial text-center mt-2">
                  <img
                    src={product.src}
                    alt={product.alt}
                    className="img-fluid"
                  />
                </div>
                <div className={`inferior text-center bg-body-tertiary rounded-4 rounded-top-0 ${index == 2 ? 'p-3 pb-4 ' : 'p-4 pb_30'}`}>
                  <h2 className={`mb-3 fs-5 text-white ${index == 2 ? 'l_h_20_industrial' : ''} fs_16_industrial`}>{product.title}</h2>
                  <a
                    href={product.url_page}
                    className="btn btn-primary_industrial w-100-movil"
                  >
                    Ver más
                  </a>
                </div>
              </div>
            ))}

            <p className="text-center mt-5 p_industrial">
              Encuentra información detallada sobre cada uno de nuestros
              productos, así como recursos útiles
              <br />
              para ayudarte a elegir la mejor solución para tu negocio
            </p>
          </div>
        </div>

        <FormularioIndustrial></FormularioIndustrial>

        <FooterIndustrial></FooterIndustrial>
      </div>
    </>
  );
};

export default Industrial;
