/**
 * Componente MediosPago:
 * 
 * acá se muestra un modal con los medios de pago
 * 
 * @module ComponenteMediosPagojsx
 */


import React from "react";

const MediosPago = () => {
  return (
    <div
      className="modal fade"
      id="modal-medios-pagos"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"

    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div
            className="modal-header pb-0 "
            style={{ backgroundColor: "#131f35", border: "none" }}
          >
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              style={{ filter: "invert(1)", opacity: ".8" }}
            ></button>
          </div>
          <div className="modal-body pb-2 pt-0 px-0">
            <div
              className="container-fluid"
              style={{ backgroundColor: "#131f35" }}
            >
              <div className="row justify-content-center">
                <div className="col-lg-11 col-md-11 col-11 mb-3 text-center">
                  <span className="fs-6 text-white fw-bold">
                    Medios de pago
                  </span>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-5 col-md-6 col-11 mb-3 py-3 text-center">
                  <div className="row justify-content-center">
                    <div className="col-12">
                      <h2 className="fs-6" style={{ color: "#131f35" }}>
                        Transferencia bancaria
                      </h2>
                    </div>
                    <div className="col-lg-9 col-md-9 col-sm-9 col-11">
                      <img
                        src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/Modales/logo-transferencia.png"}
                        className="img-fluid"
                        alt="Transferencia bancaria"
                        title="Transferencia bancaria"
                        loading="lazy"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 col-md-6 col-11 mb-3 py-3 text-center">
                  <div className="row justify-content-center">
                    <div className="col-12">
                      <h2 className="fs-6" style={{ color: "#131f35" }}>Mercado Pago</h2>
                    </div>
                    <div className="col-lg-9 col-md-9 col-sm-9 col-11">
                      <img
                        src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/Modales/logo-mercadopago.png"}
                        className="img-fluid"
                        alt="Mercado Pago"
                        title="Mercado Pago"
                        loading="lazy"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 col-md-6 col-11 mb-3 py-3 text-center">
                  <div className="row  justify-content-center">
                    <div className="col-12">
                      <h2 className="fs-6" style={{ color: "#131f35" }}>Webpay</h2>
                    </div>
                    <div className="col-lg-9 col-md-9 col-sm-9 col-11">
                      <img
                        src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/Modales/logo-webpay-negro.png"}
                        className="img-fluid"
                        alt="Webpay"
                        title="Webpay"
                        loading="lazy"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer" style={{ border: "none" }}></div>
        </div>
      </div>
    </div>
  );
};

export default MediosPago;
