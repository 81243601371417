/**
 * Página Admin Grandes Marcas:
 * 
 * acá se Administran grandes marcas
 * 
 * @module AdminGrandesMarcasjsx
 */


import { useState, useEffect, useReducer } from "react";
import React, { Component } from "react";
import axios from "axios";

import { AdminSidebar } from "../../components";
import Mensaje from "../../components/Modales/Mensaje";

const GrandesMarcas = () => {



  /**
* @constant
* @name marcas
* @type {Array}
* 
* */
  const [marcas, setMarcas] = useState([]);

  /**
* @constant
* @name grandesMarcas
* @type {Array}
* 
* */
  const [grandesMarcas, setGrandesMarcas] = useState([]);

  /**
* @constant
* @name originales
* @type {Array}
* 
* */
  const [originales, setOriginales] = useState([]);

  /**
* @constant
* @name listarMarcas
* @type {number}
* 
* */
  const [listarMarcas, setListarMarcas] = useState(1);

  /**
* @constant
* @name mensaje
* @type {string}
* 
* */
  const [mensaje, setMensaje] = useState("");

  /**
* @constant
* @name foto1
* @type {?string}
* 
* */
  const [foto1, setFoto1] = useState(null);

  /**
* @constant
* @name foto2
* @type {?string}
* 
* */
  const [foto2, setFoto2] = useState(null);

  /**
* @constant
* @name foto3
* @type {?string}
* 
* */
  const [foto3, setFoto3] = useState(null);

  /**
* @constant
* @name foto4
* @type {?string}
* 
* */
  const [foto4, setFoto4] = useState(null);

  /**
* @constant
* @name foto5
* @type {?string}
* 
* */
  const [foto5, setFoto5] = useState(null);

  /**
* @constant
* @name foto6
* @type {?string}
* 
* */
  const [foto6, setFoto6] = useState(null);

  /**
* @constant
* @name foto7
* @type {?string}
* 
* */
  const [foto7, setFoto7] = useState(null);

  /**
* @constant
* @name foto8
* @type {?string}
* 
* */
  const [foto8, setFoto8] = useState(null);

  /**
* @constant
* @name marca1
* @type {string}
* 
* */
  const [marca1, setMarca1] = useState("");

  /**
* @constant
* @name marca2
* @type {string}
* 
* */
  const [marca2, setMarca2] = useState("");


  /**
* @constant
* @name marca3
* @type {string}
* 
* */
  const [marca3, setMarca3] = useState("");

  /**
* @constant
* @name marca4
* @type {string}
* 
* */
  const [marca4, setMarca4] = useState("");

  /**
* @constant
* @name marca5
* @type {string}
* 
* */
  const [marca5, setMarca5] = useState("");

  /**
* @constant
* @name marca6
* @type {string}
* 
* */
  const [marca6, setMarca6] = useState("");

  /**
* @constant
* @name marca7
* @type {string}
* 
* */
  const [marca7, setMarca7] = useState("");

  /**
* @constant
* @name marca8
* @type {string}
* 
* */
  const [marca8, setMarca8] = useState("");

  /**
* @constant
* @name enviado1
* @type {boolean}
* 
* */
  const [enviado1, setEnviado1] = useState(false);

  /**
* @constant
* @name enviado2
* @type {boolean}
* 
* */
  const [enviado2, setEnviado2] = useState(false);

  /**
* @constant
* @name enviado3
* @type {boolean}
* 
* */
  const [enviado3, setEnviado3] = useState(false);


  /**
* @constant
* @name enviado4
* @type {boolean}
* 
* */
  const [enviado4, setEnviado4] = useState(false);


  /**
* @constant
* @name enviado5
* @type {boolean}
* 
* */
  const [enviado5, setEnviado5] = useState(false);

  /**
* @constant
* @name enviado6
* @type {boolean}
* 
* */
  const [enviado6, setEnviado6] = useState(false);

  /**
* @constant
* @name enviado7
* @type {boolean}
* 
* */
  const [enviado7, setEnviado7] = useState(false);

  /**
* @constant
* @name enviado8
* @type {boolean}
* 
* */
  const [enviado8, setEnviado8] = useState(false);


  const accessToekn = sessionStorage.getItem("access");


  /**
* @function
* Peticion HTTP que retorna todas las marcas
*@returns {Array}
* 
*/
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_BACK_URL + `/api/admin/listar_marcas`
        );
        setMarcas(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    obtenerGrandesMarcas();
  }, [listarMarcas]);


  /**
* @function
* Peticion HTTP que retorna todas las grandes marcas
*@returns {Array}
* 
*/
  const obtenerGrandesMarcas = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACK_URL + `/api/admin/listar_grandes_marcas`
      );
      setGrandesMarcas(response.data);
      setOriginales(response.data);
      mostrarOriginales();
    } catch (error) {
      console.error(error);
    }
  };


  const mostrarOriginales = () => {
    for (let i = 0; i < 8; i++) {
      let img = document.getElementById("fotoMostrar" + (i + 1));
      img.src = process.env.REACT_APP_BACK_URL + originales[i].foto;
      let marca = document.querySelector("#marcaMostrar" + (i + 1));
      if (img.value == "" || img.value == null)
        marca.disabled = true;
      if ((i + 1) == 1)
        setMarca1(originales[i].marcas_id);
      if ((i + 1) == 2)
        setMarca2(originales[i].marcas_id);
      if ((i + 1) == 3)
        setMarca3(originales[i].marcas_id);
      if ((i + 1) == 4)
        setMarca4(originales[i].marcas_id);
      if ((i + 1) == 5)
        setMarca5(originales[i].marcas_id);
      if ((i + 1) == 6)
        setMarca6(originales[i].marcas_id);
      if ((i + 1) == 7)
        setMarca7(originales[i].marcas_id);
      if ((i + 1) == 8)
        setMarca8(originales[i].marcas_id);
    }
  };


  const setFotosNuevas = (id, foto) => {
    let img = document.getElementById("fotoMostrar" + id);
    let marca = document.querySelector("#marcaMostrar" + id);
    let label = document.getElementById("labelMostrar" + id);
    let input = document.getElementById("foto" + id);

    if (foto != null && foto != "") {
      img.src = URL.createObjectURL(foto);
      label.innerHTML = "Logo Nuevo";
      marca.disabled = false;
      if (id == 1) {
        setFoto1(foto);
        setEnviado1(true);
      }
      if (id == 2) {
        setFoto2(foto);
        setEnviado2(true);
      }
      if (id == 3) {
        setFoto3(foto);
        setEnviado3(true);
      }
      if (id == 4) {
        setFoto4(foto);
        setEnviado4(true);
      }
      if (id == 5) {
        setFoto5(foto);
        setEnviado5(true);
      }
      if (id == 6) {
        setFoto6(foto);
        setEnviado6(true);
      }
      if (id == 7) {
        setFoto7(foto);
        setEnviado7(true);
      }
      if (id == 8) {
        setFoto8(foto);
        setEnviado8(true);
      }
    }
    else {
      img.src = process.env.REACT_APP_BACK_URL + originales[(id - 1)].foto;
      label.innerHTML = "Logo Actual";
      marca.disabled = true;
      setMarca1(originales[(id - 1)].marcas_id);
      if (id == 1) {
        setFoto1(foto);
        setEnviado1(false);
      }
      if (id == 2) {
        setFoto2(foto);
        setEnviado2(false);
      }
      if (id == 3) {
        setFoto3(foto);
        setEnviado3(false);
      }
      if (id == 4) {
        setFoto4(foto);
        setEnviado4(false);
      }
      if (id == 5) {
        setFoto5(foto);
        setEnviado5(false);
      }
      if (id == 6) {
        setFoto6(foto);
        setEnviado6(false);
      }
      if (id == 7) {
        setFoto7(foto);
        setEnviado7(false);
      }
      if (id == 8) {
        setFoto8(foto);
        setEnviado8(false);
      }
    }
  };

  const validaDatosEnviar = () => {
    let arre = [];
    arre.push(marca1.toString());
    arre.push(marca2.toString());
    arre.push(marca3.toString());
    arre.push(marca4.toString());
    arre.push(marca5.toString());
    arre.push(marca6.toString());
    arre.push(marca7.toString());
    arre.push(marca8.toString());

    while (arre.includes(""))
      arre.splice(arre.indexOf(""), 1);

    while (arre.includes(null))
      arre.splice(arre.indexOf(null), 1);

    let arre2 = []
    for (let i = 0; i < arre.length; i++) {
      if (arre2.includes(arre[i]))
        return true;
      else
        arre2.push(arre[i]);
    }
    return false;
  };


  /**
* @function
* Peticion HTTP que modifica las grande marcas, 
*validando que las fotos se hallan agregado
* @param {Object} formData FormData con los datos para modificar las grandes marcas
* 
*/
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (enviado1 == false && enviado2 == false && enviado3 == false && enviado4 == false && enviado5 == false && enviado6 == false && enviado7 == false && enviado8 == false) {
      setMensaje("No se han realizado modificaciones-rojo");
    }
    else {
      if (!validaDatosEnviar()) {
        setMensaje("Modificando las marcas-alert");

        const formData = new FormData();
        formData.append("foto1", foto1);
        formData.append("foto2", foto2);
        formData.append("foto3", foto3);
        formData.append("foto4", foto4);
        formData.append("foto5", foto5);
        formData.append("foto6", foto6);
        formData.append("foto7", foto7);
        formData.append("foto8", foto8);
        formData.append("marca1", marca1);
        formData.append("marca2", marca2);
        formData.append("marca3", marca3);
        formData.append("marca4", marca4);
        formData.append("marca5", marca5);
        formData.append("marca6", marca6);
        formData.append("marca7", marca7);
        formData.append("marca8", marca8);
        formData.append("enviado1", enviado1);
        formData.append("enviado2", enviado2);
        formData.append("enviado3", enviado3);
        formData.append("enviado4", enviado4);
        formData.append("enviado5", enviado5);
        formData.append("enviado6", enviado6);
        formData.append("enviado7", enviado7);
        formData.append("enviado8", enviado8);

        const response = await axios.post(
          process.env.REACT_APP_BACK_URL + `/api/admin/registrar_grandes_marcas`, formData, {
          headers: {
            Authorization: `Bearer ${accessToekn}`,
            "Content-Type": "multipart/form-data",
          },
        }).then(response => {
          if (response.data == "Marcas registradas") {
            setFoto1("");
            setFoto2("");
            setFoto3("");
            setFoto4("");
            setFoto5("");
            setFoto6("");
            setFoto7("");
            setFoto8("");
            setMarca1("");
            setMarca2("");
            setMarca3("");
            setMarca4("");
            setMarca5("");
            setMarca6("");
            setMarca7("");
            setMarca8("");
            setEnviado1(false);
            setEnviado2(false);
            setEnviado3(false);
            setEnviado4(false);
            setEnviado5(false);
            setEnviado6(false);
            setEnviado7(false);
            setEnviado8(false);
            location.href = "/admin/grandesmarcas";
            setMensaje("Marca modificadas-verde");
          }
          else
            setMensaje("Error al modificar las marcas-rojo");
        }).catch(error => {
          console.log(error);
        })
      }
      else
        setMarca1("Las marcas no se pueden repetir-rojo");
    }
  };


  return (

    <div>
      <Mensaje mensaje={mensaje} setMensaje={setMensaje} />
      <AdminSidebar />


      <div className="regular">
        <h1>Dreamtec - <span>Administrador de Grandes marcas</span></h1>
        <div className="shadow">
          <div className="row">
            {listarMarcas === 1 && (
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12" >

                <div className="row">
                  <div className="col-12 text-center">
                    <h2 className="mb-5">Grandes marcas</h2>
                  </div>
                </div>

                <div className="row justify-content-end">

                  <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12 text-end mb-3">
                    <button type="button"
                      onClick={() => {
                        setListarMarcas(3);
                      }}
                      className="agregar"
                    >
                      <i className="fa-solid fa-circle-plus"></i> &nbsp;Modificar Grandes marcas
                    </button>
                  </div>

                </div>

                {grandesMarcas.length > 0 ? (

                  <div className="table-responsive">
                    <table className="table border">
                      <thead className="p-4">
                        <tr>
                          <th scope="col"><div className="id">ID</div></th>
                          <th scope="col"><div className="id text-start">Nombre</div></th>
                          <th scope="col"><div className="id text-center">Imagen</div></th>
                        </tr>
                      </thead>
                      <tbody>
                        {grandesMarcas.map((marca) => (
                          <tr key={marca.id}>
                            <th scope="row">
                              <div className="number">{marca.id}</div>
                            </th>
                            <td>
                              <div className="nameNumber">{marca.nombre}</div>
                            </td>
                            <td className="text-center">
                              <img
                                src={process.env.REACT_APP_FRONT_URL + `/${marca.foto}`}
                                alt="imagen grandes marcas"
                                className="fotoCat container-fluid"
                                loading="lazy"
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>


                ) : (


                  <div className="row my-5">
                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 fw-bold text-center">
                      <i className="fa-solid fa-circle-info"></i>&nbsp;No hay grandes marcas registradas
                    </div>
                  </div>

                )}

              </div>
            )}

            {listarMarcas === 3 && (

              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">

                <div className="row mb-4">
                  <div className="col-12 text-center">
                    <h2 className="mb-3">Modificar grandes marcas</h2>
                    <p style={{ fontSize: "12px", fontWeight: "bold" }}>Optimizado para imágenes de 515x260</p>
                  </div>
                </div>

                <div className="row justify-content-center">
                  <div className="col-xxl-11 col-xl-11 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div
                      className="alert alert-primary py-2"
                      role="alert"
                    >
                      <i className="m-2 fa-sharp fa-solid fa-circle-info"></i>
                      Debe ingresar las marcas en el orden a mostrar en el home. Para modificar, solo cambie las que necesite y presiones el botón verde.
                    </div>
                  </div>

                </div>


                <form onSubmit={handleSubmit}>
                  <div className="container">
                    <div className="row">
                      <div className="row col-12">
                        <div className="row col-xxl-6 col-xl-6 col-md-12 col-sm-12 col-12 mb-3">
                          <div className="col-xxl-8 col-xl-8 col-md-12 col-sm-12 col-12">
                            <label>
                              Foto 1: &nbsp;<span className="text-danger">*</span>
                            </label>

                            <input
                              type="file"
                              id="foto1"
                              accept="image/*"
                              onChange={(e) =>
                                setFotosNuevas(1, e.target.files[0])
                              }
                              className="form-control col-12"
                            />

                            <select id="marcaMostrar1"
                              value={marca1}
                              onChange={(e) =>
                                setMarca1(e.target.value)
                              }
                              className="mt-3 form-control rounded-pill col-8"
                            >
                              {marcas.map((marca) => (
                                <option key={marca.id} value={marca.id}>
                                  {marca.nombre_mostrar}
                                </option>
                              ))}
                              {/* Opciones de marcas */}
                            </select>
                          </div>
                          <div className="col-xxl-4 col-xl-4 col-md-12 col-sm-12 col-12 h-100 text-center">
                            <label htmlFor="fotoMostrar1" id="labelMostrar1">Logo Actual</label>
                            <img loading="lazy" src="" alt="" id="fotoMostrar1" className="container-fluid" accept="image/jpg, image/jpeg, image/png" />
                          </div>
                        </div>

                        <div className="row col-xxl-6 col-xl-6 col-md-12 col-sm-12 col-12 mb-3 ms-4">
                          <div className="col-xxl-8 col-xl-8 col-md-12 col-sm-12 col-12">
                            <label>
                              Foto 2: &nbsp;<span className="text-danger">*</span>
                            </label>

                            <input
                              type="file"
                              id="foto2"
                              accept="image/*"
                              onChange={(e) =>
                                setFotosNuevas(2, e.target.files[0])
                              }
                              className="form-control col-12"
                            />

                            <select id="marcaMostrar2"
                              value={marca2}
                              onChange={(e) =>
                                setMarca2(e.target.value)
                              }
                              className="mt-3 form-control rounded-pill col-8"
                            >
                              {marcas.map((marca) => (
                                <option key={marca.id} value={marca.id}>
                                  {marca.nombre_mostrar}
                                </option>
                              ))}
                              {/* Opciones de marcas */}
                            </select>
                          </div>
                          <div className="col-xxl-4 col-xl-4 col-md-12 col-sm-12 col-12 h-100 text-center">
                            <label htmlFor="fotoMostrar2" id="labelMostrar2">Logo Actual</label>
                            <img loading="lazy" src="" alt="" id="fotoMostrar2" className="container-fluid" accept="image/jpg, image/jpeg, image/png" />
                          </div>
                        </div>
                      </div>

                      <div className="row col-12">
                        <div className="row col-xxl-6 col-xl-6 col-md-12 col-sm-12 col-12 mb-3">
                          <div className="col-xxl-8 col-xl-8 col-md-12 col-sm-12 col-12">
                            <label>
                              Foto 3: &nbsp;<span className="text-danger">*</span>
                            </label>

                            <input
                              type="file"
                              id="foto3"
                              accept="image/*"
                              onChange={(e) =>
                                setFotosNuevas(3, e.target.files[0])
                              }
                              className="form-control col-12"
                            />

                            <select id="marcaMostrar3"
                              value={marca3}
                              onChange={(e) =>
                                setMarca3(e.target.value)
                              }
                              className="mt-3 form-control rounded-pill col-8"
                            >
                              {marcas.map((marca) => (
                                <option key={marca.id} value={marca.id}>
                                  {marca.nombre_mostrar}
                                </option>
                              ))}
                              {/* Opciones de marcas */}
                            </select>
                          </div>
                          <div className="col-xxl-4 col-xl-4 col-md-12 col-sm-12 col-12 h-100 text-center">
                            <label htmlFor="fotoMostrar3" id="labelMostrar3">Logo Actual</label>
                            <img loading="lazy" src="" alt="" id="fotoMostrar3" className="container-fluid" accept="image/jpg, image/jpeg, image/png" />
                          </div>
                        </div>

                        <div className="row col-xxl-6 col-xl-6 col-md-12 col-sm-12 col-12 mb-3 ms-4">
                          <div className="col-xxl-8 col-xl-8 col-md-12 col-sm-12 col-12">
                            <label>
                              Foto 4: &nbsp;<span className="text-danger">*</span>
                            </label>

                            <input
                              type="file"
                              id="foto4"
                              accept="image/*"
                              onChange={(e) =>
                                setFotosNuevas(4, e.target.files[0])
                              }
                              className="form-control col-12"
                            />

                            <select id="marcaMostrar4"
                              value={marca4}
                              onChange={(e) =>
                                setMarca4(e.target.value)
                              }
                              className="mt-3 form-control rounded-pill col-8"
                            >
                              {marcas.map((marca) => (
                                <option key={marca.id} value={marca.id}>
                                  {marca.nombre_mostrar}
                                </option>
                              ))}
                              {/* Opciones de marcas */}
                            </select>
                          </div>
                          <div className="col-xxl-4 col-xl-4 col-md-12 col-sm-12 col-12 h-100 text-center">
                            <label htmlFor="fotoMostrar4" id="labelMostrar4">Logo Actual</label>
                            <img loading="lazy" src="" alt="" id="fotoMostrar4" className="container-fluid" accept="image/jpg, image/jpeg, image/png" />
                          </div>
                        </div>
                      </div>

                      <div className="row col-12">
                        <div className="row col-xxl-6 col-xl-6 col-md-12 col-sm-12 col-12 mb-3">
                          <div className="col-xxl-8 col-xl-8 col-md-12 col-sm-12 col-12">
                            <label>
                              Foto 5: &nbsp;<span className="text-danger">*</span>
                            </label>

                            <input
                              type="file"
                              id="foto5"
                              accept="image/*"
                              onChange={(e) =>
                                setFotosNuevas(5, e.target.files[0])
                              }
                              className="form-control col-12"
                            />

                            <select id="marcaMostrar5"
                              value={marca5}
                              onChange={(e) =>
                                setMarca5(e.target.value)
                              }
                              className="mt-3 form-control rounded-pill col-8"
                            >
                              {marcas.map((marca) => (
                                <option key={marca.id} value={marca.id}>
                                  {marca.nombre_mostrar}
                                </option>
                              ))}
                              {/* Opciones de marcas */}
                            </select>
                          </div>
                          <div className="col-xxl-4 col-xl-4 col-md-12 col-sm-12 col-12 h-100 text-center">
                            <label htmlFor="fotoMostrar5" id="labelMostrar5">Logo Actual</label>
                            <img loading="lazy" src="" alt="" id="fotoMostrar5" className="container-fluid" accept="image/jpg, image/jpeg, image/png" />
                          </div>
                        </div>

                        <div className="row col-xxl-6 col-xl-6 col-md-12 col-sm-12 col-12 mb-3 ms-4">
                          <div className="col-xxl-8 col-xl-8 col-md-12 col-sm-12 col-12">
                            <label>
                              Foto 6: &nbsp;<span className="text-danger">*</span>
                            </label>

                            <input
                              type="file"
                              id="foto6"
                              accept="image/*"
                              onChange={(e) =>
                                setFotosNuevas(6, e.target.files[0])
                              }
                              className="form-control col-12"
                            />

                            <select id="marcaMostrar6"
                              value={marca6}
                              onChange={(e) =>
                                setMarca6(e.target.value)
                              }
                              className="mt-3 form-control rounded-pill col-8"
                            >
                              {marcas.map((marca) => (
                                <option key={marca.id} value={marca.id}>
                                  {marca.nombre_mostrar}
                                </option>
                              ))}
                              {/* Opciones de marcas */}
                            </select>
                          </div>
                          <div className="col-xxl-4 col-xl-4 col-md-12 col-sm-12 col-12 h-100 text-center">
                            <label htmlFor="fotoMostrar6" id="labelMostrar6">Logo Actual</label>
                            <img loading="lazy" src="" alt="" id="fotoMostrar6" className="container-fluid" accept="image/jpg, image/jpeg, image/png" />
                          </div>
                        </div>
                      </div>

                      <div className="row col-12">
                        <div className="row col-xxl-6 col-xl-6 col-md-12 col-sm-12 col-12 mb-3">
                          <div className="col-xxl-8 col-xl-8 col-md-12 col-sm-12 col-12">
                            <label>
                              Foto 7: &nbsp;<span className="text-danger">*</span>
                            </label>

                            <input
                              type="file"
                              id="foto7"
                              accept="image/*"
                              onChange={(e) =>
                                setFotosNuevas(7, e.target.files[0])
                              }
                              className="form-control col-12"
                            />

                            <select id="marcaMostrar7"
                              value={marca7}
                              onChange={(e) =>
                                setMarca7(e.target.value)
                              }
                              className="mt-3 form-control rounded-pill col-8"
                            >
                              {marcas.map((marca) => (
                                <option key={marca.id} value={marca.id}>
                                  {marca.nombre_mostrar}
                                </option>
                              ))}
                              {/* Opciones de marcas */}
                            </select>
                          </div>
                          <div className="col-xxl-4 col-xl-4 col-md-12 col-sm-12 col-12 h-100 text-center">
                            <label htmlFor="fotoMostrar7" id="labelMostrar7">Logo Actual</label>
                            <img loading="lazy" src="" alt="" id="fotoMostrar7" className="container-fluid" accept="image/jpg, image/jpeg, image/png" />
                          </div>
                        </div>


                        <div className="row col-xxl-6 col-xl-6 col-md-12 col-sm-12 col-12 mb-3 ms-4">
                          <div className="col-xxl-8 col-xl-8 col-md-12 col-sm-12 col-12">
                            <label>
                              Foto 8: &nbsp;<span className="text-danger">*</span>
                            </label>

                            <input
                              type="file"
                              id="foto8"
                              accept="image/*"
                              onChange={(e) =>
                                setFotosNuevas(8, e.target.files[0])
                              }
                              className="form-control col-12"
                            />

                            <select id="marcaMostrar8"
                              value={marca8}
                              onChange={(e) =>
                                setMarca8(e.target.value)
                              }
                              className="mt-3 form-control rounded-pill col-8"
                            >
                              {marcas.map((marca) => (
                                <option key={marca.id} value={marca.id}>
                                  {marca.nombre_mostrar}
                                </option>
                              ))}
                              {/* Opciones de marcas */}
                            </select>
                          </div>
                          <div className="col-xxl-4 col-xl-4 col-md-12 col-sm-12 col-12 h-100 text-center">
                            <label htmlFor="fotoMostrar8" id="labelMostrar8">Logo Actual</label>
                            <img loading="lazy" src="" alt="" id="fotoMostrar8" className="container-fluid" accept="image/jpg, image/jpeg, image/png" />
                          </div>
                        </div>
                      </div>



                      <div className="row justify-content-end mt-4 mb-5">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                          <button aria-label="Agregar grandes marcas"
                            className="btnGreen bg-primary text-white px-3 fw-bold col-12"
                            type="submit"
                          >
                            Agregar grandes marcas
                          </button>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 ">
                          <button type="button"
                            onClick={() => setListarMarcas(1)}
                            className="btnGreen text-white px-3 fw-bold cancel col-12"
                          >
                            Volver a grandes marcas
                          </button>
                        </div>
                      </div>




                    </div>
                  </div>

                  {/* Repite los bloques anteriores para las fotos 2 al 6 */}

                </form>
              </div>

            )}
          </div>
        </div>

      </div>





    </div>
  );
};

export default GrandesMarcas;
