/**
 * Página Admin Pedidos Chofer:
 * 
 * acá se Administran pedidos chofer
 * 
 * @module AdminPedidosChoferjsx
 */


import { useState, useEffect, Component, createContext } from "react";
import { useRef } from "react";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
//import Button from 'react-bootstrap/Button';
//import ModalDialog from 'react-bootstrap/ModalDialog';
import parse from 'html-react-parser';

import { AdminSidebar } from "../../components";
import Mensaje from "../../components/Modales/Mensaje";

const PedidosChofer = () => {


  /**
* @constant
* @name mensaje
* @type {string}
* 
* */

  const [mensaje, setMensaje] = useState("");

  /**
* @constant
* @name compra
* @type {string}
* 
* */

  const [compra, setCompra] = useState('');

  /**
* @constant
* @name foto
* @type {string}
* 
* */
  const [foto, setFoto] = useState('');


  /**
* @constant
* @name pedidos
* @type {Array}
* 
* */
  const [pedidos, setPedidos] = useState([]);

  /**
* @constant
* @name currentPage
* @type {number}
* 
* */
  const [currentPage, setCurrentPage] = useState(1);

  /**
* @constant
* @name show
* @type {boolean}
* 
* */
  const [show, setShow] = useState(false);


  /**
* @constant
* @name modal_body
* @type {string}
* 
* */
  const [modal_body, setModalBody] = useState('');

  /**
* @constant
* @name showFoto
* @type {boolean}
* 
* */
  const [showFoto, setShowFoto] = useState(false);

  /**
* @constant
* @name showDetalle
* @type {boolean}
* 
* */
  const [showDetalle, setShowDetalle] = useState(false);

  /**
* @constant
* @name detallePedido
* @type {string}
* 
* */
  const [detallePedido, setDetallePedido] = useState("");

  /**
* @constant
* @name totalPaginas
* @type {string}
* 
* */
  const [totalPaginas, setTotalPaginas] = useState('');
  const [mesFiltro, setMesFiltro] = useState(parseInt(new Date().getMonth()) + 1);
  const [anioFiltro, setAnioFiltro] = useState(new Date().getFullYear());

  /**
* @constant
* @name estadoFlecha
* @type {number}
* 
* */
  const [estadoFlecha, setEstadoFlecha] = useState(1);
  const tipoConsulta = useRef(1);
  const [filtroSeleccion, setFiltroSeleccion] = useState(0);
  const [filtroPage, setFiltroPage] = useState(1);

  let mesesLlenar = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
  let aniosLlenar = [];

  const handleCloseFoto = () => {
    setShowFoto(false);
  }

  const handleCloseDetalle = () => {
    setShowDetalle(false);
  }

  const handleClose = () => {
    setModalBody('');
    setShow(false);
  }

  const handleAnioChange = (e) => {
    setAnioFiltro(e.target.value);
  };

  const handleMesChange = (e) => {
    setMesFiltro(parseInt(e.target.value) + 1);
  };

  /**
* @function
* Metodo para rellenar un selector con años mayor o igual a 1900
*/
  const llenarAnio = () => {
    const year = new Date().getFullYear();
    let retornar = "";
    for (let i = year; i >= 1900; i--) {
      aniosLlenar.push(i);
    }
  };

  const filtrar = (e) => {
    obtenerPedidos();
  };

  const handleShowFoto = () => setShowFoto(true);
  const handleShowDetalle = () => setShowDetalle(true);
  const handleShow = () => setShow(true);

  /**
* @function
* Metodo para mostrar el detalle de la compra en un modal
* @param {json} detalle_compra Objeto que contiene el detalle de la compra
* @returns {string}
*/
  const mostrarDetalleCompra = (detalle_compra) => {

    let detalles = detalle_compra;
    let dc = '';

    detalles.map((index) => {

      let row = '';

      row = `
    
        <div className="row justify-content-center text-center">
            <div className="col-lg-2 col-md-2 col-12 text-center align-self-center">
                <span>${index.sku}</span>
            </div>
            <div className="col-lg-2 col-md-2 col-12 text-center align-self-center">
                <img loading="lazy" width="100px" height="auto" src="${process.env.REACT_APP_FRONT_URL + index.foto_1}" aria-label="${index.producto_nombre}">
            </div>
            <div className="col-lg-2 col-md-2 col-12 text-center align-self-center">
                ${index.producto_nombre}
            </div>
            <div className="col-lg-2 col-md-2 col-12 text-center align-self-center">
                ${index.cantidad}
            </div>
            <div className="col-lg-2 col-md-2 col-12 text-center align-self-center">
                $${Intl.NumberFormat('es-CL').format(index.precio_unitario * index.cantidad)}
            </div>

            <div className="col-10 text-center">
              <hr>
            </div>

        </div>
      
        `;

      dc += row;

    })

    setModalBody(dc);

    handleShow();

  }

  const verDetalle = (envio) => {
    setDetallePedido(envio);
    handleShowDetalle();
  };

  const cambiaFoto = (e) => {
    let foto = document.getElementById('fotoMostrar');
    foto.src = URL.createObjectURL(e.target.files[0]);
  };

  const cargaFoto = (compra, foto) => {
    setCompra(compra);
    setFoto(foto);
    handleShowFoto();
  }
  /**
* @function
* Petición HTTP para ingresar la fotode entrega del pedido
* @param {Object} enviar formData con los datos para subir la foto de entrega del pedido
* @returns {Array}
*/
  const subirFoto = async () => {
    const foto = document.getElementById('fotoSubir').files[0];
    if (foto != null) {
      setMensaje("Cambiando la foto-alert");
      const enviar = new FormData();
      enviar.append("foto", foto);
      enviar.append("id_compra", compra);
      enviar.append("id_tienda", 1);
      const response = await axios.post(
        process.env.REACT_APP_BACK_URL + `/api/admin/subir_foto_pedido_chofer`, enviar, {
        headers: {
          Authorization: `Bearer ${accessToekn}`,
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        if (response.data == "Foto registrada") {
          document.getElementById("fotoSubir").value = "";
          obtenerPedidos();
          setMensaje("Foto subida correctamente-verde");
        }
      }).catch(error => {
        setMensaje("Error al subir la foto-rojo");
      })
    }
  };

  /**
* @function
* Petición HTTP que obtiene los pedidos filtrados por mes y año 
* @param {number} currentPage Pagina actual
* @param {string} anioFiltro Año para filtrar los pedidos
* @param {string} mesFiltro Mes para filtror los pedidos
* @returns {Array}
*/
  const obtenerPedidos = async () => {
    const response = await axios.get(
      process.env.REACT_APP_BACK_URL + `/api/admin/listar_pedidos_chofer_entregar_web?page=${currentPage}&size=10&anio_filtro=${anioFiltro}&mes_filtro=${mesFiltro}`, {
      headers: {
        Authorization: `Bearer ${accessToekn}`,
      },
    }).then(response => {
      setPedidos(response.data.results);
      setTotalPaginas(response.data.pages);
    }).catch(error => {
      console.log(error);
    })
  };

  const accessToekn = sessionStorage.getItem("access");


  /**
* @function
* Petición HTTP que cambia el estado del despacho de una compra
* @param {string} despacho Estado de despacho
* @param {string} id_pedido Id del pedido
* @returns {Array}
*/
  const cambiarDespacho = (e) => {
    let id_pedido = e.target.getAttribute('data-set-id');
    let despacho = e.target.value;

    e.preventDefault();
    setMensaje("Cambiando despacho-alert");
    axios.get(process.env.REACT_APP_BACK_URL + `/api/admin/cambiar_estado_compra?despacho=${despacho}&compra=${id_pedido}`, {
      headers: {
        Authorization: `Bearer ${accessToekn}`,
      },
    }).then(response => {

      obtenerPedidos();
      setMensaje("Despacho cambiado-verde");
    }).catch(error => {
      setMensaje("Error al cambiar el despacho-rojo");
    })
  }


  /**
* @function
* Metodo para cambiar la flecha de los accordiones de bootstrap
* 
*/
  const cambiaFlecha = () => {
    let flecha = document.querySelector("#boton-acordeon");
    let collapse = document.querySelector("#collapseOne");

    if (estadoFlecha == 1) {
      flecha.classList.remove("fa-arrow-circle-down");
      flecha.classList.add("fa-arrow-circle-up");
      setEstadoFlecha(2);
    }
    else {
      flecha.classList.remove("fa-arrow-circle-up");
      flecha.classList.add("fa-arrow-circle-down");
      setEstadoFlecha(1);
    }
  };

  useEffect(() => {

    obtenerPedidos();


  }, [currentPage]);

  const limpiaFiltro = () => {
    setFiltroSeleccion(0);
    tipoConsulta.current = 1;
    obtenerPedidos();
  };

  const cambiaFiltro = (e) => {
    setFiltroSeleccion(e);
  };

  useEffect(() => {
    if (filtroSeleccion != 0) {
      const response = axios.get(
        process.env.REACT_APP_BACK_URL + `/api/admin/listar_pedidos_chofer_entregar_web?filtro_page=${filtroPage}&size=10&anio_filtro=${anioFiltro}&mes_filtro=${mesFiltro}&estado_filtro=${filtroSeleccion}`, {
        headers: {
          Authorization: `Bearer ${accessToekn}`,
        },
      }).then(response => {
        setPedidos(response.data.results);
        setTotalPaginas(response.data.pages);
        tipoConsulta.current = 2;
      }).catch(error => {
        console.log(error);
      })
    }
  }, [filtroSeleccion, filtroPage]);

  return (

    <div>
      <Mensaje mensaje={mensaje} setMensaje={setMensaje} />

      <Modal show={showDetalle} onHide={handleCloseDetalle} size="lg" centered={true}>
        <Modal.Header closeButton className="border-bottom-0">
        </Modal.Header>
        <Modal.Body>

          <div className="container" >
            <table className="table border nameNumber p-1">
              <tr className="text-center"><th colSpan={2}>Detalle del Pedido: {detallePedido.id_compra}</th></tr>
              <tr><th>Cliente</th><td>
                {detallePedido.last_name && detallePedido.last_name != ".zzzzz." ? (
                  detallePedido.first_name + " " + detallePedido.last_name
                ) : (
                  detallePedido.first_name
                )}

              </td></tr>
              <tr><th>Dirección</th><td>{detallePedido.direccion == 'Dreamtec Tienda' ? (
                'Retiro en tienda'
              ) : (

                <>
                  {detallePedido.direccion}
                  <br />
                  {detallePedido.nombre_comuna}
                  <br />
                  {detallePedido.nombre_region}
                </>

              )}</td></tr>
              <tr><th>Correo</th><td>{detallePedido.email != '' && (
                <a className="text-decoration-none p-0" style={{ color: "rgb(52, 71, 103)" }} href={"mailto:" + detallePedido.email}>{detallePedido.email}</a>
              )}</td></tr>
              <tr><th>Teléfono</th><td>
                {detallePedido.telefono != '' && (
                  <a className="text-decoration-none p-0" style={{ color: "rgb(52, 71, 103)" }} href={"tel:+" + detallePedido.telefono}>{detallePedido.telefono}</a>
                )}</td></tr>
              <tr><th>Estado Despacho</th><td>{detallePedido.estado_pago != 3 ? (
                <>

                  {
                    detallePedido.estado_despacho == 'Entregada' && (
                      <>
                        <i className="fa-solid fa-circle-check text-success"></i>
                        Entregado
                      </>
                    )
                  }
                  {
                    detallePedido.estado_despacho == 'Entregado en tienda' && (
                      <>
                        <i className="fa-solid fa-circle-check text-success"></i>
                        Entregado en tienda
                      </>
                    )
                  }
                  {
                    detallePedido.estado_despacho == 'En Camino' && (
                      <>
                        <i className="fa-solid fa-truck text-warning"></i>
                        En Camino
                      </>
                    )
                  }
                  {
                    detallePedido.estado_despacho == 'En Preparación' && (
                      <>
                        <i className="fa-solid fa-circle-pause text-danger"></i>
                        En Preparación
                      </>
                    )
                  }
                  {
                    detallePedido.estado_despacho == 'Venta Recibida' && (

                      <>
                        <i className="fa-solid fa-circle-pause text-primary"></i>
                        Venta Recibida
                      </>

                    )}

                </>

              ) : (

                <>
                  <div><strong>N/A</strong></div>
                </>

              )}</td></tr>
              <tr><th>Acciones</th><td><div className="col-lg-12 col-md-12 col-12 text-center">
                <select className="form-select rounded-pill" data-set-id={detallePedido.id_compra} onChange={cambiarDespacho}>
                  <option value="0" disabled>Seleccione una opción</option>
                  {detallePedido.estado_despacho == 'En Camino' ? <option value="3" selected>En Camino</option> : <option value="3" >En Camino</option>}
                  {detallePedido.estado_despacho == 'Entregado' ? <option value="4" selected>Entregado</option> : <option value="4" >Entregado</option>}
                </select>
              </div></td></tr>
              <tr><th>Foto Entrega</th><td>
                {detallePedido.foto_entrega && (
                  <img loading="lazy" src={process.env.REACT_APP_FRONT_URL + detallePedido.foto_entrega} className="img-fluid" alt="" />
                )}
                <div className="nameNumber text-center align-self-center">
                  <button type="button" aria-label="Ver detalle compra" title="Ver detalle compra" className="agregar border-0 btnCelesteDreamtec text-white"
                    onClick={() => { cargaFoto(detallePedido.id_compra, detallePedido.foto_entrega) }}><i className="fa-solid fa-camera"></i>
                  </button>
                </div>
              </td></tr>

              <div class="accordion d-xs-block d-md-none" id="accordionExample">
                <div class="accordion-item border border-0">
                  <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      {detallePedido.detalle_pedido?.map((detalle) => {
                        return (<tr className="align-middle"><td>{detalle.producto_nombre}</td><td><img className="img-fluid" src={process.env.REACT_APP_FRONT_URL + detalle.foto_1} alt="" /></td></tr>)
                      })}
                    </div>
                  </div>

                  <h2 class="accordion-header" id="headingOne">
                    <button class="accordion-button text-center" onClick={cambiaFlecha} type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      <i id="boton-acordeon" className="fas fa-arrow-circle-down text-turq fs-16"></i>
                      &nbsp;Ver Producto del Pedido
                    </button>
                  </h2>
                </div>
              </div>


            </table>
          </div>

        </Modal.Body>
      </Modal>

      <Modal show={showFoto} onHide={handleCloseFoto} size="lg" centered={true}>
        <Modal.Header closeButton className="border-bottom-0">
        </Modal.Header>
        <Modal.Body>

          <div className=" container" >

            <div className="row justify-content-center text-center col-12 mb-4">
              <div className="row col-lg-12 text-center align-self-center mb-4">
                <div className="col-lg-6 col-md-12">
                  <input onChange={cambiaFoto} type="file" name="fotoSubir" id="fotoSubir" className="form-control" accept="image/jpg, image/jpeg, image/png" />
                </div>
                <div className="col-lg-6 col-md-12">
                  <button onClick={subirFoto} className="agregar border-0 btnCelesteDreamtec text-white">Subir Imagen</button>
                </div>
              </div>
              <div className="col-12 text-center align-self-center">
                {foto != "" && foto != null ? (
                  <>
                    <div className="mb-2"><strong>Foto actual</strong></div>
                    <img loading="lazy" src={process.env.REACT_APP_FRONT_URL + foto} alt="" id="fotoMostrar" className="img-fluid" />
                  </>
                ) : (
                  <img loading="lazy" src="" alt="" id="fotoMostrar" className="img-fluid" />
                )}
              </div>
            </div>
          </div>

        </Modal.Body>
      </Modal>

      <Modal show={show} onHide={handleClose} size="lg" centered={true}>
        <Modal.Header closeButton className="border-bottom-0">
        </Modal.Header>
        <Modal.Body>

          <div className="container">
            <div className="row justify-content-center mb-5">

              <div className="col-lg-2 col-md-2 col-12 text-center">
                <strong>SKU</strong>
              </div>

              <div className="col-lg-2 col-md-2 col-12 text-center">
                <strong>Foto</strong>
              </div>

              <div className="col-lg-2 col-md-2 col-12 text-center">
                <strong>Nombre</strong>
              </div>

              <div className="col-lg-2 col-md-2 col-12 text-center">
                <strong>Cantidad</strong>
              </div>

              <div className="col-lg-2 col-md-2 col-12 text-center">
                <strong>Sub total</strong>
              </div>
            </div>
          </div>
          <div className=" container" >
            {modal_body != '' ? (
              parse(modal_body)
            ) : (

              <div className="row my-5">
                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 fw-bold text-center">
                  <i className="fa-solid fa-circle-info"></i>&nbsp;No hay detalle del pedido
                </div>
              </div>

            )}
          </div>

        </Modal.Body>
      </Modal>


      <AdminSidebar />

      <div className="regular">
        <h1>Dreamtec - <span>Pedidos Web para despachar</span></h1>

        <div className="shadow">

          <div className="row">

            <div className="col-12 text-center mb-4">
              <h2>Listado Pedidos Web</h2>
            </div>
            <div className="col-12">

              <div className="row d-flex justify-content-center mb-4 mt-1">
                <div className="col-lg-2 col-md-4 col-12 text-center mt-1">
                  <select id='anioSelect' onChange={handleAnioChange} className="form-select rounded-pill">
                    {llenarAnio()}
                    {
                      aniosLlenar.map((mesesLlenar) => {
                        return <option value={mesesLlenar}>{mesesLlenar}</option>
                      })
                    }
                  </select>
                </div>

                <div className="col-lg-2 col-md-4 col-12 text-center mb-4 mt-1">
                  <select id="mesSelect" onChange={handleMesChange} className="form-select rounded-pill">
                    {
                      mesesLlenar.map((mesesLlenar, index) => {
                        if ((index + 1) == mesFiltro) {
                          return <option value={index} selected>{mesesLlenar}</option>
                        }
                        else {
                          return <option value={index}>{mesesLlenar}</option>
                        }
                      })}
                  </select>
                </div>

                <div className="col-lg-2 col-md-4 col-12 mt-1">
                  <button className="agregar border-0 btnCelesteDreamtec text-white " onClick={filtrar} aria-label="filtrar">Filtrar</button>
                </div>

                <div className="row text-end justify-content-end mt-3">
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-3">
                    <div className="input-group buscador">
                      <select
                        type="text"
                        className="form-control input-search border-end-0"
                        placeholder="Filtrar por: Nombre, Rut, Correo, Teléfono, Compra o N° Documento"
                        style={{ borderStartStartRadius: "20px", borderEndStartRadius: "20px", borderRight: "0px" }}
                        value={filtroSeleccion}
                        onChange={(e) => cambiaFiltro(e.target.value)}
                      >
                        <option value="0">Filtrar Estado Compra</option>
                        <option value="3">Compras Listas Para Despachar</option>
                        <option value="4">Compras Entregadas</option>
                      </select>
                      <span role="button"
                        className="bg-transparent input-group-text border-start-0"
                        style={{ borderEndEndRadius: "20px", borderStartEndRadius: "20px" }}
                        onClick={limpiaFiltro}
                      >
                        <i className="fa fa-filter-circle-xmark"></i>
                      </span>
                    </div>
                  </div>
                </div>

              </div>

              {pedidos ? (
                <>
                  {/* Version Web */}
                  <div className="wrapper1 d-xs-none d-md-block">
                    <div className="wrapper2">

                      <table className="table border">
                        <thead className="p-4">
                          <tr><th className="col" style={{ width: "100px" }}><div className="id">ID</div></th>
                            <th className="col" style={{ width: "200px" }}><div className="id">Detalle compra</div></th>
                            <th className="col" style={{ width: "200px" }}><div className="id">Dirección</div></th>
                            <th className="col" style={{ width: "150px" }}><div className="id">Estado de Despacho</div></th>
                            <th className="col" style={{ width: "150px" }}><div className="id">Datos de cliente</div></th>
                            <th className="col" style={{ width: "150px" }}><div className="id">Contacto cliente</div></th>
                            <th className="col" style={{ width: "150px" }}><div className="id">Foto entrega</div></th>
                            <th className="col" style={{ width: "250px" }}><div className="id">Acciones</div></th>
                          </tr></thead>
                        <tbody>
                          {pedidos
                            ? pedidos.map((pedido) => (

                              <tr key={pedido.id}>
                                <td ><div style={{ width: "100px" }} className="nameNumber text-center align-self-center">{pedido.id_compra}</div></td>
                                <td ><div style={{ width: "200px" }} className="nameNumber text-center align-self-center">
                                  <button type="button" aria-label="Ver detalle compra" title="Ver detalle compra" className="btnGreen" style={{
                                    backgroundColor: "#0d6efd"
                                  }}
                                    onClick={() => { mostrarDetalleCompra(pedido.detalle_pedido) }}><i className="fa-solid fa-eye"></i>
                                  </button>
                                </div></td>

                                <td ><div style={{ width: "200px" }} className="nameNumber text-center">

                                  {pedido.direccion == 'Dreamtec Tienda' ? (
                                    'Retiro en tienda'
                                  ) : (

                                    <>
                                      {pedido.direccion}
                                      <br></br>
                                      {pedido.nombre_comuna}
                                      <br />
                                      {pedido.nombre_region}
                                    </>

                                  )}

                                  <div className="mt-1"><strong>{pedido.horario_pedido}</strong></div>

                                </div></td>


                                <td ><div style={{ width: "150px" }} className="nameNumber text-center">

                                  {pedido.estado_pago != 3 ? (

                                    <>

                                      {
                                        pedido.estado_despacho == 'Entregada' && (
                                          <>
                                            <i className="fa-solid fa-circle-check text-success"></i>
                                            <div><strong>Entregado</strong></div>
                                          </>
                                        )
                                      }
                                      {
                                        pedido.estado_despacho == 'Entregado en tienda' && (
                                          <>
                                            <i className="fa-solid fa-circle-check text-success"></i>
                                            <div><strong>Entregado en tienda</strong></div>
                                          </>
                                        )
                                      }
                                      {
                                        pedido.estado_despacho == 'En Camino' && (
                                          <>
                                            <i className="fa-solid fa-truck text-warning"></i>
                                            <div><strong>En Camino</strong></div>
                                          </>
                                        )
                                      }
                                      {
                                        pedido.estado_despacho == 'En Preparación' && (
                                          <>
                                            <i className="fa-solid fa-circle-pause text-danger"></i>
                                            <div><strong>En Preparación</strong></div>
                                          </>
                                        )
                                      }
                                      {
                                        pedido.estado_despacho == 'Venta Recibida' && (

                                          <>
                                            <i className="fa-solid fa-circle-pause text-primary"></i>
                                            <div><strong>Venta Recibida</strong></div>
                                          </>

                                        )}

                                    </>

                                  ) : (

                                    <>
                                      <div><strong>N/A</strong></div>
                                    </>

                                  )}

                                </div></td>
                                <td ><div style={{ width: "150px" }} className="nameNumber text-center">
                                  {pedido.last_name && pedido.last_name != ".zzzzz." ? (
                                    pedido.first_name + " " + pedido.last_name
                                  ) : (
                                    pedido.first_name
                                  )}
                                </div></td>


                                <td ><div style={{ width: "150px" }} className="nameNumber text-center">
                                  {pedido.email != '' && (
                                    <a className="text-decoration-none text-dark" href={"mailto:" + pedido.email}>{pedido.email}</a>
                                  )}
                                  {pedido.email != '' ? <br /> : null}
                                  {pedido.telefono != '' && (
                                    <a className="text-decoration-none text-dark" href={"tel:+" + pedido.telefono}>{pedido.telefono}</a>
                                  )}
                                </div></td>


                                <td ><div style={{ width: "150px" }} className="nameNumber text-center">

                                  <div>

                                    <div style={{ width: "150px" }} className="nameNumber text-center align-self-center">
                                      <button type="button" aria-label="Ver detalle compra" title="Ver detalle compra" className="agregar border-0 btnCelesteDreamtec text-white"
                                        onClick={() => { cargaFoto(pedido.id_compra, pedido.foto_entrega) }}><i className="fa-solid fa-camera"></i>
                                      </button>
                                    </div>
                                  </div>
                                </div></td>
                                <td ><div style={{ width: "250px" }} className="nameNumber text-center">




                                  {pedido.estado_pago != 3 && (

                                    <>


                                      {pedido.direccion == 'Dreamtec Tienda' ? (
                                        <>

                                          <div className="col-lg-12 col-md-12 col-12 text-center">
                                            <label>Despacho</label>
                                            <select className={pedido.estado_pago == 2 ? "form-select rounded-pill" : "form-select rounded-pill disabled-select disabled readonly"} data-set-id={pedido.id_compra} onChange={cambiarDespacho}>
                                              <option value="0" disabled>Seleccione una opción</option>
                                              {pedido.estado_despacho == 'Entregado en tienda' ? <option value="5" selected>Entregado en tienda</option> : <option value="5" >Entregado en tienda</option>}
                                              {pedido.estado_despacho == 'En Preparación' ? <option value="2" selected>En Preparación</option> : <option value="2" >En Preparación</option>}
                                              {pedido.estado_despacho == 'En Camino' ? <option value="3" selected>En Camino</option> : <option value="3" >En Camino</option>}
                                            </select>
                                          </div>

                                        </>

                                      ) : (


                                        <>


                                          <div className="col-lg-12 col-md-12 col-12 text-center">
                                            <label>Despacho</label>
                                            <select className="form-select rounded-pill" data-set-id={pedido.id_compra} onChange={cambiarDespacho}>
                                              <option value="0" disabled>Seleccione una opción</option>
                                              {pedido.estado_despacho == 'En Camino' ? <option value="3" selected>En Camino</option> : <option value="3" >En Camino</option>}
                                              {pedido.estado_despacho == 'Entregada' ? <option value="4" selected>Entregado</option> : <option value="4" >Entregado</option>}
                                            </select>
                                          </div>



                                        </>

                                      )}

                                    </>


                                  )}

                                </div>
                                </td>
                              </tr>

                            ))
                            : ""}
                        </tbody>
                      </table>

                    </div>
                  </div>

                  <div className="row justify-content-end mt-3">
                    <div className="col-xxl-1 col-xl-1 col-lg-1 col-md-6 col-sm-6 col-6">

                      {currentPage > 1 && (
                        <button type="button" aria-label="Anterior" className="agregar border-0 btnCelesteDreamtec" onClick={() => setCurrentPage(currentPage - 1)}>
                          <i className="fa-solid fa-circle-arrow-left fs-16 text-white"></i>
                        </button>
                      )}

                    </div>

                    <div className="col-xxl-1 col-xl-1 col-lg-1 col-md-6 col-sm-6 col-6">

                      {currentPage + 1 <= totalPaginas && (

                        <button type="button" aria-label="Siguiente" className="agregar border-0 btnCelesteDreamtec" onClick={() => setCurrentPage(currentPage + 1)}>
                          <i className="fa-sharp fa-solid fs-16  fa-circle-arrow-right text-white"></i>
                        </button>

                      )}


                    </div>
                  </div>

                  {/* Version Movil */}
                  <div className="wrapper1 d-xs-block d-md-none">
                    <div className="wrapper2">
                      {pedidos ? pedidos.map((pedido) => (
                        <>
                          <div className="row border border-1 nameNumber mb-5 border rounded">
                            <div className="row border-bottom px-0 mx-0 py-1 bg-light">
                              <div className="col-4 px-0 ms-2"><span><strong>Pedido: </strong></span>{pedido.id_compra}</div>
                              <div className="col-7 text-end px-0"><span><strong>Para:</strong></span>
                                {pedido.last_name && pedido.last_name != ".zzzzz." ? (
                                  pedido.first_name + " " + pedido.last_name
                                ) : (
                                  pedido.first_name
                                )}
                              </div>
                            </div>
                            <div className="row mx-0 py-2">

                              <div className="col-6 mb-3">
                                <span><strong>Dirección</strong></span>
                                <br />
                                {pedido.direccion == 'Dreamtec Tienda' ? (
                                  'Retiro en tienda'
                                ) : (

                                  <>
                                    {pedido.direccion}
                                    <br></br>
                                    {pedido.nombre_comuna}
                                  </>

                                )}
                              </div>
                              <div className="col-6 mb-3">
                                <span><strong>Contacto</strong></span>
                                <br />
                                {pedido.email != '' && (
                                  <a className="text-decoration-none" style={{ color: "rgb(52, 71, 103)" }} href={"mailto:" + pedido.email}>{pedido.email}</a>
                                )}
                                {pedido.email != '' ? <br /> : null}
                                {pedido.telefono != '' && (
                                  <a className="text-decoration-none" style={{ color: "rgb(52, 71, 103)" }} href={"tel:+" + pedido.telefono}>{pedido.telefono}</a>
                                )}
                              </div>

                              <div className="col-6">
                                <span><strong>Estado</strong></span>
                                <br />
                                {pedido.estado_pago != 3 ? (
                                  <>

                                    {
                                      pedido.estado_despacho == 'Entregada' && (
                                        <>
                                          <div>Entregado</div>
                                          <i className="fa-solid fa-circle-check text-success"></i>
                                        </>
                                      )
                                    }
                                    {
                                      pedido.estado_despacho == 'Entregado en tienda' && (
                                        <>
                                          <div>Entregado en tienda</div>
                                          <i className="fa-solid fa-circle-check text-success"></i>
                                        </>
                                      )
                                    }
                                    {
                                      pedido.estado_despacho == 'En Camino' && (
                                        <>
                                          <div>En Camino</div>
                                          <i className="fa-solid fa-truck text-warning"></i>
                                        </>
                                      )
                                    }
                                    {
                                      pedido.estado_despacho == 'En Preparación' && (
                                        <>
                                          <div>En Preparación</div>
                                          <i className="fa-solid fa-circle-pause text-danger"></i>
                                        </>
                                      )
                                    }
                                    {
                                      pedido.estado_despacho == 'Venta Recibida' && (

                                        <>
                                          <div>Venta Recibida</div>
                                          <i className="fa-solid fa-circle-pause text-primary"></i>
                                        </>

                                      )}

                                  </>

                                ) : (

                                  <>
                                    <div><strong>N/A</strong></div>
                                  </>

                                )}
                              </div>
                              <div className="col-6 mb-2">
                                <span><strong>Detalles</strong></span>
                                <br />
                                <div className="col-3 nameNumber text-center align-self-center">
                                  <button type="button" aria-label="Ver detalle compra" title="Ver detalle compra" className=" btnGreen agregar border-0 btnCelesteDreamtec text-white"
                                    onClick={() => { verDetalle(pedido) }}><i className="fa-solid fa-plus"></i>
                                  </button>
                                </div>
                              </div>

                            </div>
                          </div>
                        </>
                      ))
                        :
                        ""}

                    </div>
                  </div>

                  <div className="row justify-content-end mt-3">
                    <div className="col-xxl-1 col-xl-1 col-lg-1 col-md-6 col-sm-6 col-6">
                      {tipoConsulta.current == 1 && (
                        <>
                          {currentPage > 1 && (
                            <button type="button" aria-label="Anterior" className="agregar border-0 btnCelesteDreamtec" onClick={() => setCurrentPage(currentPage - 1)}>
                              <i className="fa-solid fa-circle-arrow-left fs-16 text-white"></i>
                            </button>
                          )}
                        </>
                      )}
                      {tipoConsulta.current == 2 && (
                        <>
                          {filtroPage > 1 && (
                            <button type="button" aria-label="Anterior" className="agregar border-0 btnCelesteDreamtec" onClick={() => setFiltroPage(filtroPage - 1)}>
                              <i className="fa-solid fa-circle-arrow-left fs-16 text-white"></i>
                            </button>
                          )}
                        </>
                      )}
                    </div>

                    <div className="col-xxl-1 col-xl-1 col-lg-1 col-md-6 col-sm-6 col-6">
                      {tipoConsulta.current == 1 && (
                        <>
                          {currentPage + 1 <= totalPaginas && (

                            <button type="button" aria-label="Siguiente" className="agregar border-0 btnCelesteDreamtec" onClick={() => {
                              setCurrentPage(currentPage + 1)
                            }}>
                              <i className="fa-sharp fa-solid fs-16  fa-circle-arrow-right text-white"></i>
                            </button>

                          )}
                        </>
                      )}
                      {tipoConsulta.current == 2 && (
                        <>
                          {filtroPage + 1 <= totalPaginas && (

                            <button type="button" aria-label="Siguiente" className="agregar border-0 btnCelesteDreamtec" onClick={() => {
                              setFiltroPage(filtroPage + 1)
                            }}>
                              <i className="fa-sharp fa-solid fs-16  fa-circle-arrow-right text-white"></i>
                            </button>

                          )}
                        </>
                      )}

                    </div>
                  </div>

                </>

              ) : (

                <div className="row my-5">
                  <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 fw-bold text-center">
                    <i className="fa-solid fa-circle-info"></i>&nbsp;No hay pedidos web para despachar
                  </div>
                </div>

              )}

            </div>

          </div>

        </div>
      </div>





    </div >
  );

};

export default PedidosChofer;
