/**
 * Página Admin Usuarios:
 * 
 * acá se visualizan los usuarios web y pdv
 * 
 * @module LogTransaccionesjsx
 */


import { useState, useEffect, useRef } from "react";
import axios from "axios";

import { AdminSidebar } from "../../components";

const LogTransacciones = () => {
  const accessToekn = sessionStorage.getItem("access");
  const [logs, setLogs] = useState("");
  const [buscar, setBuscar] = useState("");
  const [totalPaginas, setTotalPaginas] = useState(0);
  const [paginaActual, setPaginaActual] = useState(1);
  const queryId = useRef(1);
  const mostrando = useRef(1);

  const setBuscarLog = (texto) => {
    setBuscar(texto)
  };

  const clearFiltro = () => {
    setBuscar("");
    setLogs("");
    setTotalPaginas(0);
    setPaginaActual(1);
    queryId.current = 1;
    mostrando.current = 1;
  };

  const getFecha = (fecha) => {
    if (fecha === null)
      return null;
    else {
      let fecha_compra = new Date(fecha);
      let dia = fecha_compra.getDate();
      let mes = fecha_compra.getMonth() + 1;
      let anio = fecha_compra.getFullYear();

      let hour = fecha_compra.getHours();
      let minutes = fecha_compra.getMinutes();
      let seg = fecha_compra.getSeconds();

      dia = dia <= 9 ? '0' + dia : dia;
      mes = mes <= 9 ? '0' + mes : mes;
      minutes = minutes <= 9 ? '0' + minutes : minutes;
      seg = seg <= 9 ? '0' + seg : seg;

      return `${dia}-${mes}-${anio} ${hour}:${minutes}:${seg}`;
    }
  }

  useEffect(() => {
    if (buscar != "") {
      const fetchData = async () => {
        const response = await axios.get(
          process.env.REACT_APP_BACK_URL + `/api/admin/buscar_log_transacciones?cadena=${buscar}&page=${paginaActual}&size=20&queryId=${queryId.current}`, {
          headers: {
            Authorization: `Bearer ${accessToekn}`,
          },
        }).then(response => {
          console.log(response);
          if (parseInt(response.data.queryId) >= parseInt(mostrando.current)) {
            setLogs(response.data.results);
            setTotalPaginas(response.data.pages);
            mostrando.current = response.data.queryId;
          }
        }).catch(error => {
          console.log(error);
        })
      };

      fetchData();
      queryId.current = queryId.current + 1;
    }
  }, [buscar, paginaActual]);


  return (
    <div>

      <AdminSidebar />

      <div className="regular">
        <h1 className="ms-2">Dreamtec - <span>Log de Transacciones</span></h1>



        <div className="row shadow pb-4 mt-3 border ms-2">

          <div className="col-12">

            <div className="row">

              <div className="col-12 text-center">
                <h2>Log de Transacciones</h2>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="row justify-content-center mb-5">
              <div className="col-xs-12 col-md-8 col-lg-6 mt-3">
                <div className="input-group buscador">
                  <input
                    type="text"
                    className="form-control input-search border-end-0"
                    value={buscar}
                    onChange={(e) => setBuscarLog(e.target.value)}
                    placeholder="Buscar por: Nombre Colaborador, Correo Colaborador, N° Compra, Listar Todo o Fecha, Ej: 2024-02-27"
                    style={{ borderStartStartRadius: "20px", borderEndStartRadius: "20px", borderRight: "0px" }}
                  />
                  <span role="button"
                    className="bg-transparent input-group-text border-start-0"
                    onClick={clearFiltro} style={{ borderEndEndRadius: "20px", borderStartEndRadius: "20px" }}
                  >
                    <i className="fa fa-filter-circle-xmark"></i>
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                {logs.length <= 0 ? (
                  <>
                    {buscar != "" ? (
                      <>
                        <div className="row my-5">
                          <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 fw-bold text-center">
                            <i className="fa-solid fa-circle-info"></i>&nbsp;No se encontraron resultados con su cadena de búsqueda
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="row my-5">
                          <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 fw-bold text-center">
                            <i className="fa-solid fa-circle-info"></i>&nbsp;Realice una búsqueda
                          </div>
                        </div>
                      </>
                    )}

                  </>
                ) : (
                  <>
                    <div className="table-responsive">
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th className="col bg-secondary-subtle"><div className="id text-black">ID Log</div></th>
                            <th className="col bg-secondary-subtle"><div className="id text-black">Nombre y correo</div></th>
                            <th className="col bg-secondary-subtle"><div className="id text-black">Rol</div></th>
                            <th className="col bg-secondary-subtle"><div className="id text-black">Compra</div></th>
                            <th className="col bg-secondary-subtle"><div className="id text-black">Tipo Cambio</div></th>
                            <th className="col bg-secondary-subtle"><div className="id text-black">Estado Anterior</div></th>
                            <th className="col bg-secondary-subtle"><div className="id text-black">Estado Nuevo</div></th>
                            <th className="col bg-secondary-subtle"><div className="id text-black">Fecha Cambio</div></th>
                          </tr>
                        </thead>
                        <tbody>
                          {logs
                            ? logs.map((log) => (
                              <tr key={log.id}>
                                <td><div className="nameNumber text-center"><b>{log.id}</b></div></td>
                                <td><div className="nameNumber text-center">{log.nombre ?
                                  <>
                                    <strong>{log.nombre}
                                      {(log.apellido != "" && log.apellido != " " && log.apellido != ".zzzzz.") ?
                                        <>
                                          {" " + log.apellido}
                                        </>
                                        : ""}</strong>
                                    <div>{log.correo ? log.correo : ""} </div>
                                  </>
                                  : 'N/A'}</div></td>
                                <td><div className="nameNumber text-center mt-2">{log.rol ?
                                  <>
                                    {log.rol == 1 && "Super Admin"}
                                    {log.rol == 2 && "Admin"}
                                    {log.rol == 3 && "Vendedor"}
                                    {log.rol == 4 && "Chofer"}
                                    {log.rol == 5 && "Bodega"}
                                  </>
                                  : "N/A"}</div></td>
                                <td><div className="nameNumber text-center mt-2">{log.compra ? log.compra : "N/A"}</div></td>
                                <td><div className="nameNumber text-center">{log.estado_cambiado ?
                                  <>
                                    {log.estado_cambiado == 1 ?
                                      <>
                                        <i className="fa-solid fa-truck text-warning" style={{ fontSize: "16px" }}></i>
                                        <div>Despacho</div>
                                      </>
                                      :
                                      <>
                                        {<i className="fa-solid fa-money-bill text-success" style={{ fontSize: "18px" }}></i>}
                                        <div>Pago</div>
                                      </>
                                    }
                                  </>
                                  : "N/A"}</div></td>
                                <td><div className="nameNumber text-center">{(log.estado_anterior >= 0 && log.estado_anterior <= 6) ?
                                  <>
                                    {log.estado_cambiado ?
                                      <>
                                        {log.estado_cambiado == 1 ?
                                          <>
                                            {log.estado_anterior == 1 && (
                                              <>
                                                <i className="fa-solid fa-circle-pause text-primary" style={{ fontSize: "18px" }}></i>
                                                <div><strong>Venta Recibida</strong></div>
                                              </>
                                            )}
                                            {log.estado_anterior == 2 && (
                                              <>
                                                <i class="fa-solid fa-tape text-dark-emphasis" style={{ fontSize: "18px" }}></i>
                                                <div>En preparación</div>
                                              </>
                                            )}
                                            {log.estado_anterior == 3 && (
                                              <>
                                                <i class="fa-solid fa-map-location-dot" style={{ fontSize: "16px", color: "red" }}></i>
                                                <div>En camino</div>
                                              </>
                                            )}
                                            {log.estado_anterior == 4 && (
                                              <>
                                                <i class="fa-solid fa-clipboard-check" style={{ "color": "#00bfd6", fontSize: "18px" }}></i>
                                                <div>Entregado</div>
                                              </>
                                            )}
                                            {log.estado_anterior == 5 && (
                                              <>
                                                <i class="fa-solid fa-people-carry-box" style={{ "color": "#00bfd6", fontSize: "18px" }}></i>
                                                <div>Entregado en tienda</div>
                                              </>
                                            )}
                                            {log.estado_anterior == 6 && (
                                              <>
                                                <i class="fa-solid fa-person-running" style={{ "color": "red", fontSize: "18px" }}></i>
                                                <div>Listo para retiro</div>
                                              </>
                                            )}
                                          </>
                                          :
                                          <>
                                            {log.estado_anterior == 0 && (
                                              <>
                                                <i class="fa-solid fa-handshake-simple" style={{ color: "white", backgroundColor: "#00A4FB", padding: "4px 8px", borderRadius: "50%", borderBottom: "2px solid #001787" }}></i>
                                                <div>Pendiente</div>
                                              </>
                                            )}
                                            {log.estado_anterior == 1 && (
                                              <>
                                                <div><i className="fa-solid fa-building-columns"></i> <i className="fa-solid fa-money-bill-transfer text-success"></i> <i className="fa-solid fa-building-columns"></i></div>
                                                Pendiente de <br />confirmación de transferencia
                                              </>
                                            )}
                                            {log.estado_anterior == 2 && (
                                              <>
                                                <i className="fa-solid fa-circle-check" style={{ 'color': '#21C301', fontSize: "18px" }}></i><br></br>
                                                <div>Aprobada</div>
                                              </>
                                            )}
                                            {log.estado_anterior == 3 && (
                                              <>
                                                <i className="fa-solid fa-shop-slash text-dark"></i><br></br>
                                                <div>Anulado</div>
                                              </>
                                            )}
                                          </>
                                        }
                                      </>
                                      :
                                      ""
                                    }
                                  </>
                                  : "N/A"}</div></td>
                                <td><div className="nameNumber text-center">{(log.estado_nuevo >= 0 && log.estado_nuevo <= 6) ?
                                  <>
                                    {log.estado_cambiado ?
                                      <>
                                        {log.estado_cambiado == 1 ?
                                          <>
                                            {log.estado_nuevo == 1 && (
                                              <>
                                                <i className="fa-solid fa-circle-pause text-primary"></i>
                                                <div><strong>Venta Recibida</strong></div>
                                              </>
                                            )}
                                            {log.estado_nuevo == 2 && (
                                              <>
                                                <i class="fa-solid fa-tape text-dark-emphasis" style={{ fontSize: "18px" }}></i>
                                                <div>En preparación</div>
                                              </>
                                            )}
                                            {log.estado_nuevo == 3 && (
                                              <>
                                                <i class="fa-solid fa-map-location-dot" style={{ fontSize: "16px", color: "red" }}></i>
                                                <div>En camino</div>
                                              </>
                                            )}
                                            {log.estado_nuevo == 4 && (
                                              <>
                                                <i class="fa-solid fa-clipboard-check" style={{ "color": "#00bfd6", fontSize: "18px" }}></i>
                                                <div>Entregado</div>
                                              </>
                                            )}
                                            {log.estado_nuevo == 5 && (
                                              <>
                                                <i class="fa-solid fa-people-carry-box" style={{ "color": "#00bfd6", fontSize: "18px" }}></i>
                                                <div>Entregado en tienda</div>
                                              </>
                                            )}
                                            {log.estado_nuevo == 6 && (
                                              <>
                                                <i class="fa-solid fa-person-running" style={{ "color": "red", fontSize: "18px" }}></i>
                                                <div>Listo para retiro</div>
                                              </>
                                            )}
                                          </>
                                          :
                                          <>
                                            {log.estado_nuevo == 0 && (
                                              <>
                                                <i class="fa-solid fa-handshake-simple" style={{ color: "white", backgroundColor: "#00A4FB", padding: "4px 8px", borderRadius: "50%", borderBottom: "2px solid #001787" }}></i>
                                                <div>Pendiente</div>
                                              </>
                                            )}
                                            {log.estado_nuevo == 1 && (
                                              <>
                                                <div><i className="fa-solid fa-building-columns"></i> <i className="fa-solid fa-money-bill-transfer text-success"></i> <i className="fa-solid fa-building-columns"></i></div>
                                                <div>Pendiente de <br />confirmación de transferencia</div>
                                              </>
                                            )}
                                            {log.estado_nuevo == 2 && (
                                              <>
                                                <i className="fa-solid fa-circle-check" style={{ 'color': '#21C301', fontSize: "18px" }}></i><br></br>
                                                <div>Aprobada</div>
                                              </>
                                            )}
                                            {log.estado_nuevo == 3 && (
                                              <>
                                                <i className="fa-solid fa-shop-slash text-dark"></i><br></br>
                                                <div>Anulado</div>
                                              </>
                                            )}
                                          </>
                                        }
                                      </>
                                      :
                                      ""
                                    }
                                  </>
                                  : "N/A"}</div></td>
                                <td><div className="nameNumber text-center mt-2"><i class="fa-solid fa-calendar-check"></i> {log.fecha_cambio ? getFecha(log.fecha_cambio) : "N/A"}</div></td>
                              </tr>
                            ))
                            : ""}
                        </tbody>
                      </table>

                      <div className="row justify-content-end mb-3">
                        <div className="col-xxl-1 col-xl-1 col-lg-1 col-md-6 col-sm-6 col-6">
                          {paginaActual > 1 && (
                            <button type="button" aria-label="Anterior" className="agregar border-0 btnCelesteDreamtec" onClick={() => setPaginaActual(paginaActual - 1)}>
                              <i class="fa-solid fa-circle-arrow-left fs-16 text-white"></i>
                            </button>
                          )}
                        </div>

                        {paginaActual + 1 <= totalPaginas && (
                          <div className="col-xxl-1 col-xl-1 col-lg-1 col-md-6 col-sm-6 col-6">
                            <button type="button" aria-label="Siguiente" className="agregar border-0 btnCelesteDreamtec" onClick={() => setPaginaActual(paginaActual + 1)}>
                              <i class="fa-sharp fa-solid fs-16  fa-circle-arrow-right text-white"></i>
                            </button>
                          </div>
                        )}
                      </div>

                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

      </div>



    </div>
  );
};

export default LogTransacciones;
