/**
 * Componente Login:
 * 
 * acá se muestra el modal de login
 * 
 * @module ComponenteLoginjsx
 */

import { useState, useEffect } from "react";
import axios from "axios";
import "./login.css";
import Modal from 'react-bootstrap/Modal';

import { useNavigate, useLocation } from "react-router-dom";

import { SignUp, Passback, CodigoRecPass, PassChange } from "../index";

const Login = () => {

  /**
* @constant
* @name tipo
* @type {string}
* 
* */
  const [tipo, setTipo] = useState('');

  /**
* @constant
* @name showPass
* @type {boolean}
* 
* */
  const [showPass, setShowPass] = useState(false);

  /**
* @constant
* @name password
* @type {string}
* 
* */
  const [password, setPassword] = useState("");

  /**
* @constant
* @name email
* @type {string}
* 
* */
  const [email, setEmail] = useState("");

  /**
* @constant
* @name successMessage
* @type {null}
* 
* */
  const [successMessage, setSuccessMessage] = useState(null);

  /**
* @constant
* @name token
* @type {null}
* 
* */
  const [token, setToken] = useState(null);

  /**
* @constant
* @name correoRecuperar
* @type {string}
* 
* */
  const [correoRecuperar, setCorreoRecuperar] = useState("");

  /**
* @constant
* @name codeRecuperar
* @type {string}
* 
* */
  const [codeRecuperar, setCodeRecuperar] = useState("");

  /**
* @constant
* @name claveRecuperar
* @type {string}
* 
* */
  const [claveRecuperar, setClaveRecuperar] = useState("");

  /**
* @constant
* @name cambioClave
* @type {string}
* 
* */
  const [cambioClave, setCambioClave] = useState("");

  /**
* @constant
* @name showAgregado
* @type {boolean}
* 
* */
  const [showAgregado, setShowAgregado] = useState(false);

  /**
* @constant
* @name userAlready
* @type {string}
* 
* */
  const [userAlready, setUserAlready] = useState(
    sessionStorage.getItem("IDusuario")
  );

  /**
* @constant
* @name mensajeError
* @type {string}
* 
* */
  const [mensajeError, setMensajeError] = useState("");

  const navigate = useNavigate();
  const location = useLocation();

  const handleShowAgregado = () => setShowAgregado(true);
  const handleCloseAgregado = () => {
    setShowAgregado(false);
  }


  /**
* @function
* Petición HTTP para hacer login con los datos del usuario
* @param email Correo del usuario
* @param password Contraseña del usuario
* @returns {string}
*/
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (email.toLowerCase().includes("@dreamtec.cl"))
      mostrarError("Los correos @dreamtec.cl solo pueden ser usados como administrador");
    else {
      axios
        .post(process.env.REACT_APP_BACK_URL + "/api/login", {
          username: email,
          password: password,
          id: userAlready ? userAlready : 0,
        })
        .then((response) => {
          setToken(response.data.access);
          sessionStorage.setItem("accessTokenWeb", response.data.access);
          sessionStorage.setItem("IDusuario", response.data.id_usuario_web);
        })
        .catch((error) => {
          setSuccessMessage("Usuario o contraseña incorrectos");
          setTipo(2);
        });
    }
  };


  /**
* @function
* Metodo que valida si existe el token web
*/
  useEffect(() => {
    if (token) {
      sessionStorage.setItem("accessTokenWeb", token);
      setSuccessMessage("Inicio de sesión exitoso");
      setTipo(1);

      if (location.pathname === "/paso1") {
        navigate("/paso2");
      }

      setTimeout(() => {
        document.getElementById("btn_closeidmodal").click();
        window.location.reload();
      }, 1000);
    } else {
      setSuccessMessage("");
      setTipo(0);
    }
  }, [token]);

  const mostrarError = (msj) => {
    setMensajeError(msj);
    handleShowAgregado();
  };

  return (
    <>
      <Modal show={showAgregado} onHide={handleCloseAgregado} size="lg" centered={true}>
        <Modal.Header closeButton className="border-bottom-0">
        </Modal.Header>
        <Modal.Body>

          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 mb-3 text-center bg-black py-3">
                <i className="fa-solid fa-circle-xmark text-turq fs-1 mb-3 d-block"></i>
                <span className="fs-6 text-white mt-1" id="titulo_error">ERROR AL INGRESAR DATOS</span>
              </div>
              <div className="col-lg-10 col-md-10 col-11 mb-2 text-center">
                <p className="fs-14 text-black mb-0" id="texto_error">{mensajeError}</p>
              </div>
              <div className="col-lg-6 col-md-7 col-8 bg-turq" style={{ height: "3px" }}>
              </div>
            </div>
          </div>

        </Modal.Body>
      </Modal>
      <div
        className="modal fade"
        id="modal-inicio-sesion"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-bottom-0">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="btn_closeidmodal"
              ></button>
            </div>
            <div className="modal-body pb-2 pt-0">
              <div className="container">
                <form onSubmit={handleSubmit} id="form_login">
                  <div className="row justify-content-center">
                    <div className="col-lg-10 col-md-11 col-11 mb-3">
                      <span className="fs-6 text-black">
                        Correo electrónico
                      </span>
                      <input
                        type="email"
                        className="form-control rounded-pill mt-2"
                        id="correo_login"
                        placeholder="Ej: ejemplo@mail.com"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="col-lg-10 col-md-11 col-11 mb-4">
                      <span className="fs-6 text-black">Contraseña</span>
                      <div className="input-group input-group-pass rounded-pill">
                        <input
                          type={!showPass ? "password" : "text"}
                          className="form-control input-pass rounded-pill"
                          id="password_login"
                          placeholder="***********"
                          required
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <span
                          role="button"
                          id="ver-password-login"
                          className="input-group-text btn-ojo"
                        >
                          {!showPass ? (
                            <i
                              className="fas fa-eye"
                              onClick={(e) => setShowPass(true)}
                            ></i>
                          ) : (
                            <i
                              className="fa-sharp fa-solid fa-eye-slash"
                              onClick={(e) => setShowPass(false)}
                            ></i>
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-5 col-md-5 col-11 mb-3 text-center">
                      <button
                        type="submit"
                        id="btn_submit_login"
                        aria-label="Iniciar Sesión"
                        className="bg-turq text-white fs-6 px-4 py-1 rounded-pill border-0"
                      >
                        Iniciar Sesión
                      </button>

                    </div>
                    <div className="col-lg-5 col-md-5 col-11 mb-3 text-center align-self-center">
                      <button
                        type="button"
                        className="text-black fs-14 fst-italic bg-transparent border-0 a-borde-inf"
                        data-ds-dismiss="modal"
                        data-bs-toggle="modal"
                        data-bs-target="#recuperar-pass"
                      >
                        Olvidé mi contraseña
                      </button>
                    </div>
                    <div className="col-12 text-center">

                      {successMessage && <span className={tipo == 1 ? "text-success fw-bold" : "text-danger fw-bold"}>{successMessage}</span>}
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="modal-footer pb-3 border-top-0">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-11 col-mb-11 col-11">
                    <hr />
                  </div>
                  <div className="col-lg-11 col-mb-11 col-11 text-center mb-3">
                    <span className="fs-6 text-black fst-italic">
                      ¿No estás registrado/a?
                    </span>
                  </div>
                  <div className="col-lg-5 col-md-5 col-sm-6 col-7 d-grid gap-2">
                    <button
                      type="button"
                      aria-label="Registrarme"
                      data-bs-dismiss="modal"
                      data-bs-toggle="modal"
                      data-bs-target="#modal-registro"
                      className="rounded-pill btn-borde-turq py-1"
                    >
                      Registrarme
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SignUp />
      <Passback
        cambioClave={cambioClave}
        setCambioClave={setCambioClave}
        correoRecuperar={correoRecuperar}
        setCorreoRecuperar={setCorreoRecuperar}
      />
      <CodigoRecPass
        cambioClave={cambioClave}
        setCambioClave={setCambioClave}
        correoRecuperar={correoRecuperar}
        setCorreoRecuperar={setCorreoRecuperar}
        codeRecuperar={codeRecuperar}
        setCodeRecuperar={setCodeRecuperar}
      />
      <PassChange
        cambioClave={cambioClave}
        setCambioClave={setCambioClave}
        correoRecuperar={correoRecuperar}
        setCorreoRecuperar={setCorreoRecuperar}
        codeRecuperar={codeRecuperar}
        setCodeRecuperar={setCodeRecuperar}
      />
    </>
  );
};

export default Login;
