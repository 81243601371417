
import { createSlice } from '@reduxjs/toolkit';

const initialState = 0;

const checkStockSlice = createSlice({
  name: 'checkstock',
  initialState,
  reducers: {
    setCheckStock: (state, action) => action.payload,
  },
});

export const { setCheckStock } = checkStockSlice.actions;
export default checkStockSlice.reducer;
