/**
 * Página Categoría:
 * 
 * acá se válida el elemento a renderizar
 * 
 * @module PaginaCategoryjsx
 */

import { useState, useEffect, useRef } from "react";
import axios from "axios";
import BrandPage from "./BrandPage";
import ProductDetail from "./ProductDetail";
import CategoriaPadrePage from "./CategoriaPadrePage";
import NotFound from "./NotFound";
import { useNavigate, useLocation } from "react-router-dom";

const Category = () => {
    const [tipoLink, setTipoLink] = useState("");
    const navigate = useNavigate();
    useEffect(() => {
        const pathname = window.location.pathname;
        if (pathname != "/") {
            const regex = /\/(.+)/;
            const match = regex.exec(pathname);
            const categoria = match[1];
            validaLink();
        }
    });

    /**
* @function
* Petición HTTP que trae el tipo de link el cual se valida para saber que componente renderizar, 
* @param {string} link link para validar
*@returns {string}
* 
*/

    const validaLink = () => {
        const pathname = window.location.pathname;
        const match = pathname.split("/");
        let link = "";
        if (match.length == 2)
            link = match[1];
        else
            link = match[2];
        axios.get(
            process.env.REACT_APP_BACK_URL + `/api/url/tipoLink?dato=${link}`
        )
            .then((response) => {
                setTipoLink(response.data.tipoLink);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    return (
        <>
            {tipoLink == "" && (
                <div></div>
            )}
            {tipoLink == "marca" && (
                <BrandPage />
            )}
            {tipoLink == "producto" && (
                <ProductDetail />
            )}
            {tipoLink == "padre" && (
                <CategoriaPadrePage />
            )}
            {tipoLink == "desconocido" && (
                <NotFound />
            )}
        </>
    );
};

export default Category;