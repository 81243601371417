/**
 * Componente PassChange:
 * 
 * acá se muestra el modal para cambiar la contraseña
 * 
 * @module ComponentePassChangejsx
 */


import { useState, useEffect, useRef } from "react";
import axios from "axios";

const PassChange = (props) => {

  /**
* @constant
* @name showPass
* @type {boolean}
* 
* */

  const [showPass, setShowPass] = useState(false);

  /**
* @constant
* @name showPass2
* @type {boolean}
* 
* */

  const [showPass2, setShowPass2] = useState(false);

  /**
* @constant
* @name passWord
* @type {string}
* 
* */

  const [passWord, setPassword] = useState("");


  /**
* @constant
* @name passWord2
* @type {string}
* 
* */

  const [passWord2, setPassword2] = useState("");

  const mensajeError = useRef("");

  let spanMensaje = document.getElementById("pass-mensaje-recuperar");
  if (spanMensaje != null) {
    spanMensaje.innerHTML = ".";
    spanMensaje.classList.remove("text-success");
    spanMensaje.classList.remove("text-danger");
    spanMensaje.classList.add("text-white");
  }
  /**
* @function
* Método para validar si el texto ingresado incluye mayúsculas, minúsculas y número
* @param {string} texto texto para validar
* @returns {boolean}
*/
  const validaClave = (clave) => {
    let mayus = [`a`, `b`, `c`, `d`, `e`, `f`, `g`, `h`, `i`, `j`, `k`, `l`, `m`, `n`, `ñ`, `o`, `p`, `q`, `r`, `s`, `t`, `u`, `v`, `w`, `x`, `y`, `z`];
    let minus = [`A`, `B`, `C`, `D`, `E`, `F`, `G`, `H`, `I`, `J`, `K`, `L`, `M`, `N`, `Ñ`, `O`, `P`, `Q`, `R`, `S`, `T`, `U`, `V`, `W`, `X`, `Y`, `Z`];
    let nums = [`0`, `1`, `2`, `3`, `4`, `5`, `6`, `7`, `8`, `9`];
    let simbolos = [`.`, `,`, `_`, `@`, `!`, `*`, `;`]
    if (validaQuery(clave)) {
      if (clave.length < 8) {
        mensajeError.current = "La clave debe tener al menos 8 caracteres";
        return false;
      }
      else {
        let incluye = false;
        for (let i = 0; i < mayus.length; i++) {
          if (clave.includes(mayus[i]))
            incluye = true;
        }
        if (!incluye) {
          mensajeError.current = "Debe incluir letras minúsculas";
          return false;
        }
        incluye = false;
        for (let i = 0; i < minus.length; i++) {
          if (clave.includes(minus[i]))
            incluye = true;
        }
        if (!incluye) {
          mensajeError.current = "Debe incluir letras mayúsculas";
          return false;
        }

        incluye = false;
        for (let i = 0; i < nums.length; i++) {
          if (clave.includes(nums[i]))
            incluye = true;
        }
        if (!incluye) {
          mensajeError.current = "Debe incluir números";
          return false;
        }

        incluye = false;
        for (let i = 0; i < nums.length; i++) {
          if (clave.includes(simbolos[i])) {
            incluye = true;
          }
        }
        if (!incluye)
          mensajeError.current = "Debe incluir alguno de estos caracteres especiales: . , _ @ ! * ;";
        return incluye;
      }
    }
    else {
      mensajeError.current = "Solo se permiten estos caracteres especiales: . , _ @ ! * ;";
      return false;
    }
  };

  /**
* @function
* Metodo para validar el texto ingresado
* @param {string} texto texto para validar
* @returns {boolean}
*/
  const validaQuery = (texto) => {
    let validar = [`"`, `?`, `=`, `'`, `<`, `>`, `(`, `)`, `{`, `}`, `[`, `]`, `:`, '`', '+'];
    for (let i = 0; i < validar.length; i++) {
      if (texto.includes(validar[i]))
        return false;
    }
    return true;
  };


  /**
* @function
* Petición HTTP para cambiar la contraseña
* @param {Object} formData formData con todos los datos del usuario para cambiar la contraseña
*/

  const handleSubmit = (e) => {
    e.preventDefault();
    let spanMensaje = document.getElementById("pass-mensaje-recuperar");
    const formData = new FormData();
    formData.append("correo", props.correoRecuperar);
    formData.append("code", props.codeRecuperar);
    formData.append("password", document.querySelector("#password_cambiar").value);
    if (validaClave(document.querySelector("#password_cambiar").value)) {
      axios
        .post(process.env.REACT_APP_BACK_URL + `/api/usuario/cambiar_password`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          }
        })
        .then((response) => {
          if (response.data.message == "Contraseña Cambiada") {
            document.getElementById("volverLogin").click();
            document.querySelector("#password_cambiar").value = "";
            document.querySelector("#confirmar_password_cambiar").value = "";
            spanMensaje.innerHTML = ".";
            spanMensaje.classList.remove("text-success");
            spanMensaje.classList.remove("text-danger");
            spanMensaje.classList.add("text-white");
          }
          else {
            spanMensaje.innerHTML = response.data.message;
            spanMensaje.classList.remove("text-danger");
            spanMensaje.classList.remove("text-white");
            spanMensaje.classList.add("text-success");
          }
        })
        .catch((error) => {
          if (error.response.data.message == "code no corresponde con el usuario")
            spanMensaje.innerHTML = "Código inválido"
          else
            spanMensaje.innerHTML = "Error al cambiar la clave, intenta nuevamente";
          spanMensaje.classList.remove("text-success");
          spanMensaje.classList.remove("text-white");
          spanMensaje.classList.add("text-danger");
        });
    }
    else {
      spanMensaje.innerHTML = mensajeError.current;
      spanMensaje.classList.remove("text-success");
      spanMensaje.classList.remove("text-white");
      spanMensaje.classList.add("text-danger");
    }
  };

  return (
    <div
      className="modal fade"
      id="modal-cambiar-pass"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header border-bottom-0">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Cerrar"
            ></button>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="modal-body pb-2 pt-0">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-10 col-md-11 col-11 mb-4 text-center">
                    <span className="text-black fs-6 fw-bold">
                      CAMBIAR CONTRASEÑA
                    </span>
                    <br />
                    <span id="pass-mensaje-recuperar">.</span>
                  </div>

                  <div className="col-lg-10 col-md-11 col-11 mb-3">
                    <span className="fs-6 text-black">Contraseña nueva</span>

                    <div className="input-group input-group-pass rounded-pill ">
                      <input
                        type={!showPass ? "password" : "text"}
                        id="password_cambiar"
                        minLength="6"
                        className="form-control input-pass rounded-pill"
                        placeholder="Nueva contraseña"
                        required
                        value={passWord}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <span
                        role="button"
                        className="input-group-text btn-ojo"
                        id="ver_password_cambio"
                      >
                        {!showPass ? (
                          <i
                            className="fas fa-eye"
                            onClick={(e) => setShowPass(true)}
                          ></i>
                        ) : (
                          <i
                            className="fa-sharp fa-solid fa-eye-slash"
                            onClick={(e) => setShowPass(false)}
                          ></i>
                        )}
                      </span>

                    </div>
                    <span style={{ fontSize: "12px" }}>Mínimo 8, mayúsculas, minúsculas, números y símbolos: . , _ @ ! * ;
                      <span className="text-danger">*</span>
                    </span>
                  </div>
                  <div className="col-lg-10 col-md-11 col-11 mb-3">
                    <span className="fs-6 text-black">
                      Contraseña confirmación
                    </span>

                    <div className="input-group input-group-pass rounded-pill">
                      <input
                        type={!showPass2 ? "password" : "text"}
                        id="confirmar_password_cambiar"
                        minLength="6"
                        className="form-control input-pass rounded-pill"
                        placeholder="Confirma nueva contraseña"
                        required
                        value={passWord2}
                        onChange={(e) => setPassword2(e.target.value)}
                      />
                      <span
                        role="button"
                        className="input-group-text btn-ojo"
                        id="ver_password_confirmacion_cambio"
                      >
                        {!showPass2 ? (
                          <i
                            className="fas fa-eye"
                            onClick={(e) => setShowPass2(true)}
                          ></i>
                        ) : (
                          <i
                            className="fa-sharp fa-solid fa-eye-slash"
                            onClick={(e) => setShowPass2(false)}
                          ></i>
                        )}
                      </span>
                    </div>
                    {passWord2.length > 0 && passWord2 === passWord ? (
                      <div
                        className="col-lg-11 col-md-11"
                        id="success_password"
                      >
                        <div className="text-success fz-12">
                          <i className="fa-solid fa-circle-check"></i>Las
                          contraseñas coinciden
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {passWord2.length > 0 && passWord2 !== passWord ? (
                      <div className="col-lg-11 col-md-11" id="alerta_password">
                        <div className="text-danger fz-12">
                          <i className="fa-solid fa-circle-exclamation"></i>
                          Las contraseñas no coinciden
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer pb-4 border-top-0">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-5 col-md-5 col-sm-6 col-7 d-grid gap-2">
                    <button
                      type="submit"
                      id="btn-cambiar-password"
                      aria-label="Cambiar contraseña"
                      className="rounded-pill bg-turq text-white py-1 border-0"
                    >
                      Cambiar contraseña
                    </button>
                    <input
                      id="volverLogin"
                      className="d-none"
                      type="checkbox"
                      data-bs-toggle="modal"
                      data-bs-target="#modal-inicio-sesion"
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PassChange;
