/**
 * Página 404:
 * 
 * acá se genera la vista para cuando no se encuentra lo solicitado en la Web
 * 
 * @module PaginaNotFoundjsx
 */

import { useState, useEffect, useRef } from "react";
import React from 'react'
import './NotFound.css';
import { setTipoEnvio } from "../redux/tipoEnvioSlice";
import { setFechaEntrega } from "../redux/fechaEntregaSlice";
import { setComunaSelected } from "../redux/comunaSelectedSlice";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";

const NotFound = () => {


  const dispatch = useDispatch();

  /**
  * @constant
  * @name userAlready
  * @type {Array}
  * 
  * */
  const [userAlready] = useState(sessionStorage.getItem("IDusuario"));

  /**
  * @constant
  * @name tipoEnvio
  * @type {number}
  * 
  * */
  const tipoEnvio = useSelector((state) => state.tipoEnvio);


  /**
* @function
* Petición HTTP que trae la dirección ingresada por el usuario anteriormente, 
* validando que inicio sesión y el tipo de envio sea distinto de 4 (Retiro en tienda)
* @param {string} userAlready Id del usuario
* @param {number} tipoEnvio Tipo de envio seleccionado anteriormente
*@returns {Array}
* 
*/
  useEffect(() => {
    if (userAlready && tipoEnvio !== 4) {
      axios
        .get(
          process.env.REACT_APP_BACK_URL + `/api/direccion/get_direccion/${userAlready}`
        )
        .then((response) => {
          dispatch(setTipoEnvio(response.data.direcicon.codigo_envio));
          dispatch(setFechaEntrega(response.data.direcicon.fecha));
          dispatch(setComunaSelected(response.data.direcicon.nombre_comuna));


        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [tipoEnvio]);

  return (
    <div className="fondo-404">
      <Helmet>
        <title>Página no encontrado</title>
        <meta name='errorpage' content='true' />
        <meta name='errortype' content='404 - Not Found' />
        <meta name='prerender-status-code' content='404' />
      </Helmet>
      <div>
        <div className="container pb-5">
          <div className="row justify-content-center">
            <div className="col-lg-2 col-md-2 col-sm-3 col-4 mb-2 px-3">
              <img loading="lazy" src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/notfound/icono-404-rayo.png"} className="img-fluid rayo-404" alt="Rayo" title="Rayo" />
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-1 col-md-1 col-sm-2 col-2 px-0 pt-1">
              <img loading="lazy" src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/notfound/icono-404-a.png"} className="img-fluid icon-404-a" alt="control roto" title="control roto" />
            </div>
            <div className="col-lg-1 col-md-1 col-sm-2 col-2 px-0 pt-5">
              <img loading="lazy" src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/notfound/icono-404-b.png"} className="img-fluid icon-404-b" alt="control roto" title="control roto" />
            </div>
            <div className="col-lg-1 col-md-1 col-sm-2 col-2 px-0 pt-1">
              <img loading="lazy" src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/notfound/icono-404-c.png"} className="img-fluid icon-404-c" alt="control roto" title="control roto" />
            </div>
          </div>
          <div className="row justify-content-center mb-3 mt-5">
            <div className="col-lg-6 col-md-9 col-sm-11 col-11 text-center">
              <p className="txt-404 text-turq mb-0 lh-1 fw-bolder">No hemos podido encontrar el contenido</p>
            </div>
          </div>
          <div className="row justify-content-center my-3">
            <div className="col-lg-2 col-md-3 col-sm-4 col-4 text-center bg-white" style={{ height: "4px" }}></div>
          </div>
          <div className="row justify-content-center mb-3">
            <div className="col-lg-2 col-md-3 col-sm-9 col-10">
              <img loading="lazy" src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/notfound/icono-404-texto.png"} className="img-fluid titulo-404" alt="Error 404" title="Error 404" />
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 col-sm-10 col-11 text-center">
              <p className="fs-6 text-white mb-0">Por favor, busca en otra de nuestras secciones
                o regresa a nuestro<a role='button' onClick={() => {
                  location.href = "/"
                }} className="a-turq text-turq fs-6 fw-bold"><i className="fa-solid fa-house pe-1 ps-2"></i>INICIO</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default NotFound;