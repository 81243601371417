import React, { useState, useRef } from "react";
import FormularioIndustrial from "../components/Industrial/formulario";
import FooterIndustrial from "../components/FooterIndustrial/footer";

const Plotter = () => {
  const [productos, setProductos] = useState([]);
  const formularioRef = useRef(null);
  const selectRef = useRef(null);

  const productosInfo = [
    {
      id: 1,
      nombre: "SureColor T3170SR",
      img: process.env.REACT_APP_FRONT_URL + "/uploads/assets/industrial/producto_cuatro.png",
      link: "https://www.dreamtec.cl/impresora-inalambrica-plotter-epson-surecolor-t3170-sr",
    },
    {
      id: 2,
      nombre: "SureColor T3170X",
      img: process.env.REACT_APP_FRONT_URL + "/uploads/assets/industrial/producto_cinco.webp",
      link: "https://www.dreamtec.cl/impresora-plotter-surecolor-t3170x-24-wifi-tinta-continua",
    },
    {
      id: 3,
      nombre: "Surecolor T5170M",
      img: process.env.REACT_APP_FRONT_URL + "/uploads/assets/industrial/producto_seis.png",
      link: "https://www.dreamtec.cl/plotter-epson-surecolor-t5170m36-escaner-usb-wi-fi-direct",
    },
    {
      id: 4,
      nombre: "SureColor T5470M",
      img: process.env.REACT_APP_FRONT_URL + "/uploads/assets/industrial/producto_siete.png",
      link: "https://www.dreamtec.cl/plotter-surecolor-epson-t5470m--36-ethernet--wifi-usb",
    },
    {
      id: 5,
      nombre: "SureColor SCT5170SR",
      img: process.env.REACT_APP_FRONT_URL + "/uploads/assets/industrial/producto_ocho.webp",
      link: "https://www.dreamtec.cl/plotter-surecolor-stand--epson-sct5170sr-36-wifi-lan-usb",
    },
  ];

  const handleCotizar = (producto) => {
    formularioRef.current.scrollIntoView({ behavior: "smooth" });
    setTimeout(() => {
      selectRef.current.focus();
    }, 500);
  };

  return (
    <>
      <div className="container-fluid mt-4 bgPlotter">
        <div className="row">
          <div className="col-md-12 text-white pt-5 text-center">
            <h1 className="pt-5 fw-bold h1">Plotter</h1>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="container py-5 my-5">
          <div className="row justify-content-center">
            <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12 d-xxl-block d-xl-block d-lg-block d-md-none d-sm-none d-none text-center">
              <img
                src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/industrial/ploter.png"}
                alt="persona configurando un plotter"
                className="img-fluid"
              />
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <h1 className="h1">Nuestros Plotter Epson</h1>
              <img src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/industrial/epson.png"} alt="Logo Epson" className="marca mb-3" />
              <p className="p_industrial">
                Ofrecen una precisión excepcional para tus necesidades de
                impresión y corte. Ya sea que estés creando planos
                arquitectónicos, carteles publicitarios o material promocional,
                nuestros plotters te brindan la calidad y la velocidad que tu
                empresa necesita para destacarse en el mercado.
              </p>
              <a
                href="/industrial/contacto"
                className="btn btn-primary_industrial"
              >
                CONTACTAR AHORA
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-5">
        <div className="row justify-content-md-center">
          {productosInfo.map((producto) => (
            <div
              key={producto.id}
              className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 border border-4 rounded-4 border-3 border-primary-subtle m-2 text-center pb-3"
            >
              <div className="border border-2 rounded-3 mt-4">
                <img src={producto.img} alt="Plotter" className="img-fluid" />
              </div>
              <h2 className="mb-3 fs-5 mt-3" style={{ color: "initial" }}>
                {producto.nombre}
              </h2>
              <a
                href={producto.link}
                className="btn btn-primary_industrial mb-2"
              >
                VER PRODUCTO
              </a>
              <button
                type="button"
                aria-label="Cotizar Ahora"
                className="btn btn-secondary_industrial"
                onClick={() => handleCotizar(producto.nombre)}
              >
                COTIZAR AHORA
              </button>
            </div>
          ))}
        </div>
      </div>

      <div ref={formularioRef}>
        <FormularioIndustrial productos={productos} selectRef={selectRef} />
      </div>

      <FooterIndustrial />
    </>
  );
};

export default Plotter;
