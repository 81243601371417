
import { createSlice } from '@reduxjs/toolkit';

const initialState = "";

const regionSelectedSlice = createSlice({
  name: 'regionSelected',
  initialState,
  reducers: {
    setRegionSelected: (state, action) => action.payload,
  },
});

export const { setRegionSelected } = regionSelectedSlice.actions;
export default regionSelectedSlice.reducer;
