
import { createSlice } from '@reduxjs/toolkit';

const initialState = "";

const comunaSelectedSlice = createSlice({
  name: 'comunaSelected',
  initialState,
  reducers: {
    setComunaSelected: (state, action) => action.payload,
  },
});

export const { setComunaSelected } = comunaSelectedSlice.actions;
export default comunaSelectedSlice.reducer;
