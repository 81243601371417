/**
 * Componente PassBack:
 * 
 * acá se muestra el modal para ingresar el correo para 
 * recuperar la contraseña
 * 
 * @module ComponentePassBackjsx
 */


//import { useEffect } from "react";
import axios from "axios";

let spanMensaje = document.getElementById("correo-mensaje-recuperar");
if (spanMensaje != null) {
  spanMensaje.innerHTML = ".";
  spanMensaje.classList.remove("text-success");
  spanMensaje.classList.remove("text-danger");
  spanMensaje.classList.add("text-white");
}

const Passback = (props) => {

  /**
* @function
* Método para validar si el texto ingresado incluye mayúsculas, minúsculas y número
* @param {Object} formData formData con todos los datos para validar el correo
 y enviar un correo con las instrucciones
*/

  const handleSubmit = (e) => {
    e.preventDefault();
    let spanMensaje = document.getElementById("correo-mensaje-recuperar");
    props.setCorreoRecuperar(document.querySelector("#correo_recuperar").value);

    let formData = new FormData();
    formData.append("correo", document.querySelector("#correo_recuperar").value);
    axios
      .post(process.env.REACT_APP_BACK_URL + `/api/usuario/recuperar_password`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      })
      .then((response) => {
        if (response.data.message == "correo enviado") {
          document.getElementById("checkModalCodigo").click();
          document.querySelector("#correo_recuperar").value = "";
          spanMensaje.innerHTML = ".";
          spanMensaje.classList.remove("text-success");
          spanMensaje.classList.remove("text-danger");
          spanMensaje.classList.add("text-white");
        }
        else {
          spanMensaje.innerHTML = response.data.message;
          spanMensaje.classList.remove("text-danger");
          spanMensaje.classList.remove("text-white");
          spanMensaje.classList.add("text-success");
        }
      })
      .catch((error) => {
        if (error.response.data.message == "correo no existe")
          spanMensaje.innerHTML = "El correo no existe"
        else
          spanMensaje.innerHTML = "Error al enviar el correo, intenta nuevamente";
        spanMensaje.classList.remove("text-success");
        spanMensaje.classList.remove("text-white");
        spanMensaje.classList.add("text-danger");
      });
  };




  return (
    <>
      <div
        className="modal fade"
        id="recuperar-pass"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-bottom-0">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Cerrar"
              ></button>
            </div>

            <form onSubmit={handleSubmit}>
              <div className="modal-body pb-2 pt-0">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-lg-10 col-md-11 col-11 mb-3 text-center">
                      <span className="text-black fs-6 fw-bold">
                        RECUPERAR CONTRASEÑA
                      </span>
                      <br />
                      <span id="correo-mensaje-recuperar" className="text-white">.</span>
                    </div>
                    <div className="col-lg-10 col-md-11 col-11 mb-3">
                      <span className="fs-6 text-black">
                        Dirección correo electrónico
                      </span>
                      <input
                        type="email"
                        className="form-control rounded-pill mt-2"
                        id="correo_recuperar"
                        placeholder="Ej: ejemplo@mail.com"
                        required
                        autoComplete="off"
                      />
                    </div>
                    <div className="col-lg-10 col-md-11 col-11 text-start">
                      <p className="text-black fs-14">
                        Se enviará una contraseña a tu dirección de correo
                        electrónico.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer pb-4 border-top-0">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-lg-5 col-md-5 col-sm-6 col-7 d-grid gap-2">
                      <button
                        type="submit"
                        id="btn-olvidaste-password"
                        aria-label="Continuar"
                        className="rounded-pill bg-turq text-white py-1 border-0"
                      >
                        Continuar
                      </button>
                      <input
                        id="checkModalCodigo"
                        className="d-none"
                        type="checkbox"
                        data-bs-toggle="modal"
                        data-bs-target="#ingresar_codigo"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Passback;
