/**
 * Página Admin Tarifas:
 * 
 * acá se Adminsitran las tarifas de despacho
 * 
 * @module AdminTarifasjsx
 */


import { useEffect, useState } from "react";
import axios from "axios";

import { AdminSidebar } from "../../components";
import Mensaje from "../../components/Modales/Mensaje";

const Tarifas = () => {

  /**
* @constant
* @name regiones
* @type {Array}
* 
* */
  const [regiones, setRegiones] = useState([]);

  /**
* @constant
* @name comunas
* @type {Array}
* 
* */

  const [comunas, setComunas] = useState([]);

  /**
* @constant
* @name selectedRegion
* @type {string}
* 
* */
  const [selectedRegion, setSelectedRegion] = useState("");

  /**
* @constant
* @name selectedComuna
* @type {string}
* 
* */
  const [selectedComuna, setSelectedComuna] = useState("");


  /**
* @constant
* @name valoresTallas
* @type {Array}
* 
* */
  const [valoresTallas, setValoresTallas] = useState([]);


  /**
* @constant
* @name checkTodas
* @type {boolean}
* 
* */

  const [checkTodas, setCheckTodas] = useState(false);

  /**
* @constant
* @name diasHabiles
* @type {nunber}
* 
* */

  const [diasHabiles, setDiasHabiles] = useState(0);

  /**
* @constant
* @name msg
* @type {string}
* 
* */
  const [msg, setMsg] = useState("");

  /**
* @constant
* @name tipo
* @type {string}
* 
* */
  const [tipo, setTipo] = useState("");


  const accessToekn = sessionStorage.getItem("access");

  /**
* @constant
* @name mensaje
* @type {string}
* 
* */
  const [mensaje, setMensaje] = useState("");



  /**
* @function
* Petición HTTP que obtiene todas las regiones del país
* @returns {Array}
*/
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BACK_URL + "/api/region/get_regiones")
      .then((response) => {
        setRegiones(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);


  /**
* @function
* Petición HTTP que obtiene todas las comunas de una región seleccionada
* @param {string} selectedRegion Región seleccionada
* @returns {Array}
*/
  useEffect(() => {
    if (selectedRegion !== "") {
      axios
        .get(
          process.env.REACT_APP_BACK_URL + `/api/comuna/get_comunas_by_region/${selectedRegion}`
        )
        .then((response) => {
          setComunas(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setComunas([]);
    }
  }, [selectedRegion]);


  /**
* @function
* Petición HTTP que obtiene todas las tarifas por comuna seleecionada
* @param {string} selectedComuna Comuna seleccionada
* @returns {Array}
*/
  useEffect(() => {
    if (selectedComuna !== "") {
      axios
        .get(
          process.env.REACT_APP_BACK_URL + `/api/admin/listar_tarifas_por_comuna?comuna=${selectedComuna}`
        )
        .then((response) => {
          setValoresTallas(response.data);
          setDiasHabiles(response.data[0].dias_habiles);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setComunas([]);
    }
  }, [selectedComuna]);

  /**
* @function
* Petición HTTP que obtiene todas las tallas de los productos
* @returns {Array}
*/
  useEffect(() => {
    if (checkTodas) {
      axios
        .get(process.env.REACT_APP_BACK_URL + `/api/admin/listar_todas_tallas_productos`)
        .then((response) => {
          setValoresTallas(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
    }
  }, [checkTodas]);



  const handleRegionChange = (event) => {
    setSelectedRegion(event.target.value);
    setSelectedComuna("");

    setMsg();
    setTipo(0);
  };

  const handleComunaChange = (event) => {
    setSelectedComuna(event.target.value);

    setMsg();
    setTipo(0);
  };

  const handleModificarTodas = () => {
    const newData = valoresTallas.reduce((result, talla) => {
      if (talla.valor !== undefined) {
        result[talla.id] = talla.valor;
      }
      return result;
    }, {});

    const dataToSend = {
      ...newData,
      region: selectedRegion,
    };


    try {
      setMensaje("Modificando tarifas-rojo");
      axios
        .post(
          process.env.REACT_APP_BACK_URL + `/api/admin/modificar_tarifa_por_region_check_todas`,
          dataToSend,
          {
            headers: {
              Authorization: `Bearer ${accessToekn}`,
            },
          }
        )
        .then((response) => {

          setMsg(response.data);
          setTipo(1);
          setMensaje("Tarifas modificadas-verde");
          location.href = "/admin/tarifas";
          setTimeout(() => {
            setMsg("");
            setTipo(0);
          }, 5000);
        })
        .catch((error) => {
          setMensaje("Error al modificar las tarifas-rojo");

          setMsg(
            "No se pudo agregar el valor de tarifas, si el problema persiste comunicate con los desarrolladores"
          );
          setTipo(2);

          setTimeout(() => {
            setMsg("");
            setTipo(0);
          }, 5000);
        });
    } catch (error) { }

  };


  /**
* @function
* Petición HTTP que guarda los valores por tallas por comuna
* @param {Object} jsonFormattedData FormData con todos los datos de los precios por tallas por comuna
* @returns {string}
*/
  const handleSubmit = () => {
    let validacion = 0;
    valoresTallas.forEach(function (valor) {
      if (valor.valor == "")
        validacion += 1;
    })
    if (selectedComuna == "" || diasHabiles == "")
      validacion += 1;

    if (validacion == 0) {
      const jsonFormattedData = { comuna: selectedComuna, diasHabiles: diasHabiles };
      const dataToSend = {
        valores: valoresTallas.map((talla) => {
          return { id: talla.id, valor: talla.valor };
        }),
      };


      dataToSend.valores.forEach((obj) => {
        jsonFormattedData[obj.id] = obj.valor;
      });


      setMensaje("Modificando tarifas-alert");
      axios
        .post(
          process.env.REACT_APP_BACK_URL + `/api/admin/modificar_tarifa_por_comuna`,
          jsonFormattedData,
          {
            headers: {
              Authorization: `Bearer ${accessToekn}`,
            },
          }
        )
        .then((response) => {

          setMsg(response.data);
          setTipo(1);
          setMensaje("Tarifas modificadas-verde");
          location.href = "/admin/tarifas";
          setTimeout(() => {
            setMsg("");
            setTipo(0);
          }, 5000);
        })
        .catch((error) => {
          setMensaje("Error al modificar las tarifas-rojo");

          setMsg(
            "No se pudo agregar el valor de tarifas, si el problema persiste comunícate con los desarrolladores"
          );
          setTipo(2);

          setTimeout(() => {
            setMsg("");
            setTipo(0);
          }, 5000);
        });
    }
  };

  const handleChange = (index, newValue) => {
    setValoresTallas((prevValores) => {
      return prevValores.map((talla, idx) => {
        if (idx === index) {
          return { ...talla, valor: newValue };
        }
        return talla;
      });
    });
  };

  const handleCheckBoxChange = () => {
    setCheckTodas(!checkTodas);
    if (checkTodas === false) {
      setSelectedRegion("");
    }
  };

  const handleChangeTodas = (index, value) => {
    setValoresTallas((prevValoresTallas) => {
      const updatedValoresTallas = [...prevValoresTallas];
      updatedValoresTallas[index] = {
        ...updatedValoresTallas[index],
        valor: value,
      };
      return updatedValoresTallas;
    });
  };

  return (
    <div>
      <Mensaje mensaje={mensaje} setMensaje={setMensaje} />
      <AdminSidebar />

      <div className="regular">
        <h1>
          Dreamtec - <span>Administrador de tarifas de despachos</span>
        </h1>

        <div className="shadow">
          <div className="row">
            <div className="col-12 text-center mb-3">
              <h2>Tarifas de despacho</h2>
            </div>
            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
              <div className="alert alert-primary py-2" role="alert">
                <i className="m-2 fa-sharp fa-solid fa-circle-info"></i>
                Debe ingresar los valores sin comas ni letras solo números.
              </div>
            </div>

            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
              <label htmlFor="regiones">
                Seleciona una región para desplegar sus comunas:
              </label>
              <select
                className="form-control rounded-pill"
                name="regiones"
                id="region"
                value={selectedRegion}
                onChange={handleRegionChange}
                required
                placeholder="Seleccione una opción"
              >
                <option value="">Selecciona una región</option>
                {regiones.map((region) => (
                  <option key={region.id} value={region.id}>
                    {region.nombre_region}
                  </option>
                ))}
              </select>
            </div>

            {selectedRegion && !checkTodas && (
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
                <label htmlFor="regiones">
                  Seleciona una comuna para desplegar sus tarifas:
                </label>
                <select
                  className="form-control rounded-pill"
                  name="regiones"
                  id="region"
                  value={selectedComuna}
                  onChange={handleComunaChange}
                  required
                  placeholder="Seleccione una opción"
                >
                  <option value="">Selecciona una comuna</option>
                  {comunas.map((comuna) => (
                    <option key={comuna.id} value={comuna.id}>
                      {comuna.nombre_comuna}
                    </option>
                  ))}
                </select>
              </div>
            )}

            <div className="row justify-content-end">
              <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-3 text-end">
                <label className="mb-3 mt-2">
                  Tarifas para toda la región:
                </label>
                <input
                  type="checkbox"
                  className="mx-2"
                  checked={checkTodas}
                  onChange={handleCheckBoxChange}
                />
              </div>
            </div>

            {selectedComuna && !checkTodas && (
              <>
                <div className="row mb-3">
                  <div className="col-12">
                    <div className="row">
                      {valoresTallas.map((talla, index) => (
                        <div
                          key={talla.id}
                          className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mb-3"
                        >
                          <label htmlFor={`talla-${index}`}></label>
                          {talla.talla}

                          <input
                            type="text"
                            className="form-control"
                            id={`talla-${index}`}
                            value={talla.valor}
                            onChange={(event) =>
                              handleChange(index, event.target.value)
                            }
                            maxLength={9}
                            max={9}
                          />
                        </div>
                      ))}

                      {valoresTallas != null && valoresTallas != "" && (
                        <>
                          {valoresTallas.slice(0, 1).map((dias) => (
                            <div
                              key={dias.id}
                              className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mb-3"
                            >
                              <label htmlFor={`talla-dias-habiles`}></label>
                              {`Dias Hábiles`}

                              <input
                                type="text"
                                className="form-control"
                                id={`talla-dias-habiles`}
                                defaultValue={diasHabiles}
                                onChange={(e) =>
                                  setDiasHabiles(e.target.value)
                                }
                                maxLength={9}
                                max={9}
                              />
                            </div>
                          ))}
                        </>
                      )}





                    </div>
                  </div>

                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
                    <button
                      aria-label="Guardar tarifas por comuna"
                      onClick={() => {
                        handleSubmit();
                      }}
                      className="w-100 btnGreen"
                    >
                      Guardar tarifas por comuna
                    </button>
                  </div>
                </div>

                <div className="row">
                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 co-sm-12 col-12 text-center">
                    <span
                      className={
                        tipo == 1
                          ? "text-success fw-bold"
                          : "text-danger fw-bold"
                      }
                    >
                      {msg}
                    </span>
                  </div>
                </div>
              </>
            )}

            {selectedRegion && checkTodas && (
              <>
                <div className="row mb-3">
                  <div className="col-12">
                    <div className="row">
                      {valoresTallas.map((talla, index) => (
                        <div
                          key={talla.id}
                          className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mb-3"
                        >
                          <label htmlFor={`talla-${index}`}></label>
                          {talla.name}
                          <br />
                          <input
                            type="text"
                            className="form-control"
                            id={`talla-${index}`}
                            value={talla.valor !== undefined ? talla.valor : ""}
                            onChange={(event) =>
                              handleChangeTodas(index, event.target.value)
                            }
                            maxLength={9}
                            max={9}
                          />
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
                    <button
                      type="button"
                      aria-label="Guardar tarifas para toda la región"
                      onClick={() => {
                        handleModificarTodas();
                      }}
                      className="w-100 btnGreen"
                    >
                      Guardar tarifas para toda la región
                    </button>
                  </div>
                </div>

                <div className="row">
                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 co-sm-12 col-12 text-center">
                    <span
                      className={
                        tipo == 1
                          ? "text-success fw-bold"
                          : "text-danger fw-bold"
                      }
                    >
                      {msg}
                    </span>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tarifas;
