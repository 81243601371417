/**
 * Componente Mensaje:
 * 
 * acá se muestra el mensaje que se añade a los modales correspondintes
 * 
 * @module ComponenteMensajejsx
 */

import React, { useState, useEffect } from "react";
import "./Mensaje.css"

const Mensaje = (props) => {
    const timer = ms => new Promise(res => setTimeout(res, ms))
    let modal = document.getElementById("modalMostrarMensaje");
    let barra = document.querySelector("#barraVerdeModal");
    let largo = 100;
    let texto = props.mensaje.split("-")[0];
    let color = props.mensaje.split("-")[1];
    async function load() {
        for (var i = 0; i < 100; i++) {
            await timer(30);
            largo--;
            barra.style.width = largo + "%";
        }
    }

    if (props.mensaje != null && props.mensaje != "" && modal.classList.toString().includes("d-none")) {
        modal.classList.remove("d-none");
        setTimeout(function () {
            modal.classList.add("d-none");
            props.setMensaje("");
        }, 3500);

        load();
    };

    return (
        <div className="modalMensaje shadow-lg p-3 mb-5 bg-white rounded m-0 p-0 d-none justify-content-end border" id="modalMostrarMensaje">
            {color == "rojo" && (
                <div className="interior fw-bold" style={{ "color": "rgb(220, 53, 59)" }}>
                    {texto}&nbsp;&nbsp;<i class="fa-solid fa-triangle-exclamation text-danger"></i>
                </div>
            )}
            {color == "verde" && (
                <div className="interior fw-bold" style={{ "color": "rgb(102, 187, 106)" }}>
                    {texto}&nbsp;&nbsp;<i class="fa-solid fa-circle-check text-success"></i>
                </div>
            )}
            {color == "alert" && (
                <div className="interior fw-bold" style={{ "color": "#ffc107" }}>
                    {texto}&nbsp;&nbsp;<i class="fa-solid fa-triangle-exclamation text-warning"></i>
                </div>
            )}
            <div className="barra fondo-celeste" id="barraVerdeModal"></div>
        </div>
    );
};

export default Mensaje