/**
 * Página Admin Banners Categorias:
 * 
 * acá se Administran los banner de Categorias
 * 
 * @module AdminBannersCategoriasjsx
 */


import { useState, useEffect } from "react";
import axios from "axios";
import { AdminSidebar } from "../../components";
import "./BannersCategorias.css";
import Mensaje from "../../components/Modales/Mensaje";

const BannersCategorias = () => {


  /**
* @constant
* @name tipoFuncion
* @type {number}
* 
* */
  const [tipoFuncion, setTipoFuncion] = useState(0);

  /**
* @constant
* @name categorias
* @type {?string}
* 
* */
  const [categorias, setCategorias] = useState(null);

  /**
* @constant
* @name padres
* @type {?string}
* 
* */
  const [padres, setPadres] = useState(null);

  /**
* @constant
* @name hijas
* @type {?string}
* 
* */
  const [hijas, setHijas] = useState(null);

  /**
* @constant
* @name selectedName
* @type {string}
* 
* */
  const [selectedName, setSelectedName] = useState("");

  const accessToekn = sessionStorage.getItem("access");


  /**
* @constant
* @name banner
* @type {?string}
* 
* */
  const [banner, setBanner] = useState(null);

  /**
* @constant
* @name producto
* @type {?string}
* 
* */
  const [producto, setProducto] = useState(null);

  /**
* @constant
* @name actuales
* @type {?string}
* 
* */
  const [actuales, setActuales] = useState(null);

  /**
* @constant
* @name mensaje
* @type {string}
* 
* */
  const [mensaje, setMensaje] = useState("");
  const [bannerEstaticos, setBannerEstaticos] = useState("");
  const [bannerEstaticoMod, setBannerEstaticoMod] = useState("");


  useEffect(() => {

    /**
* @function
* Peticion HTTP que retorna todas las categorias de banners
*@returns {Array}
* 
*/

    const fetchData = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_BACK_URL + `/api/categoria_banner/get_categorias_banners`
        );
        setCategorias(response.data);
      } catch (error) {
        console.error(error);
      }
    };


    /**
* @function
* Peticion HTTP que retorna todas las categorias y los banners
*@returns {Array}
* 
*/


    axios
      .get(process.env.REACT_APP_BACK_URL + `/api/admin/categoria/get_all_categorias_and_banners`)
      .then((response) => {
        setPadres(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    /**
* @function
* Peticion HTTP que retorna todas las categorias hijas por padre
*@returns {Array}
* 
*/

    axios
      .get(process.env.REACT_APP_BACK_URL + `/api/admin/categoria/get_all_categoria_hija_by_padre/0`)
      .then((response) => {
        setHijas(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    fetchData();
  }, [tipoFuncion]);

  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_BACK_URL + `/api/banners/get_banner_estaticos`
      )
      .then((response) => {
        setBannerEstaticos(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);


  /**
* @function
* Metodo para volver atras
* 
*/
  const handleVolverAtras = () => {
    setTipoFuncion(0);
  };


  /**
* @function
* Metodo que muestra un preview de la foto ingresada
* 
*/
  const muestra_foto_crear = () => {
    let file = document.querySelector("#actualizar-foto-banner-nueva");
    let img = document.querySelector("#foto-cat-crear");
    img.src = URL.createObjectURL(file.files[0]);
  };

  /**
* @function
* Metodo que muestra un preview de la foto modificada
* 
*/
  function muestra_foto_modificar() {
    let file = document.querySelector("#actualizar-foto-banner");
    let img = document.querySelector("#foto-cat-modificar");
    img.src = URL.createObjectURL(file.files[0]);
  };


  const mostrarDetalleBanner = (banner) => {
    setBanner(banner);
    setTipoFuncion(1);
  }


  /**
* @function
* Metodo que cambia las categorias hijas a modificar por el padre seleccionado
* 
*/
  const cambiaHijas = () => {
    let padre = document.querySelector("#select-categorias-padres").value;
    let daug = document.querySelector("#select-categorias-hijas-modificar");
    let row = "";
    hijas?.map((hija) => {
      if (padre == hija.categoria_padre_id)
        row += `<option value=${hija.id} key=${hija.id}>${hija.nombre_mostrar}</option>`

    });
    daug.innerHTML = row;
  };

  const validaTexto = (texto) => {
    let validar = [`"`, `.`, `?`, `select`, `where`, `=`, `'`, `*`, `<`, `>`, `php`, `;`, `(`, `)`, `{`, `}`, `[`, `]`, `,`, `_`, `:`, '`', '#', '|', '°', '!', '$', '%', '&', '/', '(', ')', '?', '¿', '¡', '´', '¨', '«', '+', '*', '~', '^', ',', ';', '-', '_', '–', '…',];
    for (let i = 0; i < validar.length; i++) {
      if (texto.includes(validar[i]))
        return false;
    }
    return true;
  };


  /**
* @function
* Petición HTTP para traer los productos por categoria
*@param catp Categoria padre
@param cath Categoria hija
* 
*/
  const traeProductosCategoria = () => {
    let prods = document.querySelector("#productos-categoria-actuales");
    let catp = document.querySelector("#categoria-producto-padre").value;
    let cath = document.querySelector("#categoria-producto-hija").value;
    axios.get(
      process.env.REACT_APP_BACK_URL + `/get_productos_by_categorias?categoria=${catp}&categoria_hija=${cath}`
    )
      .then((response) => {
        if (response.data.length > 0)
          setActuales(response.data);
        else
          setActuales(null);
      })
      .catch((error) => {
        console.error(error);
      });
  };


  /**
* @function
* Petición HTTP para guardar los cambios del banner
* @param {Object} formData FormData con los datos del banner a guardar
*/
  const handleGuardarCambios = () => {
    let hija_id = document.querySelector("#select-categorias-hijas-modificar").value;
    let estado = 1;
    let foto = document.querySelector("#actualizar-foto-banner").files[0];
    let categoria = document.querySelector("#select-categorias-padres").value;
    let posicion = banner.posicion_id;
    let indice = banner.indice;

    if (foto != null) {
      setMensaje("Modificando datos-alert");
      const formData = new FormData();
      formData.append("id", hija_id);
      formData.append("estado", estado);
      formData.append("foto", foto);
      formData.append("categoria", categoria);
      formData.append("destacada", "1");
      formData.append("posicion", posicion);
      formData.append("indice", indice);

      axios
        .post(process.env.REACT_APP_BACK_URL + `/api/admin/categoria_hija/update_categoriahija`, formData, {
          headers: {
            Authorization: `Bearer ${accessToekn}`,
            "Content-Type": "multipart/form-data",
          }
        })
        .then((response) => {
          location.reload();
          setMensaje("Datos modificados-verde");
          location.href = "/admin/bannercategorias";
        })
        .catch((error) => {
          setMensaje("Error al modificar los datos-rojo");
        });
    }
    else
      setMensaje("Complete todos los campos-rojo");
  };


  /**
* @function
* Petición HTTP que busca un producto por SKU ingresado
* @param {string} sku sku del producto a buscar
*/
  const handleSkuCode = () => {
    validaDisabled();
    let sku = document.querySelector("#txt-buscar-sku").value;
    axios
      .get(process.env.REACT_APP_BACK_URL + `/api/admin/producto/get_producto_by_sku/${sku.toUpperCase()}`)
      .then((response) => {
        if (response.data.length == 0)
          setProducto(null);
        else
          setProducto(response.data[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  };


  /**
* @function
* Metodo que muestra las categorias hijas de un padre seleccionado
*/
  const muestraHijas = () => {
    validaDisabled();
    let padre = document.querySelector("#categoria-producto-padre").value;
    let daug = document.querySelector("#categoria-producto-hija");
    let row = "";
    if (padre != 0) {
      hijas?.map((hija) => {
        if (padre == hija.categoria_padre_id)
          row += `<option value=${hija.id} key=${hija.id}>${hija.nombre_mostrar}</option>`

      });
      daug.innerHTML = row;
    }
    else
      daug.innerHTML = "";
  };

  const agregarProducto = () => {
    let agregar = [];
    if (actuales != null)
      agregar = actuales;

    let repetido = false;
    agregar.forEach(element => {
      if (element.sku == producto.sku)
        repetido = true;
    });

    if (!repetido)
      agregar.push(producto);
    setActuales([...agregar]);
    setMensaje("Producto agregado-verde");
  };

  const validaDisabled = () => {
    let padre = document.querySelector("#categoria-producto-padre");
    let daug = document.querySelector("#categoria-producto-hija");
    let sku = document.querySelector("#txt-buscar-sku");
    let prod = document.querySelector("#mostrar-detalle-producto");
    if (padre.value == 0)
      daug.disabled = true;
    else
      daug.disabled = false
    sku.disabled = daug.disabled

    if (sku.disabled == true)
      sku.value = "";

    try {
      if (sku.value == "") {
        prod.style.display = "none";
      }
      else {
        prod.style.display = "block";
      }
    } catch (error) { }
  };

  const quitarProducto = () => {
    let incluido = document.querySelector("#productos-categoria-actuales").value;
    let quitar = [];
    let cont = 0;
    if (actuales != null) {
      quitar = actuales;
      quitar.forEach(element => {
        if (element.sku == incluido) {
          quitar.splice(quitar.indexOf(element), 1);
          setMensaje("Producto removido-verde");
          cont++;
        }
      });
    }
    if (cont == 0)
      setMensaje("Marque el producto que desea eliminar-rojo");
    setActuales([...quitar]);
  };



  /**
* @function
* Petición HTTP para crear una nueva categoria con su banner correspondiente
* validando que la foto este y el texto de la categoria se valido
* @param {Object} formData todos los datos de la categoria
*/
  const handleCrearCategoria = () => {
    let hija_mostrar = document.querySelector("#nombre-nueva-categoria").value;
    let hija = hija_mostrar.toLowerCase();
    while (hija.includes(" "))
      hija = hija.replace(" ", "-");
    let estado = 1;
    let foto = document.querySelector("#actualizar-foto-banner-nueva").files[0];
    let categoria = document.querySelector("#select-categorias-padres-nueva").value;
    let posicion = banner.posicion_id;
    let indice = banner.indice;

    if (foto != null && validaTexto(hija_mostrar)) {
      setMensaje("Creando categoría-alert");
      const formData = new FormData();
      formData.append("hija", hija);
      formData.append("categoria", categoria);
      formData.append("nombre_mostrar", hija_mostrar);
      formData.append("estado", estado);
      formData.append("foto", foto);
      formData.append("indice", indice);
      formData.append("posicion", posicion);

      axios
        .post(process.env.REACT_APP_BACK_URL + `/api/admin/categoria_hija/register_categoriahija`, formData, {
          headers: {
            Authorization: `Bearer ${accessToekn}`,
            "Content-Type": "multipart/form-data",
          }
        })
        .then((response) => {
          location.href = "/admin/bannercategorias";
          setMensaje("Categoría registrada-verde");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleGuardarCambios2 = () => {
    let foto = document.querySelector("#actualizar-foto-banner").files[0];
    const formData = new FormData();
    formData.append("foto", foto);
    formData.append("id", bannerEstaticoMod.id);

    axios
    .post(process.env.REACT_APP_BACK_URL + `/api/admin/banner/update_banner_estatico`, formData, {
      headers: {
        Authorization: `Bearer ${accessToekn}`,
        "Content-Type": "multipart/form-data",
      }
    })
    .then((response) => {
      location.reload();
      setMensaje("Datos modificados-verde");
      location.href = "/admin/bannercategorias";
    })
    .catch((error) => {
      setMensaje("Error al modificar los datos-rojo");
    });
  };

  /**
* @function
* Petición HTTP que guarda los cambios para registrar un producto a una categoria
* @param {Object} formData todos los datos para agregar un producto a la categoria
*/
  const enviarCambios = () => {
    let padre = document.querySelector("#categoria-producto-padre");
    let daug = document.querySelector("#categoria-producto-hija");
    const formData = new FormData();
    formData.append("categoria", padre.value);
    formData.append("categoria_hija", daug.value);
    for (let i = 0; i < actuales.length; i++) {
      formData.append("sku_" + (i + 1), actuales[i].sku);
    }
    setMensaje("Modificando datos-alert");
    axios
      .post(process.env.REACT_APP_BACK_URL + `/api/admin/categoria_producto/register_categoriaproductos`, formData, {
        headers: {
          Authorization: `Bearer ${accessToekn}`,
          "Content-Type": "multipart/form-data",
        }
      })
      .then((response) => {
        location.reload();
        setMensaje("Datos modificados-verde");
      })
      .catch((error) => {
        setMensaje("Error al modificar los datos-rojo");
      });
  };

  const mostrarDetalleBanner2 = (banner) => {
    setTipoFuncion(3);
    setBannerEstaticoMod(banner);
  };
  
  return (
    <div >
      <Mensaje mensaje={mensaje} setMensaje={setMensaje} />
      <AdminSidebar />

      <div className="regular h-100">
        <h1>
          Dreamtec - <span>Administrador de banners de Categorías</span>
        </h1>

        <div className="shadow justify-content-center text-center" style={{ maxWidth: "1400px" }}>
          {tipoFuncion === 0 && (
            <div className="row">
              <div className="col-12">
                <div className="row justify-content-end">
                  <div className="col-xxl-4 col-xl-5 col-lg-7 col-md-12 col-sm-12 col-12 mb-3">
                    <button
                      className="agregar"
                      aria-label="Agregar producto a categoría"
                      onClick={() => setTipoFuncion(2)}
                    >
                      <i className="fa-solid fa-circle-plus"></i> &nbsp;Agregar
                      productos a una categoría
                    </button>
                  </div>
                </div>
              </div>

              <div className="col-12 text-center mb-4">
                <h2>Vista categor&iacute;as especiales con banner</h2>
              </div>

              {categorias != null && categorias != [] && bannerEstaticos != "" ? (
                <div className=" text-center">
                  {/* Llega Hoy */}
                  <div className="row mb-3">
                    <div className="col-xs-12">
                      <img 
                        className="img-fluid" 
                        src={process.env.REACT_APP_FRONT_URL + bannerEstaticos[0].url}  
                        role="button" 
                        onClick={() => {mostrarDetalleBanner2(bannerEstaticos[0])}} 
                      />
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-xs-12">
                      <img className="img-fluid" src={process.env.REACT_APP_FRONT_URL + "/uploads/placeholder/compromisos.png"} />
                    </div>
                  </div>

                  {/* Fila 1 */}
                  <div className="row mb-3">
                    <div className="col-md-3">
                      <img
                        onClick={() => { mostrarDetalleBanner(categorias.banners_superior[0]) }}
                        role="button"
                        src={process.env.REACT_APP_FRONT_URL + categorias.banners_superior[0]?.foto}
                        alt="banner-1"
                        className="img-fluid"
                        loading="lazy"
                      />
                    </div>
                    <div className="col-md-3">
                      <img
                        onClick={() => { mostrarDetalleBanner(categorias.banners_superior[1]) }}
                        role="button"
                        src={process.env.REACT_APP_FRONT_URL + categorias.banners_superior[1]?.foto}
                        alt="banner-1"
                        className="img-fluid"
                        loading="lazy"
                      />
                    </div>
                    <div className="col-md-3">
                      <img
                        onClick={() => { mostrarDetalleBanner(categorias.banners_superior[2]) }}
                        role="button"
                        src={process.env.REACT_APP_FRONT_URL + categorias.banners_superior[2]?.foto}
                        alt="banner-1"
                        className="img-fluid"
                        loading="lazy"
                      />
                    </div>
                    <div className="col-md-3">
                      <img
                        onClick={() => { mostrarDetalleBanner(categorias.banners_superior[3]) }}
                        role="button"
                        src={process.env.REACT_APP_FRONT_URL + categorias.banners_superior[3]?.foto}
                        alt="banner-1"
                        className="img-fluid"
                        loading="lazy"
                      />
                    </div>
                  </div>

                  {/* Slider 1 */}
                  <div className="row mb-3">
                    <div className="col-md-12">
                      <img
                        src={process.env.REACT_APP_FRONT_URL + "/uploads/placeholder/slider1.png"}
                        alt=""
                        className="img-fluid"
                        loading="lazy"
                      />
                    </div>
                  </div>

                  {/* Fila 2 */}
                  <div className="row mb-3">
                    <div className="col-md-3 mb-3">
                      <img
                        onClick={() => { mostrarDetalleBanner(categorias.banners_300_400[0]) }}
                        role="button"
                        src={process.env.REACT_APP_FRONT_URL + categorias.banners_300_400[0]?.foto}
                        alt="banner-2"
                        className="img-fluid"
                        loading="lazy"
                      />
                    </div>
                    <div className="col-md-3">
                      <img
                        onClick={() => { mostrarDetalleBanner(categorias.banners_300_400[1]) }}
                        role="button"
                        src={process.env.REACT_APP_FRONT_URL + categorias.banners_300_400[1]?.foto}
                        alt="banner-2"
                        className="img-fluid"
                        loading="lazy"
                      />
                    </div>
                    <div className="col-md-6">
                      <div className="row mb-3">
                        <div className="col-md-12">
                          <img
                            onClick={() => { mostrarDetalleBanner(categorias.banners_600_200[0]) }}
                            role="button"
                            src={process.env.REACT_APP_FRONT_URL + categorias.banners_600_200[0]?.foto}
                            alt="banner-3"
                            className="img-fluid"
                            loading="lazy"
                          />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-md-6">
                          <img
                            onClick={() => { mostrarDetalleBanner(categorias.banners_300_200[0]) }}
                            role="button"
                            src={process.env.REACT_APP_FRONT_URL + categorias.banners_300_200[0]?.foto}
                            alt="banner-4"
                            className="img-fluid"
                            loading="lazy"
                          />
                        </div>
                        <div className="col-md-6">
                          <img
                            onClick={() => { mostrarDetalleBanner(categorias.banners_300_200[1]) }}
                            role="button"
                            src={process.env.REACT_APP_FRONT_URL + categorias.banners_300_200[1]?.foto}
                            alt="banner-4"
                            className="img-fluid"
                            loading="lazy"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Fila 3 */}
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <img
                        src={process.env.REACT_APP_FRONT_URL + "/uploads/placeholder/slider2.png"}
                        alt=""
                        className="img-fluid"
                        loading="lazy"
                      />
                    </div>
                    <div className="col-md-6">
                      <img
                        onClick={() => { mostrarDetalleBanner(categorias.banners_600_500[0]) }}
                        role="button"
                        src={process.env.REACT_APP_FRONT_URL + categorias.banners_600_500[0]?.foto}
                        alt="banner-5"
                        className="img-fluid"
                        loading="lazy"
                      />
                    </div>
                  </div>

                  {/* Fila 4 */}
                  <div className="row">
                    <div className="col-md-12">
                      <img
                        onClick={() => { mostrarDetalleBanner(categorias.banner_inferior[0]) }}
                        role="button"
                        src={process.env.REACT_APP_FRONT_URL + categorias.banner_inferior[0]?.foto}
                        alt="banner-6"
                        className="img-fluid"
                        loading="lazy"
                      />
                    </div>
                  </div>
                </div>

              ) : (
                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 fw-bold text-center my-5">
                  <i className="fa-solid fa-circle-info"></i>&nbsp;No hay categorías
                  especiales registradas
                </div>
              )}
            </div>
          )}
          {tipoFuncion === 1 && (
            <>
              <div className="row justify-content-center text-center">
                <label className='mb-3 color-azul'>Modificar el banner actual</label>
                {banner.posicion_id == 1 && (
                  <p style={{ fontSize: "12px", fontWeight: "bold" }}>Optimizado para imágenes de 515x260</p>
                )}
                {banner.posicion_id == 2 && (
                  <p style={{ fontSize: "12px", fontWeight: "bold" }}>Optimizado para imágenes de 515x475</p>
                )}
                {banner.posicion_id == 3 && (
                  <p style={{ fontSize: "12px", fontWeight: "bold" }}>Optimizado para imágenes de 1025x220</p>
                )}
                {banner.posicion_id == 4 && (
                  <p style={{ fontSize: "12px", fontWeight: "bold" }}>Optimizado para imágenes de 510x215</p>
                )}
                {banner.posicion_id == 5 && (
                  <p style={{ fontSize: "12px", fontWeight: "bold" }}>Optimizado para imágenes de 696x424</p>
                )}
                {banner.posicion_id == 6 && (
                  <p style={{ fontSize: "12px", fontWeight: "bold" }}>Optimizado para imágenes de 2065x215</p>
                )}
                <div className="col-lg-6 col-md-6 col-12 text-center align-self-center mb-1">
                  <label className='mb-1'>Categoría</label>
                  <select defaultValue={banner.categoria_padre_id} className='form-control rounded-pill mb-2' onChange={cambiaHijas} id='select-categorias-padres'>
                    {padres?.map((padre) => (
                      <option value={padre.id} key={padre.id}>{padre.nombre_mostrar}</option>
                    ))}
                  </select>
                  <label className='mb-1'>Sub Categoría</label>
                  <select defaultValue={banner.id} className='form-control rounded-pill mb-2' id='select-categorias-hijas-modificar'>
                    {
                      hijas?.map((hija) => {
                        if (hija.categoria_padre_id == banner.categoria_padre_id)
                          return <option value={hija.id} key={hija.id}>{hija.nombre_mostrar}</option>

                      })
                    }
                  </select>
                  <label className='mb-1'> Cargar nuevo banner </label>
                  <input type='file' id='actualizar-foto-banner' className='form-control mb-3' accept="image/png, image/jpeg, image/jpg, image/webp" onChange={muestra_foto_modificar} />
                </div>
                <div className="col-lg-6 col-md-6 col-12 text-center align-self-center mb-3">
                  <img src={process.env.REACT_APP_FRONT_URL + banner.foto} id="foto-cat-modificar" loading="lazy" className='img-fluid' />
                </div>
                <div className='col-md-3 mb-3'>
                  <button aria-label="Modificar banner"
                    onClick={() => handleGuardarCambios()}
                    className="btnGreen bg-primary text-white px-3 fw-bold col-12 mb-3"
                    type="submit">Guardar Cambios</button>
                </div>

                <label className='mb-3 color-azul mb-3'>Crear nueva categoría en esta posición</label>
                {/* <div className="col-lg-12 col-md-12 col-12 text-center align-self-center"> */}
                <div className='col-lg-6 col-md-6 col-12 text-center align-self-center mb-3'>
                  <label className='mb-1'>Categoría</label>
                  <select defaultValue={4} className='form-control rounded-pill mb-2' id='select-categorias-padres-nueva' >
                    {padres?.map((padre) => {
                      if (padre.id == 4)
                        return <option value={padre.id} key={padre.id}>{padre.nombre_mostrar}</option>
                    })}
                  </select>
                  <label className='mb-1'>Nombre de la sub categoría</label>
                  <input type='text' id='nombre-nueva-categoria' className='form-control mb-2' />
                  <label className='mb-1'> Foto del banner </label>
                  <input type='file' id='actualizar-foto-banner-nueva' className='form-control mb-3' accept="image/png, image/jpeg, image/jpg, image/webp" onChange={muestra_foto_crear} />
                </div>

                <div className='col-lg-6 col-md-6 col-12 text-center align-self-center mb-3'>
                  <img src="" loading="lazy" className="img-fluid" id="foto-cat-crear" />
                </div>
                {/* </div> */}

                <div className='col-md-3 mb-1'>
                  <button aria-label="Crear categoría"
                    onClick={() => handleCrearCategoria()}
                    className="btnGreen bg-primary text-white px-3 fw-bold col-12 mb-3"
                    type="submit">Crear Categoría</button>
                </div>
                <div className='col-md-3 mb-1'>
                  <button aria-label="Modificar banner"
                    onClick={handleVolverAtras}
                    className="btnGreen bg-primary text-white px-3 fw-bold cancel col-12 mb-3"
                    type="submit">Volver Atrás</button>
                </div>
              </div>
            </>
          )}
          {tipoFuncion === 2 && (
            <>
              {/* Texto azul */}
              <div className="row justify-content-center">
                <h2 className="col-md-4 mb-4">
                  Agregar productos a categoría: <span> {selectedName} </span>
                </h2>
              </div>

              {/* Select categorías */}
              <div className="row mb-5">
                <div className="col-md-5">
                  <label htmlFor="">Categoría</label>
                  <select defaultValue={0} name="" onChange={muestraHijas} id="categoria-producto-padre" className="form-control rounded-pill mb-3">
                    <option value="0">Seleccione categoría</option>
                    {padres?.map((padre) => (
                      <option value={padre.id} key={padre.id}>{padre.nombre_mostrar}</option>
                    ))}
                  </select>

                  <label htmlFor="">Sub Categoría</label>
                  <select name="" disabled id="categoria-producto-hija" className="form-control rounded-pill" onChange={traeProductosCategoria}>
                  </select>
                </div>
              </div>

              {/* Acciones */}
              <div className="row">

                {/* Buscar producto por sku */}
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 mb-3">
                  <label htmlFor="">
                    Sku del producto: &nbsp;
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    disabled
                    className="mb-3 form-control"
                    id="txt-buscar-sku"
                    required
                  />

                  <button
                    aria-label="Buscar producto por sku"
                    className="btnCelesteDreamtec mb-3 px-3 col-md-6 col-xs-12 w-100"
                    onClick={() => handleSkuCode()}
                    type="submit"
                  >
                    Buscar producto por sku
                  </button>

                  <button
                    aria-label="Volver a atrás"
                    onClick={handleVolverAtras}
                    className="cancel px-3 w-100"
                  >
                    Volver atrás
                  </button>
                </div>

                <div className="col-md-1"></div>

                {/* Card visualizar producto */}
                {producto != null ? (
                  <>
                    <div className="col-md-2" id="mostrar-detalle-producto">
                      <label htmlFor="">Verificar Producto</label>
                      <div className="col-md-12 card p-3">
                        {producto.fotos.length > 0 ?
                          <>
                            <div className="text-end ">
                              <div className="tag-prod-skunov mrc-5 text-center mt-1 ms-2 w-auto px-2">
                                SKU {producto.sku}
                              </div>
                            </div>
                            <img loading="lazy" src={process.env.REACT_APP_FRONT_URL + producto.fotos[0].ruta} className="img-fluid" alt="" id="img-producto-mostrar" />
                          </>
                          : ""}
                        <p className="nombre-marca mx-auto mt-3">
                          <i className="fas fa-circle pe-1 icono-nombre-marca"></i>
                          {producto.marca}
                        </p>
                        <label htmlFor="" id="lbl-producto-mostrar" className="text-black mt-1 nombre-tarj-prod fw-normal text-center">{producto.nombre}</label>
                        <label htmlFor="" id="lbl-precio-mostrar" className="fs-5 fw-bold mt-1 text-black text-center">{"$" + producto.precio.toLocaleString("es-CL")}</label>
                      </div>

                      <button
                        aria-label="Agregar producto por sku"
                        className="btnCelesteDreamtec mt-3 mb-3 px-3 col-md-6 col-xs-12 w-100"
                        onClick={agregarProducto}
                        type="submit"
                      >
                        Agregar producto
                      </button>
                    </div>
                  </>
                ) : (
                  <div className="col-md-3"></div>
                )}

                <div className="col-md-1"></div>

                {/* Agregar o quitar productos de la categoría */}
                {actuales != null &&
                  <div className="col-md-4">
                    <label htmlFor="">Productos en la categoría</label> <br />
                    <select name="" size={10} id="productos-categoria-actuales" className="form-control">
                      {actuales?.map((actual) => (
                        <option value={actual.sku} key={actual.sku}>{actual.nombre}</option>
                      ))}
                    </select>

                    <button
                      aria-label="Agregar producto por sku"
                      className="btnRed mt-3 mb-1 px-3 col-md-6 col-xs-12 w-100"
                      type="submit"
                      style={{ backgroundColor: "#dc3545 !important" }}
                      onClick={quitarProducto}
                    >
                      Quitar Producto
                    </button>

                    <button
                      aria-label="Agregar producto por sku"
                      className="btnGreen mt-3 mb-3 px-3 col-md-6 col-xs-12 w-100"
                      type="submit"
                      onClick={enviarCambios}
                    >
                      Guardar Todos Los Cambios
                    </button>
                  </div>
                }
              </div>
            </>
          )}
          {tipoFuncion === 3 && (
            <>
            <div className="row justify-content-center text-center">
              <label className='mb-3 color-azul'>Modificar el banner actual</label>
              <p className="mb-5" style={{ fontSize: "12px", fontWeight: "bold" }}>Optimizado para imágenes de 2065x215</p>

              <div className="col-lg-6 col-md-6 col-12 text-center align-self-center mb-1">
                <label className='mb-1'> Cargar nuevo banner </label>
                <input type='file' id='actualizar-foto-banner' className='form-control mb-3' accept="image/png, image/jpeg, image/jpg, image/webp" onChange={muestra_foto_modificar} />
              </div> 
              <div className="col-lg-6 col-md-6 col-12 text-center align-self-center mb-3">
                <img src={process.env.REACT_APP_FRONT_URL + bannerEstaticoMod.url} id="foto-cat-modificar" loading="lazy" className='img-fluid' />
              </div>
              <div className='col-md-3 mb-3'>
                <button aria-label="Modificar banner"
                  onClick={() => handleGuardarCambios2()}
                  className="btnGreen bg-primary text-white px-3 fw-bold col-12 mb-3"
                  type="submit">Guardar Cambios</button>
                  
              </div>
              <div className='col-md-3 mb-1'>
                <button aria-label="Modificar banner"
                  onClick={handleVolverAtras}
                  className="btnGreen bg-primary text-white px-3 fw-bold cancel col-12 mb-3"
                  type="submit">Volver Atrás</button>
              </div>
            </div>
          </>
          )}
        </div>
      </div>
    </div>
  );
};

export default BannersCategorias;
