/**
 * Componente CodigoRecPass:
 * 
 * acá se muestra el modal para verificar el codigo enviado al correo
 * 
 * @module ComponenteCodigoRecPassjsx
 */

import React, { useEffect } from "react";
import axios from "axios";

let spanMensaje = document.getElementById("code-mensaje-recuperar");
if (spanMensaje != null) {
  spanMensaje.innerHTML = ".";
  spanMensaje.classList.remove("text-success");
  spanMensaje.classList.remove("text-danger");
  spanMensaje.classList.add("text-white");
}

const CodigoRecPass = (props) => {

  /**
  * @function
  * Petición HTTP para validar el codigo ingresado
  * @param {Object} formData formData con los datos 
  * @returns {string}
  */

  const handleSubmit = (e) => {
    e.preventDefault();
    let spanMensaje = document.getElementById("code-mensaje-recuperar");
    props.setCodeRecuperar(document.querySelector("#codigo").value);

    let formData = new FormData();
    formData.append("correo", props.correoRecuperar);
    formData.append("code", document.querySelector("#codigo").value);
    axios
      .post(process.env.REACT_APP_BACK_URL + `/api/usuario/validar_codigo`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      })
      .then((response) => {
        if (response.data.message == "datos correctos") {
          document.getElementById("checkModalChangePass").click();
          document.querySelector("#codigo").value = "";
          document.querySelector("#").value = "";
          spanMensaje.innerHTML = ".";
          spanMensaje.classList.remove("text-success");
          spanMensaje.classList.remove("text-danger");
          spanMensaje.classList.add("text-white");
        }
        else {
          spanMensaje.innerHTML = "Código no válido";
          spanMensaje.classList.remove("text-success");
          spanMensaje.classList.remove("text-white");
          spanMensaje.classList.add("text-danger");
        }
      })
      .catch((error) => {
        spanMensaje.innerHTML = "Error al validar el código, intenta nuevamente";
        spanMensaje.classList.remove("text-success");
        spanMensaje.classList.remove("text-white");
        spanMensaje.classList.add("text-danger");
      });
  };

  return (
    <>
      <div
        className="modal fade"
        id="ingresar_codigo"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-bottom-0">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Cerrar"
              ></button>
            </div>

            <form onSubmit={handleSubmit}>
              <div className="modal-body pb-2 pt-0">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-lg-10 col-md-11 col-11 mb-3 text-center">
                      <span className="text-black fs-6 fw-bold">
                        VERIFICAR CÓDIGO
                      </span>
                      <br />
                      <span id="code-mensaje-recuperar">.</span>
                    </div>
                    <div className="co-lg-10 col-md-10 col-11 text-center">
                      <p className="fw-bolder fz-16">
                        Ingrese el código de verificación.
                      </p>
                    </div>
                    <div className="col-lg-10 col-md-11 col-11 mb-3">
                      <span className="fs-6 text-black">
                        Código verificación
                      </span>
                      <input
                        type="text"
                        id="codigo"
                        className="form-control rounded-pill mt-2"
                        placeholder="Ingrese el código de 6 dígitos"
                        maxLength={6}
                        required
                        autoComplete="off"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer pb-4 border-top-0">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-lg-5 col-md-5 col-sm-6 col-7 d-grid gap-2">
                      <button
                        type="submit"
                        id="btn-verificar-codigo"
                        aria-label="Ingresar Código"
                        className="rounded-pill bg-turq text-white py-1 border-0"
                      >
                        Ingresar Código
                      </button>
                      <input
                        className="d-none"
                        id="checkModalChangePass"
                        type="checkbox"
                        data-bs-toggle="modal"
                        data-bs-target="#modal-cambiar-pass"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CodigoRecPass;
