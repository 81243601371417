/**
 * Componente Buscador:
 * 
 * acá se muestran los resultados de busqueda de la barra
 * superior del header
 * 
 * @module ComponenteBuscadorjsx
 */


import { useEffect, useState } from "react";
import "./buscador.css";
import axios from "axios";
//import { useNavigate } from "react-router-dom";

import { useRef } from "react";
//import { current } from "@reduxjs/toolkit";

const Buscador = () => {

  axios.defaults.headers.common["Content-Type"] = "application/json";

  /**
* @constant
* @name searchQuery
* @type {string}
* 
* */
  const [searchQuery, setSearchQuery] = useState("");

  /**
* @constant
* @name searchResults
* @type {Array}
* 
* */
  const [searchResults, setSearchResults] = useState([]);

  /**
* @constant
* @name searchResultsRef
* @type {null}
* 
* */
  const searchResultsRef = useRef(null);

  const timeDelay = useRef(150);
  const queryId = useRef(1);
  const mostrando = useRef(1);

  /**
* @constant
* @name tieneResultados
* @type {boolean}
* 
* */
  const [tieneResultados, setResultados] = useState(false);

  /**
* @constant
* @name mostrarCargando
* @type {boolean}
* 
* */
  const [mostrarCargando, setMostrarCargando] = useState(false);


  /**
* @function
* Metodo utilizado para validar si se hace click ahora del buscador, se limpia tanto
* el input como el div con los resultados anterioress
* @param {Event} event target actual
* 
*/
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchResultsRef.current &&
        !searchResultsRef.current.contains(event.target)
      ) {
        let campo = document.querySelector("#form-input-search-texto-buscar");
        if (campo != null)
          campo.value = "";
        setSearchQuery("");
        setSearchResults("");
        queryId.current = 1;
        mostrando.current = 1;


      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const cambiarTexto = (e) => {
    setSearchQuery(e.target.value);
  };


  /**
* @function
* Petición HTTP que obtiene los resultados del texto ingresado en el buscador,
* validando que no sea nulo el texto y que sea distinto de vacio
* @param {string} searchQuery texto ingresado en el buscador
* @param {string} queryId Id de la query actual
*@returns {Array}
* 
*/
  useEffect(() => {
    if (searchQuery != null && searchQuery != "") {
      if (validaQuery(searchQuery)) {
        if (searchResults.length == 0)
          setMostrarCargando(true);

        axios.get(
          process.env.REACT_APP_BACK_URL + `/?s=${searchQuery}&queryid=${queryId.current}`, { timeout: 2000 }
        ).then(response => {
          if (parseInt(response.data.queryid) >= parseInt(mostrando.current)) {

            setMostrarCargando(false);

            let total = Object.values(response.data.results).length;
            if (total > 0) {

              setResultados(true)

            } else {
              setResultados(false)
            }

            setSearchResults(response.data.results);
            mostrando.current = response.data.queryid;
          }
        }).catch(error => {
          console.log(error);
        })
      }
      queryId.current = queryId.current + 1;
    }
  }, [searchQuery]);

  /**
* @function
* Metodo que se utiliza para validar el texto escrito en el input
* @param {string} texto texto para validar
*@returns {boolean}
* 
*/
  const validaQuery = (texto) => {
    let validar = [`?`, `=`, `'`, `*`, `<`, `>`, `;`, `(`, `)`, `{`, `}`, `[`, `]`, `_`, `:`, '`'];
    for (let i = 0; i < validar.length; i++) {
      if (texto.includes(validar[i]))
        return false;
    }
    return true;
  };

  /**
* @function
* Metodo utilizado para saber cual es la tecla que se esta apresionando
* @param {Event} event texto para validar
* 
*/
  const saberTecla = (event) => {
    if (event.which == 13) {
      event.preventDefault();
      if (validaQuery(searchQuery)) {
        location.href = `/search?q=${searchQuery}`;
        setSearchQuery("");
        queryId.current = 1;
        mostrando.current = 1;
      }
    }
  };


  const enviarBusqueda = (event) => {
    event.preventDefault();
    if (validaQuery(searchQuery)) {
      location.href = `/search?q=${searchQuery}`;
      setSearchQuery("");
      queryId.current = 1;
      mostrando.current = 1;
    }
  };

  return (
    <div>
      <form id="form-busqueda-barra-superior"
        onKeyDown={saberTecla}
        className="form-inline my-2 bg-white rounded-pill  mx-auto"
      >
        <div className="input-group">
          <input
            className="form-control input-search border-0 rounded-pill"
            type="search"
            placeholder="Escribe aquí lo que buscas"
            aria-label="Search"
            id="form-input-search-texto-buscar"
            value={searchQuery}
            onChange={cambiarTexto}
          />

          <button
            className="border-0 bg-transparent"
            onClick={enviarBusqueda}
          >
            <i className="fa fa-search"></i>
          </button>

        </div>
      </form>

      <div id="lista-resultados-busqueda" style={{ zIndex: "3" }} ref={searchResultsRef} className={`search-results max-height-4 max-w-4 ${(searchResults == [] || searchResults == "" || searchQuery == "")}`}>


        {searchQuery.length > 1 && mostrarCargando && (

          <div className="w-400 bg-white py-5 text-center">
            <h1 className="fw-bold fs-1 mb-2 text-dark">Cargando</h1>
            <div className="spinner-border color-35 spinner-detail" role="status">
              <span className="visually-hidden">Cargando...</span>
            </div>
          </div>

        )}

        {!mostrarCargando && searchQuery.length > 1 && !tieneResultados && searchResults.length === 0 && (

          <div className="w-100">
            <span
              className="card text-black mt-1 mb-5 px-5 py-5"
              style={{ textDecoration: "none" }}
            >
              No se encontraron productos
            </span>
          </div>

        )}

        {!mostrarCargando && searchResults && searchResults.map((result) => (
          <a
            key={result.id}
            className="card text-black"
            style={{ textDecoration: "none" }}
            href={`/${result.slug}`}
          >
            <div className="row">
              <div className="col-3">
                <img
                  src={process.env.REACT_APP_FRONT_URL + `${result.foto_1}`}
                  alt={result.nombre}
                  title={result.nombre}
                  className="mt-4 img-fluid ms-3"
                  loading="lazy"
                />
              </div>

              <div className="card-body col-4">
                <h5 className="card-title">{result.nombre}</h5>
                <p className="card-text fw-bold">
                  {result.stock == 0 ?
                    <>
                      <span><i className="fa-solid fa-ban rojo"></i>&nbsp;Sin Stock</span>
                    </>
                    :
                    <>
                      {result.precio_descuento.toLocaleString("es-CL", {
                        style: "currency",
                        currency: "CLP",
                      })}
                      &nbsp;<span style={{ fontSize: "10px" }}>Pagando por transferencia</span>
                    </>
                  }

                </p>
              </div>
            </div>
          </a>
        ))}

      </div>
    </div>
  );
};

export default Buscador;
