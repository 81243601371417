/**
 * Página Admin Login:
 * 
 * acá se loguea el admin
 * 
 * @module AdminLoginjsx
 */

import { useState, useEffect } from "react";

import axios from "axios";

import { useNavigate } from "react-router-dom";
//import { current } from "@reduxjs/toolkit";

const AdminLogin = () => {

  /**
* @constant
* @name user
* @type {string}
* 
* */

  const [user, setUser] = useState("");

  /**
* @constant
* @name password
* @type {string}
* 
* */

  const [password, setPassword] = useState("");

  /**
* @constant
* @name successMessage
* @type {string}
* 
* */


  const [successMessage, setSuccessMessage] = useState('');

  /**
* @constant
* @name tipo
* @type {string}
* 
* */

  const [tipo, setTipo] = useState('');

  /**
* @constant
* @name showPass
* @type {boolean}
* 
* */

  const [showPass, setShowPass] = useState(false);

  const togglePasswordShow = () => {
    setShowPass(current => !current);
  };

  const navigate = useNavigate();


  /**
* @function
* Metodo submit que primero valida que los correos ingresados sean del dominio de dreamtec y despues hace el llamado
* a la petición HTTP para loguear al usuario y validar según el rol a que URL lo mandara
* @param {string} user Correo ingresado en el input
* @param {number} password Contraseña ingresada en el input
* @param {number} id 0
*@returns {Array}
* 
*/
  const handlesubmit = async (e) => {
    e.preventDefault();
    if (!user.includes("@dreamtec.cl") && !user.includes("@jotaa.cl") && !user.includes("@serendipitysoft.com")) {
      setSuccessMessage('Solo usar correos @dreamtec.cl');
      setTipo(2);
    }
    else {
      try {
        const response = await axios.post(process.env.REACT_APP_BACK_URL + "/api/login", {
          username: user,
          password: password,
          id: 0,
        });


        setSuccessMessage('Inicio de sesión correcto');
        setTipo(1);


        setTimeout(() => {

          setSuccessMessage('');
          setTipo(0);

        }, 5000);

        sessionStorage.setItem("access", response.data.access);
        sessionStorage.setItem("refresh", response.data.refresh);
        sessionStorage.setItem("rol", response.data.rol);
        setTimeout(function () {
          if (response.data.rol != null && response.data.rol != "") {
            if (response.data.rol == 1)
              window.location.href = '/admin/pedidos';
            if (response.data.rol == 2)
              window.location.href = '/admin/pedidos';
            if (response.data.rol == 3)
              window.location.href = '/admin/pedidos';
            if (response.data.rol == 4)
              window.location.href = '/admin/pedidoschofer';
            if (response.data.rol == 5)
              window.location.href = '/admin/pedidosbodegaweb';
          }
        }, 1000);

      } catch (error) {
        console.log(error);

        setSuccessMessage('Usuario o contraseña incorrectos');
        setTipo(2);


        setTimeout(() => {

          setSuccessMessage('');
          setTipo(0);

        }, 5000);

      }
    }
  };

  return (
    <div className="container mt-5">
      <div className="row  justify-content-center">

        <div className="col-xxl-4 col-xl-4 col-lg-8 col-md-8 col-sm-8 col-11 shadow border rounded py-5">

          <div className="row px-3">
            <div className="col-12 text-center mb-4">
              <img
                loading="lazy"
                src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/admin/logo-header-negro.png"}
                alt="Logo Dreamtec"
                title="Logo Dreamtec"
                className="logoAdmin"
              />
            </div>

            <form onSubmit={handlesubmit}>
              <div className="row">
                <div className="col-12 mb-4">
                  <label htmlFor="mail">
                    Correo electrónico:&nbsp;<span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control rounded-pill"
                    type="text"
                    id="mail"
                    name="correo"
                    placeholder="Ej: ejemplo@gmail.cl"
                    aria-label="Ingrese su correo"
                    required
                    value={user}
                    onChange={(e) => setUser(e.target.value)}
                  />
                </div>
                <div className="col-12 mb-4">

                  <label

                    htmlFor="password"
                  >
                    Contraseña:&nbsp;<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      className="form-control border-radius-left input-pass-login"
                      type={!showPass ? "password" : "text"}
                      id="password"
                      name="password"
                      placeholder="************"
                      aria-label="Ingrese su contraseña"
                      required
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />

                    <span
                      role="button"
                      className="input-group-text border-start-0 border-radius-right boton-ver-pass-login"
                      id="show_password" onClick={togglePasswordShow}
                    >

                      <i className={!showPass ? "fa-sharp fa-solid fa-eye-slash" : "fas fa-eye"}></i>

                    </span>
                  </div>

                </div>
                <div className="col-12 mb-4">
                  <button
                    type="submit"
                    className="text-white my-4 px-4 py-1 btn btnCelesteDreamtec buttonAdmin"
                    aria-label="Iniciar Sesi&oacute;n"
                  >
                    Iniciar sesi&oacute;n
                  </button>
                </div>

                <div className="col-12 text-center mb-3">
                  <span className={tipo == 1 ? "text-success fw-bold" : "text-danger fw-bold"}>{successMessage}</span>
                </div>

              </div>

            </form>




          </div>


        </div>
      </div>
    </div >
  );
};

export default AdminLogin;
