/**
 * Componente Carousel:
 * 
 * acá se muestran los banners para movil y web
 * 
 * @module ComponenteCarouseljsx
 */


import { useState, useEffect } from "react";
import axios from "axios";

import "./carousel.css";


const Carousel = () => {


  /**
* @constant
* @name bannersDesk
* @type {Array}
* 
* */
  const [bannersDesk, setBannersDesk] = useState([]);

  /**
* @constant
* @name bannersMovil
* @type {Array}
* 
* */
  const [bannersMovil, setBannersMovil] = useState([]);


  /**
* @function
* Petición HTTP que trae todos los banners
*@returns {Array}
* 
*/
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BACK_URL + `/api/banners/get_all_banners`)
      .then((response) => {
        setBannersDesk(response.data.banners_web);
        setBannersMovil(response.data.banners_movil);
      })
      .catch((error) => {
        console.log(error);
      });


  }, []);

  /**
* @function
* Metodo que valida el tipo de URL que trae el banner y segun esto es la petición HTTP que se ejecuta
* 
*/
  const construyeLink = (banner) => {


    if (banner.tipo_url_banner == 2) {


      /**
    * @function
    * Petición HTTP que retorna un href con la categoria padre e hija
    * @param {string} categoria_padre Nombre de la categoria padre
    * @param {string} categoria_hija Nombre de la categoria hija
    * 
    */
      axios
        .get(process.env.REACT_APP_BACK_URL + `/api/categoria/get_categorias_by_id?padre=${banner.original.split("-")[0]}&hija=${banner.original.split("-")[1]}`)
        .then((response) => {
          location.href = process.env.REACT_APP_FRONT_URL + `/${response.data.padre}/${response.data.hija}`;
        })
        .catch((error) => {
          console.log(error);
        });
    }
    else if (banner.tipo_url_banner == 3) {


      /**
    * @function
    * Petición HTTP que retorna un href con el slug del producto
    * @param {string} sku Sku del producto
    * 
    */

      axios
        .get(process.env.REACT_APP_BACK_URL + `/api/admin/producto/get_producto_by_sku/${banner.original}`)
        .then((response) => {
          location.href = process.env.REACT_APP_FRONT_URL + `/${response.data[0].slug}`
        })
        .catch((error) => {
          console.log(error);
        });
    }
    else if (banner.tipo_url_banner == 4) {

      location.href = process.env.REACT_APP_FRONT_URL + `/search?q=${banner.original}`;
    }
    else if (banner.tipo_url_banner == 5) {


      let host = window.location.hostname;
      let link = banner.original;
      link = link.replace("https://", "");
      link = link.replace("http://", "");
      link = link.replace("www.", "");
      link = link.split(":")[0];
      link = link.split("/")[0];
      if (link == host)
        location.href = banner.original;
      else
        window.open(banner.original);
    }
  };


  return (
    <div className="">
      <div id="carouselExampleIndicators" className="carousel slide d-none d-sm-none d-md-block d-lg-block d-xl-block d-xxl-block" data-bs-ride="carousel">

        <div className="carousel-indicators">
          {bannersDesk.map((pic, index) => (
            <button

              key={index}
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to={index.toString()}
              className={index === 0 ? "active" : ""}
              aria-current={index === 0 ? "true" : ""}
              aria-label={`Slide ${index + 1}`}
            ></button>
          ))}
        </div>


        <div className="carousel-inner">
          {bannersDesk.map((pic, index) => (
            <div key={index} className={`carousel-item ${index === 0 ? "active" : ""}`}>
              {pic.tipo_url_banner == 1 ? (
                <img src={process.env.REACT_APP_FRONT_URL + `/${pic.foto}`} className="d-block w-100" alt={`Slide ${index + 1}`} loading="eager" />
              ) : (
                <img src={process.env.REACT_APP_FRONT_URL + `/${pic.foto}`} role="button" onClick={() => construyeLink(pic)} loading="eager" className="d-block w-100" alt={`Slide ${index + 1}`} />
              )}
            </div>
          ))}
        </div>

        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="prev"
        >
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Anterior</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="next"
        >
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Siguiente</span>
        </button>

      </div>

      <div
        id="carouselExampleIndicators1"
        className="carousel slide d-block d-sm-block d-md-none d-lg-none d-xl-none d-xxl-none"
        data-bs-ride="carousel"
      >

        <div className="carousel-indicators">
          {bannersMovil.map((pic, index) => (
            <button

              key={index}
              type="button"
              data-bs-target="#carouselExampleIndicators1"
              data-bs-slide-to={index.toString()}
              className={index === 0 ? "active" : ""}
              aria-current={index === 0 ? "true" : ""}
              aria-label={`Slide ${index + 1}`}
            ></button>
          ))}
        </div>

        <div className="carousel-inner">
          {bannersMovil.map((pic, index) => (
            <div key={index} className={`carousel-item ${index === 0 ? "active" : ""}`}>
              {pic.tipo_url_banner == 1 ? (
                <img src={process.env.REACT_APP_FRONT_URL + `/${pic.foto}`} className="d-block w-100" alt={`Slide ${index + 1}`} loading="eager" />
              ) : (
                <img src={process.env.REACT_APP_FRONT_URL + `/${pic.foto}`} role="button" onClick={() => construyeLink(pic)} className="d-block w-100" alt={`Slide ${index + 1}`} loading="eager" />
              )}
            </div>
          ))}
        </div>

      </div>
    </div>
  );
};

export default Carousel;

