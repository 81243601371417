import { createSlice } from '@reduxjs/toolkit';

const initialState = true;

const esCategoriaSlice = createSlice({
  name: 'booleanCategoria',
  initialState,
  reducers: {
    setBooleanCategoria: (state, action) => action.payload,
  },
});

export const { setBooleanCategoria } = esCategoriaSlice.actions;
export default esCategoriaSlice.reducer;
