/**
 * Página Admin Productos:
 * 
 * acá se selecciona si quiere ingresar un producto o 
 * editar uno
 * 
 * @module AdminProductosjsx
 */

import React, { useState } from "react";
import "./producto.css";

import { AdminSidebar } from "../../components";
//import { useNavigate } from "react-router-dom";
import ProductAdd from "./ProductAdd";
import ProductUpdate from "./ProductUpdate";

const Productos = () => {


  /**
* @constant
* @name accion
* @type {number}
* 
* */

  const [accion, setAccion] = useState(1);


  return (

    <div>

      {accion === 2 && (
        <ProductAdd setAccion={setAccion} />
      )}

      {accion === 3 && (
        <ProductUpdate setAccion={setAccion} />
      )}

      {accion === 1 && (
        <>
          <AdminSidebar />

          <div className="regular">
            <h1>Dreamtec - <span>Administrador de productos</span></h1>

            <div className="shadow">

              <div className="container">
                <div className="row justify-content-center">

                  <div className="col-12 text-center mt-3 mb-5">
                    <h2>
                      Seleccione una opción para desplegar el contenido
                    </h2>

                  </div>

                  <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12 mb-3">
                    <button aria-label="Agregar Producto"
                      className="agregar"
                      onClick={() => {
                        setAccion(2);
                      }}
                    >
                      <i className="fa-solid fa-circle-plus"></i> &nbsp; Agregar Producto
                    </button>

                  </div>


                  <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12 mb-3">

                    <button aria-label="Editar Producto"
                      className="agregar"
                      onClick={() => {
                        setAccion(3);
                      }}
                    >
                      <i className="fa-solid fa-pen-to-square"></i> &nbsp; Editar Producto
                    </button>

                  </div>

                </div>
              </div>

            </div>

          </div>
        </>
      )}








    </div>
  );
};

export default Productos;
