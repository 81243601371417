import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import $ from 'jquery';

import { AdminSidebar } from "../../components";
import Mensaje from "../../components/Modales/Mensaje";

import "../../assets/summernote/summernote-lite.min.css";
import "../../assets/summernote/summernote-lite.min.js";

const ProductAdd = (props) => {
  const navigate = useNavigate();

  const [marcas, setMarcas] = useState([]);
  const [tallas, setTallas] = useState([]);
  const [check_mercado_libre, setCheck_mercado_libre] = useState(false);
  const [check_paris, setCheck_paris] = useState(false);
  const [check_ripley, setCheck_ripley] = useState(false);
  const [mercadolibre_categorias, setMercadolibre_categorias] = useState([]);
  const [selected_mercadolibre_categoria, setSelected_mercadolibre_categoria] = useState("");
  const [paris_familias, setParis_familias] = useState([]);
  const [selected_paris_familias, setSelected_paris_familias] = useState("");
  const [paris_categorias, setParis_categorias] = useState([]);
  const [selected_paris_categoria, setSelected_paris_categoria] = useState("");
  const [ripley_categorias, setRipley_categorias] = useState([]);
  const [selected_ripley_categoria, setSelected_ripley_categoria] = useState("");
  const [inputs, setInputs] = useState([]);
  const [inputs_paris, setInputs_paris] = useState([]);
  const [inputs_ripley, setInputs_ripley] = useState([]);
  const [mensaje, setMensaje] = useState("");
  const [data_caracteritica, setData_caracteritica] = useState("");
  const [data_short, setData_short] = useState("");
  const [data_descripcion, setData_descripcion] = useState("");
  const [productName, setProductName] = useState("");
  const [sku, setSku] = useState("");
  const [brand, setBrand] = useState("");
  const [price, setPrice] = useState("");
  const [discountPrice, setDiscountPrice] = useState("");
  const [stock, setStock] = useState("");
  const [slug, setSlug] = useState("");
  const [selectedSize, setSelectedSize] = useState("");
  const [upc, setUpc] = useState("");
  const [pn, setPn] = useState("");
  const [categoria_padre, setCategoria_padre] = useState([]);
  const [selected_categoria_padre, setSelected_categoria_padre] = useState([]);
  const [categoria_hija, setCategoria_hija] = useState([]);
  const [selected_categoria_hija, setSelected_categoria_hija] = useState([]);
  const [msg, setMsg] = useState("");
  const [tipo, setTipo] = useState("");
  const [normalPrice, setNormalPrice] = useState("");

  const accessToekn = sessionStorage.getItem("access");

  const [fotos, setFotos] = useState([null, null, null, null, null, null]);
  const [fotosSrc, setFotosSrc] = useState([null, null, null, null, null, null]);

  const fileInputs = useRef([]);

  useEffect(() => {
    axios.get(process.env.REACT_APP_BACK_URL + `/api/admin/listar_todas_tallas_productos`)
      .then((response) => setTallas(response.data))
      .catch((error) => console.log(error));

    axios.get(process.env.REACT_APP_BACK_URL + `/api/admin/marca/get_all_marcas`)
      .then((response) => setMarcas(response.data))
      .catch((error) => console.log(error));

    axios.get(process.env.REACT_APP_BACK_URL + `/api/admin/paris/get_familias`)
      .then((response) => setParis_familias(response.data))
      .catch((error) => console.log(error));

    axios.get(process.env.REACT_APP_BACK_URL + `/api/admin/ripley/get_categorias`)
      .then((response) => setRipley_categorias(response.data))
      .catch((error) => console.log(error));

    axios.get(process.env.REACT_APP_BACK_URL + `/api/admin/categoria/get_all_categoria`)
      .then((response) => setCategoria_padre(response.data))
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    if (selected_categoria_padre !== "") {
      axios.get(process.env.REACT_APP_BACK_URL + `/api/admin/categoria/get_all_categoria_hija_by_padre/${selected_categoria_padre}`)
        .then((response) => setCategoria_hija(response.data))
        .catch((error) => console.log(error));
    } else {
      setCategoria_hija([]);
    }
  }, [selected_categoria_padre]);

  useEffect(() => {
    if (productName !== "") {
      axios.get(process.env.REACT_APP_BACK_URL + `/api/admin/mercado_libre/get_categoria_mercado_libre_by_search/${productName}`)
        .then((response) => {
          setMercadolibre_categorias(response.data);
          setInputs([]);
        })
        .catch((error) => console.log(error));
    } else {
      setMercadolibre_categorias([]);
    }
  }, [productName]);

  useEffect(() => {
    if (selected_mercadolibre_categoria !== "") {
      axios.get(process.env.REACT_APP_BACK_URL + `/api/admin/mercado_libre/get_atributos_categoria_mercado_libre/${mercadolibre_categorias[0]?.category_id}`)
        .then((response) => setInputs(response.data))
        .catch((error) => console.log(error));
    } else {
      setMercadolibre_categorias([]);
    }
  }, [selected_mercadolibre_categoria]);

  useEffect(() => {
    if (selected_paris_familias !== "") {
      setParis_categorias([]);
      axios.get(process.env.REACT_APP_BACK_URL + `/api/admin/paris/get_categorias_by_code/${selected_paris_familias}`)
        .then((response) => setParis_categorias(response.data))
        .catch((error) => console.log(error));
    } else {
      setParis_categorias([]);
    }
  }, [selected_paris_familias]);

  useEffect(() => {
    if (selected_paris_familias !== "") {
      setInputs_paris([]);
      axios.get(process.env.REACT_APP_BACK_URL + `/api/admin/paris/get_atributos_by_familia/${selected_paris_familias}`)
        .then((response) => setInputs_paris(response.data))
        .catch((error) => console.log(error));
    } else {
      setInputs_paris([]);
    }
  }, [selected_paris_familias]);

  useEffect(() => {
    if (selected_ripley_categoria !== "") {
      setInputs_ripley([]);
      axios.get(process.env.REACT_APP_BACK_URL + `/api/admin/ripley/get_atributos?hierarchy=${selected_ripley_categoria}`)
        .then((response) => setInputs_ripley(response.data))
        .catch((error) => console.log(error));
    } else {
      setInputs_ripley([]);
    }
  }, [selected_ripley_categoria]);

  const handleInputChange = (index, event) => {
    const values = [...inputs];
    values[index].value = event.target.value;
    setInputs(values);
  };

  const handleInputChangeParis = (index, event) => {
    const values = [...inputs_paris];
    values[index].value = event.target.value;
    setInputs_paris(values);
  };

  const handleInputChangerRipley = (index, event) => {
    const values = [...inputs_ripley];
    values[index].value = event.target.value;
    setInputs_ripley(values);
  };

  const handle_check_mercado_libre = () => {
    setCheck_mercado_libre(!check_mercado_libre);
  };

  const handle_check_paris = () => {
    setCheck_paris(!check_paris);
  };

  const handle_check_ripley = () => {
    setCheck_ripley(!check_ripley);
  };

  const handleFotoChange = (index, event) => {
    const newFotos = [...fotos];
    newFotos[index] = event.target.files[0];
    setFotos(newFotos);

    const newFotosSrc = [...fotosSrc];
    newFotosSrc[index] = URL.createObjectURL(event.target.files[0]);
    setFotosSrc(newFotosSrc);
  };

  const handleFotoRemove = (index) => {
    const newFotos = [...fotos];
    const newFotosSrc = [...fotosSrc];

    // Elimina la foto y reordena las demás
    for (let i = index; i < fotos.length - 1; i++) {
      newFotos[i] = newFotos[i + 1];
      newFotosSrc[i] = newFotosSrc[i + 1];

      // Mueve el valor del input file al anterior
      if (fileInputs.current[i + 1]) {
        fileInputs.current[i].files = fileInputs.current[i + 1].files;
      }
    }

    // Eliminar el último valor ya que se ha movido hacia adelante
    newFotos[fotos.length - 1] = null;
    newFotosSrc[fotos.length - 1] = null;

    // Limpia el último input file
    if (fileInputs.current[fotos.length - 1]) {
      fileInputs.current[fotos.length - 1].value = "";
    }

    setFotos(newFotos);
    setFotosSrc(newFotosSrc);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setMensaje("Creando producto-alert");
    setMsg("");
    setTipo(0);
    let des = parseInt(discountPrice);
    let tac = parseInt(normalPrice);
    let dif = (tac - des) * 100 / tac;
    if (dif <= 10) {
      setMsg("El precio tachado debe ser más de 10% superior al precio descuento");
      setMensaje("El precio tachado debe ser más de 10% superior al precio descuento-rojo");
      return false;
    }

    const formData = new FormData();
    formData.append("name", productName);
    formData.append("sku", sku.toUpperCase());
    formData.append("description", data_descripcion.replaceAll("&lt;", "<").replaceAll("&gt;", ">"));
    formData.append("short", data_short.replaceAll("&lt;", "<").replaceAll("&gt;", ">"));
    formData.append("marcas_id", brand);
    formData.append("precio_descuento", discountPrice);
    formData.append("stock", stock);
    formData.append("slug", slug);
    formData.append("caracteristicas", data_caracteritica.replaceAll("&lt;", "<").replaceAll("&gt;", ">"));
    formData.append("talla_producto", selectedSize);
    formData.append("upc", upc);
    formData.append("pn", pn);
    formData.append("categoria", selected_categoria_padre);
    formData.append("categoria_hija", selected_categoria_hija);
    formData.append("precio_tachado", normalPrice);

    fotos.forEach((foto, index) => {
      if (foto) {
        formData.append(`foto_${index + 1}`, foto);
      }
    });

    axios.post(process.env.REACT_APP_BACK_URL + "/api/admin/producto/register_producto", formData, {
      headers: {
        Authorization: `Bearer ${accessToekn}`,
      },
    })
      .then((response) => {
        setMsg("Producto agregado correctamente");
        setMensaje("Producto creado correctamente-verde");

        setTipo(1);
        setTimeout(() => {
          setMsg("");
          setTipo(0);
        }, 5000);

        setTimeout(() => {
          props.setAccion(1);
        }, 5000);

        // Resetea los campos del formulario
        setProductName("");
        setSku("");
        setBrand("");
        setPrice("");
        setDiscountPrice("");
        setStock("");
        setSlug("");
        setSelectedSize("");
        setUpc("");
        setPn("");
        setCheck_mercado_libre(false);
        setMercadolibre_categorias([]);
        setSelected_mercadolibre_categoria("");
        setFotos([null, null, null, null, null, null]);
        setFotosSrc([null, null, null, null, null, null]);
        fileInputs.current.forEach(input => input && (input.value = ""));
      })
      .catch((error) => {
        console.error(error);
        // Verifica si error.response.data.message existe, de lo contrario usa el mensaje por defecto
        const errorMessage = error?.response?.data?.message
          ? error.response.data.message + "-rojo"
          : "No se pudo agregar el producto, intente nuevamente-rojo";
        setMensaje(errorMessage);
        setTipo(2);

        setTimeout(() => {
          setMsg("");
          setTipo(0);
        }, 5000);
      });
  };

  $('#summernote').summernote({
    height: 100,
    toolbar: [
      ['style', ['style']],
      ['font', ['bold', 'underline', 'clear', 'italic']],
      ['color', ['color']],
      ['para', ['ul', 'ol', 'paragraph']],
      ['table', ['table']],
      ['insert', ['link', 'picture']],
      ['view', ['fullscreen', 'codeview', 'help']]
    ],
    callbacks: {
      onKeydown: function (e) {
        var t = e.currentTarget.innerText;
        if (t.trim().length >= 1000) {
          //delete keys, arrow keys, copy, cut, select all
          if (e.keyCode != 8 && !(e.keyCode >= 37 && e.keyCode <= 40) && e.keyCode != 46 && !(e.keyCode == 88 && e.ctrlKey) && !(e.keyCode == 67 && e.ctrlKey) && !(e.keyCode == 65 && e.ctrlKey))
            e.preventDefault();
        }
      },
      onKeyup: function (e) {
        var t = e.currentTarget.innerText;
        // $('#maxContentPost').text(400 - t.trim().length);
      },
      onPaste: function (e) {
        var t = e.currentTarget.innerText;
        var bufferText = ((e.originalEvent || e).clipboardData || window.clipboardData).getData('Text');
        e.preventDefault();
        var maxPaste = bufferText.length;
        if (t.length + bufferText.length > 1000) {
          maxPaste = 1000 - t.length;
        }
        if (maxPaste > 0) {
          document.execCommand('insertText', false, bufferText.substring(0, maxPaste));
        }
        // $('#maxContentPost').text(400 - t.length);
      },
      onChange: function (e) {
        setData_caracteritica(e);
      }
    }
  });

  $('#summernote2').summernote({
    height: 100,
    toolbar: [
      ['style', ['style']],
      ['font', ['bold', 'underline', 'clear', 'italic']],
      ['color', ['color']],
      ['para', ['ul', 'ol', 'paragraph']],
      ['table', ['table']],
      ['insert', ['link', 'picture', 'video']],
      ['view', ['fullscreen', 'codeview', 'help']]
    ],
    callbacks: {
      onKeydown: function (e) {
        var t = e.currentTarget.innerText;
        if (t.trim().length >= 50000) {
          //delete keys, arrow keys, copy, cut, select all
          if (e.keyCode != 8 && !(e.keyCode >= 37 && e.keyCode <= 40) && e.keyCode != 46 && !(e.keyCode == 88 && e.ctrlKey) && !(e.keyCode == 67 && e.ctrlKey) && !(e.keyCode == 65 && e.ctrlKey))
            e.preventDefault();
        }
      },
      onKeyup: function (e) {
        var t = e.currentTarget.innerText;
        // $('#maxContentPost').text(400 - t.trim().length);
      },
      onPaste: function (e) {
        var t = e.currentTarget.innerText;
        var bufferText = ((e.originalEvent || e).clipboardData || window.clipboardData).getData('Text');
        e.preventDefault();
        var maxPaste = bufferText.length;
        if (t.length + bufferText.length > 50000) {
          maxPaste = 50000 - t.length;
        }
        if (maxPaste > 0) {
          document.execCommand('insertText', false, bufferText.substring(0, maxPaste));
        }
        // $('#maxContentPost').text(400 - t.length);
      },
      onChange: function (e) {
        setData_descripcion(e);
      }
    }
  });

  $('#summernote3').summernote({
    height: 100,
    toolbar: [
      ['style', ['style']],
      ['font', ['bold', 'underline', 'clear', 'italic']],
      ['color', ['color']],
      ['para', ['ul', 'ol', 'paragraph']],
      ['table', ['table']],
      ['insert', ['link', 'picture', 'video']],
      ['view', ['fullscreen', 'codeview', 'help']]
    ],
    callbacks: {
      onKeydown: function (e) {
        var t = e.currentTarget.innerText;
        if (t.trim().length >= 10000) {
          //delete keys, arrow keys, copy, cut, select all
          if (e.keyCode != 8 && !(e.keyCode >= 37 && e.keyCode <= 40) && e.keyCode != 46 && !(e.keyCode == 88 && e.ctrlKey) && !(e.keyCode == 67 && e.ctrlKey) && !(e.keyCode == 65 && e.ctrlKey))
            e.preventDefault();
        }
      },
      onKeyup: function (e) {
        var t = e.currentTarget.innerText;
        // $('#maxContentPost').text(400 - t.trim().length);
      },
      onPaste: function (e) {
        var t = e.currentTarget.innerText;
        var bufferText = ((e.originalEvent || e).clipboardData || window.clipboardData).getData('Text');
        e.preventDefault();
        var maxPaste = bufferText.length;
        if (t.length + bufferText.length > 10000) {
          maxPaste = 10000 - t.length;
        }
        if (maxPaste > 0) {
          document.execCommand('insertText', false, bufferText.substring(0, maxPaste));
        }
        // $('#maxContentPost').text(400 - t.length);
      },
      onChange: function (e) {
        setData_short(e);
      }
    }
  });

  const setProductName2 = (texto) => {
    setProductName(texto);
    setSlug(limpiaSlug(texto));
  };

  const setSlug2 = (texto) => {
    let vari = limpiaSlug(texto);
    setSlug(vari);
  };

  const limpiaSlug = (text) => {
    // Convertir todo el texto a minúsculas
    text = text.toLowerCase();

    // Reemplazar ñ y Ñ por 'n'
    text = text.replaceAll('ñ', 'n').replaceAll('Ñ', 'n');

    // Eliminar cualquier carácter que no sea letra (a-z), número (0-9) o espacio en blanco
    text = text.replace(/[^a-z0-9\s]/g, '');

    // Reemplazar espacios en blanco por guiones
    text = text.replace(/\s+/g, '-');

    return text;
  };

  var imageUploadDiv = $('div.note-group-select-from-files');
  if (imageUploadDiv.length) {
    imageUploadDiv.remove();
  }

  return (
    <div>
      <AdminSidebar />
      <Mensaje mensaje={mensaje} setMensaje={setMensaje} />

      <div className="regular">
        <h1>Dreamtec - <span>Administrador de productos</span></h1>
        <div className="shadow">
          <div className="row">
            <div className="col-12 text-start my-3">
              <h2>Agregar Producto</h2>
            </div>

            <div className="col-12">
              <div className="row">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4">
                      <label className="fw-bold mx-1 form-label">
                        Nombre del producto: &nbsp;
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        placeholder="Ingresa nombre producto"
                        type="text"
                        value={productName}
                        required
                        onChange={(e) => setProductName2(e.target.value)}
                      />
                    </div>

                    <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4">
                      <label className="fw-bold mx-1 form-label">
                        SKU: &nbsp;<span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        required
                        placeholder="Ingresa SKU"
                        value={sku}
                        onChange={(e) => setSku(e.target.value.toUpperCase())}
                      />
                    </div>

                    <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4">
                      <label className="fw-bold mx-1 form-label">
                        Categoria: &nbsp;<span className="text-danger">*</span>
                      </label>
                      <select
                        value={selected_categoria_padre}
                        onChange={(e) => setSelected_categoria_padre(e.target.value)}
                        className="form-control rounded-pill"
                        required
                      >
                        <option value="">Selecciona una categoria</option>
                        {categoria_padre.map((categoria) => (
                          <option key={categoria.id} value={categoria.id}>
                            {categoria.categoria.charAt(0).toUpperCase() + categoria.categoria.slice(1)}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4">
                      <label className="fw-bold mx-1 form-label">
                        Categoria Hija: &nbsp;<span className="text-danger">*</span>
                      </label>
                      <select
                        value={selected_categoria_hija}
                        onChange={(e) => setSelected_categoria_hija(e.target.value)}
                        className="form-control rounded-pill"
                        required
                      >
                        <option value="">Selecciona una categoria hija</option>
                        {categoria_hija.map((categoria) => (
                          <option key={categoria.id} value={categoria.id}>
                            {categoria.hija.charAt(0).toUpperCase() + categoria.hija.slice(1)}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4">
                      <label className="fw-bold mx-1 form-label">
                        Stock: &nbsp;<span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="number"
                        required
                        placeholder="Ingresa Stock"
                        value={stock}
                        onChange={(e) => setStock(parseInt(e.target.value))}
                      />
                    </div>

                    <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4">
                      <label className="fw-bold mx-1 form-label">
                        Slug: &nbsp;<span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        required
                        placeholder="Ingresa slug"
                        value={slug}
                        onChange={(e) => setSlug2(e.target.value)}
                      />
                    </div>

                    <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12 mb-4">
                      <label className="fw-bold mx-1 form-label">
                        Características destacadas (máximo 1.000 caracteres): &nbsp;
                        <span className="text-danger">*</span>
                      </label>
                      <div id="summernote"></div>
                    </div>

                    <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12 mb-4">
                      <label className="fw-bold mx-1 form-label">
                        Short (mini descripción, máximo 10.000 caracteres): &nbsp;
                        <span className="text-danger">*</span>
                      </label>
                      <div id="summernote3"></div>
                    </div>

                    <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12 mb-4">
                      <label className="fw-bold mx-1 form-label">
                        Descripción (máximo 50.000 caracteres): &nbsp;
                        <span className="text-danger">*</span>
                      </label>
                      <div id="summernote2"></div>
                    </div>

                    <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4">
                      <label className="fw-bold mx-1 form-label">
                        Marca: &nbsp;<span className="text-danger">*</span>
                      </label>
                      <select
                        value={brand}
                        onChange={(e) => setBrand(e.target.value)}
                        className="form-control rounded-pill"
                        required
                      >
                        <option value="">Selecciona una marca</option>
                        {marcas?.map((marca) => (
                          <option key={marca.id} value={marca.id}>
                            {marca.nombre_mostrar}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4">
                      <label className="fw-bold mx-1 form-label">
                        Talla del producto: &nbsp;
                        <span className="text-danger">*</span>
                      </label>
                      <select
                        value={selectedSize}
                        onChange={(e) => setSelectedSize(e.target.value)}
                        className="form-control rounded-pill"
                        required
                      >
                        <option value="">Selecciona una talla</option>
                        {tallas?.map((talla) => (
                          <option key={talla.id} value={talla.id}>
                            {talla.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4">
                      <label className="fw-bold mx-1 form-label">
                        Precio con descuento: &nbsp;
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="number"
                        value={discountPrice}
                        onChange={(e) =>
                          setDiscountPrice(parseFloat(e.target.value))
                        }
                        placeholder="Ingresa precio descuento"
                        required
                      />
                    </div>

                    <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4">
                      <label className="fw-bold mx-1 form-label">
                        Precio normal (tachado): &nbsp;
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="number"
                        value={normalPrice}
                        onChange={(e) =>
                          setNormalPrice(parseFloat(e.target.value))
                        }
                        placeholder="Ingresa precio normal"
                        required
                      />
                    </div>

                    <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4">
                      <label className="fw-bold mx-1 form-label">
                        UPC: &nbsp;<span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        placeholder="Ingresa UPC"
                        type="text"
                        value={upc}
                        required
                        onChange={(e) => setUpc(e.target.value)}
                      />
                    </div>

                    <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4">
                      <label className="fw-bold mx-1 form-label">
                        P/N: &nbsp;<span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        required
                        placeholder="Ingresa P/N"
                        value={pn}
                        onChange={(e) => setPn(e.target.value)}
                      />
                    </div>

                    {fotos.map((foto, index) => (
                      <div key={index} className="row col-10">
                        <div className="row col-xxl-6 col-xl-6 col-md-12 col-sm-12 col-12 mb-3">
                          <div className="col-xxl-8 col-xl-8 col-md-12 col-sm-12 col-12">
                            <label>Foto {index + 1}: {index === 0 && <span className="text-danger">*</span>}</label>
                            <input
                              type="file"
                              id={`foto${index + 1}`}
                              accept="image/*"
                              onChange={(e) => handleFotoChange(index, e)}
                              className="form-control col-12"
                              required={index === 0}
                              ref={el => fileInputs.current[index] = el}
                            />
                          </div>
                          <div className="col-xxl-4 col-xl-4 col-md-12 col-sm-12 col-12 h-100 text-center">
                            {fotosSrc[index] && (
                              <>
                                <label htmlFor={`fotoMostrar${index + 1}`} id={`labelMostrar${index + 1}`}>Foto Actual</label>
                                <img
                                  loading="lazy"
                                  src={fotosSrc[index]}
                                  alt=""
                                  id={`fotoMostrar${index + 1}`}
                                  className="container-fluid"
                                  accept="image/jpg, image/jpeg, image/png"
                                />
                                <button
                                  type="button"
                                  onClick={() => handleFotoRemove(index)}
                                  className="btn btn-danger mt-2"
                                >
                                  Eliminar Foto
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}

                    <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4">
                      <button
                        aria-label="Agregar producto"
                        className="btnGreen w-100"
                        type="submit"
                      >
                        Agregar Producto
                      </button>
                    </div>

                    <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4">
                      <button
                        aria-label="Cancelar"
                        className="cancel w-100"
                        onClick={() => {
                          props.setAccion(1);
                        }}
                      >
                        Volver atrás
                      </button>
                    </div>
                  </div>

                  <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-10 col-sm-12 col-12 my-3 text-center">
                    <span className={tipo === 1 ? "text-success fw-bold" : "text-danger fw-bold"}>
                      {msg}
                    </span>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductAdd;
