/**
 * Componente Footer:
 * 
 * acá se muestra el footer del Sitio Web
 * 
 * @module ComponenteFooterjsx
 */

import React from "react";
import "./footer.css";

import { useLocation } from "react-router-dom";

const Footer = () => {
  const location = useLocation();
  const isPaso1Page =
    location.pathname === "/paso1" ||
    location.pathname === "/paso2" ||
    location.pathname === "/paso3" ||
    location.pathname === "/confirmed";

  return (
    <>
      {!isPaso1Page && (

        <div className="container-fluid bg-black pt-4 pb-5">
          <div className="container">

            <div className="row">

              <div className="col-lg-3 col-sm-12">
                <span className="fs-14 text-white fw-bold">CONTACTO</span>

                <div className="row mt-3">
                  <a
                    className="no-underline"
                    href="tel:+56229257620"
                    target="_blank"
                  >
                    <div className="row mb-2 align-items-center">
                      <div className="col-lg-1 col-md-2 col-sm-2 col-1 text-center">
                        <i className="fas fa-mobile-alt fs-5 text-turq icon-footer"></i>
                      </div>
                      <div className="col-lg-10 col-md-10 col-10">
                        <span className="text-white fs-14 ">
                          Teléfono: (+56) 2 29257620
                        </span>
                      </div>
                    </div>
                  </a>
                  <a
                    className="no-underline"
                    href="https://wa.me/+56942428870"
                    target="_blank"
                  >
                    <div className="row mb-2 align-items-center">
                      <div className="col-lg-1 col-md-2 col-sm-2 col-1 text-center">
                        <i className="fab fa-whatsapp fs-5 text-turq icon-footer"></i>
                      </div>
                      <div className="col-lg-10 col-md-10 col-10">
                        <span className="text-white fs-14">
                          Whatsapp: (+56) 9 42428870
                        </span>
                      </div>
                    </div>
                  </a>
                  <a
                    className="no-underline"
                    href="mailto:contacto@dreamtec.cl"
                  >
                    <div className="row mb-2 align-items-center">
                      <div className="col-lg-1 col-md-2 col-sm-2 col-1 text-center">
                        <i className="far fa-envelope fs-5 text-turq icon-footer"></i>
                      </div>
                      <div className="col-lg-10 col-md-10 col-10">
                        <span className="text-white fs-14">
                          contacto@dreamtec.cl
                        </span>
                      </div>
                    </div>
                  </a>
                  <a
                    className="no-underline"
                    href="https://goo.gl/maps/KLsoFyTL5ndqpqW76"
                    target="_blank"
                  >
                    <div className="row mb-2">
                      <div className="col-lg-1 col-md-2 col-sm-2 col-1 text-center">
                        <i className="fa-solid fa-location-dot fs-5 text-turq icon-footer"></i>
                      </div>
                      <div className="col-lg-10 col-md-10 col-10">
                        <span className="text-white fs-14">
                          Av. Manuel Montt 1667, Local 2,
                          <br /> Providencia, Santiago de Chile.
                        </span>
                      </div>
                    </div>
                  </a>
                  <div className="row mb-2">
                    <div className="col-lg-1 col-md-2 col-sm-2 col-1 text-center">
                      <i className="fa-regular fa-clock fs-5 text-turq icon-footer"></i>
                    </div>
                    <div className="col-lg-10 col-md-10 col-10">
                      <span className="text-white fs-14">
                        Lunes-Sábado: 10:00am- 18:00pm
                      </span>
                    </div>
                  </div>
                </div>

                <div className="d-lg-none bg-white my-4">
                  <hr />
                </div>
              </div>

              <div className="col-lg-3 col-sm-12">

                <span className="fs-14 text-white fw-bold">
                  SERVICIO AL CLIENTE
                </span>

                <div className="row mt-3">
                  <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-6 col-sm-6 col-6 mb-2">
                    <a
                      href="/terminos"
                      className="text-white fs-14 no-underline"
                    >
                      Términos y condiciones
                    </a>
                  </div>
                  <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-6 col-sm-6 col-6 mb-2">
                    <a
                      href="/faq"
                      className="text-white fs-14 no-underline"
                    >
                      Preguntas frecuentes
                    </a>
                  </div>
                  <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-6 col-sm-6 col-6 mb-2">
                    <a
                      href="/garantias"
                      className="text-white fs-14 no-underline"
                    >
                      Garantía & Devoluciones
                    </a>
                  </div>
                  {/* <div className="col-lg-12 col-md-12 col-sm-11 col-6 mb-2">
                    <a
                  href="<?= bloginfo('url') ?>/nosotros/"
                  className="text-white fs-14 no-underline"
                >
                  Acerca de nosotros
                </a> 
                  </div> */}
                  <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-6 col-sm-6 col-6 mb-2">
                    <a
                      href="/contacto"
                      className="text-white fs-14 no-underline"
                    >
                      Contacto
                    </a>
                  </div>
                </div>

                <div className="d-lg-none bg-white my-4">
                  <hr />
                </div>
              </div>

              <div className="col-lg-3 col-sm-12 mb-4">

                <span className="fs-14 text-white fw-bold">
                  MÉTODOS DE PAGO
                </span>

                <div className="row mt-3">
                  <div className="col-xxl-11 col-xl-11 col-lg-11 col-md-6 col-sm-6 col-6 mb-2">
                    <img
                      loading="lazy"
                      src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/img/logo-transferencia.png"}
                      className="img-fluid"
                      alt="pago aceptado con transferencia bancaria"
                      width={185}
                      height={62}
                    />
                  </div>

                  <div className="col-xxl-11 col-xl-11 col-lg-11 col-md-6 col-sm-6 col-6 mb-2">
                    <img
                      loading="lazy"
                      src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/img/logo-mercadopago.png"}
                      className="img-fluid"
                      alt="pago aceptado con mercadopago"
                      width={185}
                      height={62}
                    />
                  </div>

                  <div className="col-xxl-11 col-xl-11 col-lg-11 col-md-12 col-sm-12 col-6 mb-2">
                    <img
                      loading="lazy"
                      src={process.env.REACT_APP_FRONT_URL + "/uploads/logos/logo-webpay.png"}
                      className="img-fluid"
                      alt="pago aceptado con mercadopago"
                      width={185}
                      height={62}
                    />
                  </div>
                </div>
                <div className="d-lg-none bg-white">
                  <hr />
                </div>
              </div>

              {/* <button className="btn-c-wsp-r px-0 py-0">
                <a href="https://wa.me/+56942428870" target="_blank" className="text-white fs-14 px-0 a-c-wsp2">
                  <img loading="eager" src={logowsp1} className="textowidth" alt="texto ayuda" />
                  <img loading="eager" src={logowsp2} className="btninwhs" alt="Contactar por WhatsApp" />
                </a>
              </button> */}

              <div className="col-lg-3 col-sm-12">
                <span className="fs-14 text-white fw-bold">
                  CONECTA CON NOSOTROS
                </span>
                <div className="row mt-3">

                  <div className="col-12">
                    <p className="text-white fs-14">
                      Accede a nuestro formulario de contacto y responderemos a
                      la brevedad tus dudas e inquietudes.
                    </p>
                  </div>

                  <div className="col-12 mb-3">
                    <a href="/contacto">
                      <button
                        type="button" aria-label="Contáctanos"
                        className="bg-turq text-white fs-6 px-5 py-1 rounded-pill border-0 btn-turq-hover btn-sombra"
                      >
                        Contáctanos
                      </button>
                    </a>
                  </div>

                  <div className="col-lg-10 col-md-11 col-sm-9 col-11 mb-5">
                    <img
                      src={process.env.REACT_APP_FRONT_URL + "/uploads/logos/logo-proveedores-del-estado.png"}
                      className="img-fluid mb-2"
                      alt="Somos Proveedores del Estado de Chile"
                      width={257}
                      height={52}
                      loading="lazy"
                    />
                  </div>

                  <div className="d-none">
                    <img
                      src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/carrito/flow-pagos.webp"}
                      className="img-fluid mb-2"
                      alt="Somos Proveedores del Estado de Chile"
                      width={257}
                      height={52}
                      loading="lazy"
                    />
                  </div>

                </div>
              </div>


            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Footer;
