import React, { useState } from "react";
import axios from "axios";
import { sanitize } from "dompurify";
import { useLocation } from "react-router-dom";

const FormularioIndustrial = ({ selectRef }) => {
  const [nombre, setNombre] = useState("");
  const [email, setEmail] = useState("");
  const [telefono, setTelefono] = useState("");
  const [producto, setProducto] = useState("0");
  const [mensaje, setMensaje] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [empresa, setEmpresa] = useState("");

  const location = useLocation();
  const currentRoute = location.pathname;

  const sanitizeInput = (input) => {
    return sanitize(input, { ALLOWED_TAGS: [], ALLOWED_ATTR: [] });
  };

  const handleTelefonoChange = (e) => {
    const value = e.target.value.replace(/[^0-9+]/g, "");
    setTelefono(value);
  };

  const validateForm = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\+569\d{4}\d{4}$/;

    const sanitizedNombre = sanitizeInput(nombre);
    const sanitizedEmail = sanitizeInput(email);
    const sanitizedTelefono = sanitizeInput(telefono);
    const sanitizedMensaje = sanitizeInput(mensaje);

    if (!sanitizedNombre) {
      setError("Debe ingresar su nombre completo.");
      return false;
    }
    if (sanitizedNombre.length > 100) {
      setError("El nombre completo no debe exceder los 100 caracteres.");
      return false;
    }

    if (!sanitizedEmail || !emailRegex.test(sanitizedEmail)) {
      setError("Debe ingresar un correo electrónico válido.");
      return false;
    }

    if (!sanitizedTelefono || !phoneRegex.test(sanitizedTelefono)) {
      setError("Debe ingresar un número de teléfono válido.");
      return false;
    }

    if (
      currentRoute !== "/industrial" &&
      currentRoute !== "/industrial/contacto" &&
      producto === "0"
    ) {
      setError("Debe seleccionar un tipo de producto.");
      return false;
    }

    if (sanitizedMensaje.length > 200) {
      setError("El mensaje no debe exceder los 200 caracteres.");
      return false;
    }

    setError("");
    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (validateForm()) {
      const formData = new FormData();
      formData.append("nombre", sanitizeInput(nombre));
      formData.append("correo", sanitizeInput(email));
      formData.append("telefono", sanitizeInput(telefono));
      formData.append("mensaje", sanitizeInput(mensaje));
      if (empresa != "")
        formData.append("empresa", sanitizeInput(empresa));
      if (currentRoute !== "/industrial" && currentRoute !== "/industrial/contacto")
        formData.append("tipo_producto", sanitizeInput(producto));

      axios
        .post(process.env.REACT_APP_BACK_URL + "/api/contacto/correo_contacto_industrial", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          setSuccess('Formulario enviado exitosamente.');
          setNombre('');
          setEmail('');
          setTelefono('');
          setProducto('0');
          setMensaje('');
        })
        .catch((error) => {
          setError('Hubo un error al enviar el formulario. Inténtelo de nuevo más tarde.');
        });
    }
  };

  const productosArray = [
    { id: 1, nombre: "Surecolor Ecotank F170" },
    { id: 2, nombre: "Surecolor F570 USB" },
    { id: 3, nombre: "Brother GTX PRO" },
    { id: 4, nombre: "SureColor T3170SR" },
    { id: 5, nombre: "SureColor T3170X" },
    { id: 6, nombre: "SureColor T5170M" },
    { id: 7, nombre: "SureColor T5470M" },
    { id: 8, nombre: "SureColor SCT5170SR" },
    { id: 9, nombre: "Brother PR1X" },
    { id: 10, nombre: "Brother PR 680W" },
    { id: 11, nombre: "PR1055X" },
  ];

  return (
    <>
      <div className="container-fluid bg-body-secondary py-5 mt-5">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12">
              <h1 className="h1 shadow-0 azul_oscuro">Contacto</h1>
              <p className="p_industrial">
                Nuestro equipo de expertos está aquí para brindarte
                asesoramiento personalizado y soporte técnico en cada paso del
                camino.
              </p>
              <p className="p_industrial">
                <b>Teléfono</b> : (+56) 2 29257620
                <br />
                <b>Whatsapp</b>: (+56) 9 42428870
                <br />
                <b>Email:</b> contacto@dreamtec.cl
                <br />
                <b>Dirección:</b> Av. Manuel Montt 1667, Local 2, Providencia,
                Santiago de Chile.
                <br />
                <b>Horario</b>: Lunes-Sábado: 10:00am- 18:00pm
              </p>
            </div>
            <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 border border-primary-subtle rounded-4 bg-white p-5">
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="nombre" className="form-label">
                    <b>Nombre Completo (*)</b>
                  </label>
                  <input
                    type="text"
                    className="form-control input_industrial"
                    id="nombre"
                    placeholder="Ingrese su nombre"
                    value={nombre}
                    onChange={(e) => setNombre(e.target.value)}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="nombre" className="form-label">
                    <b>Empresa</b>
                  </label>
                  <input
                    type="text"
                    className="form-control input_industrial"
                    id="empresa"
                    placeholder="Ingrese nombre de la empresa"
                    value={empresa}
                    onChange={(e) => setEmpresa(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    <b>Correo Electrónico (*)</b>
                  </label>
                  <input
                    type="email"
                    className="form-control input_industrial"
                    id="email"
                    placeholder="name@example.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="telefono" className="form-label">
                    <b>Télefono (*)</b>
                  </label>
                  <input
                    type="tel"
                    className="form-control input_industrial"
                    id="telefono"
                    placeholder="+56 9 1111 2222"
                    value={telefono}
                    onChange={(e) => handleTelefonoChange(e)}
                    required
                    pattern="^[+]*[0-9]*$"
                    title="Solo se permiten números y el símbolo +"
                    minLength="12"
                    maxLength="12"
                  />
                </div>

                {currentRoute !== "/industrial" &&
                  currentRoute !== "/industrial/contacto" && (
                    <div className="mb-3">
                      <label htmlFor="producto" className="form-label">
                        <b>Tipo de producto (*)</b>
                      </label>

                      <select
                        className="form-select select_industrial"
                        id="producto"
                        value={producto}
                        onChange={(e) => setProducto(e.target.value)}
                        aria-label="Selecciona el tipo de producto"
                        ref={selectRef}
                        required
                      >
                        <option value="0">Tipo de producto</option>
                        {productosArray.map((producto) => (
                          <option key={producto.id} value={producto.nombre}>
                            {producto.nombre}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}

                <div className="mb-3">
                  <label htmlFor="mensaje" className="form-label">
                    <b>Mensaje</b>
                  </label>
                  <textarea
                    className="form-control textarea_industrial"
                    id="mensaje"
                    style={{ height: "70px" }}
                    placeholder="Enviar mensaje"
                    value={mensaje}
                    maxLength={200}
                    onChange={(e) => setMensaje(e.target.value)}
                  ></textarea>
                </div>
                <button
                  type="submit"
                  aria-label="Enviar Formulario"
                  className="btn btn-primary_industrial w-100"
                >
                  Enviar Formulario
                </button>
                {error && (
                  <div className="alert alert-danger mt-3">{error}</div>
                )}
                {success && (
                  <div className="alert alert-success mt-3">{success}</div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormularioIndustrial;
