
import { createSlice } from '@reduxjs/toolkit';

const initialState = "";

const tipoEnvioSlice = createSlice({
  name: 'tipoEnvio',
  initialState,
  reducers: {
    setTipoEnvio: (state, action) => action.payload,
  },
});

export const { setTipoEnvio } = tipoEnvioSlice.actions;
export default tipoEnvioSlice.reducer;
