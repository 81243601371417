/**
 * Componente Devoluación Gratis:
 * 
 * acá se muestra el modal informativo sobre las devoluciones gratis
 * 
 * @module ComponenteDevolucionGratisjsx
 */

import React from "react";

const DevolucionGratis = () => {
  return (
    <div
      className="modal fade"
      id="modal-devolucion"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"

    >
      <div className="modal-dialog ">
        <div className="modal-content">
          <div
            className="modal-header pb-0 "
            style={{ backgroundColor: "#131f35", border: "none" }}
          >
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              style={{ filter: "invert(1)", opacity: ".8" }}
            ></button>
          </div>
          <div className="modal-body pb-2 pt-0 px-0">
            <div
              className="container-fluid"
              style={{ backgroundColor: "#131f35" }}
            >
              <div className="row justify-content-center">
                <div className="col-lg-11 col-md-11 col-11 mb-3 text-center">
                  <span className="fs-6 text-white fw-bold">
                    Devolución gratis
                  </span>
                </div>
              </div>
            </div>


            <div className="container-fluid mt-4">
              <div className="row">

                <div className="col-lg-12 col-md-12 col-12 mb-3">
                  <span className="fs-14 text-black">
                    I.-Ley del Consumidor. <br></br><br></br>
                    La Ley del Consumidor no contempla ninguna obligación a los proveedores respecto a otorgar devoluciones de dinero o efectuar cambios de productos, salvo cuando se trate de fallas o situaciones en que proceda la garantía legal.
                  </span>
                  <br></br>
                  <br></br>
                  <span className="fs-14 text-black">
                    II.- Servicio Técnico. <br></br>
                    Todo producto que presente fallas técnicas de fábrica o durante su uso, será enviado a servicio técnico (sin excepción), para validar lo indicado por el cliente, entregando un plazo de respuesta establecido según indique el fabricante.
                  </span>
                  <br></br>
                  <br></br>
                  <span className="fs-14 text-black">
                    III.- Compras Presenciales o Digitales <br></br>
                    Compras realizadas en la tienda comercial Dreamtec, a través de la página web <a href="https://dreamtec.cl" target="blank_" aria-label="Ir al sitio web" className="text-decoration-none">www.dreamtec.cl</a>, MercadoLibre, Marketplace o cualquier medio digital.<br></br><br></br>

                    De acuerdo a la facultad que confiere el artículo 3 BIS de la Ley del Consumidor, Dreamtec no otorga el derecho de retracto o garantía de satisfacción en ningún tipo de compras, bajo ninguna circunstancia.
                  </span>
                  <br></br>
                  <br></br>
                  <span className="fs-14 text-black">
                    IV.-Devolución de Dinero. <br></br>
                    En caso de que corresponda realizar la restitución del dinero a nuestros clientes, por cualquier motivo o causal indicado en el punto I, Dreamtec dispondrá para dicha gestión de un plazo máximo de 07 días corridos desde la emisión de la correspondiente nota de crédito.<br></br><br></br>

                    En los casos de compras que se efectúan mediante tarjeta de crédito, ya sea bancaria o de casa comercial, se entenderá cumplida la gestión de devolución una vez emitida la comunicación a la plataforma dispuesta por la entidad emisora de la tarjeta solicitando se procese la anulación de la compra. Dreamtec no se hará responsable por el retraso de los emisiones de tarjeta bancaria respecto de las restituciones de los fondos una vez solicitada la referida anulación.<br></br><br></br>

                    En cuanto a devoluciones por compras efectuadas mediante tarjeta de débito, de casa comercial o bien, mediante transferencia electrónica, Dreamtec se contactará con el cliente mediante correo electrónico o vía telefónica para comunicar la emisión de la respectiva nota de crédito junto con confirmar los datos bancarios de la cuenta a la cual se realizará la transferencia, restitución que se realizará en el plazo señalado en el párrafo anterior. La devolución se realizará siempre a la cuenta desde la cual se generó la transferencia o pago asociado a la compra (sin excepción).
                  </span>

                </div>

              </div>
            </div>




          </div>
          <div className="modal-footer" style={{ border: "none" }}></div>
        </div>
      </div>
    </div>
  );
};

export default DevolucionGratis;
