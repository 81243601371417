/**
 * Página Confirmación:
 * 
 * acá se muestra el resultado del paso 3
 * 
 * @module PaginaConfirmedjsx
 */

import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setDetalleCarro } from "../redux/DetalleCarroSlice";
import { setNumeroOrden } from "../redux/NumeroOrdenSlice";
import Modal from 'react-bootstrap/Modal';
import { Helmet } from "react-helmet";
import ReactGA from "react-ga4";

const Confirmed = () => {


  /**
* @constant
* @name tipopago
* @type {string}
* 
* */

  const [tipopago, setTipoPago] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const detalleCarro = useSelector((state) => state.detalleCarro);
  const tipo_envio = useSelector((state) => state.tipoEnvio);
  const numeroOrden = useSelector((state) => state.numeroOrden);


  /**
* @constant
* @name showFoto
* @type {boolean}
* 
* */

  const [showFoto, setShowFoto] = useState(true);



  /**
* @constant
* @name userAlready
* @type {string}
* 
* */

  const [userAlready, setUserAlready] = useState(
    sessionStorage.getItem("IDusuario")
  );


  const handleCloseFoto = () => {
    setShowFoto(false);
  }

  const handleShowFoto = () => {
    setShowFoto(true);
  };

  const searchParams = new URLSearchParams(location.search);
  const paymentId = searchParams.get("payment_id");
  const commerceID = searchParams.get("commerceID");
  const token = searchParams.get("token");
  const payment_status = searchParams.get("payment_status");
  const status = searchParams.get("status");
  const [apro, setApro] = useState("trans");
  const [total, setTotal] = useState("");

  useEffect(() => {
    if (commerceID != null && commerceID != null && payment_status != null) {
      if (commerceID != "2eftefee3dfmvnd9393" || payment_status != "internal_check")
        return () => { };
      if (token.includes("2ABE") && token.includes("D8LC") && token.length == 38)
        return () => { };

      let formData = new FormData();
      formData.append("token", token);
      formData.append("user", userAlready);
      handleShowFoto();
      axios
        .post(process.env.REACT_APP_BACK_URL + `/api/compra/obtener_estado_pago_flow_token`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          console.log(res);
          if (res.data.message != null) {
            dispatch(setNumeroOrden(res.data.message));
            setApro("not");
            handleCloseFoto();
          }
          else {
            dispatch(setDetalleCarro(res.data.detalle_compra));
            dispatch(setNumeroOrden(res.data.numero_pedido.split(" ")[res.data.numero_pedido.split(" ").length - 1]));
            setApro("apro");
            if (res.data.estado_compra == "Repetido")
              obtenerDatosMP(res.data.numero_pedido.split(" ")[res.data.numero_pedido.split(" ").length - 1], 1);
            else
              obtenerDatosMP(res.data.numero_pedido.split(" ")[res.data.numero_pedido.split(" ").length - 1], 0);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      obtenerDatos();
    }
  }, [payment_status, commerceID, token]);



  useEffect(() => {
    if (sessionStorage.getItem("accessTokenWeb") === null) {
      sessionStorage.clear();
      navigate("/");
    }

    // Agregado userAlready para el envio del correo cuando se confirma mercadopago
    handleShowFoto();

    /**
* @function
* Petición HTTP encargada de guardar los datos de mercado pago
* @param {string} userAlready Id del usuario
* @param {string} paymentId Tipo de pago (Mercado pago)
*@returns {Array}
* 
*/
    if (paymentId != null) {
      axios
        .get(process.env.REACT_APP_BACK_URL + `/api/compra/get_mercado_pago_by_id/${paymentId}?user=${userAlready}`, {

        })
        .then((res) => {
          console.log(res);
          if (res.data.message != null) {
            dispatch(setNumeroOrden(res.data.message));
            setApro("not");
            handleCloseFoto();
          }
          else {
            dispatch(setDetalleCarro(res.data.detalle_compra));
            dispatch(setNumeroOrden(res.data.numero_pedido.split(" ")[res.data.numero_pedido.split(" ").length - 1]));
            setApro("apro");
            obtenerDatosMP(res.data.numero_pedido.split(" ")[res.data.numero_pedido.split(" ").length - 1], 0);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      obtenerDatos();
    }

  }, [paymentId]);

  const obtenerDatos = () => {
    if (paymentId == null && commerceID == null && payment_status == null && token == null) {
      axios
        .get(process.env.REACT_APP_BACK_URL + `/api/compra/datos_compra_finalizada?compra=${numeroOrden}`, {

        })
        .then((res) => {
          handleCloseFoto();
          if (Number.isInteger(res.data.total)) {
            setTotal(res.data.total);
          }
          if (numeroOrden != 0 && numeroOrden != "" && numeroOrden != null && numeroOrden != 100)
            purchaseGa4(numeroOrden);
        })
        .catch((err) => {
          handleCloseFoto();
          console.log(err);
        });
    }
  };

  const obtenerDatosMP = (buy, repetido) => {
    axios
      .get(process.env.REACT_APP_BACK_URL + `/api/compra/datos_compra_finalizada?compra=${buy}`, {

      })
      .then((res) => {
        handleCloseFoto();
        if (Number.isInteger(res.data.total)) {
          setTotal(res.data.total);
        }
        if (repetido == 0)
          purchaseGa4(buy);
      })
      .catch((err) => {
        handleCloseFoto();
        console.log(err);
      });
  };

  const purchaseGa4 = (compra) => {
    axios
      .get(process.env.REACT_APP_BACK_URL + `/api/ga/finalizarcompraga4?compra=${compra}`)
      .then((response) => {
        console.log(response);
        ReactGA.gtag("event", "purchase", {
          currency: "CLP",
          transaction_id: compra,
          value: response.data.total,
          tax: response.data.tax,
          shipping: response.data.despacho,
          items: response.data.productos
        });
      })
      .catch((error) => {
        console.log(error)
      })
  };

  return (
    <div>
      <Helmet>
        <title>Dreamtec</title>
      </Helmet>
      <Modal show={showFoto} onHide={handleCloseFoto} size="lg" centered={true}>
        <Modal.Header closeButton className="border-bottom-0">
        </Modal.Header>
        <Modal.Body>

          <div className=" container" >

            <div className="row justify-content-center text-center col-12 mb-4">
              <div className="col-12 text-center align-self-center">
                <label htmlFor="">Esperando confirmación</label>
                <br />
                <img loading="lazy" src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/Modales/cargando.gif"} alt="cargando" title="cargando" className="img-fluid" />
              </div>
            </div>
          </div>

        </Modal.Body>
      </Modal>
      <div className="container mt-5 mb-3">

        <div className="container mb-3" id="div-paso-1">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 px-0 text-center">
              <img
                src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/carrito/paso_4.png"}
                className="img-fluid mx-auto"
                alt="Paso 1 Opciones de envío"
                title="Paso 1 Opciones de envío"
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div className="row text-center justify-content-center">
          <div className="col-6 mt-5">

            {apro == "apro" ?
              <img loading="lazy" src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/carrito/icono-check-turq.png"} width={80} alt="ícono compra realizada" title="ícono compra realizada" />
              :
              null
            }

            {apro == "trans" ?
              <img loading="lazy" src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/carrito/icono-check-turq.png"} width={80} alt="ícono compra realizada" title="ícono compra realizada" />
              :
              null
            }

            {apro == "not" ?
              <img loading="lazy" src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/carrito/icono-x-turq.png"} width={80} alt="ícono compra fallida" title="ícono compra fallida" />
              :
              null
            }

            {tipo_envio === "Transferencia" && (
              <h1 className="text-black fs-2 mt-3">
                ¡Tu compra está siendo procesada!
              </h1>
            )}

            <div className="col-lg-12 col-md-12 col-12 text-center mt-4">
              <p className="mb-0 text-dark fw-bold">
                {numeroOrden != "Compra no aprobada por Mercado Pago" ?
                  <>
                    Número de orden: {numeroOrden}
                  </>
                  :
                  <>
                    {numeroOrden}
                  </>
                }
              </p>
            </div>

            <div className="col-lg-12 col-md-12 col-12 text-center mt-4">
              <button
                type="button"
                className="text-decoration-none w-100 btn px-4 py-1 text-dark bg-turq btn-turq-hover rounded-pill fw-bolder"
                aria-label="Volver al inicio"
                onClick={() => {
                  navigate("/");
                }}
              >
                Volver al inicio
              </button>
            </div>
          </div>
        </div>
        <div className="container mt-5">
          {tipo_envio === "Transferencia" && (
            <div className="row">
              <div className="row justify-content-center mb-5">
                <div className="col-lg-5 col-md-5 col-11 mb-3 bg-turq py-4 px-4 borde-round-20">
                  <p className="text-white fs-6 mb-2">
                    <strong>
                      <i className="fas fa-exclamation-circle text-white pe-1"></i>
                      Importante:
                    </strong>{" "}
                    una vez realizada la transferencia bancaría, se emitirá un
                    comprobante de pago, el cual deberás guardar y enviar por
                    mail a ventasweb@dreamtec.cl con tu número de orden para
                    procesar tu compra.
                  </p>
                  <div className="col-lg-12 col-md-12 col-12 text-center">
                    <p className="mb-0 text-white fw-bold">
                      {numeroOrden != "Compra no aprobada por Mercado Pago" ?
                        <>
                          Número de orden: {numeroOrden}
                        </>
                        :
                        <>
                          {numeroOrden}
                        </>
                      }
                    </p>
                  </div>
                </div>

                <div className="col-lg-5 col-md-6 col-11 borde-round-20 borde-gris pt-3 mb-3 mx-3">
                  <div className="row mb-3">
                    <div className="col-12 mb-3">
                      <strong className="text-black fs-6">
                        Nuestros detalles bancarios
                      </strong>
                      <br />
                      <br />
                      <span className="fs-14 text-black">
                        Dreamtec HOLDING SPA
                      </span>
                    </div>
                    <div className="col-lg-3 col-md-3 col-10 mb-2">
                      <span className="text-black fs-14">BANCO:</span>
                      <br />
                      <span className="fs-14 text-black">
                        SCOTIABANK SUD AMERICANO
                      </span>
                    </div>
                    <div className="col-lg-3 col-md-3 col-10 mb-2 margin-l">
                      <span className="text-black fs-14">RUT:</span>
                      <br />
                      <span className="fs-14 text-black">76.905.656-4</span>
                    </div>
                    <div className="col-lg-3 col-md-3 col-10 mb-2 margin-l">
                      <span className="text-black fs-14">
                        NÚMERO DE CUENTA:
                      </span>
                      <br />
                      <span className="fs-14 text-black">980532240</span>
                    </div>
                    <div className="col-lg-3 col-md-3 col-10 mb-2 margin-l">
                      <span className="text-black fs-14">TIPO CUENTA:</span>
                      <br />
                      <span className="fs-14 text-black">CORRIENTE</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="container">
            <div className="row">
              <div className="row align-items-center">
                {apro != "not" ?
                  <div className="col-lg-9 col-md-9 col-12">
                    <h2 className="text-black fs-6 fw-bold">
                      CONTENIDO DEL PEDIDO:
                    </h2>
                    <hr />
                    {detalleCarro.map((producto, index) => (
                      <div key={index}>
                        <div className="row">
                          <div className="col-md-3 col-4 mt-1 mb-5">
                            <img
                              className="img-fluid"
                              src={process.env.REACT_APP_FRONT_URL + `${producto.foto ? producto.foto : producto.producto_foto}`}
                              alt={producto.producto_nombre ? producto.producto_nombre : producto.nombre}
                              title={producto.producto_nombre ? producto.producto_nombre : producto.nombre}
                              width={130}
                              loading="lazy"
                            />

                          </div>
                          <div className="col-8">
                            <div>{producto.producto_sku ? producto.producto_sku : producto.sku}</div>
                            <div>Cantidad: {producto.cantidad}</div>
                            <div>{producto.producto_nombre ? producto.producto_nombre : producto.nombre}</div>
                          </div>
                        </div>
                      </div>
                    ))}
                    <hr />
                    {total != "" && (
                      <div className="text-black fs-18 fw-bold">Total: <span id='total-compra'>{total.toLocaleString("es-CL")}</span></div>
                    )}
                  </div>
                  :
                  null
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Confirmed;
