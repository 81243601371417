/**
 * Página Admin Marcas:
 * 
 * acá se Administran Marcas
 * 
 * @module AdminMarcasjsx
 */



import { useState, useEffect } from "react";
import axios from "axios";

import { AdminSidebar } from "../../components";
import Mensaje from "../../components/Modales/Mensaje";

const Marcas = () => {


  /**
* @constant
* @name marcas
* @type {Array}
* 
* */

  const [marcas, setMarcas] = useState([]);

  /**
* @constant
* @name listarMarcas
* @type {number}
* 
* */

  const [listarMarcas, setListarMarcas] = useState(1);


  /**
* @constant
* @name mensaje
* @type {string}
* 
* */
  const [mensaje, setMensaje] = useState("");


  /**
* @constant
* @name nombre
* @type {string}
* 
* */
  const [nombre, setNombre] = useState("");


  /**
* @constant
* @name nombreModificar
* @type {string}
* 
* */
  const [nombreModificar, setNombreModificar] = useState("");


  const accessToekn = sessionStorage.getItem("access");



  useEffect(() => {
    obtenerMarcas();
  }, [listarMarcas]);


  /**
* @function
* Peticion HTTP que lista las marcas en modo asincrono 
* @returns {Array}
*/
  const obtenerMarcas = async () => {
    const response = await axios.get(
      process.env.REACT_APP_BACK_URL + `/api/admin/listar_marcas`
    ).then(response => {

      setMarcas(response.data);

    }).catch(error => {
      console.log(error);
    })
  };

  const elNombre = (e) => {
    setNombre(e.target.value);
  };

  const elNombreModificar = (e) => {
    setNombreModificar(e.target.value);
  };


  /**
* @function
* Metodo que asigna el nombre si el valor del target es distinto de 0, por medio de
* la opción seleccionada
*/
  const asignaNombre = (e) => {
    if (e.target.value != 0) {
      let texto = e.target.options[e.target.selectedIndex].text;
      setNombreModificar(texto);
      document.getElementById("nombreModificar").value = texto;
    } else {
      setNombreModificar("");
      document.getElementById("nombreModificar").value = "";
    }
  };



  /**
  * @function
  * Petición HTTP que registra la marca 
  * @param {Object} enviar FormData con los datos para registrar la marca
  * @returns {string}
  */
  const handleRegistrar = async (e) => {
    e.preventDefault();
    let input = document.getElementById("nombre");
    let enviar = new FormData();
    enviar.append("nombre", nombre);
    if (input.value != "" && input.value != null) {
      setMensaje("Creando la marca-alert");
      const response = await axios.post(
        process.env.REACT_APP_BACK_URL + `/api/admin/marca/register_marca`, enviar, {
        headers: {
          Authorization: `Bearer ${accessToekn}`,
        },
      }).then(response => {
        setMensaje("Marca registrada-verde");
        location.href = "/admin/marcas";
      }).catch(error => {
        console.log(error);
      })
    }
    else
      setMensaje("Ingrese el nombre de la marca-rojo");
  };


  /**
* @function
* Petición HTTP que modifica los datos de una marca 
* @param {Object} enviar FormData con los datos para modificar la marca
* @returns {string}
*/
  const handleModificar = async (e) => {
    e.preventDefault();
    let input = document.getElementById("nombreModificar");
    let idModificar = document.getElementById("listarMarcas");
    let enviar = new FormData();
    enviar.append("nombre", nombreModificar);
    enviar.append("id", idModificar.value);
    if (input.value != "" && input.value != null) {
      setMensaje("Modificando la marca-alert");
      const response = await axios.post(
        process.env.REACT_APP_BACK_URL + `/api/admin/modificar_marca`, enviar, {
        headers: {
          Authorization: `Bearer ${accessToekn}`,
        },
      }).then(algo => {
        idModificar.value = 0;
        input.value = "";
        setNombreModificar("");
        obtenerMarcas();
        setMensaje("Marca modificada-verde");
        location.href = "/admin/marcas";
      }).catch(error => {
        console.log(error);
      })
    }
    else
      setMensaje("Complete los campos-rojo");
  };

  return (

    <div>
      <Mensaje mensaje={mensaje} setMensaje={setMensaje} />
      <AdminSidebar />


      <div className="regular">
        <h1>Dreamtec - <span>Administrador de marcas</span></h1>
        <div className="shadow">
          <div className="row">
            {listarMarcas === 1 && (
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12" >

                <div className="row">
                  <div className="col-12 text-center">
                    <h2 className="mb-5">Marcas</h2>
                  </div>
                </div>

                <div className="row justify-content-end">
                  <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12 text-end mb-3">
                    <button type="button"
                      onClick={() => {
                        setListarMarcas(2);
                      }}
                      className="agregar"
                    >
                      <i className="fa-solid fa-circle-plus"></i> &nbsp;Agregar Marcas
                    </button>
                  </div>


                  <div className="col-xxl-3 col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12 text-end mb-3">
                    <button type="button"
                      onClick={() => {
                        setListarMarcas(3);
                      }}
                      className="agregar"
                    >
                      <i className="fa-solid fa-pen-to-square"></i> &nbsp;Editar Marcas
                    </button>
                  </div>

                </div>

                {marcas.length > 0 ? (

                  <div className="table-responsive">
                    <table className="table border">
                      <thead className="p-4">
                        <tr>
                          <th scope="col"><div className="id">ID</div></th>
                          <th scope="col"><div className="id text-start">Nombre</div></th>
                        </tr>
                      </thead>
                      <tbody>
                        {marcas.map((marca) => (
                          <tr key={marca.id}>
                            <th scope="row">
                              <div className="number">{marca.id}</div>
                            </th>
                            <td>
                              <div className="nameNumber">{marca.nombre_mostrar}</div>
                            </td>

                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>


                ) : (


                  <div className="row my-5">
                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 fw-bold text-center">
                      <i className="fa-solid fa-circle-info"></i>&nbsp;No hay marcas registradas
                    </div>
                  </div>

                )}

              </div>

            )}
            {listarMarcas === 2 && (

              <div className="container">
                <div className="row">
                  <div className="col-12 text-center">
                    <h2 className="mb-5">Registrar marca</h2>
                  </div>
                </div>

                <div className="row col-lg-3 col-md-6 col-sm-12">
                  <form onSubmit={handleRegistrar}>
                    <div className="col-12">
                      <label htmlFor="nombre">Nombre de la marca:</label>
                      <span className="text-danger">*</span>
                      <input type="text"
                        placeholder="Ingrese el nombre de la marca"
                        className="form-control"
                        id="nombre"
                        name="nombre"
                        onChange={elNombre}
                      />
                    </div>

                    <div className="col-12 mt-3">
                      <button
                        aria-label="Agregar marca"
                        className="btnGreen w-100"
                        type="submit"
                      >
                        Agregar Marca
                      </button>
                    </div>
                    <div className="col-12 mt-2">
                      <button
                        aria-label="Volver atras"
                        className="cancel w-100"
                        type="submit"
                        onClick={() => {
                          setListarMarcas(1);
                        }}
                      >
                        Volver atrás
                      </button>
                    </div>

                  </form>
                </div>




              </div>

            )}
            {listarMarcas === 3 && (

              <div className="container">
                <div className="row">
                  <div className="col-12 text-center">
                    <h2 className="mb-5">Registrar marca</h2>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 d-flex justify-content-center">
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <select
                        name="listarMarcas"
                        id="listarMarcas"
                        className="form-control rounded-pill"
                        onChange={asignaNombre}
                      >
                        <option value="0">Seleccione una marca</option>
                        {marcas.map((marca) => (
                          <>
                            <option key={marca.id} value={marca.id}>{marca.nombre_mostrar}</option>
                          </>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="row col-lg-3 col-md-6 col-sm-12">
                  <form onSubmit={handleModificar}>
                    <div className="col-12">
                      <label htmlFor="nombreModificar">Nombre de la marca:</label>
                      <span className="text-danger">*</span>
                      <input type="text"
                        placeholder="Ingrese el nombre de la marca"
                        className="form-control"
                        id="nombreModificar"
                        name="nombreModificar"
                        onChange={elNombreModificar}
                      />
                    </div>

                    <div className="col-12 mt-3">
                      <button
                        aria-label="Editar marca"
                        className="btnGreen w-100"
                        type="submit"
                      >
                        Editar Marca
                      </button>
                    </div>
                    <div className="col-12 mt-2">
                      <button
                        aria-label="Volver atras"
                        className="cancel w-100"
                        type="submit"
                        onClick={() => {
                          setListarMarcas(1);
                        }}
                      >
                        Volver atrás
                      </button>
                    </div>

                  </form>
                </div>
              </div>
            )}
          </div>
        </div>

      </div>





    </div>
  );
};

export default Marcas;
