/**
 * Página Admin Personal:
 * 
 * acá se Adminsitra el personal
 * 
 * @module AdminPersonaljsx
 */


import { useEffect, useState } from "react";
import axios from "axios";

import { AdminSidebar } from "../../components";
import Mensaje from "../../components/Modales/Mensaje";

const Personal = () => {

  /**
* @constant
* @name firstName
* @type {string}
* 
* */

  const [firstName, setFirstName] = useState("");

  /**
* @constant
* @name lastName
* @type {string}
* 
* */

  const [lastName, setLastName] = useState("");

  /**
* @constant
* @name email
* @type {string}
* 
* */

  const [email, setEmail] = useState("");


  /**
* @constant
* @name opciones
* @type {Array}
* 
* */
  const [opciones, setOpciones] = useState([]);

  /**
* @constant
* @name opcioneopcionSeleccionadas
* @type {string}
* 
* */
  const [opcionSeleccionada, setOpcionSeleccionada] = useState("");


  /**
* @constant
* @name password
* @type {string}
* 
* */
  const [password, setPassword] = useState("");

  /**
* @constant
* @name password2
* @type {string}
* 
* */
  const [password2, setPassword2] = useState("");


  /**
* @constant
* @name personal
* @type {Array}
* 
* */
  const [personal, setPersonal] = useState([]);


  /**
* @constant
* @name agregar
* @type {string}
* 
* */
  const [agregar, setAgregar] = useState("");

  /**
* @constant
* @name msg
* @type {string}
* 
* */
  const [msg, setMsg] = useState('');

  /**
* @constant
* @name tipo
* @type {string}
* 
* */
  const [tipo, setTipo] = useState('');


  /**
* @constant
* @name currentPage
* @type {number}
* 
* */
  const [currentPage, setCurrentPage] = useState(1);

  /**
* @constant
* @name showPass
* @type {boolean}
* 
* */
  const [showPass, setShowPass] = useState(false);

  /**
* @constant
* @name showPass1
* @type {boolean}
* 
* */
  const [showPass1, setShowPass1] = useState(false);

  /**
* @constant
* @name totalPaginas
* @type {string}
* 
* */
  const [totalPaginas, setTotalPaginas] = useState('');

  /**
* @constant
* @name mensaje
* @type {string}
* 
* */
  const [mensaje, setMensaje] = useState("");

  const togglePasswordShow = () => {
    setShowPass(current => !current);
  };

  const togglePasswordShow1 = () => {
    setShowPass1(current => !current);
  };


  const accessToekn = sessionStorage.getItem("access");

  useEffect(() => {
    obtenerUsuarios();
  }, [agregar, currentPage]);



  /**
* @function
* Petición HTTP que lista los roles 
* @returns {Array}
*/

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BACK_URL + "/api/admin/listar_roles", {
        headers: {
          Authorization: `Bearer ${accessToekn}`,
        },
      })
      .then((response) => {
        setOpciones(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);


  /**
* @function
* Petición HTTP que lista todos los usuarios personal
* @param {number} currentPage Pagina Actual 
* @returns {Array}
*/
  const obtenerUsuarios = async () => {
    const response = await axios.get(
      process.env.REACT_APP_BACK_URL + `/api/admin/listar_all_usuarios_personal?page=${currentPage}&size=10`, {
      headers: {
        Authorization: `Bearer ${accessToekn}`,
      },
    }).then(response => {

      setPersonal(response.data.results);
      setTotalPaginas(response.data.pages);

    }).catch(error => {
      console.log(error);
    })
  };

  /**
* @function
* Petición HTTP que registra el usuario personal
* @param {Object} formData FormData con todos los datos para ingresar el personal
* @returns {string}
*/
  const handleSubmit = (e) => {

    e.preventDefault();

    const formData = new FormData();
    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    formData.append("password1", password);
    formData.append("password2", password2);
    formData.append("email", email);
    formData.append("rol", opcionSeleccionada);

    setMensaje("Registrando personal-alert");
    axios
      .post(
        process.env.REACT_APP_BACK_URL + "/api/admin/registrar_usuario_personal",
        formData, {
        headers: {
          Authorization: `Bearer ${accessToekn}`,
        },
      }

      )
      .then((response) => {

        setMsg(response.data);
        setTipo(1);

        setFirstName("");
        setLastName("");
        setPassword("");
        setPassword2("");
        setEmail("");
        setMensaje("Usuario registrado-verde");
        location.href = "/admin/personal";
        if (msg === "Usuario registrado correctamente") {
          setAgregar(false);
          setMensaje("Usuario registrado-verde");
          setMsg('');
          setTipo(0);

        }
      })
      .catch((error) => {
        setMensaje("Error al registrar el personal-rojo");

        setMsg("No se pudo agregar personal");
        setTipo(2);

        setTimeout(() => {

          setMsg('');
          setTipo(0);

        }, 5000);

      });
  };

  return (

    <div>

      <Mensaje mensaje={mensaje} setMensaje={setMensaje} />
      <AdminSidebar />


      <div className="regular">
        <h1>Dreamtec - <span>Administrador de Personal</span></h1>

        <div className="shadow">

          <div className="row">

            <div className="col-12">

              <div className="row">

                {!agregar && (

                  <div className="col-12">
                    <div className="row">
                      <div className="col-12 text-center my-3">
                        <h2>Listado personal</h2>
                      </div>
                    </div>

                    <div>
                      {personal.length > 0 ? (

                        <div className="table-responsive">

                          <table className="table boder">
                            <thead className="p-4">
                              <tr>
                                <th scope="col"><div className="id text-center">ID</div></th>
                                <th scope="col"><div className="id text-center">Nombre</div></th>
                                <th scope="col"><div className="id text-center">Apellido</div></th>
                                <th scope="col"><div className="id text-center">Email</div></th>
                                <th scope="col"><div className="id text-center">Rol</div></th>
                                <th scope="col"><div className="id text-center">Fecha registro</div></th>
                              </tr>
                            </thead>
                            <tbody>
                              {personal?.map((usuario) => (
                                <tr key={usuario.id}>
                                  <th scope="row"><div className="number text-center">{usuario.id}</div></th>
                                  <td><div className="nameNumber text-center">{usuario.first_name}</div></td>
                                  <td><div className="nameNumber text-center">{usuario.last_name}</div></td>
                                  <td><div className="nameNumber text-center">{usuario.email}</div></td>
                                  <td><div className="nameNumber text-center">{usuario.rol}</div></td>
                                  <td><div className="nameNumber text-center">{usuario.fecha_registro}</div></td>
                                </tr>
                              ))}
                            </tbody>
                          </table>

                          <div className="row justify-content-end mb-3">
                            <div className="col-xxl-1 col-xl-1 col-lg-1 col-md-6 col-sm-6 col-6">

                              {currentPage > 1 && (
                                <button type="button" aria-label="Anterior" className="agregar border-0 btnCelesteDreamtec" onClick={() => setCurrentPage(currentPage - 1)}>
                                  <i class="fa-solid fa-circle-arrow-left fs-16 text-white"></i>
                                </button>
                              )}

                            </div>

                            <div className="col-xxl-1 col-xl-1 col-lg-1 col-md-6 col-sm-6 col-6">

                              {currentPage + 1 <= totalPaginas && (
                                <button type="button" aria-label="Siguiente" className="agregar border-0 btnCelesteDreamtec" onClick={() => setCurrentPage(currentPage + 1)}>
                                  <i class="fa-sharp fa-solid fs-16  fa-circle-arrow-right text-white"></i>
                                </button>
                              )}

                            </div>
                          </div>

                        </div>



                      ) : (


                        <div className="row my-5">
                          <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 fw-bold text-center">
                            <i className="fa-solid fa-circle-info"></i>&nbsp;No hay personal registrado
                          </div>
                        </div>

                      )}

                    </div>


                    <div className={personal.length > 0 ? "row justify-content-end mt-3" : "row justify-content-end "} >
                      <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
                        <button
                          onClick={() => {
                            setAgregar(true);
                          }}
                          className="agregar"
                        >
                          <i className="fa-solid fa-circle-plus"></i> &nbsp; Agregar personal
                        </button>
                      </div>
                    </div>


                  </div>

                )}
                {agregar && (

                  <div className="col-12">

                    <div className="row">
                      <div className="col-12 text-start mb-3">
                        <h2 className="">Agregar personal</h2>
                      </div>
                    </div>

                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mb-4">
                          <label >Nombre: &nbsp;<span className="text-danger">*</span></label>

                          <input
                            type="text"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            className="form-control"
                            required
                            placeholder="Ingresa nombre"
                          />
                        </div>

                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mb-4">
                          <label >Apellido: &nbsp;<span className="text-danger">*</span></label>

                          <input
                            type="text"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            className="form-control"
                            required
                            placeholder="Ingresa apellido"
                          />
                        </div>


                        <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 mb-4">
                          <label >Email: &nbsp;<span className="text-danger">*</span></label>

                          <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="form-control"
                            required
                            placeholder="Ingresa email"
                          />
                        </div>

                        <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 mb-4">
                          <label>Rol: &nbsp;<span className="text-danger">*</span></label>

                          <select
                            value={opcionSeleccionada}
                            onChange={(e) =>
                              setOpcionSeleccionada(e.target.value)
                            }
                            className="form-control rounded-pill"
                            required
                          >
                            <option value="">Seleccione una opción</option>
                            {opciones.map((opcion) => (
                              <option key={opcion.id} value={opcion.id}>
                                {opcion.nombre}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 mb-4">
                          <label >Contraseña: &nbsp;<span className="text-danger">*</span></label>

                          <div className="input-group">


                            <input
                              type={!showPass1 ? "password" : "text"}
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              className="form-control"
                              minLength={8}
                              required
                              placeholder="********"
                            />
                            <span
                              role="button"
                              className="input-group-text border-start-0 border-radius-right boton-ver-pass-login"
                              id="show_password" onClick={togglePasswordShow1}
                            >

                              <i className={!showPass1 ? "fa-sharp fa-solid fa-eye-slash" : "fas fa-eye"}></i>

                            </span>
                          </div>
                        </div>

                        <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 mb-4">
                          <label >Confirmar contraseña: &nbsp;<span className="text-danger">*</span></label>

                          <div className="input-group">
                            <input
                              type={!showPass ? "password" : "text"}
                              value={password2}
                              onChange={(e) => setPassword2(e.target.value)}
                              className="form-control"
                              minLength={8}
                              required
                              placeholder="********"
                            />

                            <span
                              role="button"
                              className="input-group-text border-start-0 border-radius-right boton-ver-pass-login"
                              id="show_password" onClick={togglePasswordShow}
                            >

                              <i className={!showPass ? "fa-sharp fa-solid fa-eye-slash" : "fas fa-eye"}></i>

                            </span>

                          </div>



                          {password !== password2 && (
                            <p style={{ color: "red" }}>
                              Las contraseñas no coinciden
                            </p>

                          )}

                          {password === password2 && password !== "" && (
                            <p style={{ color: "green" }}>
                              Las contraseñas coinciden
                            </p>
                          )}

                        </div>

                        <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 mb-4">

                          <button aria-label="Agregar personal"
                            className="btnGreen w-100"
                            type="submit"
                          >
                            Agregar personal
                          </button>
                        </div>

                        <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 mb-4">
                          <button type="button" aria-label="Volver al listado"
                            className="cancel w-100"
                            onClick={() => {
                              setAgregar(false);
                            }}
                          >
                            Volver al listado
                          </button>
                        </div>

                        <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 my-3 text-center">

                          <span className={tipo == 1 ? "text-success fw-bold" : "text-danger fw-bold"}>{msg}</span>

                        </div>

                      </div>

                    </form>

                  </div>

                )}
              </div>

            </div>

          </div>
        </div>

      </div>


    </div>
  );
};

export default Personal;
