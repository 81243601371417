/**
 * Página Admin Dashboard:
 * 
 * acá se muestra el dashBoard
 * 
 * @module AdminDashBoardjsx
 */


import React from "react";
import { AdminSidebar } from "../../components";

const DashBoard = () => {
  return (
  <>

                <AdminSidebar/>

  </>
  );
};

export default DashBoard;
