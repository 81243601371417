/**
 * Componente SignUp:
 * 
 * acá se muestra el modal de Registro para los usuarios nuevos
 * 
 * @module ComponenteSignUpjsx
 */

import { useState, useEffect, useRef } from "react";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import htmlReactParser from 'html-react-parser';

const SignUp = () => {


  /**
* @constant
* @name password
* @type {string}
* 
* */
  const [password, setPassword] = useState("");

  /**
* @constant
* @name password2
* @type {string}
* 
* */
  const [password2, setPassword2] = useState("");

  /**
* @constant
* @name email
* @type {string}
* 
* */
  const [email, setEmail] = useState("");


  /**
* @constant
* @name name
* @type {string}
* 
* */
  const [name, setName] = useState("");

  /**
* @constant
* @name apellido
* @type {string}
* 
* */
  const [apellido, setApellido] = useState("");

  /**
* @constant
* @name number
* @type {string}
* 
* */
  const [number, setNumber] = useState("");


  /**
* @constant
* @name successMessage
* @type {null}
* 
* */
  const [successMessage, setSuccessMessage] = useState(null);

  /**
* @constant
* @name rut
* @type {string}
* 
* */
  const [rut, setRut] = useState("");
  /**
* @constant
* @name direccion
* @type {string}
* 
* */
  const [direccion, setDireccion] = useState("");

  /**
* @constant
* @name showAgregado
* @type {boolean}
* 
* */
  const [showAgregado, setShowAgregado] = useState(false);


  /**
* @constant
* @name regiones
* @type {Array}
* 
* */
  const [regiones, setRegiones] = useState([]);

  /**
* @constant
* @name comunas
* @type {Array}
* 
* */
  const [comunas, setComunas] = useState([]);
  /**
* @constant
* @name selectedRegion
* @type {string}
* 
* */
  const [selectedRegion, setSelectedRegion] = useState("");

  /**
* @constant
* @name selectedComuna
* @type {string}
* 
* */
  const [selectedComuna, setSelectedComuna] = useState("");


  /**
* @constant
* @name mensajeError
* @type {string}
* 
* */
  const [mensajeError, setMensajeError] = useState("");
  const handleShowAgregado = () => setShowAgregado(true);
  const handleCloseAgregado = () => {
    setShowAgregado(false);
  }

  const i_pass = useRef();
  const pass_input = useRef();
  const c_pass = useRef();
  const pass_c = useRef();


  /**
* @function
* Metodo que cambia el tipo de input al hacer clic en el botón del ojo del input
* @param {Element} e Input
*/
  const cambiarPass = (e) => {

    let type = pass_input.current.getAttribute('type') === 'password' ? "text" : "password";
    pass_input.current.setAttribute('type', type);

    i_pass.current.classList.toggle('fa-eye-slash');
    i_pass.current.classList.toggle('fa-eye');

  }

  /**
* @function
* Metodo que cambia el tipo de input al hacer clic en el botón del ojo del input
* @param {Element} e Input
*/
  const cambiarPassC = (e) => {

    let type = pass_c.current.getAttribute('type') === 'password' ? "text" : "password";
    pass_c.current.setAttribute('type', type);

    c_pass.current.classList.toggle('fa-eye-slash');
    c_pass.current.classList.toggle('fa-eye');

  }

  /**
* @function
* Metodo para dar formato al rut del usuario (sin puntos y con guión)
* @param {Element} rut Input
*/
  const formatRut = (rut) => {

    if (rut === '') {
      return rut;
    }

    const newRut = rut.replace(/\./g, '').replace(/\-/g, '').trim().toLowerCase();
    const lastDigit = newRut.substr(-1, 1);
    const rutDigit = newRut.substr(0, newRut.length - 1);
    let format = '';
    for (let i = rutDigit.length, j = 1; i > 0; i--, j++) {
      const e = rutDigit.charAt(i - 1);
      format = e.concat(format);
      // if (j % 3 === 0) {
      //     format = '.'.concat(format);
      // }
    }
    return format.concat('-').concat(lastDigit);
  }

  /**
* @function
* Metodo para validar el largo y reemplazar etiquetas HTML del input
* @param {Element} rut Input
*/
  const formatRutKey = (e) => {
    e.target.value = formatRut(e.target.value);
    if (e.target.value.length > 10) {
      e.target.value = e.target.value.substr(0, 10);
    }
    e.target.value = e.target.value.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g, '');
  }


  /**
* @functionkeyupDireccion
* Metodo para reemplazar todos los digitos y los tags HTML
* @param {Element} nombre Input
*/
  const keyupNombre = (e) => {
    e.target.value = e.target.value.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g, '');
    e.target.value = e.target.value.replace(/\d/g, '');
  }

  /**
* @function
* Metodo para reemplazar todos los tags HTML
* @param {Element} direccion Input
*/
  const keyupDireccion = (e) => {
    e.target.value = e.target.value.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g, '');
  }

  /**
* @function
* Metodo para validar el valor y reemplazar caracteres especiales y tags HTML
* @param {Element} telefono Input
*/
  const keyupTelefono = (e) => {
    if (e.target.value.length > 10) {
      e.target.value = e.target.value.substr(0, 10);
    }
    e.target.value = e.target.value.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g, '');
    e.target.value = e.target.value.replace(/\,\-\+/g, '');
  }


  const div_password_alert = document.getElementById('alerta_password');
  const div_password_success = document.getElementById('success_password');

  /**
* @function
* Metodo que verifica que tanto la contraseña como la confirmación sean iguales
*/
  const validate_password = () => {
    if (!pass_input.current.value.replace(/\s/g, '') == "" && !pass_c.current.value.replace(/\s/g, '') == "") {
      if (pass_input.current.value != pass_c.current.value) {
        if (div_password_alert.classList.contains('d-none')) {
          div_password_alert.classList.remove('d-none');
          div_password_alert.classList.add('d-flex');
        }

        if (div_password_success.classList.contains('d-flex')) {
          div_password_success.classList.remove('d-flex');
          div_password_success.classList.add('d-none');
        }

      } else {

        if (div_password_alert.classList.contains('d-flex')) {
          div_password_alert.classList.remove('d-flex');
          div_password_alert.classList.add('d-none');
        }

        if (div_password_success.classList.contains('d-none')) {
          div_password_success.classList.remove('d-none');
          div_password_success.classList.add('d-flex');
        }

      }
    } else {

      if (div_password_alert.classList.contains('d-flex')) {
        div_password_alert.classList.remove('d-flex');
        div_password_alert.classList.add('d-none');
      }

      if (div_password_success.classList.contains('d-flex')) {
        div_password_success.classList.remove('d-flex');
        div_password_success.classList.add('d-none');
      }

    }
  }

  /**
* @function
* Petición HTTP que obtiene todas las regiones
* @returns {Array}
*/
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BACK_URL + "/api/region/get_regiones")
      .then((response) => {
        setRegiones(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  /**
* @function
* Petición HTTP que obtiene todas las comunas por región seleccionada
* @returns {Array}
*/
  useEffect(() => {
    if (selectedRegion !== "") {
      axios
        .get(
          process.env.REACT_APP_BACK_URL + `/api/comuna/get_comunas_by_region/${selectedRegion}`
        )
        .then((response) => {
          setComunas(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setComunas([]);
    }
  }, [selectedRegion]);

  /**
* @function
* Metodo que setea el useState con la región seleccionada
*/
  const handleRegionChange = (event) => {
    setSelectedRegion(event.target.value);
    setSelectedComuna("");
  };

  /**
* @function
* Metodo que setea el useState con la comuna seleccionada
*/
  const handleComunaChange = (event) => {
    setSelectedComuna(event.target.value);
  };


  var Fn = {
    validaRut: function (rutCompleto) {
      rutCompleto = rutCompleto.replace("‐", "-");
      if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto))
        return false;
      var tmp = rutCompleto.split('-');
      var digv = tmp[1];
      var rut = tmp[0];
      if (digv == 'K') digv = 'k';

      return (Fn.dv(rut) == digv);
    },
    dv: function (T) {
      var M = 0, S = 1;
      for (; T; T = Math.floor(T / 10))
        S = (S + T % 10 * (9 - M++ % 6)) % 11;
      return S ? S - 1 : 'k';
    }
  }

  const mostrarError = (msj) => {
    setMensajeError(msj);
    handleShowAgregado();
  };

  const contiene = (valor) => {
    var carac = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'Ñ', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'ñ', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', '.', ',', ';', ':', '-', '_', '{', '}', '[', ']', '|', '!', '"', '#', '$', '%', '&', '/', '(', ')', '=', '?', '¡', '¿', '*', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    for (var i = 0; i < valor.length; i++) {
      if (!carac.includes(valor.charAt(i)))
        return false;
    }
    return true;
  };

  /**
* @function
* Metodo para validar los datos que seran enviados por medio de la 
* Petición HTTP
*/
  const validarDatos = () => {
    var validCorrreo = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var validTelefono = /^([0-9])*$/;
    var validarHTML = /<("[^"]*"|'[^']*'|[^'">])*>/;

    if (name == "") {
      mostrarError("Complete nombre y apellido");
      return false;
    }

    if (name.split(" ").length < 2) {
      mostrarError("Debe ingresar nombre y apellido");
      return false;
    }

    let nombreSplit = name.split(" ");
    let conts = 0
    nombreSplit.forEach((n) => {
      if (n != "")
        conts++;
    });
    if (conts < 2) {
      mostrarError("Debe ingresar nombre y apellido");
      return false;
    }

    if (validarHTML.test(name)) {
      mostrarError("Debe ingresar un nombre y apellido válido");
      return false;
    }

    if (apellido != "") {
      if (validarHTML.test(apellido)) {
        mostrarError("Debe ingresar un apellido válido");
        return false;
      }
    }

    if (email == "" || !validCorrreo.test(email)) {
      mostrarError("Email no válido");
      return false;
    }
    if (email.toLowerCase().includes("@dreamtec.cl")) {
      mostrarError("Los correos @dreamtec.cl solo pueden ser usados como administrador");
      return false;
    }

    if (rut == "" || !Fn.validaRut(rut)) {
      mostrarError("Complete el rut");
      return false;
    }

    if (validarHTML.test(rut)) {
      mostrarError("Debe ingresar un rut válido");
      return false;
    }

    if (number == "" || !validTelefono.test(number)) {
      mostrarError("Telefono no válido");
      return false;
    }

    if (selectedRegion == "" || selectedRegion < 1) {
      mostrarError("Seleccione región");
      return false;
    }
    if (selectedComuna == "" || selectedComuna < 1) {
      mostrarError("Seleccione comuna");
      return false;
    }
    if (direccion == "") {
      mostrarError("Complete la direccion");
      return false;
    }

    if (validarHTML.test(direccion)) {
      mostrarError("Debe ingresar una dirección válida");
      return false;
    }

    if (password == "" || password.length < 8 || !contiene(password)) {
      mostrarError("Ingrese una contraseña que contenga: <br/>Al menos 8 caracteres, mayúsculas, minúsculas, números y símbolos");
      return false;
    }

    if (password != password2) {
      mostrarError("Las contraseñas deben coincidir");
      return false;
    }

    return true;
  };


  /**
* @function
* Petición HTTP para registrar los datos el nuevo usuario web
* @param {Object} formData formData con todos los datos nuevos del usuario web
* @returns {string}
*/
  const handleSubmit = (e) => {
    e.preventDefault();

    setRut(formatRut(rut));
    setNumber(number.substr(0, 9));

    if (validarDatos()) {
      const formData = new FormData();
      formData.append("username", email);
      formData.append("name", name);
      formData.append("email", email);
      formData.append("rut", rut);
      formData.append("telefono", number);
      formData.append("comuna", selectedComuna);
      formData.append("direccion", direccion);
      formData.append("password1", password);
      formData.append("password2", password2);
      formData.append("suegt", "nap"); // Invitado=no

      axios
        .post(process.env.REACT_APP_BACK_URL + "/api/usuario/register_usuario", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          setSuccessMessage("Registro exitoso");
          setTimeout(() => {
            document.getElementById("btn_closeidmodalregistro").click();
          }, 2000);
          setName("");
          setEmail("");
          setNumber("");
          setPassword("");
          setPassword2("");
          setMensajeError("Registrado correctamente, ya puede realizar el inicio de sesión");
          handleShowAgregado();
        })
        .catch((err) => {
          if (err.response.data.mensaje.username != "" && err.response.data.mensaje.username != null)
            if (err.response.data.mensaje.username == "Ya existe un usuario con el username " + email)
              mostrarError("Ya existe un usuario registrado con el correo: " + email);
        });
    }
  };

  return (

    <>

      <Modal show={showAgregado} onHide={handleCloseAgregado} centered={true}>
        <Modal.Header closeButton className="border-bottom-0">
        </Modal.Header>
        <Modal.Body>

          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 mb-3 text-center bg-black py-3">
                {mensajeError == "Registrado correctamente, ya puede realizar el inicio de sesión" ?
                  <>
                    <i class="fa-solid fa-circle-check text-success fs-1 mb-3 d-block"></i>
                  </>
                  :
                  <>
                    <i className="fa-solid fa-circle-xmark text-turq fs-1 mb-3 d-block"></i>
                    <span className="fs-6 text-white mt-1" id="titulo_error">ERROR AL INGRESAR DATOS</span>
                  </>
                }
              </div>
              <div className="col-lg-10 col-md-10 col-11 mb-2 text-center">
                <p className="fs-14 text-black mb-0" id="texto_error" >{htmlReactParser(mensajeError)}</p>
              </div>
              <div className="col-lg-6 col-md-7 col-8 bg-turq" style={{ height: "3px" }}>
              </div>
            </div>
          </div>

        </Modal.Body>
      </Modal>

      <div
        className="modal fade"
        id="modal-registro"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >

        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-bottom-0">
              {/* 
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="btn_closeidmodalregistro"
              ></button> */}

              <div className="container">
                <div className="row">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    id="btn_closeidmodalregistro"
                  ></button>
                </div>
                <div className="row justify-content-center text-center mt-4">
                  <div className="col-12">
                    <label className="fw-bold">Registrarme</label>
                  </div>
                </div>
              </div>

            </div>

            <div className="modal-body">

              <form>
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-lg-11 col-md-11 col-11 mb-3">
                      <label className="fs-6 text-black">
                        Nombre Completo o Razón Social<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        id="nombre_registro"
                        className="form-control rounded-pill"
                        placeholder="Ej: Dreamtec Empresa"
                        onKeyUp={keyupNombre}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>

                    {/* <div className="col-lg-11 col-md-11 col-11 mb-3">
                    <label htmlFor="apellido">
                      Apellido<span className="text-danger"></span>
                    </label>
                    <input
                      type="text"
                      className="form-control rounded-pill"
                      id="apellido"
                      onKeyUp={keyupNombre}
                      value={apellido}
                      onChange={(e) => setApellido(e.target.value)}
                      placeholder="Ingresa apellido"
                    />
                  </div> */}

                    <div className="col-lg-11 col-md-11 col-11 mb-3">
                      <label className="fs-6 text-black">
                        Correo electrónico<span className="text-danger">*</span>
                      </label>
                      <input
                        type="email"
                        id="correo_registro"
                        className="form-control rounded-pill"
                        placeholder="Ej: ejemplo@mail.com"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="col-lg-11 col-md-11 col-11 mb-3">
                      <label className="fs-6 text-black">
                        RUT (sin puntos y con guión)<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        id="rut_registro"
                        className="form-control rounded-pill"
                        placeholder="Ej: 9190555-8"
                        maxLength={10}
                        minLength={9}
                        onKeyUp={formatRutKey}
                        onInput={formatRutKey}
                        value={rut}
                        onChange={(e) => setRut(e.target.value)}
                      />
                    </div>
                    <div className="col-lg-11 col-md-11 col-11 mb-3">
                      <label className="fs-6 text-black">
                        Teléfono (9 dígitos)<span className="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        id="telefono_registro"
                        className="form-control rounded-pill"
                        placeholder="Ej: 987654321"
                        maxLength={10}
                        minLength={10}
                        onKeyUp={keyupTelefono}
                        onInput={keyupTelefono}
                        value={number}
                        onChange={(e) => setNumber(e.target.value)}
                      />
                    </div>
                    <div className="col-lg-11 col-md-11 col-11 mb-3">
                      <label className="fs-6 text-black" htmlFor="">
                        Región<span className="text-danger">*</span>
                      </label>
                      <select
                        className="form-select rounded-pill"
                        name="form-select"
                        id="region"
                        value={selectedRegion}
                        onChange={handleRegionChange}
                        placeholder="Seleccione una opción"
                      >
                        <option value="">Selecciona una región</option>
                        {regiones.map((region) => (
                          <option key={region.id} value={region.id}>
                            {region.nombre_region}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-lg-11 col-md-11 col-11 mb-3">
                      <label className="fs-6 text-black" htmlFor="">
                        Comuna<span className="text-danger">*</span>
                      </label>
                      <select
                        className="form-select rounded-pill"
                        name=""
                        id="comuna"
                        value={selectedComuna}
                        onChange={handleComunaChange}
                        disabled={comunas.length === 0}
                      >
                        <option value="">Selecciona una comuna</option>
                        {comunas.map((comuna) => (
                          <option key={comuna.id} value={comuna.id}>
                            {comuna.nombre_comuna}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-lg-11 col-md-11 col-11 mb-3">
                      <label className="fs-6 text-black" htmlFor="">
                        Dirección<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        id="direccion_registro"
                        className="form-control rounded-pill"
                        placeholder="Ingresa dirección"
                        value={direccion}
                        onKeyUp={keyupDireccion}
                        onChange={(e) => setDireccion(e.target.value)}
                      />
                    </div>
                    <div className="col-lg-11 col-md-11 col-11 mb-3">
                      <label className="fs-6 text-black">
                        Contraseña<span className="text-danger">*</span>
                      </label>
                      <div className="input-group input-group-pass rounded-pill">
                        <input
                          type="password"
                          id="password_registro"
                          className="form-control input-pass rounded-pill validate_password"
                          placeholder="Ingresa contraseña"
                          value={password}
                          ref={pass_input}
                          minLength={8}

                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <span
                          onClick={cambiarPass}
                          role="button"
                          id="ver_pass_registro"
                          className="input-group-text btn-ojo"
                        >

                          <i ref={i_pass}
                            className="fas fa-eye pe-none"
                          ></i>

                        </span>
                      </div>
                    </div>
                    <div className="col-lg-11 col-md-11 col-11 mb-2">
                      <label className="fs-6 text-black">
                        Confirmar Contraseña <span className="text-danger">*</span>
                      </label>
                      <div className="input-group input-group-pass rounded-pill">
                        <input
                          type="password"
                          id="password_conf_registro"
                          className="form-control input-pass rounded-pill validate_password"
                          placeholder="Confirmar la contraseña"
                          value={password2}
                          minLength={8}
                          ref={pass_c}
                          onKeyUp={validate_password}
                          onChange={(e) => setPassword2(e.target.value)}
                        />
                        <span
                          onClick={cambiarPassC}
                          role="button"
                          className="input-group-text btn-ojo"
                        >
                          <i ref={c_pass}
                            className="fas fa-eye pe-none"
                          ></i>
                        </span>
                      </div>
                    </div>
                    <div class="col-lg-11 col-md-11 mb-3 d-none" id="alerta_password">
                      <div class="text-danger fz-12">
                        <i class="fa-solid fa-circle-exclamation"></i>&nbsp;Las contraseñas no coinciden
                      </div>
                    </div>
                    <div class="col-lg-11 col-md-11 mb-3 d-none" id="success_password">
                      <div class="text-success fz-12">
                        <i class="fa-solid fa-circle-check"></i>&nbsp;Las contraseñas coinciden
                      </div>
                    </div>
                  </div>
                </div>
              </form>

            </div>

            <div className="modal-footer pb-4 border-top-0">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-7 d-grid gap-2 text-center text-danger fw-bold">

                    <button
                      id="btn_submit_registro"
                      type="submit"
                      aria-label="Registrarme"
                      className="rounded-pill btn-borde-turq py-1"
                      onClick={handleSubmit}
                    >
                      Registrarme
                    </button>

                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>


  );
};

export default SignUp;
