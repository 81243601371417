/**
 * Página Admin Personal Admin:
 * 
 * acá se Adminsitra el personal admin
 * 
 * @module AdminPersonalAdminjsx
 */

import { useEffect, useState } from "react";
import axios from "axios";

import { AdminSidebar } from "../../components";
import Mensaje from "../../components/Modales/Mensaje";

const PersonalAdmin = () => {


  /**
* @constant
* @name opciones
* @type {Array}
* 
* */
  const [opciones, setOpciones] = useState([]);

  /**
* @constant
* @name personal
* @type {Array}
* 
* */
  const [personal, setPersonal] = useState([]);

  /**
* @constant
* @name agregar
* @type {string}
* 
* */
  const [agregar, setAgregar] = useState("");

  /**
* @constant
* @name currentPage
* @type {number}
* 
* */
  const [currentPage, setCurrentPage] = useState(1);

  /**
* @constant
* @name totalPaginas
* @type {string}
* 
* */
  const [totalPaginas, setTotalPaginas] = useState('');

  /**
* @constant
* @name mensaje
* @type {string}
* 
* */
  const [mensaje, setMensaje] = useState("");


  const accessToekn = sessionStorage.getItem("access");


  useEffect(() => {
    obtenerUsuarios();
  }, [agregar, currentPage]);

  /**
* @function
* Petición HTTP que lista los roles 
* @returns {Array}
*/
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BACK_URL + "/api/admin/listar_roles", {
        headers: {
          Authorization: `Bearer ${accessToekn}`,
        },
      })
      .then((response) => {
        setOpciones(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);


  /**
* @function
* Petición HTTP que trae todos los usuarios personal
* @param {number} currentPage Pagina actual
* @returns {Array}
*/
  const obtenerUsuarios = async () => {
    const response = await axios.get(
      process.env.REACT_APP_BACK_URL + `/api/admin/listar_all_usuarios_personal?page=${currentPage}&size=10`, {
      headers: {
        Authorization: `Bearer ${accessToekn}`,
      },
    }).then(response => {

      setPersonal(response.data.results);
      setTotalPaginas(response.data.pages);

    }).catch(error => {
      console.log(error);
    })
  };

  return (

    <div>

      <Mensaje mensaje={mensaje} setMensaje={setMensaje} />
      <AdminSidebar />


      <div className="regular">
        <h1>Dreamtec - <span>Administrador de Personal</span></h1>

        <div className="shadow">

          <div className="row">

            <div className="col-12">

              <div className="row">

                {!agregar && (

                  <div className="col-12">
                    <div className="row">
                      <div className="col-12 text-center my-3">
                        <h2>Listado personal</h2>
                      </div>
                    </div>

                    <div>
                      {personal.length > 0 ? (

                        <div className="table-responsive">

                          <table className="table boder">
                            <thead className="p-4">
                              <tr>
                                <th scope="col"><div className="id text-center">ID</div></th>
                                <th scope="col"><div className="id text-center">Nombre</div></th>
                                <th scope="col"><div className="id text-center">Apellido</div></th>
                                <th scope="col"><div className="id text-center">Email</div></th>
                                <th scope="col"><div className="id text-center">Rol</div></th>
                                <th scope="col"><div className="id text-center">Fecha registro</div></th>
                              </tr>
                            </thead>
                            <tbody>
                              {personal?.map((usuario) => (
                                <tr key={usuario.id}>
                                  <th scope="row"><div className="number text-center">{usuario.id}</div></th>
                                  <td><div className="nameNumber text-center">{usuario.first_name}</div></td>
                                  <td><div className="nameNumber text-center">{usuario.last_name}</div></td>
                                  <td><div className="nameNumber text-center">{usuario.email}</div></td>
                                  <td><div className="nameNumber text-center">{usuario.rol}</div></td>
                                  <td><div className="nameNumber text-center">{usuario.fecha_registro}</div></td>
                                </tr>
                              ))}
                            </tbody>
                          </table>

                          <div className="row justify-content-end mb-3">
                            <div className="col-xxl-1 col-xl-1 col-lg-1 col-md-6 col-sm-6 col-6">

                              {currentPage > 1 && (
                                <button type="button" aria-label="Anterior" className="agregar border-0 btnCelesteDreamtec" onClick={() => setCurrentPage(currentPage - 1)}>
                                  <i class="fa-solid fa-circle-arrow-left fs-16 text-white"></i>
                                </button>
                              )}

                            </div>

                            <div className="col-xxl-1 col-xl-1 col-lg-1 col-md-6 col-sm-6 col-6">

                              {currentPage + 1 <= totalPaginas && (
                                <button type="button" aria-label="Siguiente" className="agregar border-0 btnCelesteDreamtec" onClick={() => setCurrentPage(currentPage + 1)}>
                                  <i class="fa-sharp fa-solid fs-16  fa-circle-arrow-right text-white"></i>
                                </button>
                              )}

                            </div>
                          </div>

                        </div>



                      ) : (
                        <div className="row my-5">
                          <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 fw-bold text-center">
                            <i className="fa-solid fa-circle-info"></i>&nbsp;No hay personal registrado
                          </div>
                        </div>

                      )}

                    </div>


                  </div>

                )}

              </div>

            </div>

          </div>
        </div>

      </div>


    </div>
  );
};

export default PersonalAdmin;
