import { createSlice } from '@reduxjs/toolkit';

const initialState = false;

const esBusquedaSlice = createSlice({
  name: 'boolean',
  initialState,
  reducers: {
    setBoolean: (state, action) => action.payload,
  },
});

export const { setBoolean } = esBusquedaSlice.actions;
export default esBusquedaSlice.reducer;
