
const FooterIndustrial = () => {
  return (
    <>
      <footer className="bg-black text-white container-fluid py-5">
        <div className="container pt-5">
          <div className="row">
            <div className="col-md-3">
              <h4 className="mb-5">CONTACTO</h4>
              <p className="mt-3 textfooter">
                <b>Teléfono</b> : (+56) 2 29257620
                <br></br>
                <br></br>
                <b>Whatsapp</b>: (+56) 9 42428870
                <br></br>
                <br></br>
                <b>Email:</b> contacto@dreamtec.cl
                <br></br>
                <br></br>
                <b>Dirección:</b> Av. Manuel Montt 1667, Local 2, Providencia,
                Santiago de Chile.
                <br></br>
                <br></br>
                <b>Horario:</b> Lunes-Sábado de 10:00am - 18:00pm
              </p>
            </div>
            <div className="col-md-3">
              <h4 className="mb-5">SERVICIO AL CLIENTE</h4>
              <p className="mt-3">
                <a
                  href="https://dreamtec.cl/terminos"
                  className="text-white text-decoration-none links_footer_industrial"
                >
                  Términos y condiciones
                </a>
                <br></br>
                <br></br>
                <a
                  href="https://dreamtec.cl/faq"
                  className="text-white text-decoration-none links_footer_industrial"
                >
                  Preguntas frecuentes
                </a>
                <br></br>
                <br></br>
                <a
                  href="https://dreamtec.cl/garantias"
                  className="text-white text-decoration-none links_footer_industrial"
                >
                  Garantía & Devoluciones
                </a>
                <br></br>
                <br></br>
                <a
                  href="https://dreamtec.cl/contacto"
                  className="text-white text-decoration-none links_footer_industrial"
                >
                  Contacto
                </a>
              </p>
            </div>
            <div className="col-md-3">
              <h4 className="mb-4">MÉTODOS DE PAGO</h4>
              <img
                src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/industrial/logos_footer.png"}
                alt="Métodos de pago"
                className="img-fluid"
              />
            </div>
            <div className="col-md-3 text-center ">
              <h4 className="mb-5">CONECTEMONOS</h4>
              <p className="text-start my-3">
                Accede a nuestro formulario
                <br />
                de contacto y
                <br />
                responderemos a la
                <br />
                brevedad tus dudas e
                <br />
                inquietudes.
              </p>
              <a href="/industrial/contacto" className="btn btn-primary_industrial mt-4">
                Contáctanos
              </a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default FooterIndustrial;
