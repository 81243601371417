import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

const DetalleCarroSlice = createSlice({
  name: 'detalleCarro',
  initialState,
  reducers: {
    setDetalleCarro: (state, action) => action.payload,
  },
});

export const { setDetalleCarro } = DetalleCarroSlice.actions;
export default DetalleCarroSlice.reducer;
