/**
 * Componente AgregarInvitado:
 * 
 * acá se muestra el modal para registrar el usuario invitado
 * 
 * @module ComponenteAgregarInvitadojsx
 */


import { useState, useEffect, useRef } from "react";
import axios from "axios";

import { useDispatch } from "react-redux";
//import { setFechaEntrega } from "../../redux/fechaEntregaSlice";
//import { setComunaSelected } from "../../redux/comunaSelectedSlice";
//import { setRegionSelected } from "../../redux/regionSelectedSlice";
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from "react-router-dom";

const ModalAgregarInvitado = () => {


  /**
* @constant
* @name nombre
* @type {string}
* 
* */

  const [nombre, setNombre] = useState("");

  /**
* @constant
* @name email
* @type {string}
* 
* */

  const [email, setEmail] = useState("");

  /**
* @constant
* @name telefono
* @type {string}
* 
* */
  const [telefono, setTelefono] = useState("");

  /**
* @constant
* @name rut
* @type {string}
* 
* */
  const [rut, setRut] = useState("");


  /**
* @constant
* @name direccion
* @type {string}
* 
* */
  const [direccion, setDireccion] = useState("");


  /**
* @constant
* @name regiones
* @type {Array}
* 
* */
  const [regiones, setRegiones] = useState([]);


  /**
* @constant
* @name comunas
* @type {Array}
* 
* */
  const [comunas, setComunas] = useState([]);

  /**
* @constant
* @name selectedRegion
* @type {string}
* 
* */
  const [selectedRegion, setSelectedRegion] = useState("");


  /**
* @constant
* @name selectedComuna
* @type {string}
* 
* */
  const [selectedComuna, setSelectedComuna] = useState("");

  /**
* @constant
* @name showAgregado
* @type {boolean}
* 
* */
  const [showAgregado, setShowAgregado] = useState(false);


  /**
* @constant
* @name usuarioId
* @type {string}
* 
* */

  const useTelefono = useRef();
  const [password, setPassword] = useState("123");
  const [password1, setPassword1] = useState("123");
  const [password2, setPassword2] = useState("123");

  /**
* @function
* Metodo para validar el largo y reemplazar etiquetas HTML del input
* @param {Element} rut Input
*/
  const formatRut = (rut) => {

    if (rut === '') {
      return rut;
    }

    const newRut = rut.replace(/\./g, '').replace(/\-/g, '').trim().toLowerCase();
    const lastDigit = newRut.substr(-1, 1);
    const rutDigit = newRut.substr(0, newRut.length - 1);
    let format = '';
    for (let i = rutDigit.length, j = 1; i > 0; i--, j++) {
      const e = rutDigit.charAt(i - 1);
      format = e.concat(format);
      // if (j % 3 === 0) {
      //     format = '.'.concat(format);
      // }
    }
    return format.concat('-').concat(lastDigit);
  }


  /**
* @function
* Metodo para validar el largo y reemplazar etiquetas HTML del input
* @param {Element} rut Input
*/
  const formatRutKey = (e) => {

    e.target.value = formatRut(e.target.value);

    if (e.target.value.length > 10) {
      e.target.value = e.target.value.substr(0, 10);
    }
    e.target.value = e.target.value.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g, '');

  }

  /**
* @function
* Metodo para reemplazar todos los digitos y los tags HTML
* @param {Element} nombre Input
*/
  const keyupNombre = (e) => {
    e.target.value = e.target.value.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g, '');
    e.target.value = e.target.value.replace(/\d/g, '');
  }

  /**
* @functionkeyupDireccion
* Metodo para reemplazar todos los digitos y los tags HTML
* @param {Element} nombre Input
*/
  const keyupDireccion = (e) => {
    e.target.value = e.target.value.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g, '');
  }


  /**
* @function
* Metodo para validar el valor y reemplazar caracteres especiales y tags HTML
* @param {Element} telefono Input
*/

  const keyupTelefono = (e) => {
    if (e.target.value.length > 10) {
      e.target.value = e.target.value.substr(0, 10);
    }
    e.target.value = e.target.value.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g, '');
    e.target.value = e.target.value.replace(/\,\-\+/g, '');
  }


  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleShowAgregado = () => setShowAgregado(true);
  const handleCloseAgregado = () => {
    setShowAgregado(false);
  }
  const [mensajeError, setMensajeError] = useState("");


  /**
* @function
* Petición HTTP para obtener todas las regiones
* 
*/
  const generateRandomId = () => {
    axios
      .get(process.env.REACT_APP_BACK_URL + "/api/usuario/register_usuario_id")
      .then((response) => {
        sessionStorage.setItem("IDusuario", response.data.id);
        return response.data.id;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /**
* @function
* Petición HTTP para obtener todas las regiones
* 
*/
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BACK_URL + "/api/region/get_regiones")
      .then((response) => {
        setRegiones(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  /**
* @function
* Petición HTTP para obtener todas comunas por la región seleccionada
* 
*/
  useEffect(() => {
    if (selectedRegion !== "") {
      axios
        .get(
          process.env.REACT_APP_BACK_URL + `/api/comuna/get_comunas_by_region/${selectedRegion}`
        )
        .then((response) => {
          setComunas(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setComunas([]);
    }
  }, [selectedRegion]);



  /**
* @function
* Metodo se setea el useState de la región selecionada
* 
*/
  const handleRegionChange = (event) => {
    setSelectedRegion(event.target.value);
    setSelectedComuna("");
  };


  /**
* @function
* Metodo se setea el useState de la comuna selecionada
* 
*/
  const handleComunaChange = (event) => {
    setSelectedComuna(event.target.value);
  };

  var Fn = {
    validaRut: function (rutCompleto) {
      rutCompleto = rutCompleto.replace("‐", "-");
      if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto))
        return false;
      var tmp = rutCompleto.split('-');
      var digv = tmp[1];
      var rut = tmp[0];
      if (digv == 'K') digv = 'k';

      return (Fn.dv(rut) == digv);
    },
    dv: function (T) {
      var M = 0, S = 1;
      for (; T; T = Math.floor(T / 10))
        S = (S + T % 10 * (9 - M++ % 6)) % 11;
      return S ? S - 1 : 'k';
    }
  }




  /**
* @function
* Metodo para validar los datos que seran enviados por medio de la 
* Petición HTTP
*/
  const validarDatos = () => {
    var validCorrreo = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var validTelefono = /^([0-9])*$/;
    var validarHTML = /<("[^"]*"|'[^']*'|[^'">])*>/;

    if (nombre == "") {
      mostrarError("Debe ingresar el nombre");
      return false;
    }

    if (nombre.split(" ").length < 2) {
      mostrarError("Debe ingresar nombre y apellido");
      return false;
    }

    let nombreSplit = nombre.split(" ");
    let conts = 0
    nombreSplit.forEach((n) => {
      if (n != "")
        conts++;
    });
    if (conts < 2) {
      mostrarError("Debe ingresar nombre y apellido");
      return false;
    }

    if (validarHTML.test(nombre)) {
      mostrarError("Debe ingresar un nombre válido");
      return false;
    }

    if (email == "" || !validCorrreo.test(email)) {
      mostrarError("Email no válido");
      return false;
    }


    if (email.toLowerCase().includes("@dreamtec.cl")) {
      mostrarError("Los correos @dreamtec.cl solo pueden ser usados como administrador");
      return false;
    }

    if (telefono == "" || !validTelefono.test(telefono)) {
      mostrarError("Telefono no válido");
      return false;
    }

    if (validarHTML.test(telefono)) {
      mostrarError("Debe ingresar un teléfono válido");
      return false;
    }

    if (rut == "") {
      mostrarError("Complete el RUT");
      return false;
    }

    if (!Fn.validaRut(rut)) {
      mostrarError("Debe ingresar un RUT válido");
      return false;
    }

    if (selectedRegion == "" || selectedRegion < 1) {
      mostrarError("Seleccione región");
      return false;
    }

    if (selectedComuna == "" || selectedComuna < 1) {
      mostrarError("Seleccione comuna");
      return false;
    }

    if (direccion == "") {
      mostrarError("Complete la direccion");
      return false;
    }

    if (validarHTML.test(direccion)) {
      mostrarError("Debe ingresar una dirección válida");
      return false;
    }

    return true;
  };


  const mostrarError = (msj) => {
    setMensajeError(msj);
    handleShowAgregado();
  };


  /**
* @function
* Petición HTTP que registra el usuario invitado
* @param {string} userAlready Id del usuario
* @param {string} nombre Nombre del usuario 
* @param {string} email Correo del usuario
* @param {string} telefono Teléfono del usuario
* @param {string} rut Rut del usuario
* @param {string} direccion Direccion del usuario
* @param {string} selectedComuna Id de la comuan seleccionada
*/
  const handleAgregarInvitado = () => {

    setRut(formatRut(rut));
    setTelefono(telefono.substr(0, 9));

    if (validarDatos()) {
      const formData = new FormData();
      formData.append("username", email);
      formData.append("name", nombre);
      formData.append("email", email);
      formData.append("rut", rut);
      formData.append("telefono", telefono);
      formData.append("comuna", selectedComuna);
      formData.append("direccion", direccion);
      formData.append("password", password);
      formData.append("password1", password1);
      formData.append("password2", password2);
      formData.append("idactual", sessionStorage.getItem("IDusuario"));
      formData.append("suegt", "hail"); // Invitado=si

      axios
        .post(process.env.REACT_APP_BACK_URL + "/api/usuario/register_usuario", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          // usuario: userAlready ? userAlready : usuarioId,
          // nombre: nombre,
          // email: email,
          // telefono: telefono,
          // rut: rut,
          // direccion: direccion,
          // comuna: selectedComuna,
          // invitado: 1,
        })
        .then((response) => {
          console.log(response);
          if (response.status == 200 && response.data.usuario.email == email) {
            sessionStorage.setItem("accessTokenWeb", response.data.accessTokenWeb);
            sessionStorage.setItem("IDusuario", response.data.usuario.id);
            navigate("/paso2");
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response.data.mensaje.username != "" && err.response.data.mensaje.username != null)
            if (err.response.data.mensaje.username == "Ya existe un usuario con el username " + email)
              mostrarError("Ya existe un usuario registrado con el correo: " + email);
        });
    }

  };

  return (


    <>

      <Modal show={showAgregado} onHide={handleCloseAgregado} centered={true}>
        <Modal.Header closeButton className="border-bottom-0">
        </Modal.Header>
        <Modal.Body>

          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 mb-3 text-center bg-black py-3">
                <i className="fa-solid fa-circle-xmark text-turq fs-1 mb-3 d-block"></i>
                <span className="fs-6 text-white mt-1" id="titulo_error">ERROR AL INGRESAR DATOS</span>
              </div>
              <div className="col-lg-10 col-md-10 col-11 mb-2 text-center">
                <p className="fs-14 text-black mb-0" id="texto_error">{mensajeError}</p>
              </div>
              <div className="col-lg-6 col-md-7 col-8 bg-turq" style={{ height: "3px" }}>
              </div>
            </div>
          </div>

        </Modal.Body>
      </Modal>


      <div
        className="modal fade"
        id="modal-sesion-invitado"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >



        <div className="modal-dialog">
          <div className="modal-content">

            <div className="modal-header border-bottom-0">
              <div className="container">
                <div className="row">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    id="btn_closeidmodal"
                  ></button>
                </div>
                <div className="row justify-content-center text-center mt-4">
                  <div className="col-12">
                    <label className="fw-bold">Continuar como invitado</label>
                  </div>
                </div>


              </div>
            </div>

            <div className="modal-body">
              <div className="container">
                <div className="row">
                  <div className="col-lg-11 col-md-11 col-11 mb-3">
                    <label className="fs-6 text-black" htmlFor="nombre">
                      Nombre Completo o Razón Social<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control rounded-pill"
                      id="nombre"
                      value={nombre}
                      onKeyUp={keyupNombre}
                      onChange={(e) => setNombre(e.target.value)}
                      placeholder="Ingresa nombre"
                      required
                    />
                  </div>

                  <div className="col-lg-11 col-md-11 col-11 mb-3">
                    <label htmlFor="email">
                      Email<span className="text-danger">*</span>
                    </label>
                    <input
                      required
                      type="email"
                      className="form-control rounded-pill"
                      id="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Ingresa email"
                    />
                  </div>

                  <div className="col-lg-11 col-md-11 col-11 mb-3">
                    <label htmlFor="telefono">
                      Teléfono (9 dígitos)<span className="text-danger">*</span>
                    </label>
                    <input ref={useTelefono}
                      required
                      type="number"
                      className="form-control rounded-pill"
                      id="telefono"
                      value={telefono}
                      maxLength={10}
                      minLength={10}
                      onKeyUp={keyupTelefono}
                      onInput={keyupTelefono}
                      onChange={(e) => setTelefono(e.target.value)}
                      placeholder="Ej: 987654321"
                    />
                  </div>

                  <div className="col-lg-11 col-md-11 col-11 mb-3">
                    <label htmlFor="rut">
                      RUT (sin puntos y con guión)<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      required
                      className="form-control rounded-pill"
                      id="rut"
                      value={rut}
                      maxLength={10}
                      minLength={9}
                      onKeyUp={formatRutKey}
                      onInput={formatRutKey}
                      onChange={(e) => setRut(e.target.value)}
                      placeholder="Ej: 9190555-8"
                    />
                  </div>

                  <div className="col-lg-11 col-md-11 col-11 mb-3">
                    <label className="d-xs-none" htmlFor="">
                      Región<span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-select rounded-pill mb-2"
                      name="form-select"
                      id="region"
                      value={selectedRegion}
                      onChange={handleRegionChange}
                      required
                      placeholder="Seleccione una opción"
                    >
                      <option value="">Selecciona una región</option>
                      {regiones.map((region) => (
                        <option key={region.id} value={region.id}>
                          {region.nombre_region}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-lg-11 col-md-11 col-11 mb-3">
                    <label className="d-xs-none" htmlFor="">
                      Comuna<span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-select rounded-pill "
                      name=""
                      id="comuna"
                      value={selectedComuna}
                      onChange={handleComunaChange}
                      disabled={comunas.length === 0}
                      required
                    >
                      <option value="">Selecciona una comuna</option>
                      {comunas.map((comuna) => (
                        <option key={comuna.id} value={comuna.id}>
                          {comuna.nombre_comuna}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-lg-11 col-md-11 col-11 mb-3">
                    <label htmlFor="direccion">
                      Dirección<span className="text-danger">*</span>
                    </label>
                    <input
                      required
                      type="text"
                      className="form-control rounded-pill"
                      id="direccion"
                      onKeyUp={keyupDireccion}
                      value={direccion}
                      onChange={(e) => setDireccion(e.target.value)}
                      placeholder="Ingresa dirección"
                    />
                  </div>

                  <div className="col-lg-11 col-md-11 col-11 mb-3">

                  </div>

                </div>
              </div>
            </div>
            <div className="modal-footer pb-4 border-top-0">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-7 text-center fw-bold">
                    <button
                      type="button"
                      className="btn w-100 bg-turq py-2 bg-turq btn-turq-hover text-white rounded-pill cursor-pointer"
                      onClick={handleAgregarInvitado}
                      disabled={!direccion}
                      data-bs-dismiss="modal"
                      aria-label="Continuar"
                    >
                      Continuar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default ModalAgregarInvitado;
