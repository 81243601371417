/**
 * Página Pefil:
 * 
 * acá se muestra la información del usuario que inicio sesión
 * 
 * @module PaginaPerfiljsx
 */

import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setTipoEnvio } from "../redux/tipoEnvioSlice";
import { setFechaEntrega } from "../redux/fechaEntregaSlice";
import { setComunaSelected } from "../redux/comunaSelectedSlice";

const Perfil = () => {

  /**
  * @constant
  * @name regiones
  * @type {Array}
  * 
  * */
  const [regiones, setRegiones] = useState([]);

  /**
* @constant
* @name comunas
* @type {Array}
* 
* */
  const [comunas, setComunas] = useState([]);

  /**
* @constant
* @name selectedRegion
* @type {string}
* 
* */
  const [selectedRegion, setSelectedRegion] = useState("");


  /**
* @constant
* @name selectedComuna
* @type {string}
* 
* */
  const [selectedComuna, setSelectedComuna] = useState("");

  /**
* @constant
* @name nombre
* @type {string}
* 
* */
  const [nombre, setNombre] = useState("");

  /**
* @constant
* @name lastName
* @type {string}
* 
* */
  const [lastName, setLastName] = useState("");

  /**
* @constant
* @name rut
* @type {string}
* 
* */
  const [rut, setRut] = useState("");

  /**
* @constant
* @name direcion
* @type {string}
* 
* */
  const [direcion, setDireccion] = useState("");

  /**
* @constant
* @name telefono
* @type {string}
* 
* */
  const [telefono, setTelefono] = useState("");

  /**
* @constant
* @name msg
* @type {string}
* 
* */
  const [msg, setMsg] = useState("");


  /**
* @constant
* @name userAlready
* @type {string}
* 
* */
  const [userAlready] = useState(sessionStorage.getItem("IDusuario"));

  /**
* @constant
* @name tipoEnvio
* @type {number}
* 
* */
  const tipoEnvio = useSelector((state) => state.tipoEnvio);


  const dispatch = useDispatch();


  /**
* @constant
* @name token
* @type {string}
* 
* */
  const token = sessionStorage.getItem("accessTokenWeb");

  const navigate = useNavigate();


  /**
* @function
* Petición HTTP que trae la dirección ingresada por el usuario anteriormente, 
* validando que inicio sesión y el tipo de envio sea distinto de 4 (Retiro en tienda)
* @param {string} userAlready Id del usuario
* @param {number} tipoEnvio Tipo de envio seleccionado anteriormente
*@returns {Array}
* 
*/
  useEffect(() => {
    if (userAlready && tipoEnvio !== 4) {
      axios
        .get(
          process.env.REACT_APP_BACK_URL + `/api/direccion/get_direccion/${userAlready}`
        )
        .then((response) => {
          dispatch(setTipoEnvio(response.data.direcicon.codigo_envio));
          dispatch(setFechaEntrega(response.data.direcicon.fecha));
          dispatch(setComunaSelected(response.data.direcicon.nombre_comuna));


        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [tipoEnvio]);

  useEffect(() => {
    if (!token) {
      navigate("/");
    }

    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    /**
* @function
* Petición HTTP que trae las regiones
*@returns {Array}
* 
*/


    axios
      .get(process.env.REACT_APP_BACK_URL + "/api/region/get_regiones")
      .then((response) => {
        setRegiones(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    /**
* @function
* Petición HTTP que trae los datos del usuario logueado
*@returns {Array}
* 
*/
    axios
      .get(process.env.REACT_APP_BACK_URL + "/api/usuario/get_usuario_web", config)
      .then((response) => {
        if (response.data.apellido && response.data.apellido != ".zzzzz.") {
          setNombre(response.data.nombre + " " + response.data.apellido)
        }
        else {
          setNombre(response.data.nombre);
        }

        //setLastName(response.data.apellido);
        setRut(response.data.rut);
        setTelefono(response.data.telefono);
        setDireccion(response.data.direccion);
        setSelectedRegion(response.data.region_id);
        setSelectedComuna(response.data.comuna_id);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);


  /**
* @function
* Petición HTTP que trae las comunas de la región seleccionada
* @param {string} selectedRegion región seleccionada en el select
*@returns {Array}
* 
*/
  useEffect(() => {
    if (selectedRegion !== "") {
      axios
        .get(
          process.env.REACT_APP_BACK_URL + `/api/comuna/get_comunas_by_region/${selectedRegion}`
        )
        .then((response) => {
          setComunas(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setComunas([]);
    }
  }, [selectedRegion]);

  /**
* @function
* Metodo que setea la región en el evento change
* @param {input} event input de región
* 
*/
  const handleRegionChange = (event) => {
    setSelectedRegion(event.target.value);
    setSelectedComuna("");
  };


  /**
* @function
* Metodo que setea la comuna en el evento change
* @param {input} event input de comuna
* 
*/
  const handleComunaChange = (event) => {
    setSelectedComuna(event.target.value);
  };


  /**
* @function
* Petición HTTP que edita los datos del usuario
* @param {string} nombre nombre del usuario
* @param {string} telefono teléfono del usuario
* @param {string} rut rut del usuario
* @param {string} direccion direccion del usuario
* @param {string} comuna comuna seleccionada por el usuario
* 
*/
  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = {
      nombre: nombre,
      //apellido: lastName,
      telefono: telefono,
      rut: rut,
      direccion: direcion,
      comuna: selectedComuna,
    };


    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };


    axios
      .post(process.env.REACT_APP_BACK_URL + "/api/usuario/editar_usuario_web", formData, config)
      .then((response) => {
        setMsg(response.data.Message);

        setTimeout(() => {
          setMsg("");
        }, 2000);
      })
      .catch((error) => {
        console.error(error); // Maneja el error en caso de que ocurra
        setMsg("Error al guardar los datos intente nuevamente.");

        setTimeout(() => {
          setMsg("");
        }, 2000);
      });
  };

  return (
    <div>


      <div className="container px-0 mt-5">
        <div className="row px-1">
          <div className="col-md-12 mb-3">
            <a className="fs-14 text-black" href="/">
              Inicio
            </a>
            <span className="text-black">
              <i className="fa-solid fa-angle-right px-2 fs-12"></i>
            </span>
            <span className="fs-14 text-black">Mi cuenta</span>
            <span className="text-black">
              <i className="fa-solid fa-angle-right px-2 fs-12"></i>
            </span>
            <span className="fw-bold fs-18 text-turq">Perfil</span>
          </div>
        </div>

        <hr />

        <h1 className="text-black fw-bold my-4 text-center">
          EDITAR MI PERFIL
        </h1>
      </div>

      <div className="container mt-4 mb-5">

        <div className="row">
          <div className="col-12">
            <form onSubmit={handleSubmit}>
              <div className="row justify-content-center align-items-center mt-3">
                <div className="col-lg-5 col-md-6 col-11 mb-3">
                  <span className="fs-14 text-black">Nombre</span>
                  <input
                    type="text"
                    className="form-control rounded-pill"
                    value={nombre}
                    //pattern="[A-Za-z\u00C0-\u017F´']+"
                    onChange={(e) => {
                      setNombre(e.target.value);
                    }}
                    placeholder="Ej: Dreamtec"
                    required
                  />
                </div>
                {/* <div className="col-lg-5 col-md-6 col-11 mb-3">
                  <span className="fs-14 text-black">Apellido</span>
                  <input
                    type="text"
                    className="form-control rounded-pill"
                    value={lastName}
                    pattern="[A-Za-z\u00C0-\u017F´']+"
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                    placeholder="Ej: Dreamtec"
                  />
                </div> */}

                <div className="col-lg-5 col-md-6 col-11 mb-3">
                  <span className="fs-14 text-black">RUT</span>
                  <input
                    type="text"
                    maxLength="10"
                    minLength="9"
                    className="form-control rounded-pill"
                    disabled
                    value={rut}
                    onChange={(e) => {
                      setRut(e.target.value);
                    }}
                    placeholder="Ej: 12345678-9"
                  />
                </div>
                <div className="col-lg-5 col-md-6 col-11 mb-3">
                  <label className="d-xs-none" htmlFor="">
                    Región<span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-select rounded-pill mb-2"
                    name="form-select"
                    id="region"
                    value={selectedRegion}
                    onChange={handleRegionChange}
                    required
                    placeholder="Seleccione una opción"
                  >
                    <option value="">Selecciona una región</option>
                    {regiones.map((region) => (
                      <option key={region.id} value={region.id}>
                        {region.nombre_region}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-lg-5 col-md-6 col-11 mb-3">
                  <label className="d-xs-none" htmlFor="">
                    Comuna<span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-select rounded-pill "
                    name=""
                    id="comuna"
                    value={selectedComuna}
                    onChange={handleComunaChange}
                    disabled={comunas.length === 0}
                    required
                  >
                    <option value="">Selecciona una comuna</option>
                    {comunas.map((comuna) => (
                      <option key={comuna.id} value={comuna.id}>
                        {comuna.nombre_comuna}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-lg-5 col-md-6 col-11 mb-3">
                  <span className="fs-14 text-black">Dirección</span>
                  <input
                    type="text"
                    className="form-control rounded-pill "
                    value={direcion}
                    onChange={(e) => {
                      setDireccion(e.target.value);
                    }}
                    placeholder="Ej: Av. Dreamtec 123"
                    required
                  />
                </div>
                <div className="col-lg-5 col-md-6 col-11 mb-3">
                  <span className="fs-14 text-black">Teléfono (9 dígitos)</span>
                  <input
                    type="tel"
                    maxLength="9"
                    minLength="9"
                    pattern="[0-9]{9}"
                    value={telefono}
                    onChange={(e) => {
                      setTelefono(e.target.value);
                    }}
                    className="form-control rounded-pill"
                    placeholder="Ej: 987654321"
                    required
                  />
                </div>

                {/* <div className="col-lg-5 col-md-6 col-11 mb-3">
                <span className="fs-14 text-black">Correo electrónico</span>
                <input
                  type="email"
                  className="form-control rounded-pill"
                  value={email}
                  onChange={(e) => {
                    setEmal(e.target.value);
                  }}
                  placeholder="Ej: dreamtec@mail.com"
                />
              </div> */}

                <div className="col-lg-5 col-md-10 col-11 mb-0 d-grid gap-2">
                  <button
                    type="submit"
                    aria-label="Guardar datos personales"
                    className="btn-borde-turq rounded-pill text-turq py-1 px-4"
                  >
                    Guardar datos personales
                  </button>

                </div>

                <div className="col-12 text-center">
                  <span className="text-center fw-bold text-success">{msg}</span>
                </div>

              </div>
            </form>
          </div>
        </div>

      </div>



    </div>
  );
};

export default Perfil;
