/**
 * Página Terminos:
 * 
 * acá se muestran los terminos y condiciones
 * 
 * @module PaginaTerminosjsx
 */



import { useState, useEffect, useRef } from "react";
import axios from "axios";

import { useSelector, useDispatch } from "react-redux";

import { setTipoEnvio } from "../../redux/tipoEnvioSlice";
import { setFechaEntrega } from "../../redux/fechaEntregaSlice";
import { setComunaSelected } from "../../redux/comunaSelectedSlice";
import { Helmet } from "react-helmet";

const Terminas = () => {

  /**
* @constant
* @name userAlready
* @type {string}
* 
* */

  const [userAlready] = useState(sessionStorage.getItem("IDusuario"));


  /**
* @constant
* @name tipoEnvio
* @type {string}
* 
* */
  const tipoEnvio = useSelector((state) => state.tipoEnvio);


  const dispatch = useDispatch();

  /**
* @function
* Petición HTTP que trae la dirección ingresada por el usuario anteriormente, 
* validando que inicio sesión y el tipo de envio sea distinto de 4 (Retiro en tienda)
* @param {string} userAlready Id del usuario
* @param {number} tipoEnvio Tipo de envio seleccionado anteriormente
*@returns {Array}
* 
*/
  useEffect(() => {
    if (userAlready && tipoEnvio !== 4) {
      axios
        .get(
          process.env.REACT_APP_BACK_URL + `/api/direccion/get_direccion/${userAlready}`
        )
        .then((response) => {
          dispatch(setTipoEnvio(response.data.direcicon.codigo_envio));
          dispatch(setFechaEntrega(response.data.direcicon.fecha));
          dispatch(setComunaSelected(response.data.direcicon.nombre_comuna));

        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [tipoEnvio]);

  return (

    <>
      <Helmet>
        <link rel="canonical" href={process.env.REACT_APP_FRONT_URL + "/terminos"} />
      </Helmet>
      <div class="container mt-3 mb-4">
        <div class="row justify-content-center">
          <div class="col-lg-10 col-md-10 col-11 px-1">
            <a class="fs-14 text-black text-decoration-none" href="/">Inicio</a>
            <span class="text-black"><i class="fa-solid fa-angle-right px-2 fs-12"></i></span>
            <span class="fw-bold fs-6 text-turq">Términos y condiciones</span>
          </div>
        </div>
      </div>

      <div class="container mb-5 mt-4">
        <div class="row justify-content-center">
          <div class="col-lg-10 col-md-10 col-sm-11 col-12">
            <div class="row">
              <div class="col-12 text-center">
                <h1 class="h1 text-black fw-bold fs-18">Términos y condiciones</h1>
              </div>
              <div class="col-12 text-center">
                <div class="text-dark fw-normal fs-6 o fs-14 text-start">

                  <p>I.-Ley del Consumidor.</p>

                  <br></br>

                  <p>La Ley del Consumidor no contempla ninguna obligación a los proveedores respecto a otorgar devoluciones de dinero o efectuar cambios de productos, salvo cuando se trate de fallas o situaciones en que proceda la garantía legal.</p>

                  <br></br>

                  <p>II.- Servicio Técnico.               <br></br>Todo producto que presente fallas técnicas de fábrica o durante su uso, será enviado a servicio técnico (sin excepción), para validar lo indicado por el cliente, entregando un plazo de respuesta establecido según indique el fabricante.</p>

                  <br></br>

                  <p>III.- Compras Presenciales o Digitales               <br></br>Compras realizadas en la tienda comercial Dreamtec, a través de la página web&nbsp;<a href="https://dreamtec.cl">dreamtec.cl</a>, MercadoLibre, Marketplace o cualquier medio digital.</p>

                  <br></br>

                  <p>De acuerdo a la facultad que confiere el artículo 3 BIS de la Ley del Consumidor, Dreamtec no otorga el derecho de retracto o garantía de satisfacción en ningún tipo de compras, bajo ninguna circunstancia.</p>

                  <br></br>

                  <p>IV.-Devolución de Dinero.<br></br>En caso de que corresponda realizar la restitución del dinero a nuestros clientes, por cualquier motivo o causal indicado en el punto I, Dreamtec dispondrá para dicha gestión de un plazo máximo de 15 días corridos desde la emisión de la correspondiente nota de crédito.</p>

                  <br></br>

                  <p>En los casos de compras que se efectúan mediante tarjeta de crédito, ya sea bancaria o de casa comercial, se entenderá cumplida la gestión de devolución una vez emitida la comunicación a la plataforma dispuesta por la entidad emisora de la tarjeta solicitando se procese la anulación de la compra. Dreamtec no se hará responsable por el retraso de los emisiones de tarjeta bancaria respecto de las restituciones de los fondos una vez solicitada la referida anulación.</p>

                  <br></br>

                  <p>En cuanto a devoluciones por compras efectuadas mediante tarjeta de débito, de casa comercial o bien, mediante transferencia electrónica, Dreamtec se contactará con el cliente mediante correo electrónico o vía telefónica para comunicar la emisión de la respectiva nota de crédito junto con confirmar los datos bancarios de la cuenta a la cual se realizará la transferencia, restitución que se realizará en el plazo señalado en el párrafo anterior. La devolución se realizará siempre a la cuenta desde la cual se generó la transferencia o pago asociado a la compra (sin excepción).</p>

                  <p></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>




  );
};

export default Terminas;
