
import { createSlice } from '@reduxjs/toolkit';

const initialState = 0;

const idCarroSlice = createSlice({
  name: 'idCarro',
  initialState,
  reducers: {
    setIdCarro: (state, action) => action.payload,
  },
});

export const { setIdCarro } = idCarroSlice.actions;
export default idCarroSlice.reducer;
