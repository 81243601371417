/**
 * Página Admin Usuarios:
 * 
 * acá se visualizan los usuarios web y pdv
 * 
 * @module AdminUsuariosjsx
 */


import { useState, useEffect } from "react";
import axios from "axios";

import { AdminSidebar } from "../../components";

const Usuarios = () => {


  /**
* @constant
* @name selectedButton
* @type {string}
* 
* */

  const [selectedButton, setSelectedButton] = useState("web"); // Estado para almacenar el botón seleccionado


  /**
* @constant
* @name usuariosWeb
* @type {Array}
* 
* */
  const [usuariosWeb, setUsuariosWeb] = useState([]);


  /**
* @constant
* @name usuariosPos
* @type {Array}
* 
* */
  const [usuariosPos, setUsuariosPos] = useState([]);

  /**
* @constant
* @name currentPageWeb
* @type {number}
* 
* */
  const [currentPageWeb, setCurrentPageWeb] = useState(1);

  /**
* @constant
* @name currentPagePos
* @type {number}
* 
* */

  const [currentPagePos, setCurrentPagePos] = useState(1);

  /**
* @constant
* @name totalPaginasWeb
* @type {string}
* 
* */

  const [totalPaginasWeb, setTotalPaginasWeb] = useState('');

  /**
* @constant
* @name totalPaginasPOS
* @type {string}
* 
* */
  const [totalPaginasPOS, setPaginasPOS] = useState('');

  const handleButtonClick = (buttonType) => {
    setSelectedButton(buttonType);
  };

  const accessToekn = sessionStorage.getItem("access");


  /**
* @function
* Petición HTTP que obtiene los usuarios web filtrados por pagina y total
* @param {number} currentPageWeb Pagina actual
* @returns {Array}
*/

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(
        process.env.REACT_APP_BACK_URL + `/api/admin/listar_usuarios_web?page=${currentPageWeb}&size=20`, {
        headers: {
          Authorization: `Bearer ${accessToekn}`,
        },
      }).then(response => {

        setUsuariosWeb(response.data.results);
        setTotalPaginasWeb(response.data.pages);

      }).catch(error => {
        console.log(error);
      })
    };

    fetchData();
  }, [currentPageWeb]);


  /**
* @function
* Petición HTTP que obtiene los usuarios de punto de venta filtrados por pagina y total
* @param  {number} currentPageWeb Pagina actual
* @returns {Array}
*/

  useEffect(() => {
    const fetchData = async () => {

      const response = await axios.get(
        process.env.REACT_APP_BACK_URL + `/api/admin/listar_usuarios_punto_venta?page=${currentPagePos}&size=50`, {
        headers: {
          Authorization: `Bearer ${accessToekn}`,
        },
      }).then(response => {

        setUsuariosPos(response.data.results);
        setPaginasPOS(response.data.pages);

      }).catch(error => {
        console.log(error);
      })
    };
    fetchData();
  }, [currentPagePos]);


  return (
    <div>

      <AdminSidebar />

      <div className="regular">
        <h1>Dreamtec - <span>Administrador de Usuarios</span></h1>

        <div className="shadow">

          <div className="row">

            <div className="col-12">

              <div className="row">

                <div className="col-12 text-center">
                  <h2>Listar Usuarios</h2>
                </div>

                <div className="col-md-6 col-sm-12  mt-5 pt-3 pb-3">
                  <button type="button" aria-label="Usuarios Web"
                    className="agregar"
                    style={{
                      backgroundColor:
                        selectedButton === "web" ? "#0DCAF0" : "",
                      color: selectedButton === 'web' ? 'white' : "",
                    }}
                    onClick={() => handleButtonClick("web")}
                  >
                    <i class="fa-solid fa-globe"></i>&nbsp; Usuarios web
                  </button>
                </div>
                <div className="col-md-6 col-sm-12  mt-5 pt-3 pb-3">
                  <button
                    className="agregar"
                    style={{
                      backgroundColor:
                        selectedButton === "pos" ? "#0DCAF0" : "",
                      color: selectedButton === 'pos' ? 'white' : "",
                    }}
                    onClick={() => handleButtonClick("pos")}
                  >
                    <i class="fa-sharp fa-solid fa-computer"></i>&nbsp;Usuarios POS
                  </button>
                </div>

              </div>
            </div>
            <div className="col-12">

              {selectedButton === "web" && (

                <div className="row">
                  <div className="col-12">

                    {usuariosWeb.length > 0 ? (

                      <div className="table-responsive">
                        <table className="table border">
                          <thead className="p-4">
                            <tr>
                              <th className="col"><div className="id">ID</div></th>
                              <th className="col"><div className="id">Nombre</div></th>
                              <th className="col"><div className="id">Apellido</div></th>
                              <th className="col"><div className="id">Email</div></th>
                              <th className="col"><div className="id">Teléfono</div></th>
                              <th className="col"><div className="id">Fecha Registro</div></th>
                            </tr>
                          </thead>
                          <tbody>
                            {usuariosWeb
                              ? usuariosWeb.map((usuario) => (
                                <tr key={usuario.id}>
                                  <th className="row"><div className="nameNumber text-center">{usuario.id}</div></th>
                                  <td><div className="nameNumber text-center">{usuario.nombre ? usuario.nombre : 'N/A'}</div></td>
                                  <td><div className="nameNumber text-center">{usuario.apellido ? usuario.apellido : "N/A"}</div></td>
                                  <td><div className="nameNumber text-center">{usuario.email ? usuario.email : "N/A"}</div></td>
                                  <td><div className="nameNumber text-center">{usuario.telefono ? usuario.telefono : "N/A"}</div></td>
                                  <td><div className="nameNumber text-center">{usuario.fecha ? usuario.fecha : "N/A"}</div></td>
                                </tr>
                              ))
                              : ""}
                          </tbody>
                        </table>

                        <div className="row justify-content-end mb-3">
                          <div className="col-xxl-1 col-xl-1 col-lg-1 col-md-6 col-sm-6 col-6">
                            {currentPageWeb > 1 && (
                              <button type="button" aria-label="Anterior" className="agregar border-0 btnCelesteDreamtec" onClick={() => setCurrentPageWeb(currentPageWeb - 1)}>
                                <i class="fa-solid fa-circle-arrow-left fs-16 text-white"></i>
                              </button>
                            )}
                          </div>

                          <div className="col-xxl-1 col-xl-1 col-lg-1 col-md-6 col-sm-6 col-6">
                            {currentPageWeb + 1 <= totalPaginasWeb && (
                              <button type="button" aria-label="Siguiente" className="agregar border-0 btnCelesteDreamtec" onClick={() => setCurrentPageWeb(currentPageWeb + 1)}>
                                <i class="fa-sharp fa-solid fs-16  fa-circle-arrow-right text-white"></i>
                              </button>
                            )}
                          </div>
                        </div>

                      </div>

                    ) : (


                      <div className="row my-5">
                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 fw-bold text-center">
                          <i className="fa-solid fa-circle-info"></i>&nbsp;No hay Usuarios Web registrados
                        </div>
                      </div>

                    )}

                  </div>
                </div>

              )}


              {selectedButton === "pos" && (


                <div className="row">
                  <div className="col-12">

                    {usuariosPos.length > 0 ? (

                      <div className="table-responsive">
                        <table className="table border">
                          <thead className="p-4">
                            <tr>
                              <th className="col"><div className="id">ID</div></th>
                              <th className="col"><div className="id">Nombre</div></th>
                              <th className="col"><div className="id">Apellido</div></th>
                              <th className="col"><div className="id">Email</div></th>
                              <th className="col"><div className="id">Teléfono</div></th>
                              <th className="col"><div className="id">Fecha</div></th>
                            </tr>
                          </thead>
                          <tbody>
                            {usuariosPos
                              ? usuariosPos.map((usuario) => (
                                <tr key={usuario.id}>
                                  <th className="row"><div className="nameNumber text-center">{usuario.id}</div></th>
                                  <td><div className="nameNumber text-center">{usuario.nombre ? usuario.nombre : "N/A"}</div></td>
                                  <td><div className="nameNumber text-center">{usuario.apellido ? usuario.apellido : "N/A"}</div></td>
                                  <td><div className="nameNumber text-center">{usuario.correo ? usuario.correo : "N/A"}</div></td>
                                  <td><div className="nameNumber text-center">{usuario.telefono ? usuario.telefono : "N/A"}</div></td>
                                  <td><div className="nameNumber text-center">{usuario.fecha ? usuario.fecha : "N/A"}</div></td>
                                </tr>
                              ))
                              : ""}
                          </tbody>
                        </table>

                        <div className="row justify-content-end mb-3">
                          <div className="col-xxl-1 col-xl-1 col-lg-1 col-md-6 col-sm-6 col-6">

                            {currentPagePos > 1 && (
                              <button type="button" aria-label="Anterior" className="agregar border-0 btnCelesteDreamtec" onClick={() => setCurrentPagePos(currentPagePos - 1)}>
                                <i class="fa-solid fa-circle-arrow-left fs-16 text-white"></i>
                              </button>
                            )}

                          </div>

                          <div className="col-xxl-1 col-xl-1 col-lg-1 col-md-6 col-sm-6 col-6">

                            {currentPagePos + 1 <= totalPaginasPOS && (
                              <button type="button" aria-label="Siguiente" className="agregar border-0 btnCelesteDreamtec" onClick={() => setCurrentPagePos(currentPagePos + 1)}>
                                <i class="fa-sharp fa-solid fs-16  fa-circle-arrow-right text-white"></i>
                              </button>
                            )}

                          </div>
                        </div>

                      </div>


                    ) : (


                      <div className="row my-5">
                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 fw-bold text-center">
                          <i className="fa-solid fa-circle-info"></i>&nbsp;No hay Usuarios POS registrados
                        </div>
                      </div>

                    )}

                  </div>
                </div>

              )}

            </div>
          </div>
        </div>
      </div>



    </div>
  );
};

export default Usuarios;
