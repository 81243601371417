
import { createSlice } from '@reduxjs/toolkit';

const initialState = false;

const LoMasVendSlicer = createSlice({
  name: 'lomasvendido',
  initialState,
  reducers: {
    setLomasvendido: (state, action) => action.payload,
  },
});

export const { setLomasvendido } = LoMasVendSlicer.actions;
export default LoMasVendSlicer.reducer;
