/**
 * Página Pago 3:
 * 
 * acá se muestra el paso 3 del carrito
 * 
 * @module PaginaPaso3jsx
 */

import { useState, useEffect } from "react";
import axios from "axios";
import "./paso3.css";

import { useNavigate } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';

import { useDispatch } from "react-redux";
import { setDetalleCarro } from "../redux/DetalleCarroSlice";
import { setNumeroOrden } from "../redux/NumeroOrdenSlice";
import { setTipoEnvio } from "../redux/tipoEnvioSlice";
import crypto from 'crypto-js';

const Paso3 = () => {
  const tipoPagoMapping = {
    "transferencias": 1,
    "mercado-pago": 0,
    "flow": 2
  };

  /**
* @constant
* @name usuarioId
* @type {string}
* 
*/

  const [usuarioId, setUsuarioId] = useState("");


  /**
* @constant
* @name envio
* @type {string}
* 
*/
  const [envio, setEnvio] = useState("");


  /**
* @constant
* @name fechaEntrega
* @type {string}
* 
*/
  const [fechaEntrega, setFechaEntrega] = useState("");

  /**
* @constant
* @name iva
* @type {string}
* 
*/
  const [iva, setIva] = useState("");


  /**
* @constant
* @name ivaDesc
* @type {string}
* 
*/
  const [ivaDesc, setIvaDesc] = useState("");

  /**
* @constant
* @name total
* @type {string}
* 
*/
  const [total, setTotal] = useState("");

  /**
* @constant
* @name totalDesc
* @type {string}
* 
*/
  const [totalDesc, setTotalDesc] = useState("");

  /**
* @constant
* @name totalConEnvio
* @type {string}
* 
*/
  const [totalConEnvio, setTotalConEnvio] = useState("");

  /**
* @constant
* @name totalConEnvioDesc
* @type {string}
* 
*/
  const [totalConEnvioDesc, setTotalConEnvioDesc] = useState("");

  /**
* @constant
* @name userAlready
* @type {string}
* 
*/
  const [userAlready, setUserAlready] = useState(
    sessionStorage.getItem("IDusuario")
  );

  /**
* @constant
* @name showFoto
* @type {boolean}
* 
*/
  const [showFoto, setShowFoto] = useState(false);

  /**
* @constant
* @name selectedOption
* @type {boolean}
* 
*/
  const [selectedOption, setSelectedOption] = useState("");

  /**
* @constant
* @name tdocumentos
* @type {number}
* 
*/
  const [tdocumento, setTdocumento] = useState(0);

  /**
* @constant
* @name usarDatos
* @type {number}
* 
*/
  const [usarDatos, setUsarDatos] = useState(1);

  /**
* @constant
* @name datosUsuario
* @type {string}
* 
*/
  const [datosUsuario, setDatosUsuario] = useState("");

  /**
* @constant
* @name datosUsuario2
* @type {string}
* 
*/
  const [datosUsuario2, setDatosUsuario2] = useState("");

  /**
* @constant
* @name regiones
* @type {string}
* 
*/
  const [regiones, setRegiones] = useState("");


  /**
* @constant
* @name comunas
* @type {string}
* 
*/
  const [comunas, setComunas] = useState("");

  /**
* @constant
* @name comunas2
* @type {string}
* 
*/
  const [comunas2, setComunas2] = useState("");

  /**
* @constant
* @name direccionId
* @type {string}
* 
*/
  const [direccionId, setDireccionId] = useState("");

  /**
* @constant
* @name direccion
* @type {string}
* 
*/
  const [direccion, setDireccion] = useState("");

  /**
* @constant
* @name direccion2
* @type {string}
* 
*/
  const [direccion2, setDireccion2] = useState("");

  /**
* @constant
* @name giro
* @type {string}
* 
*/
  const [giro, setGiro] = useState("");

  /**
* @constant
* @name linkPago
* @type {string}
* 
*/

  const [linkPago, setLinkPago] = useState("");
  const [tipoEnvioInteligente, setTipoEnvioInteligente] = useState("");
  const [mensajeError, setMensajeError] = useState("");
  const [mensajeMayor, setMensajeMayor] = useState("SELECCIONE TIPO DE DOCUMENTO");


  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleOptionChange = (event) => {
    let valor = event.target.value;
    if (valor == "transferencias" || valor == "mercado-pago" || valor == "flow")
      setSelectedOption(valor);
  };

  const [showAgregado, setShowAgregado] = useState(false);
  const handleShowAgregado = () => setShowAgregado(true);
  const handleCloseAgregado = () => {
    setShowAgregado(false);
  }

  const handleCloseFoto = () => {
    setShowFoto(false);
  }
  const handleShowFoto = () => setShowFoto(true);

  const searchParams = new URLSearchParams(document.location.search);
  let getted = searchParams.get("returned");



  /**
* @function
* Petición HTTP que trae las regiones
*/
  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_BACK_URL + `/api/region/get_regiones`
      )
      .then((res) => {
        setRegiones(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  /**
* @function
* Petición HTTP que trae las comunas por región
* @param {string} comuna 
*/
  const traeComunas = (comuna) => {
    var consultar = "";
    if (comuna == 0) {
      var region = document.querySelector("region");
      consultar = region.value;
    }
    else
      consultar = comuna;
    axios
      .get(
        process.env.REACT_APP_BACK_URL + `/api/comuna/get_comunas_by_region/${consultar}`
      )
      .then((res) => {
        setComunas(res.data);
        if (comunas2 == "")
          setComunas2(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (sessionStorage.getItem("accessTokenWeb") === null) {
      sessionStorage.clear();
      navigate("/");
    }

    if (!sessionStorage.getItem("LocalTime")) {
      sessionStorage.clear();
      navigate("/");
    }

    if (!sessionStorage.getItem("tzm")) {
      navigate("/paso2");
    }
    else {
      if (sessionStorage.getItem("tzm") != crypto.SHA256(sessionStorage.getItem("LocalTime") + userAlready + sessionStorage.getItem("LocalTime")).toString()) {
        sessionStorage.clear();
        navigate("/");
      }
    }

    /** 
      *  @function
   * Validación para obtener los datos personales del usuario y su resumen de comprar si es que esta logueado
   * @param {string} userAlready Id del usuario logueado
   */
    if (userAlready) {
      axios
        .get(process.env.REACT_APP_BACK_URL + `/api/usuario/get_usuario/${userAlready}`)
        .then((res) => {
          setDireccionId(res.data.direccion.id);
          setDatosUsuario(res.data.usuario);
          setDatosUsuario2(res.data.usuario);
          setDireccion(res.data.direccion);
          setDireccion2(res.data.direccion);
          traeComunas(res.data.direccion.region_id);
          axios
            .get(
              process.env.REACT_APP_BACK_URL + `/api/compra/get_resumen_compra/${userAlready}/${res.data.direccion.comuna_id}`
            )
            .then((res) => {
              setEnvio(res.data.normal.envio);
              setFechaEntrega(res.data.normal.fecha_entrega);
              setIva(res.data.normal.iva);
              setTotal(res.data.normal.total);
              setTotalConEnvio(res.data.normal.total_con_envio);
              setIvaDesc(res.data.descuento.iva);
              setTotalDesc(res.data.descuento.total);
              setTotalConEnvioDesc(res.data.descuento.total_con_envio);
              setTipoEnvioInteligente(res.data.tipo_envio_inteligente);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);


  /** 
  *  @function
* Metodo para validar los datos del formulario
*/
  const validaDatosDocumento = () => {
    var validCorrreo = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var validTelefono = /^(\+?56)?(\s?)(0?9)(\s?)[987654321]\d{7}$/;

    if (tdocumento != 1 && tdocumento != 2) {
      document.querySelector('#ancla-boleta-factura').scrollIntoView({
        behavior: 'smooth'
      });
      document.querySelector("#alerta-boletaFactura").style.display = "block";
      setMensajeError("Por favor seleccione Boleta o Factura");
      handleShowAgregado();
      return false;
    }

    if (datosUsuario.nombre == "") {
      mostrarError("nombre");
      return false;
    }

    if (datosUsuario.rut == "" || !Fn.validaRut(datosUsuario.rut)) {
      mostrarError("rut");
      return false;
    }
    if (datosUsuario.email == "" || !validCorrreo.test(datosUsuario.email)) {
      mostrarError("email");
      return false;
    }
    if (datosUsuario.telefono == "" || !validTelefono.test(datosUsuario.telefono)) {
      mostrarError("telefono");
      return false;
    }
    if (direccion.region_id == "" || direccion.region_id < 1) {
      mostrarError("region_id");
      return false;
    }
    if (direccion.comuna_id == "" || direccion.comuna_id < 1) {
      mostrarError("comuna_id");
      return false;
    }
    if (direccion.direccion == "") {
      mostrarError("direccion");
      return false;
    }


    if (tdocumento == 2) {
      if (giro == "") {
        mostrarError("giro");
        return false;
      }
    }

    return true;
  };

  const mostrarError = (campo) => {
    setMensajeMayor("SELECCIONE TIPO DE DOCUMENTO");
    document.querySelector("#alerta-" + campo).style.display = "block";
    document.querySelector('#ancla-' + campo).scrollIntoView({
      behavior: 'smooth'
    });
  };

  const mensajeErrorGenerico = (mayor, menor) => {
    setMensajeError(menor);
    setMensajeMayor(mayor);
    handleShowAgregado();
  };

  const mensajeErrorBack = (texto) => {
    setMensajeError(texto);
    setMensajeMayor("PRODUCTO SIN STOCK");
    handleShowAgregado();
  };

  var Fn = {
    validaRut: function (rutCompleto) {
      rutCompleto = rutCompleto.replace("‐", "-");
      if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto))
        return false;
      var tmp = rutCompleto.split('-');
      var digv = tmp[1];
      var rut = tmp[0];
      if (digv == 'K') digv = 'k';

      return (Fn.dv(rut) == digv);
    },
    dv: function (T) {
      var M = 0, S = 1;
      for (; T; T = Math.floor(T / 10))
        S = (S + T % 10 * (9 - M++ % 6)) % 11;
      return S ? S - 1 : 'k';
    }
  }

  /** 
  *  @function
* Validación utilizada para ver cual es el tipo de documento y con esto saber que datos mandar a la petición HTTP
* @param {number} tdocumento numero del tipo de documento ingresado
* @param {string} usuario Id del usuario logueado
* @param {string} direccion Se valida si el envio es 0 o se envia el Id de la direccion ingresado anteriormente
* @param {number} tipo_envio Numero del tipo de envio
* @param {number} tipo_pago Numero del tipo de pago
* @param {number} tipo_documento Numero del tipo de documento
* @param {number} horario_pedido Numero del horario seleccionado para despacho
* @param {number} usar_datos Numero que sirve para saber si el usuario desea usar sus datos ingresados u otros
* @param {string} nombre_otros_datos Nombre y apellido para ser usados en la boleta o factura
* @param {string} rut_otros_datos Rut de la persona
* @param {string} correo_otros_datos Correo de la persona
* @param {string} telefono_otros_datos Telefono de la persona
* @param {string} comuna_otros_datos Id de la comuna
* @param {string} direccion_otros_datos Direccion de la persona
*/
  const handleRegisterCompra = () => {
    if (validaDatosDocumento()) {
      handleShowFoto();
      let tipo_pago = tipoPagoMapping[selectedOption] !== undefined ? tipoPagoMapping[selectedOption] : -1;
      if (tipo_pago == -1) {
        mensajeErrorBack("Seleccione un método de pago");
        return
      }
      if (tdocumento == 1) {
        axios
          .post(process.env.REACT_APP_BACK_URL + `/api/compra/register_compra`, {
            usuario: userAlready ? userAlready : usuarioId,
            direccion: tipoEnvioInteligente === "tienda" ? 1 : direccionId,
            tipo_envio: tipoEnvioInteligente === "tienda" ? 1 : 2,
            tipo_pago: tipo_pago,
            tipo_documento: tdocumento,
            horario_pedido: 3,
            usar_datos: 1,
            nombre_otros_datos: datosUsuario.nombre + " " + datosUsuario.apellido,
            rut_otros_datos: datosUsuario.rut,
            correo_otros_datos: datosUsuario.email,
            telefono_otros_datos: datosUsuario.telefono,
            comuna_otros_datos: direccion.comuna_id,
            direccion_otros_datos: direccion.direccion
          })
          .then((res) => {
            setUserAlready(sessionStorage.getItem("IDusuario"));
            if (res.data.link_mercado_pago != "Error en mercado pago")
              setLinkPago(res.data.link_mercado_pago);
            else
              setMensajeError(res.data.link_mercado_pago)
            dispatch(setDetalleCarro(res.data.detalle_carro));
            dispatch(setTipoEnvio(res.data.tipo_envio));
            dispatch(setNumeroOrden(res.data.numero_pedido));
            if (selectedOption === "transferencias") {
              navigate("/confirmed")
            }
          })
          .catch((err) => {
            if (err.response.data.includes("The userEmail") && err.response.data.includes("is not valid"))
              mensajeErrorGenerico("Error en Flow", "Flow dijo: El correo del usuario, no es un correo válido");
            else
              mensajeErrorGenerico("Error", err.response.data);
            console.log(err);
          });
      }
      else if (tdocumento == 2) {
        axios
          .post(process.env.REACT_APP_BACK_URL + `/api/compra/register_compra`, {
            usuario: userAlready ? userAlready : usuarioId,
            direccion: tipoEnvioInteligente === "tienda" ? 1 : direccionId,
            tipo_envio: tipoEnvioInteligente === "tienda" ? 1 : 2,
            tipo_pago: tipo_pago,
            tipo_documento: tdocumento,
            horario_pedido: 3,
            usar_datos: 1,
            nombre_otros_datos: datosUsuario.nombre,
            rut_otros_datos: datosUsuario.rut,
            correo_otros_datos: datosUsuario.email,
            telefono_otros_datos: datosUsuario.telefono,
            comuna_otros_datos: direccion.comuna_id,
            direccion_otros_datos: direccion.direccion,
            giro: giro
          })
          .then((res) => {
            setUserAlready(sessionStorage.getItem("IDusuario"));
            setLinkPago(res.data.link_mercado_pago);
            dispatch(setDetalleCarro(res.data.detalle_carro));
            dispatch(setTipoEnvio(res.data.tipo_envio));
            dispatch(setNumeroOrden(res.data.numero_pedido));
            if (selectedOption === "transferencias") {
              navigate("/confirmed")
            }
          })
          .catch((err) => {
            if (err.response.data.includes("The userEmail") && err.response.data.includes("is not valid"))
              mensajeErrorGenerico("Error en Flow", "Flow dijo: El correo del usuario, no es un correo válido");
            else
              mensajeErrorGenerico("Error", err.response.data);
            console.log(err);
          });
      }

    }
  };

  useEffect(() => {
    if (linkPago) {
      window.location.href = linkPago;
    }

  }, [linkPago]);


  /** 
  *  @function
* Metodo para cambiar si paga con transferencia o mercado pago
*/
  const cambiaRadio = (valor) => {
    if (valor == 1) {
      document.querySelector("#radio-transferencia").checked = true;
      setSelectedOption("transferencias")
    }
    else if (valor == 2) {
      document.querySelector("#radio-mercado-pago").checked = true;
      setSelectedOption("mercado-pago");
    }
    else if (valor == 3) {
      document.querySelector("#radio-mercado-pago").checked = true;
      setSelectedOption("flow");
    }
  };

  const cambiaDocumento = (vari) => {
    var boleta = document.querySelector("#radio-boleta");
    var factura = document.querySelector("#radio-factura");
    var alerta = document.querySelector("#alerta-boletaFactura");
    if (boleta != null && factura != null) {
      if (vari == 1) {
        boleta.checked = true;
        setTdocumento(1)
        document.querySelector("#alerta-boletaFactura").style.display = "none";
      }
      else if (vari == 2) {
        factura.checked = true;
        setTdocumento(2);
        document.querySelector("#alerta-boletaFactura").style.display = "none";
      }
    }
  };

  /** 
  *  @function
* Metodo para validar si es con factura o boleta 
*/
  const datosDocumento = (vari) => {
    var boleta = document.querySelector("#radio-usar");
    var factura = document.querySelector("#radio-editar");
    if (boleta != null && factura != null) {
      if (vari == 1) {
        boleta.checked = true;
        setUsarDatos(1)
        setDatosUsuario(datosUsuario2);
        setComunas(comunas2);
        setDireccion(direccion2);
      }
      else if (vari == 2) {
        factura.checked = true;
        traeComunas(1);
        setDatosUsuario({
          ...datosUsuario,
          nombre: "",
          apellido: "",
          rut: "",
          telefono: "",
          email: "",
        });
        setDireccion({
          ...direccion,
          region_id: 1,
          comuna_id: 1,
          direccion: ""
        });
        setGiro("");
        setUsarDatos(2);
      }
    }
  };

  /** 
  *  @function
* Metodo para cambiar los datos de los usuarios
*/
  const modDatosUsuario = (e) => {
    setDatosUsuario({ ...datosUsuario, [e.target.name]: e.target.value });
    document.querySelector("#alerta-" + e.target.name).style.display = "none";
  };

  /** 
   *  @function
* Metodo para cambiar la direccion del usuario
*/

  const modDireccionUsuario = (e) => {
    if (e.target.name == "region_id")
      traeComunas(e.target.value);
    setDireccion({ ...direccion, [e.target.name]: e.target.value });
    document.querySelector("#alerta-" + e.target.name).style.display = "none";
  };

  /**
* @function
* Metodo para cambiar si necesita el giro o no
*/

  const cambiaGiro = (e) => {
    setGiro(e.target.value);
    document.querySelector("#alerta-" + e.target.name).style.display = "none";
  };

  return (
    <div>
      <Modal show={showFoto} onHide={handleCloseFoto} size="lg" centered={true}>
        <Modal.Header closeButton className="border-bottom-0">
        </Modal.Header>
        <Modal.Body>

          <div className=" container" >

            <div className="row justify-content-center text-center col-12 mb-4">
              <div className="col-12 text-center align-self-center">
                <label htmlFor="">Generando compra</label>
                <br />
                <img loading="lazy" src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/Modales/cargando.gif"} alt="cargando" title="cargando" className="img-fluid" />
              </div>
            </div>
          </div>

        </Modal.Body>
      </Modal>

      <Modal show={showAgregado} onHide={handleCloseAgregado} centered={true}>
        <Modal.Header closeButton className="border-bottom-0">
        </Modal.Header>
        <Modal.Body>

          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 mb-3 text-center bg-black py-3">
                <i className="fa-solid fa-circle-xmark text-turq fs-1 mb-3 d-block"></i>
                <span className="fs-6 text-white mt-1" id="titulo_error">{mensajeMayor}</span>
              </div>
              <div className="col-lg-10 col-md-10 col-11 mb-2 text-center">
                <p className="fs-14 text-black mb-0" id="texto_error">{mensajeError}</p>
              </div>
              <div className="col-lg-6 col-md-7 col-8 bg-turq" style={{ height: "3px" }}>
              </div>
            </div>
          </div>


        </Modal.Body>
      </Modal>
      {" "}
      <div className="container mt-3 mb-4" id="camino-de-migas">
        <div className="row justify-content-center">

        </div>
      </div>

      <div className="container mb-3" id="div-paso-1">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 px-0 text-center">
            <img
              src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/carrito/paso_3.png"}
              className="img-fluid mx-auto"
              alt="Paso 1 Opciones de envío"
              title="Paso 1 Opciones de envío"
              loading="lazy"
            />
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-8 col-sm-12">
            <div className="row">
              {/* Pantallas XL y XXL */}
              <div className="col-12 mx-5 fw-bold d-none d-xs-none d-sm-none d-md-none d-lg-none d-xl-block d-xxl-block">
                ELIGE LA MODALIDAD DE PAGO:
              </div>

              {/* Pantallas MD Y LG */}
              <div className="col-12 mx-4 fw-bold d-none d-xs-none d-sm-none d-md-block d-lg-block d-xl-none d-xxl-none">
                ELIGE LA MODALIDAD DE PAGO:
              </div>

              {/* Pantallas XS Y SM */}
              <div className="col-12 fw-bold d-block d-xs-block d-sm-block d-md-none d-lg-none d-xl-none d-xxl-none">
                ELIGE LA MODALIDAD DE PAGO:
              </div>

              {/* Transferencias */}
              <div className="col-12 border mt-3 cursor-pointer" onClick={() => { cambiaRadio(1) }}>
                <div className="col-lg-12 col-md-12 col-sm-12 col-11 px-3 mb-2 position-relative">
                  <div className="row align-items-center borde-gris-round py-3 div-check-transf">
                    <div className="col-lg-1 col-md-1 col-sm-2 col-2 text-center">
                      <input
                        id="radio-transferencia"
                        type="radio"
                        value="transferencias"
                        checked={selectedOption === "transferencias"}
                        onChange={handleOptionChange}
                      />
                      <div
                        className="circulo-eleccion-pago mx-auto py-auto"
                        id="circulo-check"
                      ></div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-5 col-5">
                      <img
                        src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/carrito/logo-transferencia.png"}
                        className="img-fluid"
                        alt="transferencia bancaria"
                        title="transferencia bancaria"
                        loading="lazy"
                      />
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-5 col-5">
                      <h3 className="text-turq fs-3 fw-bold mb-0">
                        4% <span className="fs-6">dscto.</span>
                      </h3>
                      <p className="fs-12 text-secondary mb-0">
                        Sólo pagando con transferencia
                      </p>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 col-12 text-center py-2">
                      <p className="fs-14 text-turq mb-0 fw-semibold">
                        Precio de compra:{" "}
                        <span className="text-dark">
                          {totalConEnvioDesc.toLocaleString("es-CL", {
                            style: "currency",
                            currency: "CLP",
                          })}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {selectedOption === "transferencias" && (
                <>
                  <div className="borde-gris borde-round-20 mt-3">
                    <div className="container   pt-3 ">
                      <div className="row">
                        <div className="col-12 mb-3">
                          <h4>
                            Nuestros datos bancarios para realizar el pago vía
                            transferencia
                          </h4>
                          <strong className="text-black fs-6">
                            Nuestros detalles bancarios
                          </strong>
                          <br />
                          <span className="fs-14 text-black">
                            Dreamtec HOLDING SPA
                          </span>
                        </div>
                        <div className="col-lg-3 col-md-3 col-10 mb-2">
                          <span className="text-black fs-14">BANCO:</span>
                          <br />
                          <span className="fs-14 text-black">
                            SCOTIABANK SUD AMERICANO
                          </span>
                        </div>
                        <div className="col-lg-3 col-md-3 col-10 mb-2 margin-l">
                          <span className="text-black fs-14">RUT:</span>
                          <br />
                          <span className="fs-14 text-black">76.905.656-4</span>
                        </div>
                        <div className="col-lg-3 col-md-3 col-10 mb-2 margin-l">
                          <span className="text-black fs-14">
                            NÚMERO DE CUENTA:
                          </span>
                          <br />
                          <span className="fs-14 text-black">980532240</span>
                        </div>
                        <div className="col-lg-3 col-md-3 col-10 mb-2 margin-l">
                          <span className="text-black fs-14">TIPO CUENTA:</span>
                          <br />
                          <span className="fs-14 text-black">CORRIENTE</span>
                        </div>
                      </div>
                    </div>

                    <div
                      className="col-12 alert alert-danger pt-2"
                      style={{ color: "red" }}
                    >
                      <p className="fs-14 mb-0" style={{ color: "red" }}>
                        <i
                          style={{ color: "red" }}
                          className="fas fa-exclamation-circle pe-1"
                        ></i>
                        <strong style={{ color: "red" }}>Importante:</strong>{" "}
                        una vez realizada la transferencia o depósito bancario,
                        se emitirá un comprobante de pago, el cual deberás
                        guardar y enviar por mail a ventasweb@dreamtec.cl con tu
                        número de orden para procesar tu compra{" "}
                        <strong style={{ color: "red" }}>
                          (Tu número de orden se mostrar al finalizar la compra)
                        </strong>
                      </p>
                    </div>
                  </div>
                  <div className="container">
                    <div className="row text-end">
                      <div className="col-12 text-end d-none">
                        <span type="button">¿Necesitas factura?</span>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/* Mercado Pago */}
              <div className="border col-12 mt-2 cursor-pointer" onClick={() => { cambiaRadio(2) }}>
                <div className="col-lg-12 col-md-12 col-sm-12 col-11 px-3 mb-2 position-relative">
                  <div className="row align-items-center borde-gris-round py-3">
                    <div className="col-lg-1 col-md-1 col-sm-2 col-2 text-center">
                      <input
                        id="radio-mercado-pago"
                        type="radio"
                        value="mercado-pago"
                        checked={selectedOption === "mercado-pago"}
                        onChange={handleOptionChange}
                      />
                      <div
                        className="circulo-eleccion-pago mx-auto py-auto"
                        id="circulo-check"
                      ></div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-5 col-5">
                      <img
                        src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/carrito/logo-mercadopago.png"}
                        className="img-fluid"
                        alt="mercado pago"
                        title="mercado pago"
                        loading="lazy"
                      />
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-5 col-5 pe-5">

                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 col-12 text-center py-2">
                      <p className="fs-14 text-turq mb-0 fw-semibold">
                        Precio de compra:{" "}
                        <span className="text-dark">
                          {totalConEnvio.toLocaleString("es-CL", {
                            style: "currency",
                            currency: "CLP",
                          })}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Flow */}
              <div className="border col-12 mt-2 mb-5 cursor-pointer" onClick={() => { cambiaRadio(3) }}>
                <div className="col-lg-12 col-md-12 col-sm-12 col-11 px-3 mb-1 position-relative">
                  <div className="row align-items-center borde-gris-round py-3">
                    <div className="col-lg-1 col-md-1 col-sm-2 col-2 text-center">
                      <input
                        id="radio-flow"
                        type="radio"
                        value="flow"
                        checked={selectedOption === "flow"}
                        onChange={handleOptionChange}
                      />
                      <div
                        className="circulo-eleccion-pago mx-auto py-auto"
                        id="circulo-check"
                      ></div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-5 col-5">
                      <img
                        src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/carrito/flow-pagos.webp"}
                        className="img-fluid"
                        alt="flow"
                        title="flow"
                        loading="eager"
                        style={{ maxHeight: "63px" }}
                      />
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-5 col-5 pe-5">
                      <img
                        src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/carrito/logo-webpay-negro.png"}
                        className="img-fluid"
                        alt="Webpay"
                        title="Webpay"
                        loading="lazy"
                      />
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 col-12 text-center py-2">
                      <p className="fs-14 text-turq mb-0 fw-semibold">
                        Precio de compra:{" "}
                        <span className="text-dark">
                          {totalConEnvio.toLocaleString("es-CL", {
                            style: "currency",
                            currency: "CLP",
                          })}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {selectedOption ?
                <div className="border col-12 p-3 mb-5">
                  <h5 className="mb-4">Boleta o factura</h5>
                  <div className="border col-12 p-2 mb-5">
                    <h6>Elegir tipo de documento:</h6>
                    <span id="alerta-boletaFactura" style={{ display: "none" }} className="campo-alerta">*Seleccione boleta o factura</span>
                    <br />
                    <div style={{ float: "left" }} className="cursor-pointer mb-5" onClick={() => cambiaDocumento(1)}>
                      <input type="radio" id="radio-boleta" checked={tdocumento == 1} className="cursor-pointer" /> <span className="me-5" style={{ color: "#00bfd6", fontSize: "20px", fontWeight: "bold" }}><i className="fa-solid fa-receipt text-success ms-2 me-1"></i>Boleta</span>
                    </div>
                    <div className="cursor-pointer" onClick={() => cambiaDocumento(2)}>
                      <input type="radio" id="radio-factura" checked={tdocumento == 2} className="cursor-pointer" /> <span style={{ color: "#00bfd6", fontSize: "20px", fontWeight: "bold" }}><i className="fa-solid fa-file-invoice-dollar text-primary ms-2 me-1"></i>Factura</span>
                    </div>
                  </div>
                  <a id="ancla-boleta-factura"></a>
                  <div className="col-12 pe-5">
                    {tdocumento ?
                      <>
                        <h6 className="mb-3">Datos para boleta o factura</h6>
                        <div className="mb-2">
                          <span style={{ fontWeight: "bold", fontSize: "14px" }}>¿Desea usar estos datos para la boleta o factura?</span>
                        </div>
                        <div style={{ float: "left" }} className="cursor-pointer mb-3 col-12" onClick={() => datosDocumento(1)}>
                          <input type="radio" id="radio-usar" checked={usarDatos == 1} className="cursor-pointer" /> <span className="me-5" style={{ color: "#00bfd6", fontSize: "16px" }}>Usar Datos Registrados</span>
                        </div>
                        <div className="cursor-pointer col-12" onClick={() => datosDocumento(2)}>
                          <input type="radio" id="radio-editar" checked={usarDatos == 2} className="cursor-pointer" /> <span style={{ color: "#00bfd6", fontSize: "16px" }}>Ingresar Nuevos Datos</span>
                        </div>
                        <br />
                        <a id="ancla-nombre"></a>
                        <br />
                        <span style={{ fontWeight: "bold", fontSize: "14px" }}>{tdocumento == 1 ? "Nombre" : "Razón Social"}</span>
                        <span id="alerta-nombre" style={{ display: "none" }} className="campo-alerta">*Debe completar este campo</span>
                        <br />
                        <input type="text" name="nombre" id="nombre" className="form-control" onChange={modDatosUsuario} value={(datosUsuario.apellido && datosUsuario.apellido != ".zzzzz.") ? datosUsuario.nombre + " " + datosUsuario.apellido : datosUsuario.nombre} disabled={usarDatos == 1 ? "disabled" : null} /><br />
                        {/* {tdocumento == 1 ? 
                    <>
                    <a id="ancla-apellido"></a>
                    <span style={{fontWeight:"bold", fontSize: "14px"}}>Apellido </span>
                    <span id="alerta-apellido" style={{display:"none"}} className="campo-alerta">*Debe completar este campo</span>
                    <br />
                    <input type="text" name="apellido" id="apellido" className="form-control" onChange={modDatosUsuario} value={datosUsuario.apellido} disabled={usarDatos==1 ? "disabled" : null} />
                    <br />
                    </>
                    : null } */}
                        <a id="ancla-rut"></a>
                        <span style={{ fontWeight: "bold", fontSize: "14px" }}>Rut</span><span style={{ fontSize: "14px" }}> (Sin punto y con guíon)</span>
                        <span id="alerta-rut" style={{ display: "none" }} className="campo-alerta">*Debe completar este campo</span>
                        <br />
                        <input type="text" name="rut" id="rut" className="form-control" onChange={modDatosUsuario} value={datosUsuario.rut} disabled={usarDatos == 1 ? "disabled" : null} placeholder="Ej: 11111111-1" />
                        <br />
                        <a id="ancla-email"></a>
                        <span style={{ fontWeight: "bold", fontSize: "14px" }}>Correo</span>
                        <span id="alerta-email" style={{ display: "none" }} className="campo-alerta">*Debe completar este campo</span>
                        <br />
                        <input type="text" name="email" id="email" className="form-control" onChange={modDatosUsuario} value={datosUsuario.email} disabled={usarDatos == 1 ? "disabled" : null} /><br />
                        <a id="ancla-telefono"></a>
                        <span style={{ fontWeight: "bold", fontSize: "14px" }}>Teléfono</span><span style={{ fontSize: "14px" }}> (+569XXXXXXXX)</span>
                        <span id="alerta-telefono" style={{ display: "none" }} className="campo-alerta">*Debe completar este campo</span>
                        <br />
                        <input type="text" name="telefono" id="telefono" className="form-control" onChange={modDatosUsuario} value={datosUsuario.telefono} disabled={usarDatos == 1 ? "disabled" : null} placeholder="Ej: +569XXXXXXXX" /><br />
                        <a id="ancla-region_id"></a>
                        <span style={{ fontWeight: "bold", fontSize: "14px" }}>Región</span>
                        <span id="alerta-region_id" style={{ display: "none" }} className="campo-alerta">*Debe completar este campo</span>
                        <br />
                        <select name="region_id" id="region_id" value={direccion.region_id} onChange={modDireccionUsuario} className="form-select rounded-pill" disabled={usarDatos == 1 ? "disabled" : null}>
                          {regiones.map((region) => (
                            <option value={region.id} key={region.id}>{region.nombre_region}</option>
                          ))}
                        </select><br />
                        <a id="ancla-comuna_id"></a>
                        <span style={{ fontWeight: "bold", fontSize: "14px" }}>Comuna</span>
                        <span id="alerta-comuna_id" style={{ display: "none" }} className="campo-alerta">*Debe completar este campo</span>
                        <br />
                        <select name="comuna_id" id="comuna_id" value={direccion.comuna_id} onChange={modDireccionUsuario} className="form-select rounded-pill" disabled={usarDatos == 1 ? "disabled" : null}>
                          {comunas.map((comuna) => (
                            <option value={comuna.id} key={comuna.id}>{comuna.nombre_comuna}</option>
                          ))}
                        </select><br />
                        <a id="ancla-direccion"></a>
                        <span style={{ fontWeight: "bold", fontSize: "14px" }}>Direccion</span>
                        <span id="alerta-direccion" style={{ display: "none" }} className="campo-alerta">*Debe completar este campo</span>
                        <br />
                        <input type="text" name="direccion" id="direccion" value={direccion.direccion} onChange={modDireccionUsuario} className="form-control" disabled={usarDatos == 1 ? "disabled" : null} /><br />
                        {tdocumento == 2 ?
                          <>
                            <a id="ancla-giro"></a>
                            <span style={{ fontWeight: "bold", fontSize: "14px" }}>Giro</span>
                            <span id="alerta-giro" style={{ display: "none" }} className="campo-alerta">*Debe completar este campo</span>
                            <br />
                            <input type="text" name="giro" id="giro" className="form-control mb-3" value={giro} onChange={cambiaGiro} />
                          </>
                          : null}
                      </>
                      : null}

                  </div>
                </div>
                : null}

            </div>
          </div>
          <div className="col-md-4 col-sm-12 text-start">
            <div className="border">
              <div className="container">
                <div className="row mt-5 justify-content-start">
                  <div className="col-lg-8 col-md-8 col-sm-7 col-8">
                    <h2 className="fs-6 text-black fw-bolder">
                      Resumen de la compra
                    </h2>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-5 col-3 align-self-center">
                    <img
                      loading="lazy"
                      src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/carrito/logo-header-negro.png"}
                      className="img-fluid"
                      alt="Logo Dreamtec"
                      title="Logo Dreamtec"
                    />
                  </div>
                  <div className="col-12">
                    <hr />
                  </div>
                </div>

                {tipoEnvioInteligente == "tienda" ?
                  <>
                    <div className="row justify-content-start mb-2">
                      <div className="col-lg-5 col-md-5 col-5">
                        <span className="text-black fs-14">
                          <span>&nbsp;</span>
                        </span>
                      </div>
                      <div className="col-lg-6 col-md-6 col-6 text-end text-black fs-14 fw-bold">
                        &nbsp;
                      </div>
                    </div>
                  </>
                  :
                  <>
                    <div className="row justify-content-start mb-2">
                      <div className="col-lg-5 col-md-5 col-5">
                        <span className="text-black fs-14">
                          <span>Envío: </span>
                        </span>
                      </div>
                      <div className="col-lg-6 col-md-6 col-6 text-end text-black fs-14 fw-bold">
                        {envio == 0 ? "Gratis" :
                          <>
                            {envio.toLocaleString("es-CL", {
                              style: "currency",
                              currency: "CLP",
                            })}
                          </>
                        }
                      </div>
                    </div>
                  </>
                }

                <div className="row justify-content-start mb-2">
                  <div className="col-lg-5 col-md-5 col-5">
                    <span className="text-black fs-14">Producto:</span>
                  </div>
                  <div className="col-lg-6 col-md-6 col-6 text-end">
                    <span className="text-black fs-14 fw-bold">
                      {selectedOption === "transferencias"
                        ? totalDesc.toLocaleString("es-CL", {
                          style: "currency",
                          currency: "CLP",
                        })
                        : total.toLocaleString("es-CL", {
                          style: "currency",
                          currency: "CLP",
                        })}
                    </span>
                  </div>
                </div>
                <div className="row justify-content-start mb-2">
                  <div className="col-lg-5 col-md-5 col-5">
                    <span className="text-black fs-14">IVA incluído:</span>
                  </div>
                  <div className="col-lg-6 col-md-6 col-6 text-end">
                    <span className="text-black fs-14 fw-bold">
                      {selectedOption === "transferencias"
                        ? ivaDesc.toLocaleString("es-CL", {
                          style: "currency",
                          currency: "CLP",
                        })
                        : iva.toLocaleString("es-CL", {
                          style: "currency",
                          currency: "CLP",
                        })}
                    </span>
                  </div>
                </div>
                <div className="row justify-content-start">
                  <div
                    className="col-11 bg-gris-c mb-4"
                    style={{ height: "1px" }}
                  ></div>
                </div>
                <div className="row justify-content-start">
                  <div className="col-lg-5 col-md-5 col-5">
                    <span className="text-black fs-14">Total:</span>
                  </div>
                  <div className="col-lg-6 col-md-6 col-6 text-end">
                    <span className="text-black fs-14 fw-bold">
                      {selectedOption === "transferencias"
                        ? totalConEnvioDesc.toLocaleString("es-CL", {
                          style: "currency",
                          currency: "CLP",
                        })
                        : totalConEnvio.toLocaleString("es-CL", {
                          style: "currency",
                          currency: "CLP",
                        })}
                    </span>
                  </div>
                </div>

                {tipoEnvioInteligente == "tienda" ?
                  <>
                    <div className="row justify-content-center mt-5 mb-5">
                      <div className="col-lg-12 col-md-12 col-12 text-center">
                        <span className="text-black fs-14 fw-bold">
                          Retiro en Tienda
                        </span>
                      </div>
                    </div>
                  </>
                  :
                  <>
                    <div className="row justify-content-center mt-5 mb-5">
                      <div className="col-lg-12 col-md-12 col-12 text-center">
                        <span className="text-black fs-14 fw-bold">
                          {fechaEntrega ? fechaEntrega : ""}
                        </span>
                      </div>
                    </div>
                  </>
                }

              </div>
            </div>

            <div className="row mt-3">
              <div className="col-6">
                <button className="btn w-100 bg-secondary mb-3 py-2 text-white rounded-pill" onClick={() => navigate("/paso2")}>Volver atrás</button>
              </div>
              <div className="col-6">
                <button className="btn w-100 bg-turq btn-turq-hover py-2 text-white rounded-pill" disabled={!selectedOption} onClick={() => handleRegisterCompra()}>
                  Continuar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Paso3;
