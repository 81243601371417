
import { createSlice } from '@reduxjs/toolkit';

const initialState = "";

const fechaEntregaSlice = createSlice({
  name: 'fechaEntrega',
  initialState,
  reducers: {
    setFechaEntrega: (state, action) => action.payload,
  },
});

export const { setFechaEntrega } = fechaEntregaSlice.actions;
export default fechaEntregaSlice.reducer;
